import React from 'react';

import MedflowLogoLoading from '@/components/Logo/MedflowLogoLoading';

import colors from '@/design/colors';
import TextLoop from 'react-text-loop';
import LoadingSwitchTransition from '../LoadingSwitchTransition';
import { MessageWrapper, PreloaderWrapper } from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
  loading: boolean;
  done?: boolean;
  onLoadingDoneAnimationEnd?: () => void;
  message?: string;
}

const messages = [
  'Estamos preparando o protocolo para você.',
  'Solicite medicamentos e exames de forma rápida e inteligente.',
  'Nossos conteúdos são criados de médicos para médicos.',
];

const Preloader: React.FC<Props> = ({
  children,
  loading,
  style,
  done = false,
  className,
  message,
  onLoadingDoneAnimationEnd,
}) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);

  return (
    <LoadingSwitchTransition
      loading={loading}
      style={style}
      className={className}
      LoadingComponent={
        <PreloaderWrapper ref={nodeRef}>
          <MedflowLogoLoading
            done={done}
            style={{
              zIndex: 10,
              padding: 14,
              backgroundColor: colors.gray50,
            }}
            onLoadingDoneAnimationEnd={onLoadingDoneAnimationEnd}
          />
          {message ? (
            <MessageWrapper>{message}</MessageWrapper>
          ) : (
            <TextLoop
              fade
              noWrap={false}
              interval={4000}
              springConfig={{ stiffness: 30, damping: 8 }}
              className="animated-text"
            >
              {messages.map(item => (
                <MessageWrapper>{item}</MessageWrapper>
              ))}
            </TextLoop>
          )}
        </PreloaderWrapper>
      }
    >
      {children}
    </LoadingSwitchTransition>
  );
};

export default Preloader;
