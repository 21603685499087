/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { codeableConceptDisplay } from '@/features/fhir/helpers/fhirpath';
import {
  ArtifactFormLayout,
  ArtifactFormTitle,
} from '@/features/artifacts/components/ArtifactForm/styles';

import FormExame, { FormExameProps } from '../FormExame';

type Props = FormExameProps;

const AddCondutaExame: React.FC<Props> = ({ ...formProps }) => {
  const title = React.useMemo(
    () => codeableConceptDisplay(formProps.resource, 'code'),
    [formProps.resource],
  );

  return (
    <ArtifactFormLayout>
      <ArtifactFormTitle>{title}</ArtifactFormTitle>
      <FormExame {...formProps} />
    </ArtifactFormLayout>
  );
};

export default AddCondutaExame;
