import styled from 'styled-components';

import FormGroup from '@/components/FormGroup';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
`;

export const Form = styled.form`
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  button {
    min-width: 459px;
    margin-top: 103px;
    align-self: center;
  }
`;

export const EmailInput = styled(FormGroup).attrs({
  id: 'email',
  name: 'email',
  label: 'E-mail cadastrado',
})``;
