/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';

import ReactModal from 'react-modal';
import ModalContext from './ModalContext';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalTitle from './ModalTitle';

import './styles.css';

export interface ModalProps extends Omit<ReactModal.Props, 'closeTimeoutMS'> {
  children: React.ReactNode;
}

type ModalType = React.FC<ModalProps> & {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Title: typeof ModalTitle;
};

const Modal: ModalType = ({
  onAfterClose = () => {},
  onAfterOpen,
  children,
  className,
  overlayClassName,
  ...modalProps
}: ModalProps) => {
  const [wasOpen, setWasOpen] = React.useState(false);

  const modalContext = React.useMemo(
    () => ({
      onClose: onAfterClose,
    }),
    [onAfterClose],
  );

  function afterOpenModal() {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);

    if (onAfterOpen) {
      onAfterOpen();
    }
  }

  function afterClose() {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);

    if (onAfterClose) {
      onAfterClose();
    }
  }

  return (
    <ReactModal
      closeTimeoutMS={300}
      className={{
        base: classNames('Modal__Content', className as string | undefined),
        afterOpen: wasOpen ? 'Modal__Content--after-open' : '',
        beforeClose: 'Modal__Content--before-close',
      }}
      onRequestClose={() => afterClose()}
      shouldCloseOnOverlayClick
      overlayClassName={{
        base: classNames(
          'Modal__Overlay',
          overlayClassName as string | undefined,
        ),
        afterOpen: wasOpen ? 'Modal__Overlay--after-open' : '',
        beforeClose: 'Modal__Overlay--before-close',
      }}
      onAfterOpen={() => afterOpenModal()}
      onAfterClose={() => afterClose()}
      {...modalProps}
    >
      <ModalContext.Provider value={modalContext}>
        {children}
      </ModalContext.Provider>
    </ReactModal>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Title = ModalTitle;

export default Modal;
