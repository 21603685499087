import { FormLoadingState } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';

export type TipoItemComplementar =
  | 'solicitacao-exame'
  | 'procedimento'
  | 'prescricao-medica'
  | 'encaminhamento'
  | 'orientacao'
  | 'manipulado';

export const TipoItemComplementarList: TipoItemComplementar[] = [
  'solicitacao-exame',
  'procedimento',
  'prescricao-medica',
  'encaminhamento',
  'orientacao',
];

export type ItemUIDisplayMode = 'item' | 'form';

export interface ItemUIState {
  displayMode: ItemUIDisplayMode;
  loading: FormLoadingState;
}

export interface ItemComplementarUI {
  key: string;
  title: string;
  description?: string;
  resource: fhir4.FhirResource;
}

export interface ItemComplementarUIState extends ItemComplementarUI {
  itemState?: Partial<ItemUIState>;
}

export interface ItemStatus {
  isEditing: boolean; // O usuário está editando o item (formulário aberto)
  isSubmitting: boolean; // O processo de edição do item foi submetido pelo usuário e está sendo executado
  isCanceling: boolean; // O processo de cancelamento do item submetido pelo usuário e está sendo executado
  isAdding: boolean; // O item está sendo adicionado, não foi salvo ainda
  reference: string; // Referência ao antigo ID local do item, caso seja um item recém-salvo
}

export interface ItemComplementarState {
  id: string;
  type: TipoItemComplementar;
  resource: fhir4.FhirResource;
  itemState?: Partial<ItemStatus>;
}

export type AddingItemComplementarState = Omit<ItemComplementarState, 'id'>;
export type RetrievingItemState = Pick<ItemComplementarState, 'id'>;

export interface ItemTerapiaComplementarAPI {
  id: number;
  artefato_id: string;
  titulo: string;
  tipo: TipoItemComplementar;
  recurso: fhir4.FhirResource;
}
