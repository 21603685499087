import { startOfDay } from 'date-fns';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import CalendarMonthly from './CalendarMonthly';
import CalendarYearly from './CalendarYearly';
import { CalendarBox, CalendarContentContainer } from './styles';
import { CalendarPeriod } from './types';

interface Props {
  value?: Date;
  onChange?: (date: Date) => void;
  style?: React.CSSProperties;
  className?: string;
}

const Calendar: React.FC<Props> = ({ style, className, onChange, value }) => {
  const [period, setPeriod] = React.useState<CalendarPeriod>('monthly');
  const [currentValue, setCurrentValue] = React.useState<Date>(
    startOfDay(value || new Date()),
  );

  const handleChange = React.useCallback(
    (date: Date) => {
      if (onChange) {
        onChange(date);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    // Force initial state (monthly) when value changed outside calendar
    setPeriod('monthly');
    setCurrentValue(startOfDay(value || new Date()));
  }, [value]);

  return (
    <CalendarBox style={style} className={className}>
      <SwitchTransition>
        <CSSTransition
          unmountOnExit
          key={period}
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <CalendarContentContainer>
            {period === 'monthly' ? (
              <CalendarMonthly
                value={value}
                period={currentValue}
                onPeriodChange={month => {
                  setPeriod('yearly');
                  setCurrentValue(month);
                }}
                onChange={handleChange}
              />
            ) : (
              <CalendarYearly
                value={currentValue}
                onChange={date => {
                  setPeriod('monthly');
                  setCurrentValue(date);
                }}
              />
            )}
          </CalendarContentContainer>
        </CSSTransition>
      </SwitchTransition>
    </CalendarBox>
  );
};

export default Calendar;
