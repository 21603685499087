import React from 'react';
import styled from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';
import colors from '../../styles/colors';

const Container = styled.div`
  background-color: ${colors.errorLight};
  border-radius: 4px;
  padding: 8px 5px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  span {
    color: ${colors.errorText};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const ErrorMessage: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <FiAlertCircle size={40} color={colors.errorText} />

      <span>{children}</span>
    </Container>
  );
};

export default ErrorMessage;
