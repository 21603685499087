import styled from 'styled-components';

export const CarouselContent = styled.div`
  position: relative;

  .react-horizontal-scrolling-menu--scroll-container {
    overflow: hidden;
    position: unset;
  }
`;
