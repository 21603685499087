import colors from '@/design/colors';
import { HeadingLarge, HeadingSmall, Text } from '@/design/typography';
import styled from 'styled-components';

export const AlertBox = styled.div`
  margin: 0 16px;
  border-radius: 8px;
  padding: 16px;
  background-color: ${colors.purple50};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
`;

export const AlertBoxInfo = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    ${HeadingSmall(18)};
    margin-bottom: 8px;
  }

  h3 {
    ${HeadingSmall(14)};
    margin-top: 16px;
    margin-bottom: 8px;
  }

  p {
    ${Text(14)};

    strong {
      ${HeadingLarge(14)};
    }
  }
`;

export const AlertBoxActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;

  span {
    ${Text(14)};
    flex: 1%;
  }
`;

export const AlertBoxResume = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  ${Text(14)};
  color: ${colors.blackAlpha100};
`;
