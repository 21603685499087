import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import FlatList from '@/components/FlatList';
import { getNextAPIResultPage } from '@/utils/query';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import {
  SpinnerInfinite,
  SpinnerWindow,
} from '@/components/Spinner/variations';

import { naturalTime } from '@/utils/datetime';
import ProtocoloExecutadoAPI from '../../services/ProtocoloExecutadoAPI';
import InputLinkProtocoloExecutado from '../InputLinkProtocoloExecutado';

const PageContentProtocoloExecutadoList: React.FC = () => {
  const {
    data,
    isLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['protocolo-executado-list'],
    ({ pageParam: page }) =>
      ProtocoloExecutadoAPI.list({ page, page_size: 25, executado: false }),
    {
      getNextPageParam: getNextAPIResultPage,
    },
  );

  const protocolos = React.useMemo(() => {
    if (data) {
      return data.pages.map(group => group.results).flat();
    }

    return [];
  }, [data]);

  const handleMore = React.useCallback(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching]);

  const renderFooter = React.useCallback(() => {
    if (isFetchingNextPage) {
      return <SpinnerInfinite />;
    }

    return null;
  }, [isFetchingNextPage]);

  return (
    <LoadingSwitchTransition
      loading={isLoading}
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      LoadingComponent={<SpinnerWindow>Buscando resultados</SpinnerWindow>}
    >
      <FlatList
        data={protocolos}
        keyExtractor={({ item: { id } }) => id}
        renderItem={({ item }) => (
          <InputLinkProtocoloExecutado
            helperInfo={naturalTime(item.data_criacao, 'Iniciado')}
            protocolo={item}
          />
        )}
        onEndReached={handleMore}
        hasMore={hasNextPage}
        ListFooterComponent={renderFooter}
      />
    </LoadingSwitchTransition>
  );
};

export default PageContentProtocoloExecutadoList;
