import React, { useEffect, useState } from 'react';

import spinner from '@/assets/spinner.svg';
import OptionIntegracao from '@/types/OptionIntegracao';

import { Medicamento } from '@/models/Medicamento';
import { Procedimento } from '@/models/Procedimento';

import BoxEncaminhamento from '@/features/recursos/encaminhamentos/components/BoxEncaminhamento';
import BoxExame from '@/features/recursos/exames/components/BoxExame';
import BoxFotoceutico from '@/features/recursos/fotoceutico/components/BoxFotoceutico';
import BoxMedicamento from '@/features/recursos/medicamentos/components/BoxMedicamento';
import BoxOrientacao from '@/features/recursos/orientacoes/components/BoxOrientacao';

import BoxEncaminhamentoEditavel from '@/features/recursos/encaminhamentos/components/BoxEncaminhamentoEditavel';
import BoxFotoceuticoEditavel from '@/features/recursos/fotoceutico/components/BoxFotoceuticoEditavel';
import BoxMedicamentoEditavel from '@/features/recursos/medicamentos/components/BoxMedicamentoEditavel';
import BoxOrientacaoEditavel from '@/features/recursos/orientacoes/components/BoxOrientacaoEditavel';

import BoxEncaminhamentoOld from '@/components/BoxEncaminhamento';
import BoxExameOld from '@/components/BoxExame';
import BoxMedicamentoOld from '@/components/BoxMedicamento';

import { Checkbox } from '@/components/SelectionControl';
import { useFeatures } from '@/features/features/providers/FeaturesProvider';
import BoxExameEditavel from '@/features/recursos/exames/components/BoxExameEditavel';
import BoxProcedimento from '@/features/recursos/procedimentos/components/BoxProcedimento';
import BoxProcedimentoEditavel from '@/features/recursos/procedimentos/components/BoxProcedimentoEditavel';
import {
  ButtonTitle,
  CheckboxDetailContainer,
  CheckboxDetailItem,
  CheckboxItemContent,
  SpinnerContainer,
} from './styles';

interface OptionCheckboxItemProps {
  type:
    | 'Procedimento'
    | 'Exame'
    | 'Medicamento'
    | 'Encaminhamento'
    | 'Orientacao'
    | 'Fotoceutico';
  option: OptionIntegracao;
  selected: boolean;
  disabled: boolean;
  included: boolean;
  loading: boolean;
  error: boolean;
  editavel?: boolean;
  opened?: boolean;
  handleClick: (option: OptionIntegracao) => void;
}

const OptionCheckboxItem: React.FC<OptionCheckboxItemProps> = ({
  type,
  option,
  selected,
  disabled,
  included,
  loading,
  error,
  editavel,
  opened,
  handleClick,
}: OptionCheckboxItemProps) => {
  const [optionLoading, setOptionLoading] = useState(false);
  const [selectedFromEdit, setSelectedFromEdit] = useState(false);

  const { hasFeature } = useFeatures();

  const showMedicamentoEditavel = React.useMemo(
    () => hasFeature('MEDICAMENTO_EDITAVEL'),
    [hasFeature],
  );

  const showEncaminhamentoEditavel = React.useMemo(
    () => hasFeature('ENCAMINHAMENTO_EDITAVEL'),
    [hasFeature],
  );

  const showOrientacaoEditavel = React.useMemo(
    () => hasFeature('ORIENTACAO_EDITAVEL'),
    [hasFeature],
  );

  // Exames incluídos via conduta complementar são editáveis
  const showExameEditavel = React.useMemo(
    () => included || hasFeature('EXAME_EDITAVEL'),
    [hasFeature, included],
  );

  const showProcedimentoEditavel = React.useMemo(
    () => included || hasFeature('PROCEDIMENTO_EDITAVEL'),
    [hasFeature, included],
  );

  const renderItemBox = (typeItem: string) => {
    if (option.customContent) {
      return {
        Exame: (
          <BoxExameOld
            exame={option.customContent as Procedimento}
            disableCopy
          />
        ),
        Encaminhamento: (
          <BoxEncaminhamentoOld
            encaminhamento={option.customContent as Procedimento}
          />
        ),
        Medicamento: (
          <BoxMedicamentoOld
            medicamento={option.customContent as Medicamento}
            disableCopy
          />
        ),
      }[typeItem];
    }

    return {
      Procedimento:
        editavel || showProcedimentoEditavel ? (
          <BoxProcedimentoEditavel
            procedimento={option.pedido}
            detailsOpened={opened}
            disabled={disabled}
            onBeforeEdit={() => {
              // Força seleção de item, ao abrir p/ edição, item ainda
              // não selecionado
              if (!selected) {
                handleClick(option);
                setSelectedFromEdit(true);
              }
            }}
            onAfterEdit={() => {
              // Reseta seleção via edição
              setSelectedFromEdit(false);
            }}
            onAfterEditCancel={() => {
              // Se o item foi selecionado via abertura do modo edição, ao
              // cancelar edição, o item deve ser desselecionado
              if (selected && selectedFromEdit) {
                handleClick(option);
              }

              // Reseta seleção via edição
              setSelectedFromEdit(false);
            }}
          />
        ) : (
          <BoxProcedimento procedimento={option.pedido} disableCopy />
        ),
      Exame:
        editavel || showExameEditavel ? (
          <BoxExameEditavel
            exame={option.pedido}
            detailsOpened={opened}
            disabled={disabled}
          />
        ) : (
          <BoxExame exame={option.pedido} disableCopy />
        ),
      Fotoceutico:
        editavel || showMedicamentoEditavel ? (
          <BoxFotoceuticoEditavel
            disableCopy
            fotoceutico={option.pedido}
            selected={selected}
            disabled={disabled}
          />
        ) : (
          <BoxFotoceutico fotoceutico={option.pedido} disableCopy />
        ),
      Encaminhamento:
        editavel || showEncaminhamentoEditavel ? (
          <BoxEncaminhamentoEditavel
            encaminhamento={option.pedido}
            selected={selected}
            disabled={disabled}
          />
        ) : (
          <BoxEncaminhamento encaminhamento={option.pedido} />
        ),
      Medicamento:
        editavel || showMedicamentoEditavel ? (
          <BoxMedicamentoEditavel
            disableCopy
            medicamento={option.pedido}
            selected={selected}
            disabled={disabled}
          />
        ) : (
          <BoxMedicamento medicamento={option.pedido} disableCopy />
        ),
      Orientacao:
        editavel || showOrientacaoEditavel ? (
          <BoxOrientacaoEditavel
            orientacao={option.pedido}
            selected={selected}
            disabled={disabled}
          />
        ) : (
          <BoxOrientacao orientacao={option.pedido} />
        ),
    }[typeItem];
  };

  useEffect(() => {
    setOptionLoading(false);
  }, [option]);

  return (
    <CheckboxDetailContainer>
      <CheckboxDetailItem
        status={option.status}
        selected={selected}
        disabled={disabled}
        included={included}
        error={error}
      >
        <ButtonTitle>
          <CheckboxItemContent>
            {loading || optionLoading ? (
              <SpinnerContainer>
                <img src={spinner} alt="Carregando..." />
              </SpinnerContainer>
            ) : (
              <Checkbox
                style={{
                  marginTop: '8px',
                }}
                state={selected ? 'marked' : 'unmarked'}
                disabled={disabled}
                size="small"
                onClick={() => {
                  handleClick(option);
                }}
              />
            )}
          </CheckboxItemContent>
          {renderItemBox(type)}
        </ButtonTitle>
      </CheckboxDetailItem>
    </CheckboxDetailContainer>
  );
};

export default OptionCheckboxItem;
