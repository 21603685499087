import styled from 'styled-components';

import colors from '@/styles/colors';

export const Container = styled.div`
  width: 100%;
  background: ${colors.white};
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

  margin: 16px 0;

  position: sticky;
  top: 120px;
`;

export const Header = styled.div`
  background-color: ${colors.primaryTint};
  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const HeaderIcon = styled.img`
  display: inline-block;
  margin-bottom: -5px;
  margin-top: 5px;
`;

export const Body = styled.div`
  padding: 15px 25px;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 15px 25px;

  display: flex;
  justify-content: space-between;

  @media (max-width: 1320px) {
    flex-direction: column;

    button:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
