import React from 'react';

import SelectionControlAlternatives, {
  SelectionControlAlternativesProps,
} from '../SelectionControlAlternatives';
import { SelectionControlOption } from '../SelectionControlInput';

export interface Props<TValue>
  extends Omit<
    SelectionControlAlternativesProps<TValue>,
    'selectionType' | 'selected'
  > {
  value?: TValue | null;
  onChange?: (value: TValue) => void;
}

export default function RadioAlternatives<TValue>({
  value,
  onChange,
  onAlternativeSelect,
  ...props
}: Props<TValue>): JSX.Element {
  const handleClick = React.useCallback(
    (option: SelectionControlOption<TValue>) => {
      if (onAlternativeSelect) {
        onAlternativeSelect(option);
      }

      if (onChange && option.value !== value) {
        onChange(option.value);
      }
    },
    [onChange, onAlternativeSelect, value],
  );

  const selected: TValue[] = React.useMemo(() => {
    if (value !== undefined && value !== null) {
      return [value];
    }

    return [];
  }, [value]);

  return (
    <SelectionControlAlternatives
      selected={selected}
      onAlternativeSelect={handleClick}
      selectionType="radio"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
