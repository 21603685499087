import { Checkbox } from '@/components/SelectionControl';
import React from 'react';
import { MainContent, TextContent } from './styles';

interface Props {
  allow_null: boolean;
  allow_null_placeholder: string;
  not_applicable: boolean;
  disabled?: boolean;
  onClickHandle?: (state: ControlStateType) => void;
}

type ControlStateType = 'unmarked' | 'marked' | 'half-marked';

const PlaceholderAllowNull: React.FC<Props> = ({
  allow_null,
  allow_null_placeholder,
  not_applicable,
  disabled,
  onClickHandle,
}) => {
  const [controlState, setControlState] = React.useState<ControlStateType>(
    'unmarked',
  );

  React.useEffect(() => {
    if (allow_null && not_applicable) {
      setControlState('marked');
    }
  }, [allow_null, not_applicable]);

  return (
    <MainContent
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          setControlState(prev => {
            const newState = prev === 'unmarked' ? 'marked' : 'unmarked';

            if (onClickHandle) {
              onClickHandle(newState);
            }

            return newState;
          });
        }
      }}
    >
      <Checkbox disabled={disabled} state={controlState} size="small" />
      <TextContent>{allow_null_placeholder}</TextContent>
    </MainContent>
  );
};

export default PlaceholderAllowNull;
