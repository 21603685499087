import Preloader from '@/components/Preloader';
import React from 'react';
import { useAuth } from '../../providers/AuthProvider/hooks';

// import { Container } from './styles';

type Props = React.PropsWithChildren;

const AuthSetupPreloader: React.FC<Props> = ({ children }) => {
  const { setUp, status } = useAuth();

  React.useEffect(() => {
    setUp();
  }, [setUp]);

  return (
    <Preloader
      message="Estamos preparando o protocolo para você."
      loading={status === 'unknown'}
    >
      {children}
    </Preloader>
  );
};

export default AuthSetupPreloader;
