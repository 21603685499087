import Button from '@/components/Button';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import React from 'react';
import { useExecucaoContext } from '../../providers/ExecucaoProvider';

const ButtonSairProtocolo: React.FC = () => {
  const [, { dispatchSolicitarSaida }] = useExecucaoContext();
  const [{ mode }] = useEnterpriseContext();

  if (mode !== 'integrated') {
    return (
      <Button
        theme="secondary"
        onClick={() => {
          dispatchSolicitarSaida({});
        }}
      >
        Sair do protocolo
      </Button>
    );
  }

  return <></>;
};

export default ButtonSairProtocolo;
