import styled from 'styled-components';

import GlobalInputNumerico from '@/features/execucao/components/PerguntaDetail/InputNumerico';
import colors from '@/styles/colors';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 24px;

  margin: 16px 0 0 0;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: ${colors.white};
  opacity: 0.7;

  border-radius: 10px;
`;

export const PerguntaTitle = styled.p`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;

  margin-bottom: 10px;
`;

export const InputNumerico = styled(GlobalInputNumerico)`
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 15px;

  border-top: 1px solid ${colors.secondaryLite};
  display: flex;
  justify-content: flex-end;
`;

export const InstrucoesContainer = styled.div`
  margin-bottom: 24px;
`;

export const InstrucoesTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;

  margin-bottom: 24px;
`;

export const InstrucoesDetail = styled.p`
  font-size: 14px;
`;
