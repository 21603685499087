import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f9f9f9;
`;

export const NewPasswordBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  width: 640px;
  padding: 48px;
`;

export const NewPasswordHeader = styled.div`
  margin-bottom: 40px;

  img {
    height: 50px;
  }
`;

export const NewPasswordBody = styled.div`
  display: flex;
  flex-direction: column;
`;
