import colors from '@/styles/colors';
import styled from 'styled-components';
import listCircle from '@/assets/list-circle.svg';

export const Container = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  * {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  table {
    border: 1px solid #d9dbe3;
    border-radius: 8px;
    border-spacing: 0px;
    margin: 8px 0px;
    margin-top: 8px !important;

    th {
      padding: 12px 8px;
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }

      text-align: left;
    }

    th,
    td {
      border: 1px solid #d9dbe3;
      padding: 8px 32px 8px 8px;
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #f8f8f8;
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 8px;
            }
            &:last-child {
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    list-style-position: inside;
    color: ${colors.hardGray};

    p {
      display: inline;
    }

    li {
      :not(:first-child) {
        margin-top: 0.8em;
      }

      ::before {
        content: '';
        margin-right: 0.4em;
      }
    }

    ul,
    ol {
      margin-left: 1.5em;
      margin-top: 0.8em;
    }
  }

  ul {
    list-style-image: url(${listCircle});
  }
`;
