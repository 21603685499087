import InputField from '@/components/InputField';
import styled from 'styled-components';

export const NumberInput = styled(InputField).attrs({
  type: 'number',
})`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  appearance: textfield;
  -moz-appearance: textfield;
`;
