import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@/components/Button';

import { Container, Title, Info } from './styles';

const NewPasswordSuccess: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Title>Sua senha foi alterada</Title>
      <Info>
        Tudo pronto! Com sua nova senha você pode voltar a acessar o Medflow.
      </Info>

      <Button onClick={() => history.push('/entrar')} theme="secondary">
        Entrar no Medflow
      </Button>
    </Container>
  );
};

export default NewPasswordSuccess;
