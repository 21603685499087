import React from 'react';
import { toast } from 'react-toastify';
import { PassoExecutado } from '@/models/PassoExecutado';
import { RespostaPayload } from '@/features/execucao/types';
import { groupBy } from '@/utils/collections';

import { PerguntaDetailRef } from '../../PerguntaDetail';
import ToastDisabledFeedback from '../ToastDisabledFeedback';
import CategoriaContent from './CategoriaContent';
import PerguntasContent from './PerguntasContent';

export interface BoxPerguntasRef {
  require: (items: string[]) => void;
}

interface BoxPerguntasProps {
  passoExecutado: PassoExecutado;
  handleResposta: (resposta: RespostaPayload) => void;
}

const BoxPerguntas = React.forwardRef<BoxPerguntasRef, BoxPerguntasProps>(
  ({ passoExecutado, handleResposta }, ref) => {
    const disabledFeedbackToastId = React.useRef<string | number | null>(null);

    const questionnaireRef = React.useRef<{
      [varname: string]: PerguntaDetailRef;
    }>({});

    const perguntasPossuemCategorias = React.useMemo(() => {
      return (
        passoExecutado.perguntas.filter(pergunta => !!pergunta.categoria)
          .length > 0
      );
    }, [passoExecutado]);

    const dismissDisabledFeedbackToast = React.useCallback(() => {
      const toastId = disabledFeedbackToastId.current;
      if (toastId !== null) {
        setTimeout(() => toast.dismiss(toastId), 1000);
        disabledFeedbackToastId.current = null;
      }
    }, []);

    const redirectToInvalidQuestions = React.useCallback(
      (invalidVars: string[]) => {
        if (questionnaireRef.current) {
          const requirePromises = invalidVars.map(
            (varname, index) =>
              new Promise(resolve => {
                const questionRef = questionnaireRef.current[varname];

                questionRef.require();

                if (index === 0) {
                  questionRef.scrollTo(
                    perguntasPossuemCategorias ? 48 : undefined,
                  );
                }

                resolve(true);
              }),
          );

          Promise.all(requirePromises);
        }
      },
      [perguntasPossuemCategorias],
    );

    const handleRequireAnswers = React.useCallback(
      (items: string[]) => {
        if (!disabledFeedbackToastId.current)
          disabledFeedbackToastId.current = toast(
            <ToastDisabledFeedback
              numInvalidItems={items.length}
              onLinkClick={() => {
                redirectToInvalidQuestions(items);
                dismissDisabledFeedbackToast();
              }}
            />,
            {
              draggable: false,
              closeOnClick: false,
              pauseOnHover: true,
              onClose: dismissDisabledFeedbackToast,
              autoClose: 5000,
            },
          );
      },
      [dismissDisabledFeedbackToast, redirectToInvalidQuestions],
    );

    const handleQuestionnaire = React.useCallback(
      (perguntaRef: PerguntaDetailRef, nome: string) => {
        questionnaireRef.current[nome] = perguntaRef;
      },
      [],
    );

    const Categorias = React.useMemo(() => {
      const groupedPerguntas = groupBy(
        passoExecutado.perguntas,
        pergunta => pergunta.categoria || 'Outros',
      );

      return Object.entries(groupedPerguntas).map(([categoria, perguntas]) => (
        <CategoriaContent categoria={categoria} key={categoria}>
          <PerguntasContent
            perguntas={perguntas}
            disabled={passoExecutado.executado}
            handleResposta={handleResposta}
            handleSetQuestionnaireRef={handleQuestionnaire}
          />
        </CategoriaContent>
      ));
    }, [
      handleQuestionnaire,
      handleResposta,
      passoExecutado.executado,
      passoExecutado.perguntas,
    ]);

    React.useImperativeHandle(
      ref,
      () => ({
        require: (items: string[]) => {
          handleRequireAnswers(items);
        },
      }),
      [handleRequireAnswers],
    );

    return perguntasPossuemCategorias ? (
      <>{Categorias}</>
    ) : (
      <PerguntasContent
        perguntas={passoExecutado.perguntas}
        disabled={passoExecutado.executado}
        handleResposta={handleResposta}
        handleSetQuestionnaireRef={handleQuestionnaire}
      />
    );
  },
);

export default BoxPerguntas;
