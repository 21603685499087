import React, { FC } from 'react';
import {
  BasicFormattingButtonGroup,
  ToggleOrderedListButton,
  ToggleBulletListButton,
  VerticalDivider,
} from '@remirror/react';

import { CustomToolbar } from './styles';

export const BottomToolbar: FC = () => {
  return (
    <CustomToolbar>
      <BasicFormattingButtonGroup />
      <VerticalDivider />
      <ToggleOrderedListButton />
      <ToggleBulletListButton />
    </CustomToolbar>
  );
};
