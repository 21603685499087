import colors from '@/styles/colors';
import styled from 'styled-components';

export const FeedbackErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: ${colors.error};

  span + span {
    margin-top: 8px;
  }
`;

export const FormGroupContainer = styled.div`
  label {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
  }

  ${FeedbackErrorContainer} {
    margin-top: 8px;
  }
`;
