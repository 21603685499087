import React from 'react';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { ReactComponent as MfClockRotateCcw } from '@/assets/icons/clock-rotate-ccw.svg';

import { naturalTime } from '@/utils/datetime';
import { getNextAPIResultPage } from '@/utils/query';

import Search from '@/components/Search';
import FlatList from '@/components/FlatList';
import FeedbackGraphic from '@/components/FeedbackGraphic';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import { SpinnerInfinite } from '@/components/Spinner/variations';

import InputProtocoloExecutado from '@/features/dashboard/components/InputProtocoloExecutado';
import PlaceholderFlatListProtocolo from '@/features/dashboard/components/PlaceholderFlatListProtocolo';
import ProtocoloExecutadoAPI from '@/features/dashboard/services/ProtocoloExecutadoAPI';

import { Container, Header } from './styles';

const PageContentHistoryList: React.FC = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');

  const {
    data,
    isLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['protocolo-executado-list-history', search],
    ({ pageParam: page, queryKey: [, term] }) =>
      ProtocoloExecutadoAPI.list({
        page,
        search: term,
        tagged: true,
        page_size: 25,
        status: ['FINALIZADO', 'CANCELADO'],
        ordering: '-data_execucao',
      }),
    {
      getNextPageParam: getNextAPIResultPage,
    },
  );

  const protocolos = React.useMemo(() => {
    if (data) {
      return data.pages.map(group => group.results).flat();
    }

    return [];
  }, [data]);

  const handleMore = React.useCallback(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching]);

  const renderHeader = React.useCallback(() => {
    if (protocolos.length > 0) {
      return <Header>Histórico de protocolos</Header>;
    }

    return null;
  }, [protocolos.length]);

  const renderFooter = React.useCallback(() => {
    if (isFetchingNextPage) {
      return <SpinnerInfinite />;
    }

    return null;
  }, [isFetchingNextPage]);

  const renderListEmpty = React.useCallback(() => {
    if (search) {
      return (
        <FeedbackGraphic
          graphic="empty-box"
          title="Nenhum histórico por aqui"
          subtitle="Não conseguimos encontrar o  que você deseja.
          Tente buscar usando um termo diferente."
        />
      );
    }

    return (
      <FeedbackGraphic
        graphic="empty-box"
        title="Ainda sem histórico por aqui"
        subtitle="Não é possível localizar históricos, pois nenhum protocolo foi executado até o momento."
      />
    );
  }, [search]);

  return (
    <Container>
      <Search
        placeholder="Busque pelos históricos de protocolos"
        debounceTimeInMS={600}
        onChange={setSearch}
      />

      <LoadingSwitchTransition
        loading={isLoading}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
        LoadingComponent={
          <PlaceholderFlatListProtocolo
            length={3}
            style={{
              // Espaço ocupado pelo header "Histórico de protocolos"
              marginTop: 36,
            }}
          />
        }
      >
        <FlatList
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          data={protocolos}
          keyExtractor={({ item: { id } }) => id}
          onEndReached={handleMore}
          hasMore={hasNextPage}
          ListHeaderComponent={renderHeader}
          ListFooterComponent={renderFooter}
          ListEmptyComponent={renderListEmpty}
          renderItem={({ item }) => (
            <InputProtocoloExecutado
              protocolo={item}
              helperInfo={naturalTime(item.data_execucao, 'Executado')}
              Right={<MfClockRotateCcw width={18} height={18} />}
              onClick={() => history.push(`/historicos/${item.id}`, item)}
            />
          )}
        />
      </LoadingSwitchTransition>
    </Container>
  );
};

export default PageContentHistoryList;
