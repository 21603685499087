import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const Nav = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
`;

export const NavScroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavItemListContainer = styled.ul`
  display: flex;
  flex-direction: row;
`;

export const NavItemContainer = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  min-width: 144px;
  width: 100%;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
`;

export const NavItem = styled.a<{ actived: boolean }>`
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  width: 100%;
  text-align: center;
  transition: color 0.1s ease-in-out;

  ${HeadingSmall(14)};

  background-color: ${props => (props.actived ? 'transparent' : 'transparent')};

  color: ${props => (props.actived ? colors.purple100 : colors.gray200)};

  &:hover {
    color: ${props =>
      props.actived ? colors.purple100 : colors.blackAlpha200};
  }
`;

export const ActiveIndicator = styled.span<{ position: number }>`
  height: 4px;
  width: 144px;
  background-color: ${colors.purple100};
  border-radius: 10px;
  margin-top: 6px;
  transition: all 300ms cubic-bezier(0, 0.5, 0.06, 1);
  transform: translateX(${props => props.position}px);
`;

export const Control = styled.button`
  border-radius: 4px;
  color: ${colors.gray200};
  width: 32px;
  height: 32px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }

  &:hover {
    background-color: ${colors.gray50};
  }
`;
