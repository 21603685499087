/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { isSessionExpired } from '@/features/auth/providers/AuthProvider/helpers';
import AuthSetupPreloader from '@/features/auth/components/AuthSetupPreloader';

interface PublicRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps>;
  restricted?: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  restricted,
  ...rest
}: PublicRouteProps) => {
  const { user, status } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        // If user already logged out, there is no need to setup auth and
        // try to load the current logged user
        if (restricted && !isSessionExpired(status)) {
          return (
            <AuthSetupPreloader>
              {user ? <Redirect to="/" /> : <Component {...props} />}
            </AuthSetupPreloader>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

PublicRoute.defaultProps = {
  restricted: false,
};

export default PublicRoute;
