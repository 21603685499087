import OptionIntegracao from '@/types/OptionIntegracao';

export const parseOptionIntegracao = (
  option: OptionIntegracao,
): OptionIntegracao => {
  if (option?.pedido) {
    const optionIntegracao = {
      ...option,
      selected: option.pedido.status !== 'draft',
    } as OptionIntegracao;

    if (
      option.pedido.status === 'completed' ||
      option.pedido.status === 'active'
    )
      return {
        ...optionIntegracao,
        status: 'solicitado',
      } as OptionIntegracao;
    if (option.pedido.status === 'entered-in-error')
      return {
        ...optionIntegracao,
        status: 'rejeitado',
      } as OptionIntegracao;

    return optionIntegracao;
  }

  return option;
};

export const artefatoTypeToDashed = (type: string): string => {
  return (
    {
      Exame: 'solicitacao-exame',
      Procedimento: 'procedimento',
      Medicamento: 'prescricao-medica',
      Encaminhamento: 'encaminhamento',
      Orientacao: 'orientacoes-medicas',
      Fotoceutico: 'fotoceutico',
    }[type] || ''
  );
};
