import React from 'react';

import { ButtonLink } from '@/components/Link';

import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import InteracaoProtocoloList from '../InteracaoProtocoloList';

import { Container, Heading } from './styles';

interface Props {
  onExpandClick?: () => void;
}

const UltimaInteracao: React.FC<Props> = ({ onExpandClick }) => {
  const [{ history }] = useEnterpriseContext();

  const ultimasInteracoes = React.useMemo(() => {
    if (history.data.length) {
      return [history.data[0]];
    }

    return [];
  }, [history.data]);

  return ultimasInteracoes.length > 0 ? (
    <Container>
      <Heading>
        <h3>Última interação</h3>
        <ButtonLink onClick={onExpandClick}>Ver todas</ButtonLink>
      </Heading>

      <InteracaoProtocoloList evolucoes={ultimasInteracoes} />
    </Container>
  ) : (
    <></>
  );
};

export default UltimaInteracao;
