import React from 'react';

import ArtifactForm, {
  ArtifactFormComponentProps,
} from '@/features/artifacts/components/ArtifactForm';
import { ArtifactFormOverrides } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useArtifactFormConfig } from '@/features/artifacts/hooks/useArtifactFormConfig';

const ExameFormDefault: ArtifactFormOverrides = [
  { name: 'quantity', label: 'Quantidade' },
  { name: 'reasonCode', label: 'Indicação clínica' },
  { name: 'orderDetail', label: 'Observações' },
];

export type FormExameProps = ArtifactFormComponentProps;

const FormExame: React.FC<FormExameProps> = ({
  resource,
  onSubmit,
  onCancel,
  disabled = false,
  loading = false,
  readOnly = false,
}) => {
  const form = useArtifactFormConfig('solicitacao-exame', ExameFormDefault);

  return (
    <ArtifactForm
      form={form}
      resource={resource}
      loading={loading}
      disabled={disabled}
      readOnly={readOnly}
      onResourceSubmit={onSubmit}
      onResourceSubmitCancel={onCancel}
    />
  );
};

export default FormExame;
