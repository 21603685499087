/**
 * A set of helpers to deal with FHIR Data Types
 *
 * https://www.hl7.org/fhir/datatypes.html
 */

import { toNameCase } from '@/utils/string';

export function buildFullName(humanName: fhir4.HumanName): string {
  if (humanName.text) {
    return toNameCase(humanName.text);
  }

  const parts = [...(humanName.prefix || []), ...(humanName.given || [])];
  if (humanName.family) {
    parts.push(humanName.family);
  }

  parts.push(...(humanName.suffix || []));
  return toNameCase(parts.join(' '));
}
