import React from 'react';

import { RenderableElement, enforceElement } from '@/utils/elements';

import { Container, LeftContent } from './styles';

import './ReactToastify-reset.css';

const ToastContent: React.FC<
  React.PropsWithChildren & {
    style?: React.CSSProperties;
    className?: string;
    Left?: RenderableElement;
  }
> = ({ children, style, className, Left }) => {
  return (
    <Container style={style} className={className}>
      {Left && <LeftContent>{enforceElement(Left)}</LeftContent>}
      {children}
    </Container>
  );
};

export default ToastContent;
