// import * as Sentry from '@sentry/react';
import Feedback from '@/models/Feedback';
import { User } from '@/models/User';
import api from '@/sevices/api';

import { AuthorizationPayload } from '../types/integrated';

import { EmailLoginPayload, LoginResponse } from '../types/login';
import {
  ChangePasswordPayload,
  SignUpPayload,
  SignUpResponse,
} from '../types/registration';
import { AppleLoginPayload, GoogleLoginPayload } from '../types/social';

const AUTH_STORAGE_KEY = 'Medflow@auth_user';

function saveUser(user: User): void {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(user));
}

function getUser(): User | null {
  const userString = localStorage.getItem(AUTH_STORAGE_KEY);

  if (userString) {
    return JSON.parse(userString) as User;
  }

  return null;
}

async function enterpriseSignIn({
  code,
  redirect_uri,
}: AuthorizationPayload): Promise<User> {
  const { user } = (
    await api.post<LoginResponse>('/enterprise/authorize/', {
      code,
      redirect_uri,
    })
  ).data;

  saveUser(user);
  return user;
}

async function signIn(payload: EmailLoginPayload): Promise<LoginResponse> {
  const { data } = await api.post<LoginResponse>('/auth/signin/', {
    username: payload.email,
    password: payload.password,
  });

  // saveUser(data.user);
  return data;
}

async function signInWithGoogle(
  payload: GoogleLoginPayload,
): Promise<LoginResponse> {
  const { data } = await api.post<LoginResponse>(
    '/auth/social/google/signin/',
    payload,
  );

  // saveUser(data.user);
  return data;
}

async function signInWithApple(
  payload: AppleLoginPayload,
): Promise<LoginResponse> {
  const { data } = await api.post<LoginResponse>(
    '/auth/social/apple/signin/',
    payload,
  );

  // saveUser(data.user);
  return data;
}

async function signInWithAuthorizationCode(
  payload: AuthorizationPayload,
): Promise<LoginResponse> {
  return (await api.post<LoginResponse>('/enterprise/authorize/', payload))
    .data;
}

async function signUp(payload: SignUpPayload): Promise<User> {
  const { user } = (
    await api.post<SignUpResponse>('/auth/signup/', payload)
  ).data;

  saveUser(user);
  return user;
}

function signOut(): void {
  if (navigator.sendBeacon) {
    const baseURL = process.env.REACT_APP_API_URL;
    navigator.sendBeacon(`${baseURL}/auth/signout/`);
  }
}

async function changePassword(
  user: User,
  payload: ChangePasswordPayload,
): Promise<User> {
  await api.post('/auth/password/change/', payload);

  const newUser = { ...user, has_usable_password: true };
  saveUser(newUser);
  return newUser;
}

async function acceptUserTerms(user: User): Promise<User> {
  await api.patch('/auth/user/accept-terms/');

  const newUser = { ...user, accepted_user_terms: true };
  saveUser(newUser);
  return newUser;
}

function sendFeedback(user: User, feedback: Feedback): User {
  let newUser = user;
  if (feedback.score) {
    newUser = { ...user, gave_score_feedback: true };
  }

  saveUser(newUser);
  return newUser;
}

export default {
  saveUser,
  getUser,
  enterpriseSignIn,
  signIn,
  signInWithGoogle,
  signInWithApple,
  signInWithAuthorizationCode,
  signUp,
  signOut,
  changePassword,
  acceptUserTerms,
  sendFeedback,
};
