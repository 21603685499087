import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingLarge } from '@/design/typography';

import FlatList from '@/components/FlatList';

import ArtifactBaseForm from '@/features/artifacts/components/ArtifactBaseForm';
import { ArtifactFieldLayout } from '@/features/artifacts/components/ArtifactForm/styles';

export const ItemComplementarList = styled(FlatList)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
` as typeof FlatList;

export const ListTitle = styled.h1`
  display: flex;
  flex-direction: row;
  column-gap: 12px;

  ${HeadingLarge(16)};
  color: ${colors.blackAlpha200};
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ItemComplementarGroupForm = styled(ArtifactBaseForm)`
  row-gap: 8px;

  ${ArtifactFieldLayout} {
    flex: 0 1 calc(100%);
  }

  input,
  textarea {
    :hover:not(:focus):not(:disabled) {
      border: 1px solid ${colors.purple100};
      box-shadow: ${shadows.smallShadow};
      cursor: pointer;
    }

    :disabled {
      background-color: ${colors.gray50};
      color: ${colors.blackAlpha200};

      cursor: text;
    }
  }
` as typeof ArtifactBaseForm;
