import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.div`
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  row-gap: 16px;

  margin: 0 auto;
  max-width: 383px;

  img {
    height: 104px;
  }

  h3 {
    ${HeadingSmall(16)};

    color: ${colors.blackAlpha200};
  }

  p {
    ${Text(16)};

    color: ${colors.gray300};
    text-align: center;
  }
`;
