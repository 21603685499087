import styled from 'styled-components';

import SearchResultBox from '../SearchResultBox';

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchResults = styled(SearchResultBox)`
  position: absolute;
  top: 52px;

  max-height: 260px;
  width: 100%;

  z-index: 1;
`;
