import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

interface Props extends TippyProps {
  title: string;
}

const Tooltip: React.FC<Props> = ({
  title,
  placement = 'bottom',
  className,
  children,
  ...tippyProps
}) => {
  return (
    <Tippy
      className={className}
      content={title}
      placement={placement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tippyProps}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
