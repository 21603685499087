import { yupResolver } from '@hookform/resolvers/yup';

import React from 'react';
import { Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  ArtifactField,
  ArtifactFieldOverrides,
  ArtifactFormData,
  ArtifactFormModel,
  ArtifactFormOverrides,
  ArtifactFormType,
  ResourceSubmitErrorHandler,
  ResourceSubmitHandler,
  UseArtifactFormMethods,
  UseArtifactFormModelOptions,
} from './ArtifactForm.types';
import { getForm } from '../../forms';

type RType = fhir4.FhirResource;

export function artifactFormResolver(form: ArtifactFormModel): Resolver {
  const objectSchema = yup
    .object(
      form.reduce((spec, field) => {
        let fieldSchema = field.validator;

        if (!field.readOnly && field.required) {
          fieldSchema = fieldSchema.required('Este campo é obrigatório');
        }

        return {
          ...spec,
          [field.name]: fieldSchema,
        };
      }, {}),
    )
    .required();

  return yupResolver(objectSchema);
}

export function artifactDefaultValues({
  resource,
  form,
}: UseArtifactFormModelOptions): ArtifactFormData {
  return form.reduce<ArtifactFormData>((data, { name, getValue }) => {
    let defaultValue = '';

    if (resource) {
      defaultValue = getValue(resource);
    }

    return {
      ...data,
      [name]: defaultValue,
    };
  }, {});
}

function isEmptyValue(value: unknown): boolean {
  return (
    value === null ||
    value === '' ||
    value === undefined ||
    (Array.isArray(value) &&
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (value.length === 0 || value.every(val => isEmptyValue(val))))
  );
}

export function applyFormData(
  form: ArtifactFormModel,
  data: ArtifactFormData,
  resource: RType,
): RType {
  const result = form.reduce<RType>((artifact, field) => {
    const value = data[field.name];

    /**
     * Remove do objeto o elemento quando o seu valor for vazio
     *
     * TODO: Dependendo do contexto de uso no futuro, não faz sentido
     * remover o elemento do objeto aqui. Talvez seja melhor as próprias
     * configurações do campo definirem o que fazer com valores de entrada
     * vazios
     */

    if (isEmptyValue(value)) {
      return field.unsetValue(artifact);
    }

    return field.setValue(artifact, value);
  }, resource);

  return result;
}

export function useArtifactForm({
  resource,
  form,
}: UseArtifactFormModelOptions): UseArtifactFormMethods {
  // Inner handleResourceSubmit
  const handleResourceSubmit = React.useCallback(
    (onValid: ResourceSubmitHandler) => {
      return (data: ArtifactFormData) => {
        if (resource) {
          const result = applyFormData(form, data, resource);
          onValid(result);
        }
      };
    },
    [form, resource],
  );

  const { handleSubmit, reset: resetForm, ...useFormMethods } = useForm({
    resolver: artifactFormResolver(form),
    defaultValues: artifactDefaultValues({ resource, form }),
    shouldFocusError: true,
  });

  const reset: typeof resetForm = React.useCallback(
    (values, keepStateOptions) => {
      const defaults = values || artifactDefaultValues({ resource, form });
      resetForm(defaults, keepStateOptions);
    },
    [form, resetForm, resource],
  );

  return {
    ...useFormMethods,
    reset,
    handleSubmit,
    handleResourceSubmit: (
      onValid: ResourceSubmitHandler,
      onInvalid?: ResourceSubmitErrorHandler,
    ) => handleSubmit(handleResourceSubmit(onValid), onInvalid),
  };
}

function isValidConfigValue(value: unknown): boolean {
  // Check if the value is not null, not undefined, and not NaN

  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }

  if (typeof value === 'number') {
    return !Number.isNaN(value);
  }

  return true;
}

function overwriteField(
  field: ArtifactField,
  options: ArtifactFieldOverrides,
): ArtifactField {
  const mergedConfig: ArtifactField = { ...field };

  Object.entries(options).forEach(([key, infoValue]) => {
    if (isValidConfigValue(infoValue)) {
      mergedConfig[key as keyof ArtifactField] = infoValue as never;
    }
  });

  return mergedConfig;
}

export function buildForm(
  form: ArtifactFormModel,
  overrides: ArtifactFormOverrides,
): ArtifactFormModel {
  return overrides.reduce<ArtifactFormModel>((overwritten, fieldOverrides) => {
    const field = form.find(({ name }) => name === fieldOverrides.name);
    if (field) {
      return [...overwritten, overwriteField(field, fieldOverrides)];
    }

    return overwritten;
  }, []);
}

export function buildFormType(
  formType: ArtifactFormType,
  options: ArtifactFormOverrides,
): ArtifactFormModel {
  const modelForm = getForm(formType);

  return buildForm(modelForm, options);
}
