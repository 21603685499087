/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiServer } from 'react-icons/fi';
import { NavLinkProps, NavLink } from 'react-router-dom';

import Mode from '@/business/AppMode';

import { ReactComponent as MfCalculator } from '@/assets/icons/calculator.svg';
import { ReactComponent as MfClockRotateCcw } from '@/assets/icons/clock-rotate-ccw.svg';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import useSettings from '@/features/enterprise/hooks/useSettings';
import SideBox from '../SideBox';
import MainMenu from '../MainMenu';
import MainMenuItem from '../MainMenu/MainMenuItem';
import VersionInfo from '../VersionInfo';
import { SideBoxFooter } from './styles';

const Partnership = () => {
  const { user } = useAuth();

  const PartnershipLogo = React.useMemo(() => {
    if (user && user.empresa) {
      return user.empresa.logo;
    }

    return undefined;
  }, [user]);

  const PartnershipName = React.useMemo(() => {
    if (user && user.empresa) {
      return user.empresa.nome;
    }

    return undefined;
  }, [user]);

  return (
    <SideBoxFooter>
      {PartnershipLogo && <img src={PartnershipLogo} alt={PartnershipName} />}
    </SideBoxFooter>
  );
};

const Version = () => (
  <SideBoxFooter>
    <VersionInfo />
  </SideBoxFooter>
);

const Footer = Mode.select({
  enterprise: Partnership,
  personal: Version,
});

const MenuItem: React.FC<NavLinkProps> = ({ children, ...props }) => {
  return (
    <NavLink
      {...props}
      component={({ navigate, style, className }) => {
        return (
          <MainMenuItem style={style} className={className} onClick={navigate}>
            {children}
          </MainMenuItem>
        );
      }}
    />
  );
};

const Sidebar: React.FC = () => {
  const { hasTags, mode } = useSettings();

  return (
    <SideBox
      style={{
        width: 'max(16%, 236px)',
      }}
      FooterComponent={Footer}
    >
      <MainMenu style={{ display: 'flex', flex: 1 }}>
        <MenuItem to="/" exact>
          <FiServer size={18} />
          Protocolos
        </MenuItem>

        <MenuItem to="/calculadoras">
          <MfCalculator width={18} height={18} />
          Calculadoras
        </MenuItem>

        {mode === 'enterprise' && hasTags && (
          <MenuItem to="/historicos">
            <MfClockRotateCcw width={18} height={18} />
            Histórico
          </MenuItem>
        )}
      </MainMenu>
    </SideBox>
  );
};

export default Sidebar;
