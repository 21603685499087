import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import React, { ReactNode, useCallback } from 'react';
import EventsService from '../../services/EventsService';
import { PrintEvent } from '../../types/basic';

interface EventsDispatch {
  dispatchEvent: (evento: string, eventType: string, titulo: string) => void;
}

type EventsContextData = [EventsDispatch];

const EventsContext = React.createContext<EventsContextData>(
  {} as EventsContextData,
);

interface EventsProviderProps {
  children: ReactNode;
}

export function useEventsContext(): EventsContextData {
  return React.useContext(EventsContext);
}

export const EventsProvider: React.FC<EventsProviderProps> = ({
  children,
}: EventsProviderProps) => {
  const { user } = useAuth();
  const [{ protocoloExecutado }] = useExecucaoContext();

  const dispatchEvent = useCallback(
    async (evento: string, eventType: string, titulo: string) => {
      const eventData = {
        event: evento,
        event_type: eventType,
        title: titulo,
        datetime: new Date().toISOString(),
        protocolo_codigo: protocoloExecutado?.codigo,
        usuario_id: user?.id,
        empresa_id: user?.empresa?.id,
        protocolo_executado_id: protocoloExecutado?.id,
      } as PrintEvent;

      EventsService.createEventRecord(eventData);
    },
    [
      protocoloExecutado?.codigo,
      protocoloExecutado?.id,
      user?.empresa?.id,
      user?.id,
    ],
  );

  return (
    <EventsContext.Provider
      value={[
        {
          dispatchEvent,
        },
      ]}
    >
      {children}
    </EventsContext.Provider>
  );
};
