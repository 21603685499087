import styled from 'styled-components';

import Button from '@/components/Button';

export const Container = styled.div`
  position: relative;
`;

export const ButtonContainer = styled(Button).attrs({
  theme: 'secondary',
})`
  min-width: inherit;

  svg {
    width: 18px;
    height: 18px;
  }
`;
