import { RespostaPayload } from '@/features/execucao/types';
import { TipoVariavel } from '@/models/Expressao';
import { Pergunta } from '@/models/Pergunta';
import { chunks } from '@/utils/collections';
import React, { useState } from 'react';
import PerguntaDetail, { PerguntaDetailRef } from '../../../PerguntaDetail';
import { Content, List, Row } from './styles';

interface PerguntasContentProps {
  perguntas: Pergunta[];
  disabled: boolean;
  handleResposta: (resposta: RespostaPayload) => void;
  handleSetQuestionnaireRef: (ref: PerguntaDetailRef, nome: string) => void;
}

const PerguntasContent: React.FC<PerguntasContentProps> = ({
  perguntas,
  disabled,
  handleResposta,
  handleSetQuestionnaireRef,
}: PerguntasContentProps) => {
  const [descriptionSizes, setDescriptionSizes] = useState({
    numericas: 18,
    strings: 18,
    outras: 18,
  });

  const [
    perguntasNumericas,
    perguntasStrings,
    perguntasOutras,
  ] = React.useMemo(() => {
    const numericas = perguntas.filter(
      pergunta =>
        [
          TipoVariavel.NUMERICA,
          TipoVariavel.INTEIRO,
          TipoVariavel.DECIMAL,
        ].includes(pergunta.variavel.tipo) && !pergunta.variavel.opcoes.length,
    );
    const strings = perguntas.filter(
      pergunta =>
        pergunta.variavel.tipo === TipoVariavel.STRING &&
        !pergunta.variavel.opcoes.length,
    );

    const outras = perguntas.filter(
      n => ![...numericas, ...strings].includes(n),
    );
    return [chunks(numericas, 3), chunks(strings, 2), outras];
  }, [perguntas]);

  const addDescriptionSize = React.useCallback(
    (rectHeight: number, perguntaType: TipoVariavel) => {
      if (
        [
          TipoVariavel.NUMERICA,
          TipoVariavel.INTEIRO,
          TipoVariavel.DECIMAL,
        ].includes(perguntaType)
      ) {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            numericas:
              rectHeight > prev.numericas ? rectHeight : prev.numericas,
          },
        }));
      } else if (perguntaType === TipoVariavel.STRING) {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            strings: rectHeight > prev.strings ? rectHeight : prev.strings,
          },
        }));
      } else {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            outras: rectHeight > prev.outras ? rectHeight : prev.outras,
          },
        }));
      }
    },
    [],
  );

  return (
    <Content>
      {perguntasNumericas.map((arr: Pergunta[], i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={i.toString()} maxHeight={descriptionSizes.numericas}>
          {arr.map((pergunta, index) => (
            <PerguntaDetail
              key={pergunta.id.toString() || index.toString()}
              ref={perguntaRef => {
                if (perguntaRef) {
                  handleSetQuestionnaireRef(
                    perguntaRef,
                    pergunta.variavel.nome,
                  );
                }
              }}
              pergunta={pergunta}
              disabled={disabled}
              onChange={handleResposta}
            />
          ))}
        </Row>
      ))}
      {perguntasStrings.map((arr: Pergunta[], i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={i.toString()} maxHeight={descriptionSizes.strings}>
          {arr.map((pergunta, index) => (
            <PerguntaDetail
              key={pergunta.id.toString() || index.toString()}
              ref={perguntaRef => {
                if (perguntaRef) {
                  handleSetQuestionnaireRef(
                    perguntaRef,
                    pergunta.variavel.nome,
                  );
                }
              }}
              pergunta={pergunta}
              disabled={disabled}
              onChange={handleResposta}
              addDescriptionSize={addDescriptionSize}
            />
          ))}
        </Row>
      ))}
      {perguntasOutras.length > 0 && (
        <List>
          {perguntasOutras.map((pergunta, index) => (
            <PerguntaDetail
              key={pergunta.id.toString() || index.toString()}
              ref={perguntaRef => {
                if (perguntaRef) {
                  handleSetQuestionnaireRef(
                    perguntaRef,
                    pergunta.variavel.nome,
                  );
                }
              }}
              pergunta={pergunta}
              disabled={disabled}
              onChange={handleResposta}
            />
          ))}
        </List>
      )}
    </Content>
  );
};

export default PerguntasContent;
