import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import colors from '@/styles/colors';
import Button from '../Button';

const linkStyle = css`
  color: ${colors.primaryTint};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  padding: 0px;

  :hover,
  :focus-visible {
    text-decoration: underline;
  }
`;

export const Link = styled(RouterLink)`
  ${linkStyle}
`;

export const ButtonLink = styled(Button).attrs({
  theme: 'tertiary',
  type: 'button',
})`
  ${linkStyle}
`;

export default Link;
