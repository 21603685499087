import colors from '@/design/colors';
import { Text } from '@/design/typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  padding: 16px 24px;

  /* Black Alpha 200 */
  background: ${colors.blackAlpha200};
  border-radius: 8px;

  ${Text(16)};
  color: ${colors.white};
  background-color: ${colors.blackAlpha200};
`;

export const LeftContent = styled.div`
  display: flex;
`;

export const ToastContentLink = styled.button.attrs({
  type: 'button',
  role: 'link',
})`
  color: ${colors.white};
  text-decoration: underline;
`;
