import React from 'react';

import Button from '@/components/Button';

import { ModalDialogContainer, Container } from './styles';
import { useCalculadoraContext } from '../../contexts/CalculadoraContext';

const ModalFinalizarCalculadora: React.FC = () => {
  const { finalizarCalculadora } = useCalculadoraContext().state;
  const {
    dispatchFinalizarCalculadora,
    dispatchCancelarFinalizarCalculadora,
  } = useCalculadoraContext().actions;

  return (
    <ModalDialogContainer
      title="Finalizar calculadora"
      isOpen={finalizarCalculadora.emAndamento}
      onAfterClose={dispatchCancelarFinalizarCalculadora}
    >
      <Container>
        <p>Deseja mesmo finalizar a calculadora?</p>

        <Button onClick={dispatchFinalizarCalculadora}>
          Finalizar calculadora
        </Button>
        <Button onClick={dispatchCancelarFinalizarCalculadora} theme="tertiary">
          Voltar
        </Button>
      </Container>
    </ModalDialogContainer>
  );
};

export default ModalFinalizarCalculadora;
