import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import spinner from '@/assets/spinner-thin.svg';
import PageLayout from '@/layouts/PageLayout';
import { useQuery } from '@/utils/route';
import HeaderDefault from '@/components/HeaderDefault';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import { Container } from './styles';

const baseTimeout = new Promise(resolve => setTimeout(resolve, 2000));

const AbrirExecucao: React.FC = () => {
  const query = useQuery();
  const history = useHistory();

  const [
    ,
    {
      dispatchIniciarExecucaoFromBackoffice,
      dispatchIniciarExecucao,
      dispatchRetomarExecucao,
    },
  ] = useExecucaoContext();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  function getNomeProtocolo() {
    return query.get('protocolo_nome') || 'Protocolo';
  }

  const getDispatchFromQuery = useCallback(
    (searchParams: URLSearchParams) => {
      const protocoloId = searchParams.get('protocolo_id');
      const execucaoId = searchParams.get('execucao_id');
      const codigoProtocolo = searchParams.get('codigo_protocolo');

      // Is from backoffice
      if (protocoloId) {
        return dispatchIniciarExecucaoFromBackoffice({ protocoloId });
      }

      if (codigoProtocolo) {
        return dispatchIniciarExecucao({ codigoProtocolo });
      }

      // Retomar protocolo
      if (execucaoId) {
        return dispatchRetomarExecucao(execucaoId);
      }

      return null;
    },
    [
      dispatchIniciarExecucao,
      dispatchIniciarExecucaoFromBackoffice,
      dispatchRetomarExecucao,
    ],
  );

  useEffect(() => {
    const dispatchAction = getDispatchFromQuery(query);

    if (dispatchAction) {
      (async () => {
        await Promise.all([dispatchAction, baseTimeout]);
        setShouldRedirect(true);
      })();
    } else {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  if (shouldRedirect) {
    return <Redirect to="/execucao" />;
  }

  return (
    <PageLayout Nav={<HeaderDefault title={getNomeProtocolo()} />}>
      <Container>
        <img src={spinner} alt="Carregando" />
        <span>Carregando protocolo</span>
      </Container>
    </PageLayout>
  );
};

export default AbrirExecucao;
