/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiClock } from 'react-icons/fi';
import { IMask, useIMask } from 'react-imask';

import { InputFieldProps } from '../InputField';
import InputFieldWithIcon from '../InputFieldWithIcon';

interface Props extends Omit<InputFieldProps, 'onChange'> {
  onChangeTime(value?: string): void;
}

const InputFieldTime: React.FC<Props> = ({ value, onChangeTime, ...props }) => {
  const timeValue = React.useMemo(() => {
    if (typeof value === 'string' && value.length > 0) {
      return value;
    }

    return '';
  }, [value]);

  const inputHandlers = React.useMemo(() => {
    if (onChangeTime) {
      return {
        onAccept() {
          onChangeTime(undefined);
        },
        onComplete(completeValue: string) {
          onChangeTime(completeValue);
        },
      };
    }

    return {};
  }, [onChangeTime]);

  const [defaultValue] = React.useState(timeValue);

  const { ref } = useIMask(
    {
      overwrite: true,
      autofix: true,
      lazy: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2,
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2,
        },
      },
    },
    inputHandlers,
  );

  React.useEffect(() => {
    if (defaultValue) {
      onChangeTime(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <InputFieldWithIcon
      ref={ref}
      {...props}
      defaultValue={defaultValue}
      Icon={<FiClock size={18} />}
    />
  );
};

export default InputFieldTime;
