import React from 'react';

import {
  LogoContent,
  LogoIcon,
  LogoIconTriangleLeft,
  LogoIconTriangleRight,
  LogoIconBall,
  TrianglesWrapper,
  BallsWrapper,
  LogoText,
} from './styles';

interface Props {
  done?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onLoadingDoneAnimationEnd?: () => void;
}

const MedflowLogoLoading: React.FC<Props> = ({
  done = false,
  style,
  className,
  onLoadingDoneAnimationEnd,
}) => {
  return (
    <LogoContent style={style} className={className}>
      <LogoIcon>
        <TrianglesWrapper
          done={done}
          onTransitionEnd={onLoadingDoneAnimationEnd}
        >
          <LogoIconTriangleLeft hidden={!done} />
          <LogoIconTriangleRight hidden={!done} />
        </TrianglesWrapper>

        <BallsWrapper done={done}>
          <LogoIconBall side="left" />
          <LogoIconBall side="right" />
        </BallsWrapper>
      </LogoIcon>

      <LogoText>Medflow</LogoText>
    </LogoContent>
  );
};

export default MedflowLogoLoading;
