import React from 'react';

import organizationDefaultImage from '@/assets/default-organization.svg';
import { useOrganizationDisplay } from '@/features/enterprise/hooks/useEncounter';

import {
  OrganizationContainer,
  OrganizationContainerTitle,
  OrganizationImage,
  OrganizationName,
  OrganizationBoxContent,
  OrganizationBox,
} from './styles';

const OrganizationSection: React.FC = () => {
  const { organization, location } = useOrganizationDisplay();

  const shouldDisplay = React.useMemo(() => organization || location, [
    location,
    organization,
  ]);

  const display = React.useMemo(
    () => [location, organization].filter(val => !!val).join(' | '),
    [location, organization],
  );

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <OrganizationContainer>
      <OrganizationContainerTitle>Entidade de saúde</OrganizationContainerTitle>
      <OrganizationBox>
        <OrganizationBoxContent>
          <OrganizationImage src={organizationDefaultImage} />
          <OrganizationName>{display}</OrganizationName>
        </OrganizationBoxContent>
      </OrganizationBox>
    </OrganizationContainer>
  );
};

export default OrganizationSection;
