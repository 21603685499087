import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';

export const CalendarBox = styled.div`
  width: 279px;

  background-color: ${colors.white};
  box-shadow: ${shadows.mediumShadow};
  border-radius: 8px;
`;

export const CalendarContentContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: opacity 200ms ease-in;
  }
`;
