import styled from 'styled-components';

import colors from '@/styles/colors';

export const Timeline = styled.div`
  width: 100%;

  margin-top: 20px;
`;

export const Line = styled.div<{ isChecked: boolean }>`
  border-left: 2px solid
    ${({ isChecked }) =>
      isChecked ? colors.primaryTint : colors.secondaryLite};
  padding-bottom: 25px;

  display: flex;
  align-items: end;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    border-left: 2px solid ${colors.white};
    align-items: flex-start;
    padding-bottom: 0px;
  }
`;

export const LineText = styled.span`
  font-size: 14px;
  width: fit-content;
  margin-top: -3px;

  line-height: 18px;
`;

export const CircleContainer = styled.div`
  padding-right: 15px;
`;
