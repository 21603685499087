/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIMask } from 'react-imask';

import InputField, { InputFieldProps } from '../InputField';

interface Props extends InputFieldProps {
  minValue?: number;
  maxValue?: number;
  maxDigits?: number;
  decimalPlaces: number;
  validationError?: string;
  onChangeText?: (value: string, typedValue: number) => void;
}

const InputFieldDecimal: React.FC<Props> = ({
  maxDigits,
  decimalPlaces,
  onChangeText,
  validationError,
  ...fieldProps
}) => {
  const regex1 = React.useMemo(
    () => new RegExp(`^[0-9]{0,${maxDigits || 5}}$`),
    [maxDigits],
  );

  const regex2 = React.useMemo(
    () => new RegExp(`^[0-9]{0,${decimalPlaces}}$`),
    [decimalPlaces],
  );

  const { ref } = useIMask(
    {
      mask: 'int,dec',
      lazy: true, // make placeholder always visible

      blocks: {
        int: {
          mask: regex1,
          expose: true,
        },
        dec: {
          mask: regex2,
          expose: true,
        },
      },
    },
    {
      onAccept: value => {
        if (onChangeText) {
          const typedValue = parseFloat(value.replace(/,/, '.'));
          onChangeText(value, typedValue);
        }
      },
    },
  );

  return <InputField ref={ref} {...fieldProps} error={validationError} />;
};

export default InputFieldDecimal;
