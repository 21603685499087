import React from 'react';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import { FiPlayCircle } from 'react-icons/fi';
import { naturalTime } from '@/utils/datetime';
import { Bar, Content } from './styles';

const BarInfoProtocoloExecutado: React.FC = () => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const tags = React.useMemo(() => {
    if (protocoloExecutado?.tags?.length) {
      return protocoloExecutado.tags?.map(tag => tag.valor).join(' | ');
    }
    return '';
  }, [protocoloExecutado]);

  const createdAt = React.useMemo(() => {
    if (protocoloExecutado?.data_criacao)
      return naturalTime(protocoloExecutado.data_criacao, 'Iniciado');
    return null;
  }, [protocoloExecutado]);

  const label = React.useMemo(() => {
    if (protocoloExecutado) {
      return `${tags} | ${createdAt}`;
    }

    return '';
  }, [createdAt, protocoloExecutado, tags]);

  return (
    <Bar>
      <Content>
        {label}
        <FiPlayCircle size={24} />
      </Content>
    </Bar>
  );
};

export default BarInfoProtocoloExecutado;
