import React from 'react';
import styled from 'styled-components';

import Button from '@/components/Button';

import playIcon from '@/assets/play_icon.svg';

import { Container } from './styles';

const Icon = styled.img`
  margin-bottom: 24px;
`;

interface Props {
  loading: boolean;
  handleSairProtocolo: () => void;
  onClose: () => void;
}

const B2BContent: React.FC<Props> = ({
  loading,
  handleSairProtocolo,
  onClose,
}) => {
  return (
    <Container>
      <Icon src={playIcon} alt="Play" />
      <span>
        <strong>Esse protocolo ficará em execução</strong>
      </span>
      <p>
        Não se preocupe, você ainda poderá retomar o protocolo do ponto de onde
        parou.
      </p>
      <Button loading={loading} onClick={handleSairProtocolo}>
        OK
      </Button>
      <Button theme="tertiary" onClick={onClose}>
        Voltar para a execução
      </Button>
    </Container>
  );
};

export default B2BContent;
