import React from 'react';

import Search from '@/components/Search';

import SectionEspecialidades from '../SectionEspecialidades';
import SectionProtocolos from '../SectionProtocolos';
import SectionProtocolosEmExecucao from '../SectionProtocolosEmExecucao';

import { Container, Header } from './styles';

const PageContentProtocolos: React.FC = () => {
  const [termOfSearch, setTermOfSearch] = React.useState('');

  return (
    <Container>
      <Header>
        <Search
          placeholder="Busque por protocolos ou sintomas"
          onChange={setTermOfSearch}
          debounceTimeInMS={600}
        />
      </Header>

      <SectionEspecialidades />
      <SectionProtocolosEmExecucao search={termOfSearch} />
      <SectionProtocolos search={termOfSearch} />
    </Container>
  );
};

export default PageContentProtocolos;
