/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, useMemo } from 'react';

import Input from '@/components/Input';
import InputPassword from '@/components/InputPassword';

import { FormGroupContainer, FeedbackErrorContainer } from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

interface FormGroupProps extends InputProps {
  label?: string;
  renderLabel?: () => React.ReactNode;
  renderControl?: (renderProps: InputProps) => React.ReactNode;
  errors?: string[];
  canAlterVisibility?: boolean;
}

const FormGroup = React.forwardRef<HTMLInputElement, FormGroupProps>(
  (
    {
      label,
      renderLabel,
      renderControl,
      errors,
      className,
      canAlterVisibility = true,
      ...inputProps
    },
    ref,
  ) => {
    const hasError = useMemo(() => {
      return !!(errors && errors.length && errors.every(error => error));
    }, [errors]);

    const FormGroupLabel = useMemo(() => {
      if (renderLabel) {
        return renderLabel();
      }

      if (label) {
        return <label htmlFor={inputProps.id}>{label}</label>;
      }

      return null;
    }, [renderLabel, label, inputProps]);

    const FormGroupControl = useMemo(() => {
      if (renderControl) {
        return renderControl(inputProps);
      }

      const { type, ...rest } = inputProps;

      if (type === 'password') {
        return (
          <InputPassword
            ref={ref}
            hasError={hasError}
            {...rest}
            canAlterVisibility={canAlterVisibility}
          />
        );
      }

      return <Input ref={ref} type={type} hasError={hasError} {...rest} />;
    }, [renderControl, ref, hasError, canAlterVisibility, inputProps]);

    return (
      <FormGroupContainer className={className}>
        {FormGroupLabel}
        {FormGroupControl}
        {hasError && (
          <FeedbackErrorContainer>
            {errors?.map(error => (
              <span key={error}>{error}</span>
            ))}
          </FeedbackErrorContainer>
        )}
      </FormGroupContainer>
    );
  },
);

export default FormGroup;
