import React from 'react';
import { FiX } from 'react-icons/fi';

import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import InteracaoProtocoloList from '../InteracaoProtocoloList';

import {
  DraggableSideBox,
  HistoryContainer,
  Heading,
  CloseSideBoxButton,
} from './styles';

interface Props {
  isOpen: boolean;
  onCloseSideBox?: () => void;
}

const SideBoxHistoricoInteracoes: React.FC<Props> = ({
  onCloseSideBox,
  isOpen = false,
}) => {
  const [{ history }] = useEnterpriseContext();

  if (history.data.length === 0) {
    return <></>;
  }

  return (
    <DraggableSideBox isOpen={isOpen}>
      <HistoryContainer>
        <Heading>
          <h3>Últimas interações</h3>
          <CloseSideBoxButton onClick={onCloseSideBox}>
            <FiX size={18} />
          </CloseSideBoxButton>
        </Heading>

        <InteracaoProtocoloList evolucoes={history.data} />
      </HistoryContainer>
    </DraggableSideBox>
  );
};

export default SideBoxHistoricoInteracoes;
