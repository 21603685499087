import styled from 'styled-components';

import colors from '@/design/colors';

import { HeadingSmall } from '@/design/typography';

export const CalendarContainer = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CalendarMenu = styled.div`
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarPeriod = styled.button`
  ${HeadingSmall(16)};
  padding: 7px;
  border-radius: 8px;

  text-transform: capitalize;

  transition: background-color 100ms linear;

  :focus:not(:disabled),
  :hover:not(:disabled) {
    background-color: ${colors.purple50};
  }
`;

export const CalendarActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const CalendarAction = styled.button`
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 100ms linear;

  :focus:not(:disabled),
  :hover:not(:disabled) {
    background-color: ${colors.purple50};
  }
`;

export const CalendarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
