import * as yup from 'yup';

import { ArtifactFieldInputType } from './ArtifactForm.types';

type FieldValidator = yup.Schema;

export const NumberValidator = yup
  .number()
  .transform(val => (Number.isNaN(val) ? null : val))
  .nullable();

export const StringValidator = yup.string();

const FieldTypeValidator: Record<ArtifactFieldInputType, FieldValidator> = {
  short_answer: StringValidator,
  paragraph: StringValidator,
  number_decimal: NumberValidator,
  number_integer: NumberValidator,
};

export default FieldTypeValidator;
