import React from 'react';

import useKeyboarNavigation from '@/utils/hooks/useKeyboardNavigation';

import Search from '../Search';
import { SearchContainer, SearchResults } from './styles';
import { SearchResultItem, SearchResultsProps } from '../SearchResultBox';

export interface ResultInfo<TResult> {
  item: TResult;
  index: number;
  first: boolean;
  last: boolean;
}

type SearchControl = React.InputHTMLAttributes<HTMLInputElement>;
type SearchState = { searching: boolean };

type RenderSearchInput = (
  control: SearchControl,
  state: SearchState,
) => React.ReactElement;

interface Props<TResult> {
  // HTML Input attributes
  placeholder?: string;
  autoFocus?: boolean;
  // Search default attributes
  debounceTimeInMS?: number;
  onSearch?: (term: string) => void;
  onSearchFocusOut?: () => void;
  renderSearchInput?: RenderSearchInput;
  searching?: boolean;
  // Results box attributes
  results?: TResult[];
  resultLabel: (info: ResultInfo<TResult>) => string;
  resultKeyExtractor: (info: ResultInfo<TResult>) => string;
  onResultSelect: (result: TResult) => void;
  onResultsDismiss?: () => void;
  ResultsFooter?: SearchResultsProps['ResultsFooter'];
}

function resultInfo<TResult>(
  item: TResult,
  index: number,
  array: TResult[],
): ResultInfo<TResult> {
  return {
    item,
    index,
    first: index === 0,
    last: array.length - 1 === index,
  };
}

function SearchPanel<TResult>({
  onSearch,
  onSearchFocusOut,
  renderSearchInput,
  autoFocus = false,
  debounceTimeInMS = 300,
  searching = false,
  results,
  placeholder,
  resultLabel,
  resultKeyExtractor,
  onResultSelect,
  onResultsDismiss,
  ResultsFooter,
}: Props<TResult>): React.ReactElement<Props<TResult>> {
  const ref = React.useRef<HTMLDivElement>(null);

  const searchResults: SearchResultItem[] | undefined = React.useMemo(() => {
    return results?.map<SearchResultItem>((item, index, array) => {
      const info = resultInfo(item, index, array);

      return {
        label: resultLabel(info),
        value: resultKeyExtractor(info),
      };
    });
  }, [resultKeyExtractor, resultLabel, results]);

  const isEqualResults = React.useCallback(
    (resultValue: TResult, result: SearchResultItem, index: number) => {
      if (results) {
        return (
          resultKeyExtractor(resultInfo(resultValue, index, results)) ===
          result.value
        );
      }
      return false;
    },
    [resultKeyExtractor, results],
  );

  const handleResultSelectFromClick = React.useCallback(
    (result: SearchResultItem) => {
      if (onResultSelect) {
        const value = results?.find((item, index) =>
          isEqualResults(item, result, index),
        );
        if (value) {
          onResultSelect(value);
        }
      }
    },
    [isEqualResults, onResultSelect, results],
  );

  const handleResultSelectFromKeyboard = React.useCallback(
    (selectedPos: number) => {
      if (results !== undefined) {
        const selected = results[selectedPos];

        if (selected) {
          onResultSelect(selected);
        }
      }
    },
    [onResultSelect, results],
  );

  const handleSearchFocusOut = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onSearchFocusOut) {
        // If focus was lost from inside, should not trigger focusOut
        if (event.relatedTarget && ref.current) {
          if (ref.current.contains(event.relatedTarget)) {
            return;
          }
        }

        onSearchFocusOut();
      }
    },
    [onSearchFocusOut],
  );

  const { position, setPosition, handleKeyDown } = useKeyboarNavigation({
    total: results?.length || 0,
    onEnter: handleResultSelectFromKeyboard,
    onLeave: onResultsDismiss,
  });

  const SearchInput = React.useMemo(() => {
    if (renderSearchInput) {
      return renderSearchInput(
        {
          onKeyDown: handleKeyDown,
          onBlur: handleSearchFocusOut,
        },
        { searching },
      );
    }

    return (
      <Search
        autoFocus={autoFocus}
        onChange={onSearch}
        debounceTimeInMS={debounceTimeInMS}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onBlur={handleSearchFocusOut}
      />
    );
  }, [
    autoFocus,
    debounceTimeInMS,
    handleKeyDown,
    handleSearchFocusOut,
    onSearch,
    placeholder,
    renderSearchInput,
    searching,
  ]);

  return (
    <SearchContainer ref={ref}>
      {SearchInput}

      <SearchResults
        results={searchResults}
        loading={searching}
        activeIndex={position}
        onResultItemClick={handleResultSelectFromClick}
        onResultItemHover={setPosition}
        ResultsFooter={ResultsFooter}
      />
    </SearchContainer>
  );
}

export default SearchPanel;
