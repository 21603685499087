import React from 'react';
import { FiX } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';

import { enforceElement, RenderableElement } from '@/utils/elements';
import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';

import { Overlay, Side, Title, Heading, CloseMenuButton } from './styles';

interface Props extends React.PropsWithChildren {
  title: string;
  HeaderComponent?: RenderableElement;
}

const CondutaSideBox: React.FC<Props> = ({
  title,
  children,
  HeaderComponent,
}) => {
  const {
    state: { isMenuOpen },
    toggleMenu,
  } = useCondutaComplementarContext();

  const Header = React.useMemo(() => enforceElement(HeaderComponent), [
    HeaderComponent,
  ]);

  // Aplica class noscroll no HTML para desabilitar o scroll de página
  // enquanto o menu estiver sendo exibido
  React.useEffect(() => {
    const html = document.getElementsByTagName('html')[0];

    if (isMenuOpen) {
      html.classList.add('noscroll');
    } else {
      html.classList.remove('noscroll');
    }

    return () => {
      html.classList.remove('noscroll');
    };
  }, [isMenuOpen]);

  return (
    <>
      <CSSTransition
        in={isMenuOpen}
        classNames="fade"
        unmountOnExit
        timeout={300}
      >
        <Overlay onClick={() => toggleMenu()} />
      </CSSTransition>

      <Side isOpen={isMenuOpen}>
        <Heading>
          <Title>
            <h1>{title}</h1>
            <CloseMenuButton onClick={() => toggleMenu()}>
              <FiX size={24} />
            </CloseMenuButton>
          </Title>
          {Header}
        </Heading>

        {children}
      </Side>
    </>
  );
};

export default CondutaSideBox;
