import colors from '@/styles/colors';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 130px);
`;

export const ListaContainer = styled.div`
  margin-top: 32px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadContainer = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    margin-bottom: 24px;
    animation: ${rotate} 600ms linear infinite;
  }

  span {
    color: ${colors.black};
    font-size: 18px;
    line-height: 23px;
  }
`;
