import * as Sentry from '@sentry/react';

import {
  ExecucaoEventData,
  MedflowEventData,
  toExecucaoEventData,
} from '@/models/EventData';
import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

type SendEventOptions = {
  onFailure?: (error: Error) => void;
  onFinally?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sentryLog = async (message: string, severity: Sentry.Severity) => {
  try {
    Sentry.captureMessage(message, {
      level: severity,
    });
    await Sentry.flush(500);

    // eslint-disable-next-line no-empty
  } catch (error) {}
};

async function sendEventData<TData>(
  eventData: MedflowEventData<TData>,
  options: SendEventOptions = {},
) {
  const { onFailure, onFinally } = options;

  Sentry.addBreadcrumb({
    category: 'events',
    message: `closeMedflowApp: ${JSON.stringify(eventData)}`,
    level: Sentry.Severity.Info,
  });

  try {
    if (window.location !== window.parent.location) {
      window.parent.postMessage(eventData, '*');
    } else {
      window.postMessage(eventData, '*');
    }
  } catch (error) {
    if (error instanceof Error && onFailure) {
      onFailure(error as Error);
    }
  } finally {
    if (onFinally) {
      onFinally();
    }
  }
}

export function closeMedflowApp(protocolo: ProtocoloExecutado): void {
  const eventData: MedflowEventData<ExecucaoEventData> = {
    type: 'closeMedflowApp',
    content: toExecucaoEventData(protocolo),
  };

  sendEventData(eventData, { onFinally: () => window.close() });
}

export function medflowExecutionFinish(protocolo: ProtocoloExecutado): void {
  const eventData: MedflowEventData<ExecucaoEventData> = {
    type: 'medflowExecutionFinish',
    content: toExecucaoEventData(protocolo),
  };

  sendEventData(eventData);
}
