import React from 'react';

import calculadoraProgressIcon from '@/assets/calculadora-progress-icon.svg';
import Button from '@/components/Button';

import CalculadoraTimeline from './CalculadoraTimeline';

import { Container, Header, HeaderIcon, Body, Footer } from './styles';
import { useCalculadoraContext } from '../../contexts/CalculadoraContext';

const CalculadoraProgress: React.FC = () => {
  const {
    dispatchSolicitarSaidaCalculadora,
    dispatchResetCalculadora,
  } = useCalculadoraContext().actions;

  return (
    <Container>
      <Header>
        <HeaderIcon src={calculadoraProgressIcon} alt="calculadora" />
      </Header>
      <Body>
        <CalculadoraTimeline />
      </Body>
      <Footer>
        <Button onClick={dispatchResetCalculadora} theme="secondary">
          Reiniciar
        </Button>
        <Button
          onClick={() => dispatchSolicitarSaidaCalculadora()}
          theme="secondary"
        >
          Sair
        </Button>
      </Footer>
    </Container>
  );
};

export default CalculadoraProgress;
