import React from 'react';
import { SelectionControlProps } from '../SelectionControl/types';

import SelectionControl from '../SelectionControl';
import { Container } from './styles';

type Props = Partial<SelectionControlProps & React.PropsWithChildren>;

const CheckboxLabel: React.FC<Props> = ({
  onClick,
  className,
  style,
  size = 'normal',
  state = 'unmarked',
  disabled = false,
  children,
}) => {
  return (
    <Container
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <SelectionControl.Checkbox
        disabled={disabled}
        size={size}
        state={state}
      />
      {children}
    </Container>
  );
};

export default CheckboxLabel;
