import styled from 'styled-components';
import colors from '@/styles/colors';

interface InputProps {
  isFocused?: boolean;
}

export const InputContainer = styled.div<InputProps>`
  width: 100%;
  display: flex;

  padding: 7px;
  border-radius: 8px;
  border: 1px solid
    ${props => (props.isFocused ? colors.primaryTint : colors.secondaryLite)};
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  &:focus:not(:read-only) {
    border-color: ${colors.primaryTone};
    box-shadow: 0px 0px 8px rgba(32, 71, 174, 0.64);
  }

  &:active:not(:read-only) {
    border-color: ${colors.primaryTone};
    box-shadow: none;
  }

  &::placeholder {
    color: ${colors.gray};
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;

  flex: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const InputUnidade = styled.span`
  color: ${colors.gray};
  font-size: 16px;
`;

export default Input;
