import React from 'react';

import { Menu } from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
}

const MainMenu: React.FC<Props> = ({ style, className, children }) => {
  return (
    <Menu style={style} className={className}>
      {children}
    </Menu>
  );
};

export default MainMenu;
