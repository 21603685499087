import styled from 'styled-components';

import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const LoadingTransition = styled(LoadingSwitchTransition)`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    ${HeadingSmall(16)};
  }

  a {
    ${HeadingSmall(14)};
    color: ${colors.blackAlpha200};

    &:hover {
      text-decoration: underline;
    }
  }
`;
