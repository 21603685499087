import React from 'react';

import PageLayout from '@/layouts/PageLayout';
import HeaderDefault from '@/components/HeaderDefault';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { getUserDisplayName } from '@/features/auth/helpers';

import PageContentProtocolos from '../../components/PageContentProtocolos';

const Home: React.FC = () => {
  const { user } = useAuth();
  return (
    <PageLayout
      Nav={
        <HeaderDefault
          title={`Olá, ${getUserDisplayName(user)}`}
          subtitle="Selecione um protocolo para iniciar o atendimento."
        />
      }
      style={{
        // Remove o padding lateral padrão p/ aplicá-lo seção por seção, pois o
        // padding não pode ser aplicado ao componente CarouselEspecialidade
        padding: '24px 0',
      }}
    >
      <PageContentProtocolos />
    </PageLayout>
  );
};

export default Home;
