import React from 'react';

import PageLayout from '@/layouts/PageLayout';

import ButtonBack from '@/components/ButtonBack';
import HeaderDefault from '@/components/HeaderDefault';

import PageContentProtocoloExecutadoList from '../../components/PageContentProtocoloExecutadoList';

const PageProtocoloExecutadoList: React.FC = () => {
  return (
    <PageLayout
      Nav={
        <HeaderDefault
          title="Protocolos em execução"
          subtitle="Selecione um protocolo para retomar o atendimento."
          Left={ButtonBack}
        />
      }
    >
      <PageContentProtocoloExecutadoList />
    </PageLayout>
  );
};

export default PageProtocoloExecutadoList;
