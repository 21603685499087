import fhirpath from 'fhirpath';

export function codeableConceptDisplay<RType = fhir4.FhirResource>(
  resource: RType,
  element: string,
): string {
  const displays = fhirpath.evaluate(
    resource,
    `${element}.select(text | coding.display).first()`,
  );

  if (displays.length) {
    return displays[0];
  }

  return '';
}
