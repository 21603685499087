import React from 'react';
import { MdCheck } from 'react-icons/md';

import { Container, Icon } from './styles';

import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: React.ReactNode;
}

const Toast: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Icon>
        <MdCheck />
      </Icon>

      {children}
    </Container>
  );
};

export default Toast;
