import React from 'react';
import { FiChevronDown, FiChevronUp, FiCopy } from 'react-icons/fi';
import { Slide, toast } from 'react-toastify';

import { Procedimento } from '@/models/Procedimento';
import Toast from '../Toast';
import IconButton from '../IconButton';

import {
  Container,
  Header,
  HeaderTitle,
  CollapsedContent,
  Description,
  Actions,
} from './styles';

interface BoxExameProps {
  exame: Procedimento;
  disableCopy?: boolean;
}

const BoxExame: React.FC<BoxExameProps> = ({ exame, disableCopy }) => {
  const contentRef = React.createRef<HTMLDivElement>();

  const [contentHeight, setContentHeight] = React.useState<number>(0);
  const [isOpenedDetails, setIsOpenedDetails] = React.useState(false);
  const [onHover, setOnHover] = React.useState(false);

  const hasDescription = React.useMemo(() => !!exame.descricao, [exame]);

  const toggleDetails = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (hasDescription) {
        setIsOpenedDetails(prev => !prev);
      }
    },
    [hasDescription],
  );

  const copyExame = React.useCallback(() => {
    const texto = `${exame.nome}`;
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Exame copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [exame]);

  React.useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef]);

  if (!hasDescription) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{exame.nome}</HeaderTitle>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={isOpenedDetails || onHover}>
          {exame.nome}
        </HeaderTitle>

        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar exame"
              onClick={copyExame}
              style={{ marginRight: 16 }}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiCopy />}
            />
          )}

          <IconButton
            tooltip={isOpenedDetails ? 'Mostrar menos' : 'Mostrar mais'}
            onClick={toggleDetails}
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            icon={isOpenedDetails ? <FiChevronUp /> : <FiChevronDown />}
          />
        </Actions>
      </Header>

      <CollapsedContent
        ref={contentRef}
        style={{
          maxHeight: isOpenedDetails ? contentHeight : 0,
        }}
      >
        <Description>{exame.descricao}</Description>
      </CollapsedContent>
    </Container>
  );
};

BoxExame.defaultProps = {
  disableCopy: false,
};

export default BoxExame;
