/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Button from '@/components/Button';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { ModalDialogContainer, Container } from './styles';

const ModalFinalizarProtocolo: React.FC = () => {
  const [
    { confirmacaoFinalizar },
    { dispatchConfirmarFinalizacao, dispatchCancelarFinalizacao },
  ] = useExecucaoContext();

  const { user } = useAuth();

  const [loading, setLoading] = React.useState(false);

  const isOpen = React.useMemo(() => confirmacaoFinalizar.emAndamento, [
    confirmacaoFinalizar.emAndamento,
  ]);

  const onClose = React.useCallback(() => {
    dispatchCancelarFinalizacao();
  }, [dispatchCancelarFinalizacao]);

  const handleFinalizar = React.useCallback(async () => {
    try {
      setLoading(true);

      await dispatchConfirmarFinalizacao();

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [dispatchConfirmarFinalizacao]);

  return (
    <ModalDialogContainer
      title="Finalizar protocolo"
      isOpen={isOpen}
      onAfterClose={onClose}
    >
      <Container>
        <h1>Deseja mesmo finalizar o protocolo?</h1>
        <p>
          {user?.empresa ? (
            <>
              Ao finalizar, você não poderá mais retomar o protocolo, e as
              informações preenchidas não estarão mais disponíveis aqui.
            </>
          ) : (
            <>
              Ao finalizar, as informações que você preencheu não ficarão salvas
              e não poderão ser alteradas.
            </>
          )}
        </p>
        <Button loading={loading} onClick={handleFinalizar}>
          Finalizar protocolo
        </Button>
        <Button theme="tertiary" onClick={onClose}>
          Voltar para a execução
        </Button>
      </Container>
    </ModalDialogContainer>
  );
};

export default ModalFinalizarProtocolo;
