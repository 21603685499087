import Sidebar from '@/components/Sidebar';
import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';

import { Wrapper, ContentWrapper, Content } from './styles';

export interface PageLayoutProps extends React.PropsWithChildren {
  Side?: RenderableElement;
  Nav?: RenderableElement;
  style?: React.CSSProperties;
  className?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  Side = Sidebar,
  Nav,
  children,
  style,
  className,
}) => {
  const PageSidebar = React.useMemo(() => enforceElement(Side), [Side]);

  const PageNavbar = React.useMemo(() => enforceElement(Nav), [Nav]);

  return (
    <Wrapper>
      {PageSidebar}
      <ContentWrapper>
        {PageNavbar}
        <Content style={style} className={className}>
          {children}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PageLayout;
