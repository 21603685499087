import { toInternalCodeableConceptValue } from '../components/ArtifactForm/ArtifactForm.business';
import { FieldProxy } from '../components/ArtifactForm/ArtifactForm.fields';

const EncaminhamentoForm = [
  FieldProxy({
    name: 'reasonCode',
    label: 'Indicação clínica',
    propertyPath: 'reasonCode',
    widget: 'paragraph',
    fhirPath: 'reasonCode.select(text | coding.display).first()',
    setValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'reasonCode', value, true),
  }),
  FieldProxy({
    name: 'note',
    label: 'Comentários',
    widget: 'short_answer',
    propertyPath: 'note',
    fhirPath: 'note.text',
    setValue: (resource, value) => ({
      ...resource,
      note: [{ text: value }],
    }),
  }),
];

export default EncaminhamentoForm;
