import React from 'react';

import Button from '@/components/Button';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { AutoriaProtocolo as AutoriaProtocoloInterface } from '@/models/AutoriaProtocolo';

import Avatar from '@/assets/avatar-default.svg';

import ImageLoader from '@/components/ImageLoader';
import {
  Container,
  Header,
  Title,
  HeaderFooter,
  Atualizacao,
  AtualizacaoDate,
  AtualizacaoText,
  Body,
  AutorCard,
  AutorInfo,
  AutorTitle,
  AutorDetail,
  AutorDetailItem,
  AutorDetailItemTitle,
  AutorDetailItemSubtitle,
  SectionTitle,
} from './styles';

interface AutoriaProtocoloProps {
  autoria: AutoriaProtocoloInterface;
}

const AutoriaProtocolo: React.FC<AutoriaProtocoloProps> = ({ autoria }) => {
  const { dispatchExibirFontesProtocolo } = useExecucaoContext()[1];
  const ultimaAtualizacao = React.useMemo(() => {
    /** Formata a data para o formato dd/mm/yyyy */
    return autoria.ultima_atualizacao.split('-').reverse().join('/');
  }, [autoria]);

  return (
    <Container>
      <Header>
        <Title>Detalhes do Protocolo</Title>
        <HeaderFooter>
          <Atualizacao>
            <AtualizacaoText>Última atualização:</AtualizacaoText>
            <AtualizacaoDate>{ultimaAtualizacao}</AtualizacaoDate>
          </Atualizacao>
          {!!autoria.fontes.length && (
            <Button
              theme="secondary"
              onClick={() => {
                dispatchExibirFontesProtocolo(true);
              }}
            >
              Ver fontes
            </Button>
          )}
        </HeaderFooter>
      </Header>
      {!!autoria.autor && (
        <Body>
          <AutorCard>
            <AutorTitle>Autor</AutorTitle>
            <AutorInfo>
              <ImageLoader
                src={autoria.autor?.imagem_url || Avatar}
                alt="doctor"
              />
              <AutorDetail>
                <AutorDetailItem>
                  <AutorDetailItemTitle>
                    {autoria.autor?.nome}
                  </AutorDetailItemTitle>
                  <AutorDetailItemSubtitle>
                    {autoria.autor?.especialidade &&
                      `${autoria.autor?.especialidade} | `}

                    {autoria.autor?.conselho}
                  </AutorDetailItemSubtitle>
                </AutorDetailItem>
                {autoria.autor?.formacao && (
                  <AutorDetailItem>
                    <AutorDetailItemTitle>Formação:</AutorDetailItemTitle>
                    <AutorDetailItemSubtitle>
                      {autoria.autor?.formacao}
                    </AutorDetailItemSubtitle>
                  </AutorDetailItem>
                )}
                {autoria.autor?.atuacoes && (
                  <AutorDetailItem>
                    <AutorDetailItemTitle>Atuações:</AutorDetailItemTitle>
                    <AutorDetailItemSubtitle>
                      {autoria.autor?.atuacoes}
                    </AutorDetailItemSubtitle>
                  </AutorDetailItem>
                )}
              </AutorDetail>
            </AutorInfo>
          </AutorCard>

          {!!autoria.co_autores.length && (
            <>
              <SectionTitle>Coautores</SectionTitle>
              {autoria.co_autores?.map(coAutor => {
                return (
                  <AutorCard key={coAutor.id}>
                    <AutorInfo>
                      <ImageLoader
                        src={coAutor?.imagem_url || Avatar}
                        alt="doctor"
                      />
                      <AutorDetail alignCenter>
                        <AutorDetailItem>
                          <AutorDetailItemTitle>
                            {coAutor.nome}
                          </AutorDetailItemTitle>
                          <AutorDetailItemSubtitle>
                            {coAutor?.especialidade &&
                              `${coAutor?.especialidade} | `}

                            {coAutor?.conselho}
                          </AutorDetailItemSubtitle>
                        </AutorDetailItem>
                      </AutorDetail>
                    </AutorInfo>
                  </AutorCard>
                );
              })}
            </>
          )}
        </Body>
      )}
    </Container>
  );
};

export default AutoriaProtocolo;
