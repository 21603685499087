import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import AutoScrollList, { ListRenderItem } from '@/components/AutoScrollList';
import { IntegrationProvider } from '@/features/enterprise/contexts/IntegrationContext';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import { RenderableElement, enforceElement } from '@/utils/elements';
import { PerguntaProvider } from '../../providers/PerguntaProvider';
import PassoExecutadoDetail from '../PassoExecutadoDetail';

interface Step {
  type: 'initial' | 'execution' | 'final';
  object?: PassoExecutado;
}

type GuidelineMode = 'read-only' | 'execution';

interface Props {
  // Modo de exibição do guideline
  mode: GuidelineMode;
  // Execução desabilitada
  // Usado p/ bloquear exibição de condutas enquanto se espera por uma condição
  // (ex: esperar que um formulário renderizado no componente inicial seja
  // preenchido)
  executionDisabled?: boolean;
  // Elemento a ser renderizado no COMEÇO do guideline, antes de todas as
  // condutas de protocolo
  InitialStep?: RenderableElement;
  // Elemento a ser renderizado no FIM do guideline, após todas as condutas de
  // protocolo e quando o mesmo estiver sido FINALIZADO ou INTERROMPIDO
  FinalStep?: RenderableElement;
}

const ProtocoloExecutadoGuideline: React.FC<Props> = ({
  mode,
  executionDisabled = false,
  InitialStep,
  FinalStep,
}) => {
  const [{ protocoloExecutado, passosExecutados }] = useExecucaoContext();

  const initialItems: Step[] = React.useMemo(() => {
    if (InitialStep) {
      return [{ type: 'initial' }];
    }

    return [];
  }, [InitialStep]);

  const executionItems = React.useMemo(() => {
    if (executionDisabled) {
      return [];
    }

    return passosExecutados.map<Step>(passo => ({
      type: 'execution',
      object: passo,
    }));
  }, [executionDisabled, passosExecutados]);

  const finalItems: Step[] = React.useMemo(() => {
    if (!protocoloExecutado) {
      return [];
    }

    if (
      (mode === 'read-only' && !!protocoloExecutado.data_execucao) ||
      (mode === 'execution' && !protocoloExecutado.passo_corrente)
    ) {
      return [{ type: 'final' }];
    }

    return [];
  }, [mode, protocoloExecutado]);

  const items = React.useMemo(() => {
    return [...initialItems, ...executionItems, ...finalItems];
  }, [executionItems, finalItems, initialItems]);

  const renderStep = React.useCallback((data: PassoExecutado) => {
    return (
      <IntegrationProvider>
        <PerguntaProvider>
          <PassoExecutadoDetail passoExecutado={data} />
        </PerguntaProvider>
      </IntegrationProvider>
    );
  }, []);

  const renderInitialStep = React.useCallback(() => {
    if (InitialStep) {
      return enforceElement(InitialStep) as JSX.Element;
    }

    return <></>;
  }, [InitialStep]);

  const renderFinalStep = React.useCallback(() => {
    if (FinalStep) {
      return enforceElement(FinalStep) as JSX.Element;
    }

    return <></>;
  }, [FinalStep]);

  const renderItem = React.useCallback(
    ({ item: { type, object } }: ListRenderItem<Step>) => {
      switch (type) {
        case 'initial':
          return renderInitialStep();
        case 'final':
          return renderFinalStep();
        default:
          if (object) {
            return renderStep(object);
          }
          return null;
      }
    },
    [renderFinalStep, renderInitialStep, renderStep],
  );

  const keyExtractor = React.useCallback(
    ({ item: { type, object } }: ListRenderItem<Step>) => {
      switch (type) {
        case 'execution':
          if (object) {
            return `step-${object.id}`;
          }
          return null;
        default:
          return type;
      }
    },
    [],
  );

  return (
    <AutoScrollList
      data={items}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      options={{
        topOffset: 24,
        itemSeparatorGap: 24,
        disableAutoScroll: mode === 'read-only',
      }}
    />
  );
};

export default ProtocoloExecutadoGuideline;
