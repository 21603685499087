import PageLayout from '@/layouts/PageLayout';
import React from 'react';
import HeaderCalculadora from '../../components/HeaderCalculadora';

import CalculadoraContainer from '../../components/PageContentCalculadora';
import { CalculadoraProvider } from '../../contexts/CalculadoraContext';

const CalculadoraPage: React.FC = () => {
  return (
    <CalculadoraProvider>
      <PageLayout Nav={HeaderCalculadora}>
        <CalculadoraContainer />
      </PageLayout>
    </CalculadoraProvider>
  );
};

export default CalculadoraPage;
