import React from 'react';

import {
  ExecucaoActions,
  useExecucaoActions,
  initialExecucaoActions,
} from './actions';
import {
  ExecucaoState,
  useExecucaoReducer,
  initialExecucaoState,
} from './reducer';

export type ExecucaoContextData = [ExecucaoState, ExecucaoActions];

// Context
export const ExecucaoContext = React.createContext<ExecucaoContextData>([
  initialExecucaoState,
  initialExecucaoActions,
]);

interface ProviderProps {
  children: React.ReactNode;
}

// Provider
export const ExecucaoProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useExecucaoReducer();

  const actions = useExecucaoActions(state, dispatch);

  const value: ExecucaoContextData = React.useMemo(() => [state, actions], [
    state,
    actions,
  ]);

  return (
    <ExecucaoContext.Provider value={value}>
      {children}
    </ExecucaoContext.Provider>
  );
};

// Hook
export function useExecucaoContext(): ExecucaoContextData {
  return React.useContext(ExecucaoContext);
}
