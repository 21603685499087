import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconOrientacao from '@/assets/orientacao_icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import ListaOrientacoesDefault from './index.default';
import ListaOrientacoesFhir from './index.fhir';

import { ItemList, SectionTitle } from './styles';

interface ListaOrientacoesProps {
  orientacoes: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaOrientacoes: React.FC<ListaOrientacoesProps> = ({
  orientacoes,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  return (
    <ItemList>
      <SectionTitle>
        <img src={IconOrientacao} alt="Orientação Icone" />

        <h2>Orientações para o paciente</h2>
      </SectionTitle>

      {integracaoEnterpriseAtiva ? (
        <ListaOrientacoesFhir
          orientacoes={orientacoes}
          passo={passoExecutado}
        />
      ) : (
        <ListaOrientacoesDefault orientacoes={orientacoes} />
      )}
    </ItemList>
  );
};

export default ListaOrientacoes;
