import React from 'react';
import {
  CheckboxDetailError,
  CheckboxDetailErrorText,
  CheckboxDetailErrorButton,
} from './styles';

interface ErrorBoxProps {
  handleRetry: () => void;
  handleCancel: () => void;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ handleRetry, handleCancel }) => (
  <CheckboxDetailError>
    <CheckboxDetailErrorText>
      Erro ao prescrever. Por favor, tente novamente ou prescreva diretamente no
      seu prontuário.
    </CheckboxDetailErrorText>
    <CheckboxDetailErrorButton onClick={handleRetry}>
      Tentar Novamente
    </CheckboxDetailErrorButton>
    <CheckboxDetailErrorButton onClick={handleCancel}>
      Cancelar
    </CheckboxDetailErrorButton>
  </CheckboxDetailError>
);

export default ErrorBox;
