import api from '@/sevices/api';

export type ConsentCategory = 'prescription-record-sharing';
export type ConsentAction =
  | 'collect'
  | 'access'
  | 'use'
  | 'disclose'
  | 'correct';
export type ConsentScope = 'adr' | 'research' | 'patient-privacy' | 'treatment';
export type ConsentProvisionType = 'permit' | 'deny';

export interface ConsentInfo {
  category: ConsentCategory[];
  provisionType: ConsentProvisionType;
  scope: ConsentScope;
  action: ConsentAction[];
}

async function consent(
  integrationID: string,
  { category, provisionType: provision_type }: ConsentInfo,
): Promise<fhir4.Consent[]> {
  const { data: consents } = await api.put<fhir4.Consent[]>(
    `/integracoes/${integrationID}/consentimentos/`,
    {
      category,
      provision_type,
    },
  );

  return consents;
}

export default { consent };
