import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';

import { ArrowSide, ButtonArrow, Container } from './styles';

interface ArrowProps extends React.PropsWithChildren {
  disabled: boolean;
  side: ArrowSide;
  onClick: VoidFunction;
  style?: React.CSSProperties;
  className?: string;
}

const Arrow: React.FC<ArrowProps> = ({
  children,
  disabled,
  side,
  onClick,
  className,
  style,
}) => {
  return (
    <CSSTransition in={!disabled} timeout={300} unmountOnExit>
      <Container side={side}>
        <ButtonArrow
          type="button"
          side={side}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </ButtonArrow>
      </Container>
    </CSSTransition>
  );
};

interface SideArrowProps {
  style?: React.CSSProperties;
  className?: string;
}

export const LeftArrow: React.FC<SideArrowProps> = ({ style, className }) => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow
      side="left"
      disabled={disabled}
      onClick={() => scrollPrev()}
      style={style}
      className={className}
    >
      <FiChevronLeft size={24} />
    </Arrow>
  );
};

export const RightArrow: React.FC<SideArrowProps> = ({ className, style }) => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow
      side="right"
      disabled={disabled}
      onClick={() => scrollNext()}
      className={className}
      style={style}
    >
      <FiChevronRight size={24} />
    </Arrow>
  );
};
