import React from 'react';

import UndoableButton from '@/components/UndoableButton';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

// function confirmToExit(e: BeforeUnloadEvent) {
//   let confirmationMessage = 'It looks like you have been editing something.';
//   confirmationMessage +=
//     'If you leave before saving, your changes will be lost.';

//   (e || window.event).returnValue = confirmationMessage; // Gecko + IE
//   return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
// }

const ButtonInterromperProtocolo: React.FC = () => {
  const [
    {
      // protocoloExecutado,
      status,
    },
    { dispatchInterromperProtocolo, dispatchAlterarStatus },
  ] = useExecucaoContext();

  // const refreshKeyPressed = useRefreshKeyPressed();

  // const cancelExecutionSilently = React.useCallback(() => {
  //   if (!refreshKeyPressed.current && protocoloExecutado) {
  //     ExecucaoAPI.cancelarExecucaoSilently(protocoloExecutado);
  //   }
  // }, [protocoloExecutado, refreshKeyPressed]);

  // const shouldConfirmToExit = React.useCallback(
  //   (evt: BeforeUnloadEvent) => {
  //     if (!refreshKeyPressed.current) {
  //       confirmToExit(evt);
  //     }
  //   },
  //   [refreshKeyPressed],
  // );

  // React.useEffect(() => {
  //   window.addEventListener('beforeunload', shouldConfirmToExit);
  //   window.addEventListener('unload', cancelExecutionSilently);

  //   return () => {
  //     window.removeEventListener('beforeunload', shouldConfirmToExit);
  //     window.removeEventListener('unload', cancelExecutionSilently);
  //   };
  // }, [cancelExecutionSilently, shouldConfirmToExit]);

  const loading = React.useMemo(() => status === 'on-hold', [status]);

  const handleUndoStart = React.useCallback(() => {
    dispatchAlterarStatus({ status: 'on-hold' });
  }, [dispatchAlterarStatus]);

  const handleUndo = React.useCallback(() => {
    dispatchAlterarStatus({ status: 'active' });
  }, [dispatchAlterarStatus]);

  const handleUndoTimeout = React.useCallback(() => {
    dispatchInterromperProtocolo();
    dispatchAlterarStatus({ status: 'active' });
  }, [dispatchAlterarStatus, dispatchInterromperProtocolo]);

  return (
    <UndoableButton
      disabled={loading}
      timeoutInSeconds={4}
      helpText="Os dados preenchidos não poderão ser recuperados"
      onTimerCancel={handleUndo}
      onTimerStart={handleUndoStart}
      onTimerEnd={handleUndoTimeout}
    >
      Abandonar
    </UndoableButton>
  );
};

export default ButtonInterromperProtocolo;
