import colors from '@/design/colors';
import { HeadingLarge, HeadingSmall, Text } from '@/design/typography';
import styled from 'styled-components';

export const DoctorContainer = styled.div`
  position: relative;
  min-height: 63px;
`;

export const HelloText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export const DoctorContainerTitle = styled.div`
  ${HeadingSmall(16)};
  margin-bottom: 8px;
`;

export const DoctorContent = styled.div``;

export const DoctorActiveContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const DoctorBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: ${colors.gray50};
  padding: 16px;
  border-radius: 10px;
`;

export const DoctorInfoItem = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const DoctorInfoItemTitle = styled.span`
  ${HeadingSmall(14)};
`;

export const DoctorInfoItemValue = styled.span`
  color: ${colors.gray300};
  ${Text(14)};
`;

export const DoctorBoxContent = styled.div`
  display: flex;
`;

export const DoctorImage = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const DoctorInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DoctorName = styled.span`
  ${HeadingLarge(20)};
`;

export const DoctorSpecialty = styled.span`
  ${Text(14)};
  color: ${colors.gray200};
`;
