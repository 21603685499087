import { Solicitacao } from '@/models/Solicitacao';
import { FormLoadingState } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import {
  ItemComplementarState,
  ItemComplementarUI,
  ItemComplementarUIState,
  ItemStatus,
  ItemUIDisplayMode,
  TipoItemComplementar,
} from '../models';
import { updateItemStateData } from './managers';

export const defaultItemStatus: Partial<ItemStatus> = {
  isEditing: false,
  isCanceling: false,
  isSubmitting: false,
  isAdding: false,
};

export function apiToItemState(
  solicitacao: Solicitacao,
  keepState?: Partial<ItemStatus>,
): ItemComplementarState {
  const itemID = solicitacao.id.toString();

  const defaultItemState: ItemComplementarState = {
    id: itemID,
    type: solicitacao.tipo as TipoItemComplementar,
    resource: solicitacao.pedido,
    itemState: defaultItemStatus,
  };

  if (keepState) {
    return updateItemStateData(defaultItemState, { itemState: keepState });
  }

  return defaultItemState;
}

export function itemUILoadingState(
  itemState: ItemComplementarState['itemState'],
): FormLoadingState {
  const { isCanceling = false, isSubmitting = false } = itemState || {};

  if (isSubmitting) {
    return 'confirm';
  }

  if (isCanceling) {
    return 'cancel';
  }

  return false;
}

export function itemUIDisplayMode(
  itemState: ItemComplementarState['itemState'],
): ItemUIDisplayMode {
  const { isEditing = false } = itemState || {};

  return isEditing ? 'form' : 'item';
}

export function itemToUIState(
  item: ItemComplementarState,
  info: Pick<ItemComplementarUI, 'title' | 'description'>,
): ItemComplementarUIState {
  return {
    key: item.id,
    resource: item.resource,
    ...info,
    itemState: {
      displayMode: itemUIDisplayMode(item.itemState),
      loading: itemUILoadingState(item.itemState),
    },
  };
}
