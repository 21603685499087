import React from 'react';

export type RenderableElement = React.ComponentType | React.ReactElement | null;

export function enforceElement(
  RenderableComponent: RenderableElement | undefined,
): React.ReactElement | null | undefined {
  const element = React.isValidElement(RenderableComponent)
    ? RenderableComponent
    : RenderableComponent && <RenderableComponent />;

  return element;
}
