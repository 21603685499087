import React, { useCallback } from 'react';

import RadioAlternatives from '@/components/RadioAlternatives';
import {
  toSelectionControlOptions,
  toSelectionControlValues,
} from '@/features/execucao/helpers/perguntas';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputEscolhaUnica: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const [selected, setSelected] = React.useState(
    () => toSelectionControlValues(pergunta)[0],
  );

  const wrapItems = React.useMemo(() => pergunta.variavel.multiplas_colunas, [
    pergunta.variavel,
  ]);

  const options = React.useMemo(() => toSelectionControlOptions(pergunta), [
    pergunta,
  ]);

  const handleChange = useCallback(
    (value: number) => {
      setSelected(value);
      if (onChange) {
        onChange({ [pergunta.variavel.nome]: value });
      }
    },
    [onChange, pergunta],
  );

  return (
    <RadioAlternatives
      value={selected}
      options={options}
      disabled={disabled}
      horizontal={wrapItems}
      onChange={handleChange}
    />
  );
};

export default InputEscolhaUnica;
