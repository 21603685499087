import styled, { css } from 'styled-components';

export type ArrowSide = 'left' | 'right';

export const Container = styled.div<{ side: ArrowSide }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 116px;
  height: 100%;

  ${props =>
    props.side === 'left' &&
    css`
      left: 0;
      align-items: flex-start;
      background: linear-gradient(
        90deg,
        #f0f2f5 36.74%,
        rgba(240, 242, 245, 0) 100%
      );
    `}

  ${props =>
    props.side === 'right' &&
    css`
      right: 0;
      align-items: flex-end;
      background: linear-gradient(
        270deg,
        #f0f2f5 36.74%,
        rgba(240, 242, 245, 0) 100%
      );
    `}

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: all 300ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: all 300ms;
  }
`;

export const ButtonArrow = styled.button<{ side: ArrowSide }>`
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background: #ffffff;
  /* Gray 100 */

  border: 1px solid #d9dbe3;
  border-radius: 50%;
  box-sizing: border-box;
  /* Minimum Shadows */

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
`;
