/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiPlus } from 'react-icons/fi';

import { ButtonExtraContainer } from './styles';

type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'children' | 'type'
>;

const AddManipuladosButtonUI: React.FC<ButtonProps> = props => {
  return (
    <ButtonExtraContainer {...props} type="button">
      <FiPlus size={28} />
      Adicionar manipulados
    </ButtonExtraContainer>
  );
};

export default AddManipuladosButtonUI;
