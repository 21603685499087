import axios from 'axios';
import * as qs from 'qs';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: queryParam => {
    return qs.stringify(queryParam, { arrayFormat: 'repeat' });
  },
});

export default api;
