import React from 'react';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { getUserDisplayName } from '@/features/auth/helpers';

import HeaderDefault from '@/components/HeaderDefault';

const HeaderCalculadoraList: React.FC = () => {
  const { user } = useAuth();

  return (
    <HeaderDefault
      title={`Olá, ${getUserDisplayName(user)}`}
      subtitle="Selecione uma calculadora para começar."
    />
  );
};

export default HeaderCalculadoraList;
