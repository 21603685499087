import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import {
  CalendarContainer,
  CalendarMenu,
  CalendarActions,
  CalendarAction,
  CalendarPeriod,
  CalendarContent,
} from './styles';

interface CalendarBaseProps extends React.PropsWithChildren {
  title: string;
  onNext?: () => void;
  onPrevious?: () => void;
  onPeriodChange?: () => void;
}

const CalendarBase: React.FC<CalendarBaseProps> = ({
  title,
  children,
  onPrevious,
  onNext,
  onPeriodChange,
}) => {
  return (
    <CalendarContainer>
      <CalendarMenu>
        <CalendarPeriod onClick={onPeriodChange}>{title}</CalendarPeriod>
        <CalendarActions>
          <CalendarAction onClick={onPrevious}>
            <FiChevronLeft size={24} />
          </CalendarAction>
          <CalendarAction onClick={onNext}>
            <FiChevronRight size={24} />
          </CalendarAction>
        </CalendarActions>
      </CalendarMenu>

      <CalendarContent>{children}</CalendarContent>
    </CalendarContainer>
  );
};
export default CalendarBase;
