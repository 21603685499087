import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.div``;

export const InputIcon = styled.img`
  width: 32px;
`;

export const InputText = styled.span`
  /* Layout */
  flex: 1;
  text-align: start;

  ${Text(18)};
  color: ${colors.blackAlpha200};

  /* Animation */
  transition: color 0.3s ease-in-out;
`;

export const Input = styled.button`
  width: 100%;

  background-color: ${colors.white};
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  padding: 16px 24px;

  /* Animation */
  transition: box-shadow 0.3s ease-in-out;

  position: relative;
  margin-top: -16px;

  svg {
    color: ${colors.gray100};
    transition: color 0.3s linear;
  }

  &:hover:not(:disabled) {
    box-shadow: ${shadows.smallShadow};

    ${InputText} {
      color: ${colors.purple100};
    }

    svg {
      color: ${colors.purple100};
    }
  }
`;

export const InputContent = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  display: flex;

  column-gap: 16px;
  min-height: 32px;
`;

export const MetaInfo = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
  height: 50px;
  min-width: 270px;
  z-index: -1;

  background-color: ${colors.gray100};
  border-radius: 8px;
  padding: 8px 24px;

  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};
`;
