import styled, { css } from 'styled-components';

import SelectionControlInput from '../SelectionControlInput';

export const AlternativeInput = styled(SelectionControlInput)``;

interface LayoutProps {
  horizontal: boolean;
}

export const AlternativesContainer = styled.div<LayoutProps>`
  flex: 1;
  display: flex;
  row-gap: 8px;
  column-gap: 8px;

  ${({ horizontal }) =>
    horizontal
      ? css`
          flex-flow: row wrap;
        `
      : css`
          flex-direction: column;
        `};
`;

export const AlternativeInputWrapper = styled.div<LayoutProps>`
  ${({ horizontal }) =>
    horizontal &&
    css`
      &:nth-child(2n + 2) {
        flex: 0 0.5 50%;
      }

      &:nth-child(2n + 1) {
        flex: 0 0.5 calc(50% - 8px);
      }
    `}
`;
