import styled from 'styled-components';

export const CheckboxDetailError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxDetailErrorText = styled.div`
  margin-left: 10px;
  margin-right: auto;
`;

export const CheckboxDetailErrorButton = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Red 100 */

  color: #eb3b5a;

  cursor: pointer;

  margin-right: 14px;

  &:nth-child(2) {
    margin-left: auto;
  }

  :hover {
    text-decoration: underline;
  }
`;
