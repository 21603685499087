import React from 'react';

import { ReactComponent as ArrivalFlag } from '@/assets/illustrations/feedback-graphics/arrival.svg';

import { closeMedflowApp } from '@/utils/window';
import { Container, Button } from './styles';
import { useExecucaoContext } from '../../providers/ExecucaoProvider';

const ProtocoloFinalizadoEnterprise: React.FC<{
  hideContent?: boolean;
}> = ({ hideContent }) => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const closeTab = React.useCallback(() => {
    if (protocoloExecutado) {
      closeMedflowApp(protocoloExecutado);
    }
  }, [protocoloExecutado]);

  return (
    <Container>
      <ArrivalFlag />
      <h3>Protocolo Finalizado</h3>
      {!hideContent && (
        <>
          <p>Por favor, continue o atendimento no seu prontuário.</p>
          <Button theme="terciary" onClick={() => closeTab()}>
            Fechar
          </Button>
        </>
      )}
    </Container>
  );
};

export default ProtocoloFinalizadoEnterprise;
