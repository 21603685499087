import { JWTPair } from '@/features/auth/types/token';
import { User } from '@/models/User';

export default class StoreManager {
  private storage: Storage;

  private storageKeyPrefix: string;

  private static AUTH_TOKEN = 'authtoken';

  private static AUTH_USER = 'authuser';

  constructor(storage: Storage, storageKeyPrefix = 'Medflow@') {
    this.storage = storage;
    this.storageKeyPrefix = storageKeyPrefix;
  }

  private key(storageKey: string) {
    return this.storageKeyPrefix + storageKey;
  }

  private setObject(key: string, obj: unknown | null) {
    if (obj) {
      this.storage.setItem(this.key(key), JSON.stringify(obj));
    }
  }

  private getObject<TObject>(key: string): TObject | null {
    const objString = this.storage.getItem(this.key(key));
    if (objString) {
      return JSON.parse(objString) as TObject;
    }

    return null;
  }

  clear(): void {
    this.storage.clear();
  }

  /** JWT Pair (access and refresh token) */
  setJwtPair(jwtPair: JWTPair | null): void {
    this.setObject(StoreManager.AUTH_TOKEN, jwtPair);
  }

  removeJwtPair(): void {
    this.removeJwtPair();
  }

  get jwtPair(): JWTPair | null {
    return this.getObject<JWTPair>(StoreManager.AUTH_TOKEN);
  }

  /** Logged user */
  setUser(user: User | null): void {
    this.setObject(StoreManager.AUTH_USER, user);
  }

  removeUser(): void {
    this.storage.removeItem(this.key(StoreManager.AUTH_USER));
  }

  get user(): User | null {
    return this.getObject(StoreManager.AUTH_USER);
  }

  hasData(): boolean {
    return Object.entries(this.storage).some(([key]) =>
      key.startsWith(this.storageKeyPrefix),
    );
  }
}
