import React from 'react';

import { InfoBox, Alert, AlertIcon } from './styles';

export type InfoType = 'info' | 'warning' | 'danger';

interface Props extends React.PropsWithChildren {
  type?: InfoType;
  className?: string;
  style?: React.CSSProperties;
}

const Information: React.FC<Props> = ({
  type = 'info',
  className,
  style,
  children,
}) => {
  return (
    <InfoBox type={type} style={style} className={className}>
      <Alert>
        <AlertIcon />
      </Alert>
      {children}
    </InfoBox>
  );
};

export default Information;
