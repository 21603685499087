import React from 'react';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isEqual,
  startOfDay,
  startOfMonth,
  endOfWeek,
  startOfWeek,
  addMonths,
  subMonths,
  isSameMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';

import CalendarBase from '../Base';
import { CalendarPeriodProps } from '../types';

import { CalendarGrid, WeeklyLabel, DailyPicker } from './styles';

const CalendarMonthly: React.FC<CalendarPeriodProps> = ({
  value,
  period,
  onChange,
  onPeriodChange,
}) => {
  // Actual value (selected)
  const selectedDate = value && startOfDay(value);
  // Active displayed date (start of month)
  const [currentDate, setCurrentDate] = React.useState(
    startOfMonth(period || value || new Date()),
  );

  React.useEffect(() => {
    if (period) {
      setCurrentDate(startOfMonth(period));
    }
  }, [period]);

  const weeks = React.useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(currentDate),
        end: endOfWeek(currentDate),
      }),
    [currentDate],
  );

  const title = React.useMemo(
    () => format(currentDate, 'MMMM - yyyy', { locale: ptBR }),
    [currentDate],
  );

  const days = React.useMemo(() => {
    const start = startOfWeek(startOfMonth(currentDate));
    const end = endOfWeek(endOfMonth(currentDate));

    return eachDayOfInterval({
      start,
      end,
    });
  }, [currentDate]);

  const isToday = React.useCallback(
    (date: Date) => isEqual(date, startOfDay(new Date())),
    [],
  );

  const isActive = React.useCallback(
    (date: Date) => !!selectedDate && isEqual(date, selectedDate),
    [selectedDate],
  );

  const handlePreviousMonth = React.useCallback(() => {
    setCurrentDate(current => subMonths(current, 1));
  }, []);

  const handleNextMonth = React.useCallback(() => {
    setCurrentDate(current => addMonths(current, 1));
  }, []);

  const handlePeriodSelect = React.useCallback(() => {
    if (onPeriodChange) {
      onPeriodChange(currentDate);
    }
  }, [currentDate, onPeriodChange]);

  const handlePickerSelect = React.useCallback(
    (date: Date) => {
      if (!isSameMonth(date, currentDate)) {
        setCurrentDate(startOfMonth(date));
      }

      if (onChange) {
        onChange(date);
      }
    },
    [currentDate, onChange],
  );

  return (
    <CalendarBase
      title={title}
      onPeriodChange={handlePeriodSelect}
      onNext={handleNextMonth}
      onPrevious={handlePreviousMonth}
    >
      <CalendarGrid>
        {weeks.map(week => {
          // Domingo, Segunda-feira, ..., Sábado
          const dayOfWeek = format(week, 'EEEE', { locale: ptBR });
          // D, S, T, Q, Q, S, S
          const dayOfWeekAbbrev = format(week, 'EEEEE', { locale: ptBR });

          return (
            <WeeklyLabel key={dayOfWeek} aria-label={dayOfWeek}>
              {dayOfWeekAbbrev}
            </WeeklyLabel>
          );
        })}

        {days.map(day => (
          <DailyPicker
            key={`${day.toISOString()}`}
            today={isToday(day)}
            active={isActive(day)}
            onClick={() => handlePickerSelect(day)}
          >
            {day.getDate()}
          </DailyPicker>
        ))}
      </CalendarGrid>
    </CalendarBase>
  );
};

export default CalendarMonthly;
