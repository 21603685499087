import React from 'react';

import { enforceElement, RenderableElement } from '@/utils/elements';

import InfiniteScroll from 'react-infinite-scroller';
import { FlatListContainer, FlatListContentContainer } from './styles';

export interface ListRenderItem<ItemT> {
  item: ItemT;
  index: number;
  first: boolean;
  last: boolean;
}

export interface FlatListProps<ItemT> {
  data: ItemT[];
  renderItem: (info: ListRenderItem<ItemT>) => React.ReactElement | null;
  keyExtractor: (info: ListRenderItem<ItemT>) => React.Key | null;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  hasMore?: boolean;
  ListHeaderComponent?: RenderableElement;
  ListFooterComponent?: RenderableElement;
  ListEmptyComponent?: RenderableElement;
  ListContentContainerElement?: React.ComponentType<React.PropsWithChildren>;
  style?: React.CSSProperties;
  className?: string;
}

function FlatList<ItemT>({
  data,
  renderItem,
  keyExtractor,
  onEndReached,
  onEndReachedThreshold,
  ListFooterComponent,
  ListHeaderComponent,
  ListEmptyComponent,
  ListContentContainerElement,
  hasMore = false,
  style,
  className,
}: FlatListProps<ItemT>): React.ReactElement<FlatListProps<ItemT>> {
  const Header = React.useMemo(() => enforceElement(ListHeaderComponent), [
    ListHeaderComponent,
  ]);

  const Footer = React.useMemo(() => enforceElement(ListFooterComponent), [
    ListFooterComponent,
  ]);

  const Empty = React.useMemo(() => enforceElement(ListEmptyComponent), [
    ListEmptyComponent,
  ]);

  const ListContentContainer = React.useCallback(
    ({ children }: React.PropsWithChildren) => {
      const ContentContainer =
        ListContentContainerElement || FlatListContentContainer;

      if (onEndReached) {
        return (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={onEndReached}
            threshold={onEndReachedThreshold}
          >
            <ContentContainer>{children}</ContentContainer>
          </InfiniteScroll>
        );
      }

      return <ContentContainer>{children}</ContentContainer>;
    },
    [ListContentContainerElement, hasMore, onEndReached, onEndReachedThreshold],
  );

  const ListContent = React.useMemo(
    () => (
      <ListContentContainer>
        {data.map((item, index, array) => {
          const info = {
            item,
            index,
            first: index === 0,
            last: index === array.length - 1,
          };

          return (
            <React.Fragment key={keyExtractor(info)}>
              {renderItem(info)}
            </React.Fragment>
          );
        })}
      </ListContentContainer>
    ),
    [ListContentContainer, data, keyExtractor, renderItem],
  );

  return (
    <FlatListContainer style={style} className={className}>
      {Header}
      {data.length ? ListContent : Empty}
      {Footer}
    </FlatListContainer>
  );
}

export default FlatList;
