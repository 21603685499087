import React, { useMemo, useCallback } from 'react';

import { TipoVariavel } from '@/models/Expressao';
import Button from '@/components/Button';

import { InputPerguntaProps } from '@/features/execucao/components/PerguntaDetail/types';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';
import { RespostaPayload } from '@/features/execucao/types';

import InputEscolhaUnica from './InputEscolhaUnica';
import InputSimOuNao from './InputSimOuNao';
import InputMultiplaEscolha from './InputMultiplaEscolha';
import InputNumerico from './InputNumerico';

import {
  Container,
  PerguntaTitle,
  Overlay,
  Footer,
  InstrucoesContainer,
  InstrucoesTitle,
  InstrucoesDetail,
} from './styles';

interface PerguntaProps extends InputPerguntaProps {
  isFirstPergunta: boolean;
  isLastPergunta: boolean;
}

const PerguntaComponent = React.forwardRef<HTMLDivElement, PerguntaProps>(
  (
    { pergunta, disabled = false, onChange, isFirstPergunta, isLastPergunta },
    ref,
  ) => {
    const {
      calculadora,
      calculandoResultado,
      payloadCalculadora,
    } = useCalculadoraContext().state;
    const { dispatchCalcularResultado } = useCalculadoraContext().actions;

    const handleOnChange = useCallback(
      (resposta: RespostaPayload) => {
        if (onChange) {
          onChange(resposta);
        }
      },
      [onChange],
    );

    /** Botão de calculadar desabilitado caso não tenha respodido todas as perguntas */
    const isCalcularDisabled = useMemo(() => {
      return !!Object.values(payloadCalculadora).filter(value => value === null)
        .length;
    }, [payloadCalculadora]);

    const Input = useMemo(() => {
      if (pergunta.variavel.opcoes.length > 0) {
        return pergunta.variavel.multipla_escolha
          ? InputMultiplaEscolha
          : InputEscolhaUnica;
      }

      if (pergunta.variavel.tipo === TipoVariavel.BOOLEANA) {
        return InputSimOuNao;
      }

      return InputNumerico;
    }, [pergunta]);

    return (
      <Container ref={ref}>
        {disabled && <Overlay />}

        {isFirstPergunta && (
          <InstrucoesContainer>
            <InstrucoesTitle>Instruções</InstrucoesTitle>
            <InstrucoesDetail>{calculadora?.instrucoes}</InstrucoesDetail>
          </InstrucoesContainer>
        )}

        <PerguntaTitle>{pergunta.descricao}</PerguntaTitle>

        <Input pergunta={pergunta} onChange={handleOnChange} />

        {isLastPergunta && (
          <Footer>
            <Button
              disabled={calculandoResultado || isCalcularDisabled}
              onClick={dispatchCalcularResultado}
            >
              Calcular
            </Button>
          </Footer>
        )}
      </Container>
    );
  },
);

export default PerguntaComponent;
