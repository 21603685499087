import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  flex: 1;
  color: ${colors.blackAlpha200};

  ${({ highlight = false }) =>
    highlight ? HeadingSmall(16) : HeadingSmall(14)};
`;

export const HeaderSubTitle = styled.h3`
  display: flex;
  align-items: center;
  padding: 8px 0 0;
  align-self: left;

  ${Text(14)};
  color: ${colors.gray300};
`;

export const OpmeList = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 8px;

  gap: 8px;

  width: 100%;
`;

export const OpmesNaoPadroesContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  row-gap: 8px;

  width: 100%;
`;

export const OpmesEmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  width: 100%;
`;

export const ButtonAddOpme = styled.button`
  display: flex;
  align-items: center;
  margin-top: 8px;
  column-gap: 16px;

  color: ${colors.blackAlpha200};
  /* Text 14 */
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  :focus:not(:disabled),
  :hover:not(:disabled) {
    color: ${colors.purple100};

    > svg {
      background: ${colors.purple100};

      color: ${colors.white};
    }
  }

  > svg {
    border-radius: 999px;
    background: ${colors.white};

    color: ${colors.gray300};
  }
`;

export const OpmeRow = styled.div`
  display: flex;
  flex-direction: row;

  align-items: baseline;

  column-gap: 8px;

  width: 100%;
`;
