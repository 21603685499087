import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';

import Toast from '@/components/Toast';

import { RespostaPayload } from '@/features/execucao/types';

import { Calculadora } from '@/models/Calculadora';
import { Pergunta } from '@/models/Pergunta';
import { Expressao } from '@/models/Expressao';

import CalculadoraAPI from '../../services/CalculadoraAPI';

export interface CalculadoraState {
  /** Calculadora Selecionada da lista */
  calculadora: Calculadora | null;
  /**
   * Payload da calculadora
   * */
  payloadCalculadora: RespostaPayload;
  /** Lista de perguntas da calculadora */
  perguntas: Pergunta[];
  /** Lista de resultados */
  resultados: Expressao[];
  /** Carregando dados da calculadora */
  loadingCalculadora: boolean;
  /** Calculando resutaldo da calculadora */
  calculandoResultado: boolean;
  /** Erro ao tentar abrir a calculadora */
  hasCalculadoraError: boolean;
  /** Ocorreu algum erro ao calcular */
  hasCalculoError: boolean;
  /** Estado de finalizar calculadora */
  finalizarCalculadora: {
    emAndamento: boolean;
  };
  /** Estado para sair da calculadora */
  sairCalculadora: {
    emAndamento: boolean;
    proximo: string;
  };
}

export interface CalculadoraActions {
  /**
   * Ação responsável por carregar os dados de uma calculadora via API
   */
  dispatchOpenCalculadora: (codigo: string) => Promise<void>;
  /**
   * Ação responsável por realizar o cálculo de uma calculadora
   */
  dispatchCalcularResultado: () => void;
  /** Ação para solicitar o processo de finalizar a calculadora */
  dispatchSolicitarFinalizarCalculadora: () => void;
  /** Ação para finalizar a calculadora */
  dispatchFinalizarCalculadora: () => void;
  /** Ação para cancelar abertura da modal de finalizar */
  dispatchCancelarFinalizarCalculadora: () => void;
  /** Ação para copiar os resultados da calculadora */
  dispatchCopiarResultados: () => void;
  /** Ação para solicitar a saída da calculadora */
  dispatchSolicitarSaidaCalculadora: (proximo?: string) => void;
  /** Ação para confirmar saída da calculadora */
  dispatchConfirmarSaidaCalculadora: () => void;
  /** Ação para cancelar a saída da calculadora */
  dispatchCancelarSaidaCalculadora: () => void;
  /** Ação para atualizar o payload de pergunta */
  dispatchUpdatePayload: (resposta: RespostaPayload) => void;
  /** Ação para resetar calculadora */
  dispatchResetCalculadora: () => void;
}

export interface CalculadoraContextData {
  state: CalculadoraState;
  actions: CalculadoraActions;
}

/** Context's initial value */
const initialValue: CalculadoraContextData = {
  state: {
    calculadora: null,
    perguntas: [],
    resultados: [],
    calculandoResultado: false,
    loadingCalculadora: false,
    hasCalculadoraError: false,
    hasCalculoError: false,
    finalizarCalculadora: {
      emAndamento: false,
    },
    sairCalculadora: {
      emAndamento: false,
      proximo: '/calculadoras',
    },
    payloadCalculadora: {} as RespostaPayload,
  },
  actions: {} as CalculadoraActions,
};

/** Context */
const CalculadoraContext = React.createContext<CalculadoraContextData>(
  initialValue,
);

interface ProviderProps {
  children: React.ReactNode;
}

/** Provider */
export const CalculadoraProvider: React.FC<ProviderProps> = ({ children }) => {
  const history = useHistory();
  const [state, setState] = useState(initialValue.state);

  const getInitialCalculadoraPayload = (calculadora: Calculadora) => {
    const { perguntas } = calculadora;
    const variaveis = perguntas.map(pergunta => pergunta.variavel.nome);
    return variaveis.reduce(
      (o, key) => Object.assign(o, { [key]: null }),
      {},
    ) as RespostaPayload;
  };

  /** Context Actions Definition */
  const actions: CalculadoraActions = useMemo(() => {
    return {
      dispatchOpenCalculadora: async (codigo: string) => {
        setState(prev => ({
          ...prev,
          loadingCalculadora: true,
          hasCalculadoraError: false,
        }));

        try {
          const calculadora = await CalculadoraAPI.getCalculadora(codigo);
          const payload = getInitialCalculadoraPayload(calculadora);

          setState(prev => ({
            ...prev,
            calculadora,
            perguntas: calculadora.perguntas,
            payloadCalculadora: payload,
            loadingCalculadora: false,
          }));
        } catch (error) {
          setState(prev => ({
            ...prev,
            loadingCalculadora: false,
            hasCalculadoraError: true,
          }));
        }
      },

      dispatchCalcularResultado: async () => {
        const { calculadora, payloadCalculadora } = state;

        setState(prev => ({
          ...prev,
          calculandoResultado: true,
          hasCalculoError: false,
        }));

        if (calculadora) {
          try {
            const resultado = await CalculadoraAPI.calcular(
              calculadora,
              payloadCalculadora,
            );

            setState(prev => ({
              ...prev,
              calculadora: resultado,
              resultados: resultado.resultados,
              calculandoResultado: false,
            }));
          } catch (error) {
            setState(prev => ({
              ...prev,
              hasCalculoError: true,
              calculandoResultado: false,
            }));
          }
        }
      },

      dispatchSolicitarFinalizarCalculadora: () => {
        setState(prev => ({
          ...prev,
          finalizarCalculadora: {
            emAndamento: true,
          },
        }));
      },

      dispatchFinalizarCalculadora: () => {
        history.replace('/calculadoras');
      },

      dispatchCancelarFinalizarCalculadora: () => {
        setState(prev => ({
          ...prev,
          finalizarCalculadora: {
            emAndamento: false,
          },
        }));
      },

      dispatchCopiarResultados: () => {
        if (state.calculadora) {
          const { resultados } = state.calculadora;
          const resultadoFormatado = resultados.map(
            r => `${r.descricao}: ${r.valor_formatado}`,
          );

          navigator.clipboard.writeText(resultadoFormatado.join('\n'));

          toast.dark(
            () => (
              <Toast>
                <p>Copiado</p>
              </Toast>
            ),
            {
              position: 'bottom-center',
              transition: Slide,
            },
          );
        }
      },

      dispatchSolicitarSaidaCalculadora: (proximo?: string) => {
        setState(prev => ({
          ...prev,
          sairCalculadora: {
            emAndamento: true,
            proximo: proximo || '/calculadoras',
          },
        }));
      },

      dispatchCancelarSaidaCalculadora: () => {
        setState(prev => ({
          ...prev,
          sairCalculadora: {
            emAndamento: false,
            proximo: '/calculadoras',
          },
        }));
      },

      dispatchConfirmarSaidaCalculadora: () => {
        setState(prev => ({
          ...prev,
          sairCalculadora: {
            emAndamento: false,
            proximo: '/calculadoras',
          },
        }));

        history.replace(state.sairCalculadora.proximo);
      },

      dispatchUpdatePayload: (resposta: RespostaPayload) => {
        setState(prev => ({
          ...prev,
          payloadCalculadora: {
            ...prev.payloadCalculadora,
            ...resposta,
          },
        }));
      },

      dispatchResetCalculadora: () => {
        const resetPayload = { ...state.payloadCalculadora };

        /** Limpando o payload */
        Object.keys(resetPayload).forEach(key => {
          resetPayload[key] = null;
        });

        setState(prev => ({
          ...prev,
          resultados: [],
          payloadCalculadora: resetPayload,
        }));
      },
    };
  }, [state, history]);

  const value: CalculadoraContextData = React.useMemo(() => {
    return { state, actions };
  }, [state, actions]);

  return (
    <CalculadoraContext.Provider value={value}>
      {children}
    </CalculadoraContext.Provider>
  );
};

export function useCalculadoraContext(): CalculadoraContextData {
  return React.useContext(CalculadoraContext);
}
