import httpStatus from 'http-status';
import React from 'react';

import StoreManager from '@/business/StoreManager';
import {
  AuthInterceptors,
  configureAuthHandlers,
} from '@/features/auth/services/AuthHandlers';
import AuthService from '@/features/auth/services/AuthService';
import UserService from '@/features/auth/services/UserService';
import { RegisterPayload } from '@/features/auth/types/registration';
import api from '@/sevices/api';
import { isClientError, isHttpStatusError, isServerError } from '@/utils/http';

import { doLogin, getAuthHandler, getStore } from './helpers';
import { Auth, AuthStrategy, LoginInput } from './types';

const AuthInitial: Auth = {
  user: null,
  status: 'unknown',
  setUser: () => null,
  login: () => Promise.resolve(),
  logout: () => null,
  register: () => null,
  setUp: () => null,
};

const apiShouldUseCredentials =
  process.env.REACT_APP_API_USE_CREDENTIALS !== 'false';

const defaultStrategy: AuthStrategy = apiShouldUseCredentials
  ? 'httponly'
  : 'storage';

export const AuthContext = React.createContext<Auth>(AuthInitial);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const interceptors = React.useRef<AuthInterceptors | null>(null);
  const authStrategy = React.useRef<AuthStrategy | null>(null);

  const [user, setUser] = React.useState<Auth['user']>(AuthInitial.user);
  const [status, setStatus] = React.useState<Auth['status']>(
    AuthInitial.status,
  );

  const logout = React.useCallback((logoutStatus: 'cancelled' | 'revoked') => {
    // Detach auth handlers
    if (interceptors.current) {
      api.interceptors.request.eject(interceptors.current.request);
      api.interceptors.request.eject(interceptors.current.response);
    }

    // Clear broswer storage
    if (authStrategy.current) {
      getStore(authStrategy.current).clear();
      authStrategy.current = null;
    }

    AuthService.signOut();

    setStatus(prev => {
      if (prev === 'cancelled') return prev;
      return logoutStatus;
    });
    setUser(null);
  }, []);

  const setUpAuth = React.useCallback(
    (strategy: AuthStrategy) => {
      // Configure provider strategy ref (prevents re-render)
      authStrategy.current = strategy;

      // Confugure API to use or not credentials for cross-site Acess-Control
      // requests (store and send cookies)
      api.defaults.withCredentials = strategy === 'httponly';

      const store = getStore(strategy);
      const handler = getAuthHandler(strategy, store);

      interceptors.current = configureAuthHandlers({
        api,
        handlers: {
          ...handler,
          onRefreshFail(error) {
            if (isHttpStatusError(error, httpStatus.UNAUTHORIZED)) {
              logout('revoked');
            }
          },
        },
      });
    },
    [logout],
  );

  const login: Auth['login'] = React.useCallback(
    async (input: LoginInput) => {
      setStatus('on-hold');
      setUpAuth(input.provider === 'integrated' ? 'session' : defaultStrategy);

      try {
        const data = await doLogin(input);
        setUser(data.user);
        setStatus('active');
      } catch (error) {
        setStatus('entered-in-error');
        throw error;
      }
    },
    [setUpAuth],
  );

  const register: Auth['register'] = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (payload: RegisterPayload) => null,
    [],
  );

  const loadUser = React.useCallback(() => {
    UserService.loadUser()
      .then(data => {
        if (data) {
          setUser(data);
          setStatus('active');
        } else {
          setStatus('cancelled');
        }
      })
      .catch(error => {
        // Token expired or inexistant
        if (isClientError(error)) {
          setStatus('unavailable');
        }
        // Unknown Error
        if (isServerError(error)) {
          setStatus('entered-in-error');
        }
      });
  }, []);

  const loadStrategy: () => AuthStrategy = React.useCallback(() => {
    const hasStoredDataForThisSession = new StoreManager(
      sessionStorage,
    ).hasData();

    if (hasStoredDataForThisSession) {
      return 'session';
    }

    return defaultStrategy;
  }, []);

  const setUp = React.useCallback(() => {
    if (!authStrategy.current) {
      const strategy = loadStrategy();
      setUpAuth(strategy);
      loadUser();
    }
  }, [loadStrategy, loadUser, setUpAuth]);

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        setUser,
        setUp,
        login,
        logout: () => logout('cancelled'),
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
