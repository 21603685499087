import colors from '@/styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  display: block;
  overflow: hidden;
  background-color: transparent;
  margin-top: 16px;
  transition: max-height 400ms ease-in,
    transform 400ms cubic-bezier(0, 1, 0.6, 1) 100ms,
    opacity 200ms ease-out 100ms;

  &.enter {
    opacity: 0;
    transform: translateY(-50%);
    max-height: 0;
  }

  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    max-height: 100vh;
  }

  &.exit {
    opacity: 1;
    transform: translateY(0);
    max-height: 100vh;
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-50%);
    max-height: 0;
  }
`;

export const NavItemInfo = styled.div<{ transition: number[] }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.hardGray};
  margin-top: 16px;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 0px 12px 0px;
  overflow-x: auto;

  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }

  &.fade-enter-active {
    opacity: 0;
    height: ${props => props.transition[0]}px;
  }
  &.fade-enter-done {
    opacity: 1;
    height: ${props => props.transition[1]}px;
    transition: opacity 100ms linear 200ms, height 200ms ease-out;
  }
`;

export const NavContents = styled.div`
  transition: all 300ms ease-in-out;
`;
