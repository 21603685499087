import React from 'react';

import { TipoVariavel } from '@/models/Expressao';
import { Pergunta } from '@/models/Pergunta';

import { scrollToWithStickyHeader } from '@/utils/document';

import { RespostaPayload } from '../../types';

import InputData from './InputData';
import InputDataHora from './InputDataHora';
import InputEscolhaUnica from './InputEscolhaUnica';
import InputHora from './InputHora';
import InputMultiplaEscolha from './InputMultiplaEscolha';
import InputNumerico from './InputNumerico';
import InputNumericoDecimal from './InputNumericoDecimal';
import InputNumericoInteiro from './InputNumericoInteiro';
import InputSimOuNao from './InputSimOuNao';
import InputString from './InputString';
import NullableInput from './NullableInput';
import { Container, DescricaoPergunta } from './styles';
import { InputPergunta } from './types';

interface PerguntaDetailProps {
  pergunta: Pergunta;
  disabled?: boolean;
  onChange?: (payload: RespostaPayload) => void;
  addDescriptionSize?: (rectHeight: number, perguntaType: TipoVariavel) => void;
}

export interface PerguntaDetailRef {
  require: () => void;
  scrollTo: (customOffset?: number) => void;
}

const PerguntaInputMap: { [T in TipoVariavel]: InputPergunta } = {
  [TipoVariavel.BOOLEANA]: InputSimOuNao,
  [TipoVariavel.NUMERICA]: InputNumerico,
  [TipoVariavel.INTEIRO]: InputNumericoInteiro,
  [TipoVariavel.DECIMAL]: InputNumericoDecimal,
  [TipoVariavel.DATA]: InputData,
  [TipoVariavel.HORA]: InputHora,
  [TipoVariavel.DATA_HORA]: InputDataHora,
  [TipoVariavel.STRING]: InputString,
};

const PerguntaDetail = React.forwardRef<PerguntaDetailRef, PerguntaDetailProps>(
  ({ pergunta, disabled, onChange, addDescriptionSize }, ref) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const descriptionRef = React.useRef<HTMLDivElement>(null);

    const [requiresAnswer, setRequireFocus] = React.useState(false);

    const perguntaEscolha = React.useMemo(
      () => pergunta.variavel.opcoes.length > 0,
      [pergunta.variavel.opcoes.length],
    );

    const Input: InputPergunta = React.useMemo(() => {
      if (perguntaEscolha) {
        return pergunta.variavel.multipla_escolha
          ? InputMultiplaEscolha
          : InputEscolhaUnica;
      }

      return PerguntaInputMap[pergunta.variavel.tipo];
    }, [pergunta, perguntaEscolha]);

    const handleChange = React.useCallback(
      (payload: RespostaPayload) => {
        if (onChange) {
          onChange(payload);
        }
      },
      [onChange],
    );

    React.useImperativeHandle(
      ref,
      () => ({
        require: () => {
          setRequireFocus(true);
          setTimeout(() => setRequireFocus(false), 1000);
        },

        scrollTo: customOffset => {
          if (containerRef.current) {
            scrollToWithStickyHeader(containerRef.current, customOffset || 24);
          }
        },
      }),
      [],
    );

    React.useEffect(() => {
      if (descriptionRef.current && addDescriptionSize) {
        addDescriptionSize(
          descriptionRef.current.clientHeight,
          pergunta.variavel.tipo,
        );
      }
    }, [addDescriptionSize, descriptionRef, pergunta.variavel.tipo]);

    return (
      <Container autoFocus={requiresAnswer} ref={containerRef}>
        <DescricaoPergunta ref={descriptionRef} text={pergunta.descricao} />
        {pergunta.variavel.allow_null ? (
          <NullableInput
            pergunta={pergunta}
            disabled={disabled}
            onChange={handleChange}
            inputElement={Input}
          />
        ) : (
          <Input
            pergunta={pergunta}
            disabled={disabled}
            onChange={handleChange}
          />
        )}
      </Container>
    );
  },
);

PerguntaDetail.defaultProps = {
  disabled: false,
  onChange: undefined,
};

export default PerguntaDetail;
