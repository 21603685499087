import styled from 'styled-components';
import colors from '@/styles/colors';

export default styled.div`
  width: 14px;
  height: 14px;
  background-color: ${colors.white};
  border-radius: 50%;
  border: 2px solid ${colors.secondaryLite};

  margin-left: -8px;
`;
