import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';

export const Side = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  height: 100vh;
  row-gap: 24px;
  background-color: ${colors.white};
  box-shadow: ${shadows.shadowSideBox};

  overflow-x: hidden;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Header = styled.div`
  padding: 0 24px 26px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
`;
