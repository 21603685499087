import React from 'react';

import BoxMedicamento from '@/features/recursos/medicamentos/components/BoxMedicamento';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { groupBy } from '@/utils/collections';
import CategoriaArtefato from '@/features/execucao/components/CategoriaArtefato';

import { Content, Wrapper, DefaultList } from './styles';

interface MedicamentosProps {
  medicamentos: FhirArtefatoType[];
}

const ListaMedicamentosDefault: React.FC<MedicamentosProps> = ({
  medicamentos,
}) => {
  const medicamentosHasCategorias = React.useMemo(() => {
    return (
      medicamentos.filter(medicamento => !!medicamento.categoria).length > 0
    );
  }, [medicamentos]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      medicamentos,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(medicamento => (
          <BoxMedicamento
            key={medicamento.id.toString()}
            medicamento={medicamento.pedido}
          />
        ))}
      />
    ));
  }, [medicamentos]);

  return (
    <Wrapper>
      <Content>
        {medicamentosHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <DefaultList>
            {medicamentos.map(medicamento => (
              <BoxMedicamento
                key={medicamento.id.toString()}
                medicamento={medicamento.pedido}
              />
            ))}
          </DefaultList>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaMedicamentosDefault;
