/* eslint-disable react/jsx-props-no-spreading */
import React, {
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import Input from '@/components/Input';

import { Container, ShowPasswordButton } from './styles';

export interface PasswordInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  hasError?: boolean;
  canAlterVisibility?: boolean;
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, style, hasError, canAlterVisibility, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const inputType: 'text' | 'password' = useMemo(() => {
      return showPassword ? 'text' : 'password';
    }, [showPassword]);

    const showPasswordIcon = useMemo(() => {
      return showPassword ? <FiEyeOff /> : <FiEye />;
    }, [showPassword]);

    const showPasswordTitle = useMemo(() => {
      return showPassword ? 'Ocultar senha' : 'Exibir senha';
    }, [showPassword]);

    const handleShowPassword = useCallback(() => {
      setShowPassword(prev => !prev);
    }, []);

    return (
      <Container className={className} style={style}>
        <Input
          ref={ref}
          hasError={hasError}
          type={inputType}
          autoCapitalize="false"
          autoCorrect="false"
          {...rest}
        />
        {canAlterVisibility && (
          <ShowPasswordButton
            tabIndex={-1}
            onClick={handleShowPassword}
            title={showPasswordTitle}
          >
            {showPasswordIcon}
          </ShowPasswordButton>
        )}
      </Container>
    );
  },
);

PasswordInput.defaultProps = {
  hasError: false,
  canAlterVisibility: true,
};

export default PasswordInput;
