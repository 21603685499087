import styled from 'styled-components';

import FlatList from '@/components/FlatList';
import Tab from '@/components/Tabs/Tab';
import TabPanel from '@/components/Tabs/TabPanel';

export const TabPanelTipoProtocolo = styled(TabPanel)`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TabTipoProtocolo = styled(Tab)`
  min-width: 144px;
  min-height: 32px;
`;

export const TabGroupListProtocolo = styled(FlatList)`
  display: flex;
  flex-direction: column;
  flex: 1;
` as typeof FlatList;
