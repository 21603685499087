import React from 'react';

import { ReactComponent as ArrivalFlag } from '@/assets/illustrations/feedback-graphics/arrival.svg';

import Button from '@/components/Button';
import { Container } from './styles';

interface Props {
  title: string;
  subtitle?: string;
  buttonTitle: string;
  onButtonClick?: () => void;
}

const GuidelineFinalStep: React.FC<Props> = ({
  title,
  subtitle,
  buttonTitle,
  onButtonClick,
}) => {
  return (
    <Container>
      <ArrivalFlag />
      <h3>{title}</h3>
      {subtitle && <p>{subtitle}</p>}

      <Button theme="tertiary" onClick={onButtonClick}>
        {buttonTitle}
      </Button>
    </Container>
  );
};

export default GuidelineFinalStep;
