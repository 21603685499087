import React from 'react';

type ClickOutsideEventHandler = (event: MouseEvent) => void;

interface ClickOutsideObject<TElem> {
  ref: React.RefObject<TElem>;
  onClickOutside: ClickOutsideEventHandler;
}

export default function useOnClickOuside<TElem extends HTMLElement>(
  ref: React.RefObject<TElem>,
  handler: ClickOutsideEventHandler,
): ClickOutsideObject<TElem> {
  const onClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!ref || !ref.current) {
        return;
      }

      if (!ref.current.contains(event.target as Node)) {
        handler(event);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handler],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', onClickOutside, false);

    return () => {
      document.addEventListener('mousedown', onClickOutside, false);
    };
  });

  return {
    ref,
    onClickOutside,
  };
}
