import colors from '@/design/colors';
import gaps from '@/design/gaps';
import { HeadingLarge } from '@/design/typography';
import styled, { css } from 'styled-components';

export const PerguntaList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0px 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${gaps.defaultGap};

  width: 100%;
`;

export const Title = styled.div<{ sticky?: boolean }>`
  display: flex;

  padding: 8px 24px;

  background: ${colors.gray100};
  ${HeadingLarge(14)};

  /* Black Alpha 200 */

  color: ${colors.blackAlpha200};

  width: 100%;

  ${props =>
    props.sticky &&
    css`
      z-index: 1;
      top: 83px;
      width: auto;

      position: sticky;
    `}
`;
