import gaps from '@/design/gaps';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  width: 100%;
  padding: 0px 24px;
  row-gap: ${gaps.defaultGap};
`;
