import styled from 'styled-components';

import colors from '@/styles/colors';

interface InputProps {
  hasError?: boolean;
}

const Input = styled.input<InputProps>`
  padding: 7px;
  border-radius: 8px;
  border: 1px solid
    ${props => (props.hasError ? colors.error : colors.secondaryLite)};
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  &:focus:not(:read-only) {
    border-color: ${colors.primaryTone};
    box-shadow: 0px 0px 8px rgba(32, 71, 174, 0.64);
  }

  &:active:not(:read-only) {
    border-color: ${colors.primaryTone};
    box-shadow: none;
  }

  &::placeholder {
    color: ${colors.gray};
  }
`;

export default Input;
