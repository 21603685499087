/* eslint-disable @typescript-eslint/no-explicit-any */
import useDrag from '@/utils/hooks/useDrag';
import React, { useCallback } from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {
  LeftArrow as CarouselLefArrow,
  RightArrow as CarouselRightArrow,
} from './Arrows';
import Card from './DraggableCard';
import { CarouselContent } from './styles';

interface RenderItemInfo<ItemT> {
  item: ItemT;
  index: number;
  array: ItemT[];
}

type ArrowType = React.FC | React.ReactNode;
interface CarouselProps<ItemT> {
  width?: number;
  items: ItemT[];
  renderItem: (info: RenderItemInfo<ItemT>) => React.ReactElement | null;
  keyExtractor: (item: ItemT) => string;
  LeftArrow?: ArrowType;
  RightArrow?: ArrowType;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export default function Carousel<ItemT>({
  items,
  width,
  keyExtractor,
  renderItem,
  LeftArrow = CarouselLefArrow,
  RightArrow = CarouselRightArrow,
}: CarouselProps<ItemT>): React.ReactElement<CarouselProps<ItemT>> {
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag = useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) => (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          // eslint-disable-next-line no-param-reassign
          scrollContainer.current.scrollLeft += posDiff;
        }
      }),
    [dragMove],
  );

  return (
    <CarouselContent
      style={{
        width,
      }}
      onMouseLeave={dragStop}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        options={{
          ratio: 0.9,
          rootMargin: '5px',
          threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
        }}
      >
        {items.map((item, index, array) => {
          const itemKey = keyExtractor(item);
          const ItemElem = renderItem({ item, index, array });

          return (
            <Card key={itemKey} itemId={itemKey}>
              {ItemElem}
            </Card>
          );
        })}
      </ScrollMenu>
    </CarouselContent>
  );
}
