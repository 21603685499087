/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { ModalDialogContainer } from './styles';
import B2BContent from './B2BContent';
import B2CContent from './B2CContent';

const ModalSairProtocolo: React.FC = () => {
  const [
    { confirmacaoSair, protocoloExecutado },
    { dispatchConfirmarSaida, dispatchCancelarSaida },
  ] = useExecucaoContext();

  const { user } = useAuth();

  const [loading, setLoading] = React.useState(false);

  const isOpen = React.useMemo(() => confirmacaoSair.emAndamento, [
    confirmacaoSair.emAndamento,
  ]);

  const onClose = React.useCallback(() => {
    dispatchCancelarSaida();
  }, [dispatchCancelarSaida]);

  const handleSairProtocolo = React.useCallback(() => {
    setLoading(true);
    dispatchConfirmarSaida();
    setLoading(false);
  }, [dispatchConfirmarSaida]);

  return (
    <ModalDialogContainer
      title="Sair do protocolo"
      isOpen={isOpen}
      onAfterClose={onClose}
    >
      {user?.empresa && !!protocoloExecutado?.tags.length ? (
        <B2BContent
          loading={loading}
          handleSairProtocolo={handleSairProtocolo}
          onClose={onClose}
        />
      ) : (
        <B2CContent
          loading={loading}
          handleSairProtocolo={handleSairProtocolo}
          onClose={onClose}
        />
      )}
    </ModalDialogContainer>
  );
};

export default ModalSairProtocolo;
