/* eslint-disable @typescript-eslint/no-explicit-any */

import { PickPartial } from '@/utils/types';
import { FHIRPath, PropertyPathUnset } from './ArtifactField.manipulations';
import {
  ArtifactField,
  ArtifactFieldInputType,
  ArtifactFieldWidget,
} from './ArtifactForm.types';
import { FieldTypeWidget } from './ArtifactField.widgets';
import FieldTypeValidator from './ArtifactField.validators';

type FieldArgs = PickPartial<
  Omit<ArtifactField, 'widget'>,
  'getValue' | 'unsetValue' | 'validator'
> & {
  fhirPath?: string;
  propertyPath?: string;
  widget: ArtifactFieldInputType | ArtifactFieldWidget;
};

type FieldFunc = (args: FieldArgs) => ArtifactField;

export const FieldProxy: FieldFunc = ({
  getValue,
  unsetValue,
  fhirPath = '',
  propertyPath = '',
  widget,
  validator,
  ...args
}) => {
  const fieldValidator =
    typeof widget === 'string' ? FieldTypeValidator[widget] : validator;

  if (fieldValidator === undefined) {
    throw new Error('Should either include a `validator` schema');
  }

  const fieldWidget =
    typeof widget === 'string' ? FieldTypeWidget[widget] : widget;

  return {
    ...args,
    getValue: getValue || FHIRPath(fhirPath),
    unsetValue: unsetValue || PropertyPathUnset(propertyPath),
    widget: fieldWidget,
    validator: fieldValidator,
  };
};
