import styled from 'styled-components';

import colors from '@/design/colors';
import { Text } from '@/design/typography';

export const PlusCircle = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background-color: ${colors.white};
  color: ${colors.gray300};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonPlusContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;

  ${Text(14)};

  color: ${colors.blackAlpha200};

  &:focus-within:not(:disabled),
  &:hover:not(:disabled),
  &:focus:not(:disabled):not(:focus-visible) {
    color: ${colors.purple100};

    ${PlusCircle} {
      background-color: ${colors.purple100};
      color: ${colors.white};
    }
  }
`;
