import React, { useCallback } from 'react';

import InputFieldDecimal from '@/components/InputFieldDecimal';

import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputDecimal: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const {
    nome,
    unidade,
    meta: {
      placeholder = 'Insira',
      max_digits: maxDigits,
      decimal_places: decimalPlaces = 2,
      min_value: minValue,
      max_value: maxValue,
    },
  } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const [hasValidationError, setHasValidationError] = React.useState<
    null | 'min' | 'max'
  >(null);

  const [validationError, setValidationError] = React.useState<
    string | undefined
  >();

  const onDisplayError = useCallback(() => {
    setValidationError(undefined);

    if (hasValidationError === 'max') {
      setValidationError(
        `O valor de ${nome} não pode ser maior que ${maxValue}.`,
      );
    }

    if (hasValidationError === 'min') {
      setValidationError(
        `O valor de ${nome} não pode ser menor que ${minValue}`,
      );
    }
  }, [hasValidationError, maxValue, minValue, nome]);

  const onValidate = useCallback(
    (toValidateValue: number) => {
      const hasValidation = (!!maxValue || !!minValue) && !!toValidateValue;

      setHasValidationError(null);
      if (onChange) {
        onChange({
          [nome]: Number.isNaN(toValidateValue) ? null : toValidateValue,
        });
      }

      if (hasValidation && minValue && toValidateValue < minValue) {
        setHasValidationError('min');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }

      if (hasValidation && maxValue && toValidateValue > maxValue) {
        setHasValidationError('max');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }
    },
    [maxValue, minValue, nome, onChange],
  );

  const handleChange = useCallback(
    (textValue: string, typedValue: number) => {
      setValue(textValue);
      onValidate(typedValue);
    },
    [onValidate, setValue],
  );

  return (
    <InputFieldDecimal
      maxDigits={maxDigits}
      decimalPlaces={decimalPlaces}
      style={{ width: 240 }}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      addon={unidade}
      onChangeText={handleChange}
      onBlur={onDisplayError}
      validationError={validationError}
    />
  );
};

export default InputDecimal;
