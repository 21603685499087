import styled, { css } from 'styled-components';
import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

import DetailsCollapseButton from '../DetailsCollapseButton';
import { ContentEditableButton } from '../ContentEditable/styles';

export const Container = styled.div`
  position: relative;
  flex: 1;
`;

export const Label = styled.div<{ collapsed: boolean }>`
  ${({ collapsed }) => (collapsed ? HeadingSmall(14) : Text(14))};
  /* margin-left: 16px; */
  color: ${colors.blackAlpha200};
  transition: color 0.1s ease-in-out;
  flex: 1;
  text-align: start;
`;

interface SelectionControlStyledProps {
  marked: boolean;
  readonly?: boolean;
}

export const SelectionControlButton = styled.button<SelectionControlStyledProps>`
  background-color: ${({ marked }) => (marked ? colors.gray50 : colors.white)};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  width: 100%;
  cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};

  :disabled {
    cursor: not-allowed;
    ${({ marked }) =>
      !marked &&
      css`
        border-color: ${colors.gray50};
      `}

    ${Label},
    ${ContentEditableButton} {
      color: ${({ marked }) => (marked ? colors.gray200 : colors.gray100)};
    }

    ${ContentEditableButton} {
      cursor: not-allowed;
    }
  }

  /* Enable hover effect for not-readonly inputs */
  ${({ readonly }) =>
    !readonly &&
    css`
      :hover:not(:disabled),
      :focus-visible:not(:disabled) {
        ${Label} {
          color: ${colors.purple100};
        }
      }
    `}
`;

interface MainContentStyledProps {
  containsRightContent: boolean;
  isEditable: boolean;
}

export const MainContent = styled.div<MainContentStyledProps>`
  display: flex;
  align-items: center;
  padding-top: ${({ isEditable }) => (isEditable ? '13px' : '18px')};
  padding-bottom: ${({ isEditable }) => (isEditable ? '13px' : '18px')};
  padding-left: 16px;
  padding-right: ${({ containsRightContent }) =>
    containsRightContent ? '64px' : '16px'};

  column-gap: 16px;
`;

export const DetailsContent = styled.div<{ collapsed: boolean }>`
  margin-left: 48px;
  padding: 0 16px 18px 0;
  display: flex;
  opacity: ${({ collapsed }) => (collapsed ? 1 : 0)};
  transition: opacity 400ms;
`;

export const CollapseButton = styled(DetailsCollapseButton)`
  position: absolute;
  top: 11px;
  right: 16px;
`;

export const ExcludentOptionIndicator = styled.div<SelectionControlStyledProps>`
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(100% - 2px);
  width: 64px;
  background-color: ${colors.gray50};
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.marked ? colors.purple100 : colors.blackAlpha200)};
`;

export const Details = styled.p`
  ${Text(14)};
  color: ${colors.gray300};
  text-align: start;
`;
