/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Button from '@/components/Button';
import FeedbackGraphic from '@/components/FeedbackGraphic';

import { ModalProps } from '@/components/Modal';
import { Modal, ModalActions, ModalContent } from './styles';

interface DiscardOpmeModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'onAfterOpen'> {
  handleDiscardOpme: (id: string) => void;
  opmeId: string | undefined;
}

const ModalDiscardOpme: React.FC<DiscardOpmeModalProps> = ({
  opmeId,
  onAfterClose,
  handleDiscardOpme,
  ...modalProps
}) => {
  const handleDiscard = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (opmeId) handleDiscardOpme(opmeId);
    },
    [handleDiscardOpme, opmeId],
  );

  return (
    <Modal title="Excluir OPME" onAfterClose={onAfterClose} {...modalProps}>
      <ModalContent>
        <FeedbackGraphic
          graphic="alert"
          title="Tem certeza que deseja excluir este item?"
        />

        <ModalActions>
          <Button theme="primary" onClick={handleDiscard}>
            Excluir
          </Button>
          <Button theme="tertiary" onClick={onAfterClose}>
            Cancelar
          </Button>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default ModalDiscardOpme;
