import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Pergunta } from '@/models/Pergunta';

type UseAutoUpdateInputReturn = [string, Dispatch<SetStateAction<string>>];

function containsValue(pergunta: Pergunta): boolean {
  const {
    variavel: { valor: value },
  } = pergunta;

  return !(value === null || value === undefined);
}

function getValue(pergunta: Pergunta): string {
  if (containsValue(pergunta)) {
    return pergunta.variavel.valor as string;
  }

  return '';
}

export default function useAutoUpdateInput(
  pergunta: Pergunta,
): UseAutoUpdateInputReturn {
  const [inputValue, setInputValue] = useState(getValue(pergunta));

  useEffect(() => {
    if (!containsValue(pergunta)) {
      setInputValue('');
    }
  }, [pergunta]);

  return [inputValue, setInputValue];
}
