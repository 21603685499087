import { ArtifactFormOverrides } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';

const ExameFormDefault: ArtifactFormOverrides = [
  { name: 'orderDetail', label: 'Observação' },
];

const ExameGroupFormDefault: ArtifactFormOverrides = [
  { name: 'reasonCode', label: 'Indicação clínica', required: true },
];

const ProcedimentoFormDefault: ArtifactFormOverrides = [
  {
    name: 'reasonCode',
    label: 'Indicação clínica',
    required: true,
    attrs: { autoFocus: true },
  },
  { name: 'bodySite', label: 'Parte do Corpo' },
  { name: 'orderDetail', label: 'Observação' },
];

const EcaminhamentoFormDefault: ArtifactFormOverrides = [
  {
    name: 'reasonCode',
    label: 'Indicação clínica',
    required: true,
    attrs: { autoFocus: true },
  },
];

const MedicamentoFormDefault: ArtifactFormOverrides = [
  {
    name: 'dosageInstruction',
    label: 'Posologia',
    required: true,
    attrs: { autoFocus: true },
  },
  { name: 'patientInstruction', label: 'Observação' },
];

const MedicamentoManipuladoFormDefault: ArtifactFormOverrides = [
  {
    name: 'medicationReference',
    label: 'Nome do manipulado',
    required: true,
    attrs: { autoFocus: true },
  },
  {
    name: 'ingredient',
    label: 'Composição',
    required: true,
  },
  { name: 'dosageInstruction', label: 'Posologia', required: true },
  { name: 'patientInstruction', label: 'Observação' },
];

export default {
  Exame: ExameFormDefault,
  ExameGroup: ExameGroupFormDefault,
  Procedimento: ProcedimentoFormDefault,
  Encaminhamento: EcaminhamentoFormDefault,
  Medicamento: MedicamentoFormDefault,
  MedicamentoManipulado: MedicamentoManipuladoFormDefault,
};
