import React from 'react';

import { Container } from './styles';

interface VersionInfoProps {
  className?: string;
  style?: React.CSSProperties;
}

const VersionInfo: React.FC<VersionInfoProps> = ({ className, style }) => (
  <Container className={className} style={style}>
    Versão {process.env.REACT_APP_VERSION}
  </Container>
);

VersionInfo.defaultProps = {
  className: undefined,
};

export default VersionInfo;
