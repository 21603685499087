import styled from 'styled-components';

import { MenuItemClickable } from './MainMenuItem/styles';

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  row-gap: 4px;

  ${MenuItemClickable} {
    align-self: stretch;
  }
`;
