import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import styled from 'styled-components';

export const Container = styled.div`
  ${HeadingSmall(11)}

  display: flex;
  align-items: center;
  text-align: right;

  color: ${colors.gray200};
`;
