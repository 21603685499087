import React from 'react';

import PageLayout from '@/layouts/PageLayout';

import HeaderDefault from '@/components/HeaderDefault';
import ButtonBack from '@/components/ButtonBack';

import PageContentEspecialidadeList from '../../components/PageContentEspecialidadeList';

const EspecialidadeList: React.FC = () => {
  return (
    <PageLayout
      Nav={
        <HeaderDefault
          title="Especialidades"
          subtitle="Selecione uma categoria para encontrar os protocolos."
          Left={ButtonBack}
        />
      }
    >
      <PageContentEspecialidadeList />
    </PageLayout>
  );
};

export default EspecialidadeList;
