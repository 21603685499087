import axios from 'axios';

export function isHttpStatusError(error: Error, status: number): boolean {
  return !!(
    axios.isAxiosError(error) &&
    error.response &&
    error.response.status === status
  );
}

function isHttpClassError(error: Error, classFirstDigit: string): boolean {
  return !!(
    axios.isAxiosError(error) &&
    error.response &&
    error.response.status.toString().startsWith(classFirstDigit)
  );
}

export function isClientError(error: Error): boolean {
  return isHttpClassError(error, '4');
}

export function isServerError(error: Error): boolean {
  return isHttpClassError(error, '5');
}
