import React from 'react';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
import {
  format,
  startOfYear,
  endOfYear,
  isEqual,
  eachMonthOfInterval,
  addYears,
  startOfMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
import { subYears } from 'date-fns/esm';
import CalendarBase from '../Base';
import { CalendarGrid, MonthlyPicker } from './styles';
import { CalendarPeriodProps } from '../types';

const CalendarYearly: React.FC<CalendarPeriodProps> = ({
  value,
  onChange,
  onPeriodChange,
}) => {
  const selectedDate = value && startOfMonth(value);
  const [currentDate, setCurrentDate] = React.useState(
    startOfMonth(value || new Date()),
  );

  const title = React.useMemo(() => format(currentDate, 'yyyy'), [currentDate]);

  const months = React.useMemo(() => {
    const start = startOfYear(currentDate);
    const end = endOfYear(currentDate);

    return eachMonthOfInterval({
      start,
      end,
    });
  }, [currentDate]);

  const isActive = React.useCallback(
    (month: Date) => !!selectedDate && isEqual(month, selectedDate),
    [selectedDate],
  );

  const handleNextYear = React.useCallback(() => {
    setCurrentDate(year => addYears(year, 1));
  }, []);

  const handlePreviousYear = React.useCallback(() => {
    setCurrentDate(year => subYears(year, 1));
  }, []);

  const handlePeriodSelect = React.useCallback(() => {
    if (onPeriodChange) {
      onPeriodChange(currentDate);
    }
  }, [currentDate, onPeriodChange]);

  const handlePickerSelect = React.useCallback(
    (date: Date) => {
      if (onChange) {
        onChange(date);
      }
    },
    [onChange],
  );

  return (
    <CalendarBase
      title={title}
      onNext={handleNextYear}
      onPrevious={handlePreviousYear}
      onPeriodChange={handlePeriodSelect}
    >
      <CalendarGrid>
        {months.map(m => (
          <MonthlyPicker
            key={m.toISOString()}
            active={isActive(m)}
            onClick={() => handlePickerSelect(m)}
          >
            {format(m, 'MMM', { locale: ptBR })}
          </MonthlyPicker>
        ))}
      </CalendarGrid>
    </CalendarBase>
  );
};

export default CalendarYearly;
