import React from 'react';
import fhirpath from 'fhirpath';

import { ArtifactFormData } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import {
  artifactDefaultValues,
  buildFormType,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import {
  ItemComplementarState,
  ItemComplementarUI,
  ItemComplementarUIState,
} from '../../models';
import ItemComplementarGroup from '../ItemComplementarGroup';
import { itemToUIState } from '../../helpers/parsers';
import FormDefaults from '../../models/FormDefaults';

const itemForm = buildFormType('solicitacao-exame', FormDefaults.Exame);

const getTitle = (item: ItemComplementarState) => {
  return fhirpath.evaluate(
    item.resource,
    'ServiceRequest.code.text | ServiceRequest.code.coding.display',
  )[0];
};

const getDescription = (item: ItemComplementarState) => {
  const orderDetail = fhirpath.evaluate(
    item.resource,
    'ServiceRequest.orderDetail.text',
  );
  if (orderDetail.length) {
    return orderDetail[0];
  }

  return '';
};

const PrescricoesLivreExame: React.FC = () => {
  const {
    mode,
    getItemsByType,
    deleteItem,
    updateItem,
    updateItemState,
    updateItems,
    getGlobalForm,
    setGlobalData,
    clearGlobalData,
    state: { updatingItems, isUpdatingItem },
  } = useCondutaComplementarContext();

  const groupForm = React.useMemo(() => getGlobalForm('solicitacao-exame'), [
    getGlobalForm,
  ]);

  const exames: ItemComplementarUIState[] = React.useMemo(() => {
    const items = getItemsByType('solicitacao-exame');

    return items.map<ItemComplementarUI>(item =>
      itemToUIState(item, {
        title: getTitle(item),
        description: getDescription(item),
      }),
    );
  }, [getItemsByType]);

  const groupFormLoading = React.useMemo(
    () => !isUpdatingItem && updatingItems.length === exames.length,
    [exames.length, isUpdatingItem, updatingItems.length],
  );

  const examesValues = React.useMemo(() => {
    const refResource = exames.length ? exames[0].resource : undefined;
    if (groupForm) {
      return artifactDefaultValues({ resource: refResource, form: groupForm });
    }

    return {};
  }, [exames, groupForm]);

  const canEditOrDeleteItem = React.useMemo(
    () => !groupFormLoading && mode === 'active',
    [groupFormLoading, mode],
  );

  const canEditGroup = React.useMemo(() => mode === 'active', [mode]);

  const handleUpdate = React.useCallback(
    ({ key: id, resource }: ItemComplementarUI) => {
      updateItem({
        id,
        type: 'solicitacao-exame',
        resource,
      });
    },
    [updateItem],
  );

  const handleGroupUpdate = React.useCallback(
    (data: ArtifactFormData, items: ItemComplementarUIState[]) => {
      updateItems(
        items.map(({ key: id, resource }) => ({
          id,
          type: 'solicitacao-exame',
          resource,
        })),
      );
    },
    [updateItems],
  );

  React.useEffect(() => {
    setGlobalData('solicitacao-exame', examesValues);

    return () => {
      clearGlobalData('solicitacao-exame');
    };
  }, [clearGlobalData, examesValues, setGlobalData]);

  if (exames.length) {
    return (
      <ItemComplementarGroup
        tipo="solicitacao-exame"
        items={exames}
        itemForm={itemForm}
        groupForm={groupForm}
        groupFormLoading={groupFormLoading}
        groupFormDefaultValues={examesValues}
        groupFormAutoFocus
        onDeleteItem={({ key: id }) => deleteItem({ id })}
        onChangeItem={handleUpdate}
        onGroupChange={handleGroupUpdate}
        hasItemChangePermission={canEditOrDeleteItem}
        hasItemDeletePermission={canEditOrDeleteItem}
        hasGroupChangePermission={canEditGroup}
        onItemDisplayModeChange={({ key: id }, display) => {
          updateItemState({ id }, { isEditing: display === 'form' });
        }}
      />
    );
  }

  return <></>;
};

export default PrescricoesLivreExame;
