import { FieldProxy } from '../components/ArtifactForm/ArtifactForm.fields';

const setDosageInstruction = (resource: fhir4.FhirResource, value: string) => {
  const medicationRequest = resource as fhir4.MedicationRequest;

  if (medicationRequest.dosageInstruction) {
    const { dosageInstruction, ...request } = medicationRequest;
    const [dosage = {}] = dosageInstruction;

    return {
      ...request,
      dosageInstruction: [
        {
          ...dosage,
          text: value,
        },
      ],
    };
  }

  return {
    ...medicationRequest,
    dosageInstruction: [{ text: value }],
  };
};

const setAdditionalInstruction = (
  resource: fhir4.FhirResource,
  value: string,
) => {
  const medicationRequest = resource as fhir4.MedicationRequest;

  if (medicationRequest.dosageInstruction) {
    const { dosageInstruction, ...request } = medicationRequest;
    const [dosage = {}] = dosageInstruction;

    return {
      ...request,
      dosageInstruction: [
        {
          ...dosage,
          additionalInstruction: [{ text: value }],
        },
      ],
    };
  }

  return {
    ...medicationRequest,
    dosageInstruction: [{ additionalInstruction: [{ text: value }] }],
  };
};

const setPatientInstruction = (resource: fhir4.FhirResource, value: string) => {
  const medicationRequest = resource as fhir4.MedicationRequest;

  if (medicationRequest.dosageInstruction) {
    const { dosageInstruction, ...request } = medicationRequest;
    const [dosage = {}] = dosageInstruction;

    return {
      ...request,
      dosageInstruction: [
        {
          ...dosage,
          patientInstruction: value,
        },
      ],
    };
  }

  return {
    ...medicationRequest,
    dosageInstruction: [{ patientInstruction: value }],
  };
};

const MedicamentoForm = [
  FieldProxy({
    name: 'dosageInstruction',
    label: 'Posologia',
    propertyPath: 'dosageInstruction[0].text',
    fhirPath: 'dosageInstruction.text',
    setValue: setDosageInstruction,
    widget: 'short_answer',
  }),
  FieldProxy({
    name: 'additionalInstruction',
    label: 'Instruções adicionais',
    propertyPath: 'dosageInstruction[0].additionalInstruction',
    fhirPath: 'dosageInstruction.additionalInstruction.text',
    setValue: setAdditionalInstruction,
    widget: 'short_answer',
  }),
  FieldProxy({
    name: 'patientInstruction',
    label: 'Instruções ao paciente',
    propertyPath: 'dosageInstruction[0].patientInstruction',
    fhirPath: 'dosageInstruction.patientInstruction',
    setValue: setPatientInstruction,
    widget: 'short_answer',
  }),
];

export default MedicamentoForm;
