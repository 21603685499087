/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import Button from '@/components/Button';

import ArtifactFieldset from '../ArtifactForm/ArtifactFieldset';
import {
  ArtifactFormModel,
  FormLoadingState,
} from '../ArtifactForm/ArtifactForm.types';
import { Form, FormActions } from './styles';

interface RenderFieldsetProps {
  form: ArtifactFormModel;
  control: UseFormReturn['control'];
  loading?: FormLoadingState;
  disabled?: boolean;
  readOnly?: boolean;
}

export interface ArtifactBaseFormProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  form: ArtifactFormModel;
  disabled?: boolean;
  enabledWhenDirty?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
  renderFieldset?: (
    renderProps: RenderFieldsetProps,
  ) => React.ReactElement | null;
}

const ArtifactBaseForm: React.FC<ArtifactBaseFormProps> = ({
  form,
  disabled = false,
  loading = false,
  readOnly = false,
  showCancelButton = true,
  showSubmitButton = true,
  enabledWhenDirty = false,
  onCancel,
  renderFieldset,
  ...formProps
}) => {
  const {
    control,
    reset,
    formState: { isDirty },
  } = useFormContext();

  const onSubmitCancel = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      reset();

      if (onCancel) {
        onCancel();
      }
    },
    [onCancel, reset],
  );

  const onSubmit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const enableActions = React.useMemo(() => {
    return (showCancelButton || showSubmitButton) && !readOnly;
  }, [readOnly, showCancelButton, showSubmitButton]);

  const FormFields = React.useMemo(() => {
    if (renderFieldset) {
      return renderFieldset({
        form,
        control,
        disabled,
        loading,
        readOnly,
      });
    }

    return (
      <ArtifactFieldset
        form={form}
        control={control}
        disabled={disabled}
        loading={!!loading}
        readOnly={readOnly}
      />
    );
  }, [control, disabled, form, loading, readOnly, renderFieldset]);

  return (
    <Form {...formProps}>
      {FormFields}

      {enableActions && (
        <FormActions>
          {showCancelButton && (
            <Button
              theme="secondary"
              type="button"
              loading={loading === 'cancel'}
              disabled={!!loading || disabled}
              onClick={onSubmitCancel}
            >
              Cancelar
            </Button>
          )}
          {showSubmitButton && (
            <Button
              theme="primary"
              type="submit"
              loading={loading && loading !== 'cancel'}
              disabled={!!loading || disabled || (enabledWhenDirty && !isDirty)}
              onClick={onSubmit}
            >
              Confirmar
            </Button>
          )}
        </FormActions>
      )}
    </Form>
  );
};

export default ArtifactBaseForm;
