import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { Medicamento } from '@/models/Medicamento';
import NavTabs from '@/components/NavTabs';

import { createNavItems } from '../helpers';
import { Container, NavItemInfo, NavContents } from './styles';

interface ComponentProps {
  medicamento: Medicamento;
  innerRef: React.MutableRefObject<null>;
}

const MedicamentoDetails: React.FC<ComponentProps> = ({
  medicamento,
  innerRef,
}) => {
  const navItems = React.useMemo(() => {
    return createNavItems(medicamento);
  }, [medicamento]);

  const [itemSelected, setItemSelected] = React.useState(navItems[0]);
  const [heightTransition, setHeightTransition] = React.useState([0, 0]);

  const setSourceHeight = (node: HTMLElement) => {
    const { height: source } = node.getBoundingClientRect();

    setHeightTransition(([, target]) => [source, target]);
  };

  const setTargetHeight = (node: HTMLElement) => {
    const { height: target } = node.getBoundingClientRect();

    setHeightTransition(([source]) => [source, target]);
  };

  return (
    <Container ref={innerRef}>
      <NavTabs
        navItems={navItems}
        keyActived={itemSelected.key}
        onClick={navItem => setItemSelected(navItem)}
      />
      <NavContents>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={itemSelected.key}
            classNames="fade"
            timeout={{ enter: 0, exit: 200 }}
            onExit={setSourceHeight}
            onEnter={setTargetHeight}
          >
            <NavItemInfo transition={heightTransition}>
              {itemSelected.content}
            </NavItemInfo>
          </CSSTransition>
        </SwitchTransition>
      </NavContents>
    </Container>
  );
};

export default MedicamentoDetails;
