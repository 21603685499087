import React from 'react';

type UseEllipsisReturn<T> = [React.RefObject<T>, boolean];

export default function useEllipsis<TElement extends HTMLElement>(
  dependencies: React.DependencyList = [],
): UseEllipsisReturn<TElement> {
  const ellipsisRef = React.useRef<TElement>(null);

  const [hasEllipsis, setHasEllispsis] = React.useState(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const element = ellipsisRef.current;

    if (element) {
      const checkEllipsis = () => {
        const ellipsized = element.scrollHeight > element.clientHeight;
        setHasEllispsis(ellipsized);
      };

      // Initial check
      checkEllipsis();

      // Create a ResizeObserver to watch for changes in element size
      const resizeObserver = new ResizeObserver(() => {
        checkEllipsis();
      });

      // Observe the element
      resizeObserver.observe(element);

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [dependencies]);

  return [ellipsisRef, hasEllipsis];
}
