/* eslint-disable @typescript-eslint/no-explicit-any */
export class PDFMakerConverter {
  private buildText = (text: string, properties: any) => {
    return { text, color: '#2F3139', fontSize: 9, ...properties };
  };

  private buildH1 = (h1: Element) => {
    return this.buildText(h1.textContent || '', {
      bold: true,
      fontSize: 12,
    });
  };

  private buildH2 = (h2: Element) => {
    return this.buildText(h2.textContent || '', {
      bold: true,
      fontSize: 11,
    });
  };

  private buildH3 = (h3: Element) => {
    return this.buildText(h3.textContent || '', {
      bold: true,
      fontSize: 10,
    });
  };

  private buildParagraph = (paragraph: Element) => {
    const bold = paragraph.innerHTML.includes('<strong>');
    const italics = paragraph.innerHTML.includes('<em>');
    const underline = paragraph.innerHTML.includes('<u>');

    return this.buildText(paragraph.textContent || '', {
      bold,
      italics,
      decoration: underline ? 'underline' : '',
    });
  };

  private buildOrderedList = (unorderedList: Element) => {
    return {
      ol: Array.from(unorderedList.children).map(child =>
        this.buildElement(child),
      ),
      margin: [0, 10, 0, 10],
    };
  };

  private buildUnorderedList = (unorderedList: Element) => {
    return {
      ul: Array.from(unorderedList.children).map(child =>
        this.buildElement(child),
      ),
      margin: [0, 10, 0, 10],
    };
  };

  private buildListItem = (listItem: Element) => {
    if (Array.from(listItem.children).length) {
      return Array.from(listItem.children).map(child =>
        this.buildElement(child),
      );
    }

    return this.buildText(listItem.textContent || '', { margin: [0, 0, 0, 6] });
  };

  private htmlDOMElements = (html: string): Element[] => {
    return Array.from(
      new DOMParser().parseFromString(html, 'text/html').body.children,
    );
  };

  private buildMethod = (tagName: string): any => {
    const notFoundBuildMethod = () => [];
    return (
      {
        P: this.buildParagraph,
        UL: this.buildUnorderedList,
        LI: this.buildListItem,
        OL: this.buildOrderedList,
        H1: this.buildH1,
        H2: this.buildH2,
        H3: this.buildH3,
      }[tagName] || notFoundBuildMethod
    );
  };

  private buildElement(element: Element) {
    return this.buildMethod(element.tagName)(element);
  }

  fromHtml = (html: string): any => {
    return this.htmlDOMElements(html).map(element =>
      this.buildElement(element),
    );
  };
}
