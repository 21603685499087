import React from 'react';
import BoxEncaminhamento from '@/features/recursos/encaminhamentos/components/BoxEncaminhamento';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { groupBy } from '@/utils/collections';

import CategoriaArtefato from '../../CategoriaArtefato';
import { Content, Wrapper } from './styles';

interface encaminhamentosProps {
  encaminhamentos: FhirArtefatoType[];
}

const ListaEncaminhamentosDefault: React.FC<encaminhamentosProps> = ({
  encaminhamentos,
}) => {
  const encaminhamentosHasCategorias = React.useMemo(() => {
    return (
      encaminhamentos.filter(encaminhamento => !!encaminhamento.categoria)
        .length > 0
    );
  }, [encaminhamentos]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      encaminhamentos,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(encaminhamento => (
          <BoxEncaminhamento
            key={encaminhamento.id.toString()}
            encaminhamento={encaminhamento.pedido}
          />
        ))}
      />
    ));
  }, [encaminhamentos]);

  return (
    <Wrapper>
      <Content>
        {encaminhamentosHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <>
            {encaminhamentos.map(encaminhamento => (
              <BoxEncaminhamento
                key={encaminhamento.id.toString()}
                encaminhamento={encaminhamento.pedido}
              />
            ))}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaEncaminhamentosDefault;
