import React, { useState, useMemo, useEffect, useCallback } from 'react';

import Loading from '@/components/Loading';
import { Calculadora } from '@/models/Calculadora';
import spinner from '@/assets/spinner-thin.svg';
import useInfiniteScroll from '@/utils/hooks/useInfiniteScroll';

import Search from '@/components/Search';
import CalculadoraItem from './CalculadoraItem';
import Error from './Error';
import Empty from './Empty';
import CalculadoraAPI from '../../services/CalculadoraAPI';
import { Container, ListaContainer, LoadContainer } from './styles';

interface StateProps {
  hasError: boolean;
  isLoading: boolean;
}

const CalculadorasList: React.FC = () => {
  /** Initial State */
  const [state, setState] = useState<StateProps>({
    hasError: false,
    isLoading: true,
  });
  /** Search */
  const [search, setSearch] = useState('');
  /** Infinite Scrolling */
  const [resetPage, setResetPage] = useState(false);
  const [calculadoras, setCalculadoras] = useState<Calculadora[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, loaderRef] = useInfiniteScroll<HTMLDivElement>({
    hasMore,
    reset: resetPage,
    distance: 200,
  });

  /** Update State Aux to avoid dependencies between hooks */
  const updateState = (newS: { [key: string]: boolean | Calculadora[] }) =>
    setState(prev => ({ ...prev, ...newS }));

  const searchCalculadoras = useCallback((termOfSearch: string) => {
    setResetPage(true);
    setSearch(termOfSearch);
  }, []);

  const fetchCalculadoras = useCallback(
    async (termOfSearch: string, pageNumber = 1) => {
      if (pageNumber === 1) updateState({ isLoading: true });

      try {
        const { results, next } = await CalculadoraAPI.listCalculadoras(
          termOfSearch,
          pageNumber,
        );

        if (pageNumber !== 1) {
          setCalculadoras(prev => [...prev, ...results]);
        } else {
          setCalculadoras(results);
          setResetPage(false);
        }

        setHasMore(!!next);

        updateState({
          isLoading: false,
          hasError: false,
        });
      } catch (error) {
        updateState({ isLoading: false, hasError: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (page !== 1) {
      fetchCalculadoras(search, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCalculadoras, page]);

  useEffect(() => {
    fetchCalculadoras(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCalculadoras, search]);

  /** Content to be displayed */
  const Content = useMemo(() => {
    if (state.isLoading) {
      return <Loading>Carregando Calculadoras</Loading>;
    }
    if (state.hasError) {
      return <Error onReload={() => setResetPage(true)} />;
    }
    if (calculadoras.length === 0) {
      return <Empty />;
    }

    return (
      <>
        <ListaContainer>
          {calculadoras.map(calculadora => (
            <CalculadoraItem key={calculadora.id} calculadora={calculadora} />
          ))}
        </ListaContainer>
      </>
    );
  }, [state.isLoading, state.hasError, calculadoras]);

  return (
    <Container>
      <div>
        <Search
          placeholder="Busque por uma calculadora"
          onChange={searchCalculadoras}
          debounceTimeInMS={600}
        />
      </div>

      {Content}
      <LoadContainer ref={loaderRef} show={!state.isLoading && hasMore}>
        <img src={spinner} alt="Carregando" srcSet="" />
      </LoadContainer>
    </Container>
  );
};

export default CalculadorasList;
