import { useHistory } from 'react-router-dom';

// import { useFeedbackContext } from '@/features/feedbacks/contexts/FeedbackContext';
// import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import ExecucaoAPI from '@/features/execucao/services/ExecucaoAPI';
import helpers from '@/features/execucao/helpers';
import {
  ProtocoloExecutado,
  ProtocoloExecutadoResumo,
} from '@/models/ProtocoloExecutado';

import {
  AbrirProtocoloPayload,
  AlterarStatusPayload,
  CriarExecucaoPayload,
  ExecutarPassoPayload,
  ProsseguirPassoPayload,
  RefazerPassoPayload,
  SolicitarFinalizacaoPayload,
  SolicitarSaidaPayload,
} from '../../types';
import {
  ExecucaoAction,
  PROSSEGUIR_PROXIMO_PASSO,
  FINALIZAR_PROTOCOLO,
  REFAZER_PASSO,
  ABRIR_EXECUCAO,
  SOLICITAR_SAIDA,
  CANCELAR_SAIDA,
  SOLICITAR_FINALIZACAO,
  CANCELAR_FINALIZACAO,
  EXIBIR_FONTES_PROTOCOLO,
  ABRIR_RESUMO,
  INTERROMPER_PROTOCOLO,
  ALTERAR_STATUS,
  PARAR_PASSO,
} from './actionTypes';
import { ExecucaoState } from './reducer';

export interface ExecucaoActions {
  dispatchAlterarStatus(payload: AlterarStatusPayload): void;

  // Actions de iníciar execução de protocolo
  dispatchIniciarExecucao(payload: AbrirProtocoloPayload): Promise<void>;
  dispatchIniciarExecucaoFromBackoffice(
    payload: CriarExecucaoPayload,
  ): Promise<void>;
  dispatchRetomarExecucao(execucaoId: string): Promise<void>;
  dispatchSetExecucao(execucao: ProtocoloExecutado): void;
  dispatchSetExecucaoResumo(execucao: ProtocoloExecutadoResumo): void;
  // Actions de fluxo tradicional de execução
  dispatchExecutarPasso(payload: ExecutarPassoPayload): Promise<void>;
  dispatchPararPasso(payload: ExecutarPassoPayload): Promise<void>;
  dispatchProsseguirPasso(payload: ProsseguirPassoPayload): Promise<void>;
  dispatchRefazerPasso(payload: RefazerPassoPayload): void;
  dispatchEncerrarProtocolo(): void;
  // Actions de sair do protocolo
  dispatchSolicitarSaida(payload: SolicitarSaidaPayload): void;
  dispatchCancelarSaida(): void;
  dispatchConfirmarSaida(): void;
  // Actions de finalizar protocolo
  dispatchSolicitarFinalizacao(payload: SolicitarFinalizacaoPayload): void;
  dispatchCancelarFinalizacao(): void;
  dispatchConfirmarFinalizacao(): Promise<void>;
  // Exibir fontes do protocolo
  dispatchExibirFontesProtocolo(value: boolean): void;
  // Actions de interrupção
  dispatchInterromperProtocolo(): Promise<void>;
}

export const initialExecucaoActions: ExecucaoActions = {} as ExecucaoActions;

export function useExecucaoActions(
  state: ExecucaoState,
  dispatch: React.Dispatch<ExecucaoAction>,
): ExecucaoActions {
  const history = useHistory();
  // const feedback = useFeedbackContext();
  // const { user } = useAuth();

  return {
    dispatchIniciarExecucaoFromBackoffice: async payload => {
      const result = await ExecucaoAPI.criarExecucao(payload);

      dispatch({
        type: ABRIR_EXECUCAO,
        result,
      });
    },

    dispatchRetomarExecucao: async (execucaoId: string) => {
      const execucao = await ExecucaoAPI.getExecucao(execucaoId);

      dispatch({
        type: ABRIR_EXECUCAO,
        result: execucao,
        isProtocoloExecutadoRetomado: true,
      });
    },

    dispatchSetExecucao: (execucao: ProtocoloExecutado) => {
      dispatch({
        type: ABRIR_EXECUCAO,
        result: execucao,
      });
    },

    dispatchSetExecucaoResumo: (execucao: ProtocoloExecutadoResumo) => {
      dispatch({
        type: ABRIR_RESUMO,
        result: execucao,
        isProtocoloReadOnly: true,
      });
    },

    dispatchIniciarExecucao: async payload => {
      // feedback.actions.dispatchSuggestFeedback(false);

      const result = await ExecucaoAPI.abrirProtocolo(payload);

      dispatch({
        type: ABRIR_EXECUCAO,
        result,
      });
    },

    dispatchExecutarPasso: async ({ passoExecutado, respostas }) => {
      dispatch({ type: ALTERAR_STATUS, status: 'on-hold' });

      const result = await ExecucaoAPI.executarPasso({
        passoExecutado,
        respostas,
      });

      dispatch({
        type: PROSSEGUIR_PROXIMO_PASSO,
        origem: passoExecutado,
        result,
      });
    },

    dispatchPararPasso: async ({ passoExecutado, respostas }) => {
      dispatch({ type: ALTERAR_STATUS, status: 'on-hold' });

      const result = await ExecucaoAPI.executarPasso({
        passoExecutado,
        respostas,
      });

      dispatch({
        type: PARAR_PASSO,
        origem: passoExecutado,
        result,
      });
    },

    dispatchProsseguirPasso: async ({ passoExecutado, protocoloExecutado }) => {
      dispatch({ type: ALTERAR_STATUS, status: 'on-hold' });

      dispatch({
        type: PROSSEGUIR_PROXIMO_PASSO,
        origem: passoExecutado,
        result: protocoloExecutado,
      });
    },

    dispatchRefazerPasso: payload => {
      dispatch({ type: REFAZER_PASSO, ...payload });
    },

    dispatchEncerrarProtocolo: () => {
      // feedback.actions.dispatchSuggestFeedback(state.execucoesCount >= 5);
      dispatch({ type: FINALIZAR_PROTOCOLO });
    },

    dispatchSolicitarSaida: ({ proximo = '/' }) => {
      dispatch({ type: SOLICITAR_SAIDA, proximo });
    },

    dispatchCancelarSaida: () => {
      dispatch({ type: CANCELAR_SAIDA });
    },

    dispatchConfirmarSaida: () => {
      // feedback.actions.dispatchSuggestFeedback(state.execucoesCount >= 5);
      dispatch({ type: FINALIZAR_PROTOCOLO });

      if (typeof state.confirmacaoSair.proximo === 'function') {
        state.confirmacaoSair.proximo();
      } else {
        history.replace(state.confirmacaoSair.proximo);
      }
    },

    dispatchSolicitarFinalizacao: ({ respostas = {} }) => {
      dispatch({ type: SOLICITAR_FINALIZACAO, respostas });
    },

    dispatchCancelarFinalizacao: () => {
      dispatch({ type: CANCELAR_FINALIZACAO });
    },

    dispatchConfirmarFinalizacao: async () => {
      const {
        protocoloExecutado,
        passosExecutados,
        confirmacaoFinalizar: { respostas },
      } = state;

      if (protocoloExecutado && passosExecutados.length) {
        // Recupera o último passo do protocolo para executá-lo e finalizar o
        // protocolo
        const passoExecutado = passosExecutados[passosExecutados.length - 1];

        await ExecucaoAPI.executarPasso({ passoExecutado, respostas });

        dispatch({ type: FINALIZAR_PROTOCOLO });

        // if (user && !user.gave_score_feedback) {
        //   feedback.actions.dispatchOpenModal(500);
        // } else {
        helpers.notifyFinalizacao(protocoloExecutado);
        // }
      }
    },

    dispatchExibirFontesProtocolo: (value: boolean) => {
      dispatch({ type: EXIBIR_FONTES_PROTOCOLO, value });
    },

    dispatchInterromperProtocolo: async () => {
      const { protocoloExecutado } = state;

      if (protocoloExecutado) {
        await ExecucaoAPI.cancelarExecucao(protocoloExecutado);

        dispatch({ type: INTERROMPER_PROTOCOLO });
      }
    },

    dispatchAlterarStatus: ({ status }) => {
      dispatch({ type: ALTERAR_STATUS, status });
    },
  };
}
