import colors from '@/design/colors';
import styled from 'styled-components';
import InputField from '../InputField';

export const Input = styled(InputField)`
  padding-left: 34px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;

  width: 34px;
  height: 100%;

  color: ${colors.gray200};
`;
