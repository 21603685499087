import BoxMenu from '@/components/BoxMenu';
import BoxMenuOption from '@/components/BoxMenu/BoxMenuOption';
import styled from 'styled-components';

export const TipoArtefatoBoxMenu = styled(BoxMenu)`
  min-width: 243px;
`;

export const TipoArtefatoOption = styled(BoxMenuOption)`
  svg {
    width: 16px;
    height: 16px;
  }
`;
