import styled, {
  css,
  keyframes,
  FlattenInterpolation,
} from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

interface ButtonStyledProps {
  theme: 'primary' | 'secondary' | 'tertiary';
  loading: boolean;
}

interface ButtonTheme {
  [key: string]: FlattenInterpolation<ButtonStyledProps>;
}

const theme: ButtonTheme = {
  // Primário
  primary: css<ButtonStyledProps>`
    background: ${colors.purple100};
    border: 1px solid ${colors.purple100};
    color: ${colors.white};

    /* Disabled */
    :disabled {
      opacity: 0.64;
    }

    /* Aria-disabled */
    &[aria-disabled='true'] {
      &:not(:disabled) {
        opacity: 0.74;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.64;
        }
      }
    }

    /* Focus */
    &[aria-disabled='false']:not(:disabled) {
      &:hover,
      &:focus,
      &:active {
        background: ${colors.darkBlue100};
        border: 1px solid ${colors.darkBlue100};
      }
    }
  `,

  // Secundário
  secondary: css<ButtonStyledProps>`
    background: ${colors.white};
    color: ${colors.blackAlpha200};
    border: 1px solid ${colors.gray100};

    /* Disabled */
    :disabled {
      color: ${colors.blackAlpha50};
    }

    /* Focus */
    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      background-color: ${colors.gray50};
      border: 1px solid ${colors.gray100};
    }
  `,

  tertiary: css<{ $actived?: boolean } & ButtonStyledProps>`
    color: ${colors.blackAlpha200};
    font-weight: 600;
    border: 1px solid transparent;

    /* Disabled */
    :disabled {
      color: ${colors.blackAlpha50};
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      text-decoration: underline;
    }
  `,
};

export const ButtonContainer = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 8px 24px;
  border-radius: 32px;
  transition: all 0.3s ease-in-out;

  // Suprime o ícone caso o botão esteja em estado de loading
  ${props =>
    props.loading &&
    css`
      > svg {
        display: none;
      }
    `}

  ${HeadingSmall(14)};

  /* Disabled */
  &:disabled {
    cursor: not-allowed;
  }

  /* Theme */
  ${props => theme[props.theme]}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  svg {
    animation: ${rotate} 600ms linear infinite;
    display: block;
  }
`;
