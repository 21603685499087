import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { CardContainer } from './styles';

interface CardProps {
  itemId: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ itemId, children }) => {
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <CardContainer role="button" tabIndex={0} visible={visible}>
      {children}
    </CardContainer>
  );
};

export default Card;
