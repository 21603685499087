/* eslint-disable react/jsx-props-no-spreading */
import { parse } from 'date-fns';
import React from 'react';
import { FiCalendar } from 'react-icons/fi';

import DatePickerInput from '../DatePickerInput';
import { InputFieldProps } from '../InputField';
import InputFieldWithIcon from '../InputFieldWithIcon';

interface Props extends InputFieldProps {
  onChangeDate?: (date?: Date) => void;
}

const InputFieldDate: React.FC<Props> = ({ value, onChangeDate, ...props }) => {
  const dateValue = React.useMemo(() => {
    if (typeof value === 'string' && value.length > 0) {
      return parse(value, 'yyyy-MM-dd', new Date());
    }

    return undefined;
  }, [value]);

  return (
    <DatePickerInput
      value={dateValue}
      onChange={onChangeDate}
      renderInput={(inputProps, inputRef) => (
        <InputFieldWithIcon
          ref={inputRef}
          Icon={<FiCalendar size={18} />}
          {...inputProps}
          {...props}
        />
      )}
    />
  );
};

export default InputFieldDate;
