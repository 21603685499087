import { Toolbar } from '@remirror/react';
import styled from 'styled-components';
import colors from '@/design/colors';

export const CustomToolbar = styled(Toolbar)`
  .MuiButtonBase-root {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    margin-right: 8px;

    width: 26px;
    height: 26px;

    /* White */

    background: ${colors.white};
    border: none;
    border-radius: 4px !important;

    &:hover {
      background: ${colors.whiteAlpha100};
      color: ${colors.purple100};
    }

    &.Mui-selected {
      background: ${colors.whiteAlpha100};
      color: ${colors.blackAlpha200};

      &:hover {
        background: ${colors.whiteAlpha100};
        color: ${colors.purple100};
      }
    }
  }
`;
