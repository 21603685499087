import React from 'react';

import { Box, Options } from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
}

const BoxMenu: React.FC<Props> = ({ children, className, style }) => {
  return (
    <Box style={style} className={className}>
      <Options>{children}</Options>
    </Box>
  );
};

export default BoxMenu;
