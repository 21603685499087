import styled, { css } from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';

import colors from '@/design/colors';
import { Text } from '@/design/typography';

const info = css`
  color: ${colors.purple100};
  border-color: ${colors.purple100};
  background-color: ${colors.purple50};
`;

const danger = css`
  color: ${colors.red100};
  border-color: ${colors.red100};
  background-color: ${colors.red50};
`;

const warning = css`
  color: ${colors.blackAlpha200};
  border-color: ${colors.yellow};
  background-color: ${colors.yellow50};
`;

type InfoType = 'info' | 'warning' | 'danger';
type InfoColorMapping = {
  [key in InfoType]: typeof info;
};

const colorMapping: InfoColorMapping = {
  info,
  warning,
  danger,
};

type InfoBoxProps = { type: InfoType };

export const InfoBox = styled.div<InfoBoxProps>`
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  ${props => colorMapping[props.type]};

  ${Text(14)};

  display: flex;
  align-items: center;

  padding: 8px 8px 8px 16px;
  column-gap: 8px;
`;

// Container p/ evitar quebra de width do icon (texto longo "empurra" o icon)
export const Alert = styled.div`
  display: inline-flex;
`;

export const AlertIcon = styled(FiAlertCircle).attrs({ size: 16 })``;
