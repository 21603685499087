import { LeftArrow, RightArrow } from '@/components/Carousel/Arrows';
import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

import styled from 'styled-components';

export const SectionEspecialidadesContainer = styled.div`
  margin-bottom: 16px;
`;

export const SectionEspecialidadesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    ${HeadingSmall(16)};
  }

  a {
    ${HeadingSmall(14)};
    color: ${colors.blackAlpha200};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SectionEspecialidadesContent = styled.div``;

export const CarouselLeftArrow = styled(LeftArrow)`
  margin-left: 24px;
`;

export const CarouselRightArrow = styled(RightArrow)`
  margin-right: 24px;
`;
