import React from 'react';

import { ReactComponent as Spinner } from '@/assets/spinner-white.svg';

import { ButtonContainer, SpinnerContainer } from './styles';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'secondary' | 'tertiary';
  loading?: boolean;
  onAriaDisabledClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      theme = 'primary',
      loading = false,
      disabled = false,
      className,
      'aria-disabled': ariaDisabled,
      children,
      onClick,
      onAriaDisabledClick,
      ...rest
    },
    ref,
  ) => {
    const isButtonDisabled = React.useMemo(() => !!(disabled || loading), [
      disabled,
      loading,
    ]);

    const isButtonAriaDisabled = React.useMemo(
      () => !!(!isButtonDisabled && ariaDisabled),
      [ariaDisabled, isButtonDisabled],
    );

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isButtonAriaDisabled) {
          if (onAriaDisabledClick) {
            onAriaDisabledClick(event);
          }
        } else if (onClick) {
          onClick(event);
        }
      },
      [isButtonAriaDisabled, onAriaDisabledClick, onClick],
    );

    return (
      <ButtonContainer
        theme={theme}
        ref={ref}
        className={className}
        loading={loading}
        disabled={isButtonDisabled}
        aria-disabled={isButtonAriaDisabled}
        onClick={handleClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {children}
      </ButtonContainer>
    );
  },
);

export default Button;
