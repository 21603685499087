import React from 'react';

// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
import {
  format,
  parseISO,

  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';

import { EvolucaoProtocolo } from '@/models/EvolucaoProtocolo';
import { Container, Icon, Info } from './styles';
import { doctorDisplayModel } from '../../helpers';

interface Props {
  evolucao: EvolucaoProtocolo;
}

const InteracaoProtocolo: React.FC<Props> = ({ evolucao }) => {
  const display = React.useMemo(() => {
    const { name, specialty } = doctorDisplayModel(evolucao.resource);
    return [name, specialty].join(', ');
  }, [evolucao.resource]);

  const timestamp = React.useMemo(() => {
    return format(parseISO(evolucao.data_inicio), "dd 'de' MMMM, H'h'mm", {
      locale: ptBR,
    });
  }, [evolucao.data_inicio]);

  return (
    <Container>
      <Icon />
      <Info>
        <h4>{display}</h4>
        <small>{timestamp}</small>
      </Info>
    </Container>
  );
};

export default InteracaoProtocolo;
