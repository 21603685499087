import React, { ReactNode } from 'react';
import { MdCheck, MdInfoOutline } from 'react-icons/md';

import { Container, Icon } from './styles';

import 'react-toastify/dist/ReactToastify.css';

interface ToastProps {
  children?: ReactNode;
  check?: boolean;
}

const Toast: React.FC<ToastProps> = ({ children, check }) => {
  return (
    <Container>
      <Icon>{check ? <MdCheck /> : <MdInfoOutline />}</Icon>
      {children}
    </Container>
  );
};

export default Toast;
