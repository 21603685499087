import React from 'react';

import { Expressao } from '@/models/Expressao';
import Button from '@/components/Button';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';

import {
  ExpressaoContainer,
  ExpressaoName,
  ExpressaoValue,
  ResultadoContainer,
  ResultadoTitle,
  ResultadoActions,
  ResultadoAction,
} from './styles';

interface ResultadoProps {
  resultados: Expressao[];
}

const Resultado = React.forwardRef<HTMLDivElement, ResultadoProps>(
  ({ resultados }, ref) => {
    const {
      dispatchSolicitarFinalizarCalculadora,
      dispatchCopiarResultados,
    } = useCalculadoraContext().actions;

    return (
      <ResultadoContainer ref={ref}>
        <ResultadoTitle>Resultado</ResultadoTitle>

        {resultados.map(resultado => {
          return (
            <ExpressaoContainer key={resultado.id}>
              <ExpressaoName>{resultado.descricao}</ExpressaoName>
              <ExpressaoValue>{resultado.valor_formatado}</ExpressaoValue>
            </ExpressaoContainer>
          );
        })}

        <ResultadoActions>
          <ResultadoAction>
            <Button onClick={dispatchCopiarResultados} theme="secondary">
              Copiar resultados
            </Button>
          </ResultadoAction>
          <ResultadoAction>
            <Button onClick={dispatchSolicitarFinalizarCalculadora}>
              Finalizar
            </Button>
          </ResultadoAction>
        </ResultadoActions>
      </ResultadoContainer>
    );
  },
);

export default Resultado;
