import React from 'react';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Especialidade } from '@/models/Especialidade';
import { getNextAPIResultPage } from '@/utils/query';
import FlatList from '@/components/FlatList';
import {
  SpinnerInfinite,
  SpinnerWindow,
} from '@/components/Spinner/variations';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import EspecialidadesAPI from '@/features/dashboard/services/EspecialidadesAPI';

import InputEspecialidade from '../InputEspecialidade';
import { GridListContent } from './styles';

const PageContentEspecialidadeList: React.FC = () => {
  const history = useHistory();

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['especialidade-list'],
    ({ pageParam = 1, queryKey: [, term] }) =>
      EspecialidadesAPI.list({ page: pageParam, search: term, page_size: 50 }),
    {
      getNextPageParam: getNextAPIResultPage,
    },
  );

  const especialidades = React.useMemo(() => {
    if (data) {
      return data.pages.map(group => group.results).flat();
    }

    return [];
  }, [data]);

  const handleMore = React.useCallback(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching]);

  const navigateTo = React.useCallback(
    (especialidade: Especialidade) => {
      const location = {
        pathname: `/especialidades/${especialidade.id}`,
        state: { especialidade },
      };
      history.push(location);
    },
    [history],
  );

  const renderFooter = React.useCallback(() => {
    if (isFetchingNextPage) {
      return <SpinnerInfinite />;
    }

    return null;
  }, [isFetchingNextPage]);

  return (
    <LoadingSwitchTransition
      loading={isLoading}
      style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      LoadingComponent={<SpinnerWindow>Buscando resultados</SpinnerWindow>}
    >
      <FlatList
        data={especialidades}
        hasMore={hasNextPage}
        onEndReached={handleMore}
        keyExtractor={({ item: { id } }) => id}
        renderItem={({ item }) => (
          <InputEspecialidade
            especialidade={item}
            onClick={() => navigateTo(item)}
          />
        )}
        ListContentContainerElement={GridListContent}
        ListFooterComponent={renderFooter}
      />
    </LoadingSwitchTransition>
  );
};

export default PageContentEspecialidadeList;
