import React from 'react';
import { TagEmpresa } from '@/models/Tags';
import { Feature } from '@/models/Feature';
import { SugestaoJustificativa } from '@/models/Empresa';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

interface SettingsData {
  tags: TagEmpresa[];
  features: Feature[];
  reasonCodes: SugestaoJustificativa[];
}

interface Settings extends SettingsData {
  mode: 'enterprise' | 'personal';
  hasTags: boolean;
  hasTag: (name: string) => boolean;
  hasFeatures: boolean;
  hasFeature: (name: string) => boolean;
}

export default function useSettings(): Settings {
  const { user } = useAuth();

  const mode = React.useMemo(
    () => (user && user.empresa ? 'enterprise' : 'personal'),
    [user],
  );

  const { features, reasonCodes, tags } = React.useMemo(() => {
    if (user && user.empresa) {
      return {
        tags: user.empresa.tags_protocolo,
        features: user.empresa.features,
        reasonCodes: user.empresa.sugestoes_justificativa,
      };
    }

    return {
      tags: [],
      features: [],
      reasonCodes: [],
    };
  }, [user]);

  const hasTags = React.useMemo(() => !!tags.length, [tags.length]);

  const hasTag = React.useCallback(
    (...names: string[]) => {
      return tags.some(tag => names.includes(tag.nome));
    },
    [tags],
  );

  const hasFeatures = React.useMemo(() => !!features.length, [features.length]);

  const hasFeature = React.useCallback(
    (...names: string[]) => {
      return features.some(tag => names.includes(tag.nome));
    },
    [features],
  );

  return {
    mode,
    tags,
    hasTags,
    hasTag,
    features,
    hasFeature,
    hasFeatures,
    reasonCodes,
  };
}
