/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { MenuItemClickable } from './styles';

type Ref = HTMLAnchorElement;
interface Props extends React.AnchorHTMLAttributes<Ref> {
  isActive?: boolean;
  activeClassName?: string;
  activeStyle?: React.CSSProperties;
}

const MainMenuItem = React.forwardRef<Ref, Props>(
  (
    {
      isActive = false,
      activeClassName = 'active',
      activeStyle = {},
      style,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <MenuItemClickable
        activeClassName={activeClassName}
        className={[className, isActive ? activeClassName : null]
          .filter(Boolean)
          .join(' ')}
        style={{ ...style, ...(isActive ? activeStyle : null) }}
        ref={ref}
        {...props}
      >
        {children}
      </MenuItemClickable>
    );
  },
);

export default MainMenuItem;
