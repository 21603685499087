export function generateUniqueId(
  existingIds: string[],
  prefix = '',
  length = 2,
): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Function to generate a random ID with the specified length
  const generateId = (): string => {
    let result = prefix;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  };

  // Keep generating a new ID until it's unique
  let newId = generateId();
  while (existingIds.includes(newId)) {
    newId = generateId();
  }

  return newId;
}
