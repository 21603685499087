export function getTop(el: HTMLElement): number {
  let top = el.offsetTop;

  if (el.offsetParent) {
    top += getTop(el.offsetParent as HTMLElement);
  }

  return top;
}

export function scrollToWithStickyHeader(el: HTMLElement, offset = 0): void {
  let headerOffset = 0;
  const header = document.getElementById('medflow-header');
  if (header) {
    headerOffset = header.getBoundingClientRect().height;
  }

  window.scrollTo({
    top: getTop(el) - headerOffset - offset,
    behavior: 'smooth',
  });
}
