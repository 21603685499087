import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const DatePickerContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: calc(100% + 8px);
`;
