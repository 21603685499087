/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { format, isEqual, parse } from 'date-fns';
import { IMaskMixin, IMask } from 'react-imask';

import Calendar from '../Calendar';

import { Container, DatePickerContainer } from './styles';

type InputHTMLProps = React.InputHTMLAttributes<HTMLInputElement>;

interface Props {
  renderInput: (
    props: InputHTMLProps,
    ref: React.Ref<HTMLInputElement>,
  ) => JSX.Element;
  onChange?: (date?: Date) => void;
  style?: React.CSSProperties;
  className?: string;
  value?: Date;
  dateFormat?: string;
  datePattern?: string;
}

const DatePickerInput: React.FC<Props> = ({
  value,
  dateFormat = 'dd/MM/yyyy',
  datePattern = 'd{/}`m{/}`Y',
  style,
  className,
  onChange,
  renderInput,
}) => {
  const [textValue, setTextValue] = React.useState(
    value ? format(value, dateFormat) : '',
  );
  const [dateValue, setDateValue] = React.useState<Date | undefined>(value);
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const pickerRef = React.useRef<HTMLDivElement>(null);

  const DateInput = React.useMemo(
    () =>
      IMaskMixin<
        IMask.AnyMaskedOptions,
        false,
        string,
        HTMLInputElement,
        InputHTMLProps
      >(({ inputRef: renderRef, ...renderProps }) =>
        renderInput(renderProps, renderRef),
      ),
    [renderInput],
  );

  const formatDate = React.useCallback(
    (date: Date) => format(date, dateFormat),
    [dateFormat],
  );

  const parseDate = React.useCallback(
    (date: string) => parse(date, dateFormat, new Date()),
    [dateFormat],
  );

  const dispatchChange = React.useCallback(
    (date?: Date) => {
      if (onChange) {
        onChange(date);
      }
    },
    [onChange],
  );

  const handleChangeFromInput = React.useCallback(
    (_: string, { typedValue }: IMask.InputMask<IMask.AnyMaskedOptions>) => {
      const date = typedValue as Date;

      if (date && !isEqual(date, dateValue || new Date())) {
        setDateValue(date);
        dispatchChange(date);
      }
    },
    [dispatchChange, dateValue],
  );

  const handleChangeFromCalendar = React.useCallback(
    (date: Date) => {
      setTextValue(formatDate(date)); // calls handleChangeFromInput
      setCalendarOpen(false);
    },
    [formatDate],
  );

  const closeOnClickOutsideCalendar = React.useCallback((event: MouseEvent) => {
    const el = event.target;

    if (
      pickerRef.current &&
      inputRef.current &&
      el instanceof Node &&
      !pickerRef.current.contains(el) &&
      // Click on input should keep calendar open
      !inputRef.current.contains(el)
    ) {
      setCalendarOpen(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', closeOnClickOutsideCalendar, false);

    return () => {
      document.removeEventListener(
        'mousedown',
        closeOnClickOutsideCalendar,
        false,
      );
    };
  }, [closeOnClickOutsideCalendar]);

  React.useEffect(() => {
    setTextValue(value ? formatDate(value) : '');
    setDateValue(value);
  }, [formatDate, value]);

  return (
    <Container style={style} className={className}>
      <DateInput
        inputRef={inputRef}
        value={textValue}
        onFocus={() => setCalendarOpen(true)}
        mask={Date}
        pattern={datePattern}
        format={formatDate}
        parse={parseDate}
        onAccept={(val, { typedValue }) => {
          setTextValue(val);

          if (dateValue && !typedValue) {
            setDateValue(undefined);
            dispatchChange(undefined);
          }
        }}
        onComplete={handleChangeFromInput}
      />

      {calendarOpen && (
        <DatePickerContainer ref={pickerRef}>
          <Calendar value={dateValue} onChange={handleChangeFromCalendar} />
        </DatePickerContainer>
      )}
    </Container>
  );
};

export default DatePickerInput;
