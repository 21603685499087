import PlaceholderLoading from '@/components/PlaceholderLoading';
import React from 'react';

const PlaceholderHeaderTitle: React.FC = () => {
  return (
    <PlaceholderLoading.Rect
      style={{ display: 'flex' }}
      width={288}
      height={30}
    />
  );
};

export default PlaceholderHeaderTitle;
