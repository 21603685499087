import styled from 'styled-components';

import colors from '@/design/colors';
import { PageContentWrapper } from '@/layouts/PageLayout/styles';
import { HeadingSmall } from '@/design/typography';

export const Bar = styled.div`
  background-color: ${colors.gray100};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 24px;

  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};

  ${PageContentWrapper};
`;
