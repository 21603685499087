import React, { useCallback, useEffect } from 'react';
import { Prompt, Redirect, useParams } from 'react-router-dom';

import Loading from '@/components/Loading';

import { useCalculadoraContext } from '../../contexts/CalculadoraContext';
import CalculadoraComponent from '../Calculadora';
import CalculadoraProgress from '../CalculadoraProgress';
import ModalFinalizarCalculadora from '../ModalFinalizarCalculadora';
import ModalSairCalculadora from '../ModalSairCalculadora';

import {
  WrapperCalculadora,
  WrapperLoading,
  CalculadoraColumn,
  CalculadoraProgressColumn,
  Container,
} from './styles';

const PageContentCalculadora: React.FC = () => {
  const { codigo } = useParams<{ codigo: string }>();
  const { state, actions } = useCalculadoraContext();

  const openCalculadora = useCallback(() => {
    actions.dispatchOpenCalculadora(codigo);
  }, [actions, codigo]);

  useEffect(() => {
    openCalculadora();
    // eslint-disable-next-line
  }, []);

  if (state.hasCalculadoraError) {
    return <Redirect to="/calculadoras" />;
  }

  return (
    <Container>
      {state.calculadora ? (
        <WrapperCalculadora>
          <CalculadoraColumn>
            <CalculadoraComponent />
          </CalculadoraColumn>
          <CalculadoraProgressColumn>
            <CalculadoraProgress />
          </CalculadoraProgressColumn>
        </WrapperCalculadora>
      ) : (
        <WrapperLoading>
          <Loading>Carregando calculadora</Loading>
        </WrapperLoading>
      )}

      <Prompt
        when={
          !state.finalizarCalculadora.emAndamento &&
          !state.sairCalculadora.emAndamento
        }
        message={location => {
          actions.dispatchSolicitarSaidaCalculadora(location.pathname);
          return false;
        }}
      />

      <ModalFinalizarCalculadora />
      <ModalSairCalculadora />
    </Container>
  );
};

export default PageContentCalculadora;
