import React, { Children } from 'react';

import { TabsContext } from '..';
import { TabProps } from '../Tab';

import { Container, List, ListItem, ActiveIndicator } from './styles';

type TabListProps = React.PropsWithChildren & {
  style?: React.CSSProperties;
  className?: string;
};
type IndicatorState = { width: number; position: number };

const TabList: React.FC<TabListProps> = ({ children, style, className }) => {
  const { value } = React.useContext(TabsContext);
  const listRef = React.useRef<HTMLUListElement>(null);
  const itemsRef = React.useRef<{ [key: React.Key]: HTMLLIElement }>({});

  const indicator: IndicatorState = React.useMemo(() => {
    if (value !== undefined && itemsRef.current && listRef.current) {
      const item = itemsRef.current[value];

      if (item) {
        const { width } = item.getBoundingClientRect();
        const position = Math.abs(item.offsetLeft - listRef.current.offsetLeft);

        return { width, position };
      }
    }

    return { position: 0, width: 0 };
  }, [value]);

  return (
    <Container style={style} className={className}>
      <List ref={listRef}>
        {Children.toArray(children).map(elem => {
          if (React.isValidElement<TabProps>(elem)) {
            return (
              <ListItem
                key={elem.props.value}
                ref={el => {
                  if (el) {
                    itemsRef.current[elem.props.value] = el;
                  }
                }}
              >
                {elem}
              </ListItem>
            );
          }

          return <></>;
        })}
      </List>
      <ActiveIndicator
        style={{
          width: indicator.width,
          transform: `translateX(${indicator.position}px)`,
        }}
      />
    </Container>
  );
};

export default TabList;
