import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IntegrationProvider } from '@/features/enterprise/contexts/IntegrationContext';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import PassoExecutadoDetail from '../PassoExecutadoDetail';

import { PerguntaProvider } from '../../providers/PerguntaProvider';
import { Container } from './styles';

interface PassoListItemRefs {
  [key: number]: RefObject<HTMLDivElement>;
}

const ProtocoloExecutadoDetail: React.FC = () => {
  const [{ protocoloExecutado, passosExecutados }] = useExecucaoContext();

  const passoListItemRefs = useMemo(() => {
    return passosExecutados.reduce<PassoListItemRefs>((refs, passo) => {
      return { ...refs, [passo.id]: React.createRef() };
    }, {});
  }, [passosExecutados]);

  const [marginBottom, setMarginBottom] = useState(0);

  /**
   * Calcula o espaÃ§amento necessÃ¡rio para que o passo corrente fique sempre no
   * topo da tela quando o scroll estiver no mÃ¡ximo
   */
  const calcMarginBottom = useCallback(() => {
    if (passosExecutados.length === 0) {
      return;
    }

    const ultimoPasso = passosExecutados[passosExecutados.length - 1];
    let passoCorrenteRef = passoListItemRefs[ultimoPasso.id];

    if (protocoloExecutado && protocoloExecutado.passo_corrente) {
      const passoCorrenteId = protocoloExecutado.passo_corrente.id;
      passoCorrenteRef = passoListItemRefs[passoCorrenteId];
    }

    if (passoCorrenteRef.current) {
      const { height } = passoCorrenteRef.current.getBoundingClientRect();
      const offset = 83 + 48; // Navbar (83px) + MainContent padding (24px top + 24px bottom);
      const calcValue = window.innerHeight - height - offset;

      setMarginBottom(calcValue > 0 ? calcValue : 0);
    }
  }, [passoListItemRefs, passosExecutados, protocoloExecutado]);

  /**
   * Faz o scroll para o passo corrente
   *
   * Deve ser executado apÃ³s o cÃ¡lculo do marginBottom
   */
  const scrollToPassoCorrente = useCallback(() => {
    const totalHeigthBeforeCurrent = passosExecutados.reduce(
      (heigth, passo, index) => {
        const ref = passoListItemRefs[passo.id];

        if (index === passosExecutados.length - 1 || !ref.current) {
          return heigth;
        }

        return heigth + ref.current.getBoundingClientRect().height + 24;
      },
      0,
    );

    window.scrollTo({
      top: totalHeigthBeforeCurrent,
      left: 0,
      behavior: 'smooth',
    });
  }, [passoListItemRefs, passosExecutados]);

  useEffect(() => {
    calcMarginBottom();
  }, [calcMarginBottom]);

  useEffect(() => {
    scrollToPassoCorrente();
  }, [scrollToPassoCorrente, marginBottom]);

  return (
    <Container style={{ marginBottom }}>
      {passosExecutados.map(passo => (
        <IntegrationProvider>
          <PerguntaProvider>
            <PassoExecutadoDetail
              ref={passoListItemRefs[passo.id]}
              key={passo.id.toString()}
              passoExecutado={passo}
            />
          </PerguntaProvider>
        </IntegrationProvider>
      ))}
    </Container>
  );
};

export default ProtocoloExecutadoDetail;
