import React, { useCallback } from 'react';

import InputFieldDate from '@/components/InputFieldDate';
import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { format } from 'date-fns';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputData: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { nome } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const handleChange = useCallback(
    (changeValue?: Date) => {
      let dateString = null;
      if (changeValue !== undefined) {
        dateString = format(changeValue, 'yyyy-MM-dd');

        setValue(dateString);
      }

      if (onChange) {
        onChange({
          [nome]: dateString,
        });
      }
    },
    [nome, onChange, setValue],
  );

  return (
    <InputFieldDate
      style={{ width: 279 }}
      value={value}
      disabled={disabled}
      onChangeDate={handleChange}
    />
  );
};

export default InputData;
