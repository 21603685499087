/* eslint-disable @typescript-eslint/no-unused-vars */

import styled from 'styled-components';

import colors from '@/design/colors';
import { Text } from '@/design/typography';

export const Wrapper = styled.div`
  position: relative;
`;

export const PreloaderWrapper = styled.div`
  position: relative;

  height: 100vh;
  width: 100vw;

  background-color: ${colors.gray50};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: opacity 200ms linear;
  }
`;

export const MessageWrapper = styled.div`
  ${Text(14)};

  max-width: 200px;
  text-align: center;

  color: ${colors.blackAlpha200};
`;
