/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/sevices/api';
import { Protocolo } from '@/models/Protocolo';
import { APIRequestParams, APIResult } from '@/types/Api';
import FeedbackGraphic from '@/components/FeedbackGraphic';

import { getNextAPIResultPage } from '@/utils/query';
import FlatList, { FlatListProps } from '@/components/FlatList';
import { SpinnerInfinite } from '@/components/Spinner/variations';
import { Header, LoadingTransition } from './styles';

import TabGroupProtocolo from '../TabGroupProtocolo';

import PlaceholderFlatListProtocolo from '../PlaceholderFlatListProtocolo';
import InputProtocolo from '../InputProtocolo';

const fetchProtocolos = (
  params: APIRequestParams,
): Promise<APIResult<Protocolo>> =>
  api
    .get<APIResult<Protocolo>>(`/protocolos/`, {
      params,
    })
    .then(res => res.data);

interface Props {
  search?: string;
}

const FeedbackListEmpty: React.FC = () => (
  <FeedbackGraphic
    graphic="empty-box"
    title="Nenhum protocolo por aqui"
    subtitle="Ainda não há protocolos disponíveis para você."
  />
);

const FeedbackTabEmpty: React.FC = () => (
  <FeedbackGraphic
    graphic="empty-box"
    title="Nenhum protocolo por aqui"
    subtitle="Não conseguimos encontrar o protocolo que você deseja. Tente buscar usando um termo diferente."
  />
);

const SectionProtocolos: React.FC<Props> = ({ search = '' }) => {
  const history = useHistory();

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['protocolo-list', search],
    ({ pageParam = 1, queryKey: [, term] }) =>
      fetchProtocolos({ page: pageParam, search: term, page_size: 25 }),
    {
      getNextPageParam: getNextAPIResultPage,
    },
  );

  const handleMore = React.useCallback(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching]);

  const handleOpen = React.useCallback(
    ({ nome: protocolo_nome, codigo: codigo_protocolo }: Protocolo) => {
      const params = new URLSearchParams({
        protocolo_nome,
        codigo_protocolo,
      });

      history.push(`/abrir-execucao/?${params.toString()}`);
    },
    [history],
  );

  const protocolos: Protocolo[] = React.useMemo(() => {
    if (data) {
      return data.pages.map(group => group.results).flat();
    }

    return [];
  }, [data]);

  const LoadingComponent = React.useMemo(() => {
    // Não faz sentido exibir o skeleton durante loading via busca
    if (search) {
      return <></>;
    }

    return (
      <PlaceholderFlatListProtocolo
        length={6}
        style={{
          marginTop: 36,
        }}
      />
    );
  }, [search]);

  const renderFooter = React.useCallback(() => {
    if (isFetchingNextPage) {
      return <SpinnerInfinite />;
    }

    return null;
  }, [isFetchingNextPage]);

  const FlatListProtocolo = React.useCallback(
    (props: FlatListProps<Protocolo>) => {
      if (search) {
        return (
          <TabGroupProtocolo ListEmptyComponent={FeedbackTabEmpty} {...props} />
        );
      }

      return <FlatList ListEmptyComponent={FeedbackListEmpty} {...props} />;
    },
    [search],
  );

  return (
    <LoadingTransition
      style={{ flex: 1 }}
      loading={isLoading}
      LoadingComponent={LoadingComponent}
    >
      <Header>
        <h1>Protocolos para você</h1>
      </Header>
      <FlatListProtocolo
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        data={protocolos}
        keyExtractor={({ item: { id } }) => id}
        renderItem={({ item }) => (
          <InputProtocolo protocolo={item} onClick={() => handleOpen(item)} />
        )}
        onEndReached={handleMore}
        hasMore={hasNextPage}
        ListFooterComponent={renderFooter}
      />
    </LoadingTransition>
  );
};

export default SectionProtocolos;
