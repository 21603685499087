import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  width: 100%;
  padding: 10px;
  border-radius: 8px;

  color: ${colors.blackAlpha100};
  position: relative;

  ${HeadingSmall(14)};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${colors.purple100};
    border: 1px solid transparent;
    background-image: repeating-linear-gradient(
        0deg,
        ${colors.purple100},
        ${colors.purple100} 15px,
        transparent 15px,
        transparent 30px,
        ${colors.purple100} 30px
      ),
      repeating-linear-gradient(
        90deg,
        ${colors.purple100},
        ${colors.purple100} 15px,
        transparent 15px,
        transparent 30px,
        ${colors.purple100} 30px
      ),
      repeating-linear-gradient(
        180deg,
        ${colors.purple100},
        ${colors.purple100} 15px,
        transparent 15px,
        transparent 30px,
        ${colors.purple100} 30px
      ),
      repeating-linear-gradient(
        270deg,
        ${colors.purple100},
        ${colors.purple100} 15px,
        transparent 15px,
        transparent 30px,
        ${colors.purple100} 30px
      );
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    background-color: ${colors.purple50};
  }
`;
