import React from 'react';

import spinner from '@/assets/spinner-thin.svg';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
}

const Loading: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <img src={spinner} alt="Carregando" srcSet="" />

      <span>{children}</span>
    </Container>
  );
};

export default Loading;
