import { Medicamento } from '@/models/Medicamento';
import { NavItem } from '../NavTabs';

export function createNavItems(medicamento: Medicamento): NavItem[] {
  return [
    {
      key: 'nomes_comerciais',
      title: 'Nomes comercias',
      content: medicamento.nomes_comerciais.join(', '),
    },
    {
      key: 'contra_indicacao',
      title: 'Contra Indicação',
      content: medicamento.contra_indicacao,
    },
    {
      key: 'uso_clinico',
      title: 'Uso Cliníco',
      content: medicamento.uso_clinico,
    },
    {
      key: 'efeitos_adversos',
      title: 'Efeitos Adversos',
      content: medicamento.efeitos_adversos,
    },
    { key: 'gestacao', title: 'Gestação', content: medicamento.gestacao },
    {
      key: 'administracao',
      title: 'Preparação',
      content: medicamento.administracao,
    },
  ].filter(item => !!item.content);
}
