/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  ArtifactFormLayout,
  ArtifactFormTitle,
} from '@/features/artifacts/components/ArtifactForm/styles';
import { codeableConceptDisplay } from '@/features/fhir/helpers/fhirpath';

import FormEncaminhamento, {
  FormEncaminhamentoProps,
} from '../FormEncaminhamento';

const AddCondutaEncaminhamento: React.FC<FormEncaminhamentoProps> = ({
  ...formProps
}) => {
  const title = React.useMemo(
    () => codeableConceptDisplay(formProps.resource, 'code'),
    [formProps.resource],
  );

  return (
    <ArtifactFormLayout>
      <ArtifactFormTitle>{title}</ArtifactFormTitle>
      <FormEncaminhamento {...formProps} />
    </ArtifactFormLayout>
  );
};

export default AddCondutaEncaminhamento;
