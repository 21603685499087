/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { enforceElement, RenderableElement } from '@/utils/elements';

import { Menu, DropdownContainer } from './styles';

type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface DropdownProps extends ButtonType {
  DropdownToggle: React.ComponentType<ButtonType>;
  DropdownMenu: RenderableElement;
  menuAlignment?: 'right' | 'left';
}

export interface DropdownRef {
  show: () => void;
  hide: () => void;
}

const Dropdown = React.forwardRef<DropdownRef, DropdownProps>(
  (
    {
      DropdownToggle,
      DropdownMenu,
      style,
      className,
      onClick,
      menuAlignment = 'left',
      ...buttonProps
    },
    ref,
  ) => {
    const [menuShow, setMenuShow] = React.useState(false);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const DropdownMenuLayout = React.useMemo(() => {
      if (DropdownMenu && menuShow) {
        return (
          <DropdownContainer alignment={menuAlignment}>
            {enforceElement(DropdownMenu)}
          </DropdownContainer>
        );
      }

      return undefined;
    }, [DropdownMenu, menuAlignment, menuShow]);

    const handleToggle = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuShow(prev => !prev);

        if (onClick) {
          onClick(event);
        }
      },
      [onClick],
    );

    const handleClickOutside = React.useCallback((evt: MouseEvent) => {
      if (!dropdownRef || !dropdownRef.current) {
        return;
      }

      if (!dropdownRef.current.contains(evt.target as Node)) {
        setMenuShow(false);
      }
    }, []);

    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside, false);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside, false);
      };
    }, [handleClickOutside]);

    React.useImperativeHandle(
      ref,
      () => ({
        show: () => setMenuShow(true),
        hide: () => setMenuShow(false),
      }),
      [],
    );

    return (
      <Menu style={style} className={className} ref={dropdownRef}>
        <DropdownToggle onClick={handleToggle} {...buttonProps} />
        {DropdownMenuLayout}
      </Menu>
    );
  },
);

export default Dropdown;
