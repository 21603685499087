/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react';

import {
  AddonLabel,
  Field,
  HelperText,
  Input,
  InputContent,
  Textarea,
  Title,
} from './styles';

interface FieldLayoutProps extends React.PropsWithChildren {
  title?: string;
  error?: string;
  style?: CSSProperties;
}

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  inputTitle?: string; // Standart HTML title attribute
  addon?: string;
  error?: string;
  children?: React.ReactNode;
}

export interface TextFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  inputTitle?: string; // Standart HTML title attribute
  error?: string;
}

const FieldLayout: React.FC<FieldLayoutProps> = ({
  error,
  title,
  style,
  children,
}) => (
  <Field hasError={!!error} style={style}>
    {title && <Title>{title}</Title>}
    <InputContent>{children}</InputContent>
    {error && <HelperText>{error}</HelperText>}
  </Field>
);

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  ({ title, addon, error, children, inputTitle, ...inputProps }, ref) => {
    return (
      <FieldLayout error={error} title={title} style={inputProps.style}>
        <Input {...inputProps} ref={ref} title={inputTitle} />
        {addon && <AddonLabel>{addon}</AddonLabel>}
        {children}
      </FieldLayout>
    );
  },
);

export const TextField = React.forwardRef<HTMLTextAreaElement, TextFieldProps>(
  ({ title, error, inputTitle, ...textareaProps }, ref) => {
    return (
      <FieldLayout error={error} title={title}>
        <Textarea ref={ref} {...textareaProps} title={inputTitle} />
      </FieldLayout>
    );
  },
);

export default InputField;
