import styled from 'styled-components';

import Button from '@/components/Button';
import Card from '@/components/Card';
import colors from '@/styles/colors';

import gaps from '@/design/gaps';
import { TipoPasso } from './types';

export const RefazerButton = styled(Button).attrs({
  theme: 'secondary',
})<{ passoType: TipoPasso }>`
  display: none;
  position: absolute;
  right: 24px;
  top: ${({ passoType }) =>
    passoType === TipoPasso.CONDITION ? '32px' : '16px'};
`;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  row-gap: ${gaps.defaultGap};
  padding: 20px 0px;

  position: relative;

  width: 100%;

  & + div {
    margin-top: 24px;
  }

  &:hover {
    ${RefazerButton} {
      display: inherit;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;

  margin-inline: 24px;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  row-gap: ${gaps.defaultGap};
`;

export const DescriptionContainer = styled.div`
  padding: 0px 24px;
`;

export const ItemList = styled.div`
  width: 100%;
`;

export const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  height: 40px;

  img {
    margin-right: 20px;
  }

  h2 {
    color: ${colors.black};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid ${colors.secondaryLite};

  padding-inline: 24px;

  div + button {
    margin-left: 16px;
  }
`;
