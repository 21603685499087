import React, { useCallback } from 'react';

import InputFieldTime from '@/components/InputFieldTime';
import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputHora: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { nome } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const handleChange = useCallback(
    (timeString: string) => {
      setValue(timeString);

      if (onChange) {
        onChange({
          [nome]: timeString || null,
        });
      }
    },
    [nome, onChange, setValue],
  );

  return (
    <InputFieldTime
      style={{ width: 279 }}
      value={value}
      disabled={disabled}
      onChangeTime={handleChange}
    />
  );
};

export default InputHora;
