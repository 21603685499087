import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '@/sevices/api';

import FeedbackGraphic from '@/components/FeedbackGraphic';
import Preloader from '@/components/Preloader';

import { StatusIntegracao } from '@/models/ProtocoloExecutado';

import { FeedbackContainer } from './styles';

interface IntegracaoProtocolo {
  instance: { id: string } | null;
  status_integracao: StatusIntegracao;
}

const retryFetchTimeoutInMS = 300;

const PageIntegracaoProtocolo: React.FC = () => {
  const history = useHistory();
  const integracaoID = useParams<{ id: string }>().id;

  const [done, setDone] = React.useState(false);
  const [retries, setRetries] = React.useState(0);
  const [integracao, setIntegracao] = React.useState<IntegracaoProtocolo>();

  React.useEffect(() => {
    let retryTimeout: NodeJS.Timeout | undefined;

    (async () => {
      const { data } = await api.get<IntegracaoProtocolo>(
        `/integracoes/${integracaoID}/`,
      );

      setIntegracao(data);

      if (data.status_integracao === 'active') {
        setDone(true);
      } else if (data.status_integracao === 'on-hold') {
        retryTimeout = setTimeout(
          () => setRetries(retries + 1),
          retryFetchTimeoutInMS,
        );
      }
    })();

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [integracaoID, retries]);

  const redirectToInstance = React.useCallback(() => {
    if (integracao && integracao.instance) {
      history.replace(`/enterprise/protocolos/${integracao.instance.id}/`);
    }
  }, [history, integracao]);

  const status = React.useMemo(
    () => integracao && integracao.status_integracao,
    [integracao],
  );

  return (
    <Preloader
      loading={status !== 'entered-in-error'}
      done={done}
      style={{ flex: 1 }}
      onLoadingDoneAnimationEnd={() => redirectToInstance()}
    >
      <FeedbackContainer>
        <FeedbackGraphic
          graphic="alert"
          title="Ops, algo deu errado."
          subtitle="Por favor, tente novamente em alguns instantes."
        />
      </FeedbackContainer>
    </Preloader>
  );
};

export default PageIntegracaoProtocolo;
