import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  CollapseButton,
  CloseDetailsIcon,
  OpenDetailsIcon,
  IconContainer,
} from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  collapsed: boolean;
}

const DetailsCollapseButton: React.FC<Props> = ({ collapsed, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CollapseButton collapsed={collapsed} {...props}>
      <SwitchTransition mode="in-out">
        <CSSTransition
          key={collapsed ? 'close-details' : 'open-details'}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <IconContainer>
            {collapsed ? <CloseDetailsIcon /> : <OpenDetailsIcon />}
          </IconContainer>
        </CSSTransition>
      </SwitchTransition>
    </CollapseButton>
  );
};

export default DetailsCollapseButton;
