import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Link from '@/components/Link';
import Carousel from '@/components/Carousel';
import { Especialidade } from '@/models/Especialidade';
import EspecialidadesAPI from '@/features/dashboard/services/EspecialidadesAPI';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';

import PlaceholderCarouselEspecialidades from '../PlaceholderCarouselEspecialidade';
import InputEspecialidade from '../InputEspecialidade';

import {
  SectionEspecialidadesContainer,
  SectionEspecialidadesContent,
  SectionEspecialidadesHeader,
  CarouselLeftArrow,
  CarouselRightArrow,
} from './styles';

const fetchEspecialidades = (): Promise<Especialidade[]> =>
  EspecialidadesAPI.list({
    page: 1,
    page_size: 50,
  }).then(apiResult => apiResult.results);

const SectionEspecialidades: React.FC = () => {
  const history = useHistory();
  const { data, isLoading } = useQuery(
    ['especialidade-list-dashboard'],
    fetchEspecialidades,
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = React.useState(1024);

  const especialidades = React.useMemo(
    () => data && data.filter(item => item.count.protocolos > 0).slice(0, 8),
    [data],
  );

  const handleClickEspecialidade = React.useCallback(
    (especialidade: Especialidade) => {
      const location = {
        pathname: `/especialidades/${especialidade.id}`,
        state: { especialidade },
      };
      history.push(location);
    },
    [history],
  );

  const handleParentWidth = React.useCallback(() => {
    const { current } = containerRef;
    if (current && current.parentElement) {
      const { width } = current.parentElement.getBoundingClientRect();

      setParentWidth(width);
    }
  }, []);

  React.useEffect(() => {
    handleParentWidth();

    window.addEventListener('resize', handleParentWidth);

    return () => {
      window.removeEventListener('resize', handleParentWidth);
    };
  }, [handleParentWidth]);

  return (
    <SectionEspecialidadesContainer ref={containerRef}>
      <LoadingSwitchTransition
        loading={isLoading}
        LoadingComponent={<PlaceholderCarouselEspecialidades />}
      >
        {especialidades && especialidades.length > 0 && (
          <SectionEspecialidadesContent>
            <SectionEspecialidadesHeader>
              <h1>Navegue por especialidades</h1>
              <Link to="/especialidades">Ver todas</Link>
            </SectionEspecialidadesHeader>
            <Carousel
              items={especialidades}
              keyExtractor={item => item.id.toString()}
              LeftArrow={<CarouselLeftArrow />}
              RightArrow={<CarouselRightArrow />}
              renderItem={({ item, index, array }) => (
                <InputEspecialidade
                  style={{
                    marginRight: index === array.length - 1 ? 0 : 16,
                  }}
                  especialidade={item}
                  onClick={() => handleClickEspecialidade(item)}
                />
              )}
              width={parentWidth}
            />
          </SectionEspecialidadesContent>
        )}
      </LoadingSwitchTransition>
    </SectionEspecialidadesContainer>
  );
};

export default SectionEspecialidades;
