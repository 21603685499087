import React from 'react';
import fhirpath from 'fhirpath';
import { Slide, toast } from 'react-toastify';
import { FiCopy } from 'react-icons/fi';

import { FhirPedidoType } from '@/features/enterprise/models/types';

import Toast from '@/components/Toast';
import IconButton from '@/components/IconButton';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderSubTitle,
  Actions,
  InfoContainer,
  InfoContainerIcon,
  InfoContainerText,
} from './styles';

interface BoxMedicamentoProps {
  medicamento: FhirPedidoType;
  disableCopy?: boolean;
  isOpen?: boolean;
}

const BoxMedicamento: React.FC<BoxMedicamentoProps> = ({
  medicamento,
  disableCopy,
  isOpen,
}: BoxMedicamentoProps) => {
  const principioAtivo = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.medicationCodeableConcept.select(text | coding.display)',
    )[0];
  }, [medicamento]);

  const dosagem = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.dosageInstruction.text',
    )[0];
  }, [medicamento]);

  const instrucoesAdicionais = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.dosageInstruction.additionalInstruction.text',
    )[0];
  }, [medicamento]);

  const copyMedicamento = React.useCallback(() => {
    let texto = `${principioAtivo}`;
    if (dosagem) {
      texto += `\n${dosagem}`;
    }
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Medicamento copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [principioAtivo, dosagem]);

  const [state, setState] = React.useState({
    detailsOpened: isOpen || false,
    onHover: false,
  });

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={state.detailsOpened || state.onHover}>
          {principioAtivo}
        </HeaderTitle>
        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar medicamento"
              onClick={copyMedicamento}
              onMouseOver={() => setState(prev => ({ ...prev, onHover: true }))}
              onMouseLeave={() =>
                setState(prev => ({ ...prev, onHover: false }))
              }
              icon={<FiCopy />}
            />
          )}
        </Actions>
      </Header>

      {dosagem && (
        <HeaderSubTitle highlight={state.detailsOpened || state.onHover}>
          {dosagem}
        </HeaderSubTitle>
      )}

      {instrucoesAdicionais && (
        <InfoContainer>
          <InfoContainerIcon />
          <InfoContainerText>{instrucoesAdicionais}</InfoContainerText>
        </InfoContainer>
      )}
    </Container>
  );
};

BoxMedicamento.defaultProps = {
  disableCopy: false,
  isOpen: false,
};

export default BoxMedicamento;
