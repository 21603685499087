import { Field } from '@/components/InputField/styles';
import Markdown from '@/components/Markdown';
import { SelectionControlButton } from '@/components/SelectionControlInput/styles';
import colors from '@/design/colors';
import styled, { css } from 'styled-components';

interface StyledProps {
  autoFocus: boolean;
}

export const Container = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;

  /* & + & {
    margin-top: 24px;
  } */

  ${SelectionControlButton} {
    transition: background-color 200ms ease-out;
  }

  ${props =>
    props.autoFocus &&
    css`
      /*
      Aplica requireFocus (blink) nos componentes de pergunta que utilizam
      InputField

      - InputNumerico
      - InputNumericoInteiro
      - InputNumericoDecimal
      - InputData
      - InputHora
      - InputDataHora
      */
      ${Field} {
        input,
        textarea {
          background-color: ${colors.purple50};
        }
      }

      /*
      Aplica requireFocus (blink) nos componentes de pergunta que utilizam
      SelectionControlAlternatives (radio ou checkbox)

      - InputSimOuNao
      - InputEscolhaUnica
      - InputMultiplaEscolha
      */
      ${SelectionControlButton} {
        background-color: ${colors.purple50};
      }
    `}
`;

export const DescricaoPergunta = styled(Markdown)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 8px;
`;
