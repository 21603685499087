import { ItemComplementarState, ItemStatus } from '../models';

interface UpdateOptions {
  resource: fhir4.FhirResource;
  itemState: Partial<ItemStatus>;
}

export function updateItemStateData(
  item: ItemComplementarState,
  options: Partial<UpdateOptions>,
): ItemComplementarState {
  const { itemState: innerState = {}, resource: innerResource, ...rest } = item;
  const { itemState: updatingState = {}, resource: updatingResource } = options;

  const newItem: ItemComplementarState = {
    ...rest,
    itemState: { ...innerState, ...updatingState },
    resource: updatingResource || innerResource,
  };

  return newItem;
}
