import React from 'react';
import fhirpath from 'fhirpath';

import dadosAtendimentoImage from '@/assets/dadoAtendimento.svg';

import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import {
  DadosContainer,
  DadosBox,
  DadosBoxContent,
  DadosContainerTitle,
  DadosImage,
  DadosInfo,
  DadosInfoItem,
  DadosInfoValue,
  DadosInfoTitle,
} from './styles';

const DadosAtendimentoSection: React.FC = () => {
  const [{ encounter }] = useEnterpriseContext();

  const identifier = React.useMemo(() => {
    if (encounter) {
      return (
        fhirpath.evaluate(
          encounter.data,
          "Encounter.identifier.where(use='usual').value",
        )[0] || ''
      );
    }

    return '';
  }, [encounter]);

  return (
    identifier && (
      <DadosContainer>
        <DadosContainerTitle>Dados do atendimento</DadosContainerTitle>
        <DadosBox>
          <DadosBoxContent>
            <DadosImage src={dadosAtendimentoImage} />
            <DadosInfo>
              <DadosInfoItem>
                <DadosInfoTitle>Código de identificação:</DadosInfoTitle>
                <DadosInfoValue>{identifier}</DadosInfoValue>
              </DadosInfoItem>
            </DadosInfo>
          </DadosBoxContent>
        </DadosBox>
      </DadosContainer>
    )
  );
};

export default DadosAtendimentoSection;
