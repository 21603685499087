/* eslint-disable @typescript-eslint/no-explicit-any */
import fhirpath from 'fhirpath';
import unset from 'lodash.unset';

type RType = fhir4.FhirResource;

type ManipulationFunc = (fhirData: RType) => RType;
type ExtractionFunc = (fhirData: RType) => any;

type UnsetSettings = (path: string) => (fhirData: RType) => ManipulationFunc;
type GetSetitings = (path: string) => ExtractionFunc;

export const FHIRPath: UnsetSettings = path => {
  const evaluate = (fhirData: RType) => {
    const result = fhirpath.evaluate(fhirData, path);
    const valueString = result.length && result[0].toString();
    return valueString || '';
  };

  return evaluate;
};

export const PropertyPathUnset: GetSetitings = path => {
  const evaluate = (fhirData: RType) => {
    const obj = { ...fhirData } as { [key: string]: unknown };
    unset(obj, path);
    return (obj as unknown) as RType;
  };

  return evaluate;
};
