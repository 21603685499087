import styled, { css } from 'styled-components';

import banner from '@/assets/auth.png';
import appleStore from '@/assets/apple-store.svg';
import googlePlayStore from '@/assets/google-play.svg';
import colors from '@/styles/colors';
import { FormGroupContainer } from '@/components/FormGroup/styles';
import { scrollbar } from '@/styles/helpers';

export const Container = styled.div`
  display: flex;
`;

export const OverlayContainer = styled.div`
  background: linear-gradient(149.81deg, #2047ae 0%, #4676fa 100%);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  height: 100%;
  width: calc(100vw / 2);
`;

export const BannerContainer = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const Banner = styled.img.attrs({
  src: banner,
  alt: 'Medflow',
})`
  max-height: min(61vh, 540px);
`;

export const ComercialInfo = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.white};
  text-align: center;
  margin: 32px 0;
  max-width: 443px;
`;

export const StoreButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a + a {
    margin-left: 20px;
  }
`;

const ButtonStoreStyle = css`
  background-size: contain;
  background-repeat: no-repeat;
  width: 152px;
  height: 46px;
`;

export const ButtonAppleStore = styled.a.attrs({
  href: 'https://apps.apple.com/br/app/medflow/id1519369271',
  target: '_blank',
  rel: 'noreferrer',
})`
  background-image: url(${appleStore});
  ${ButtonStoreStyle}
`;

export const ButtonGooglePlayStore = styled.a.attrs({
  href: 'https://play.google.com/store/apps/details?id=com.intmed.medflow',
  target: '_blank',
  rel: 'noreferrer',
})`
  background-image: url(${googlePlayStore});
  ${ButtonStoreStyle}
`;

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
`;

export const LogoContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: 32px;
    display: block;
    margin-bottom: 24px;
  }

  div {
    align-self: baseline;
  }
`;

export const AuthBoxHeader = styled.div`
  padding: 24px 24px 8px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const AuthBoxContent = styled.div`
  overflow-y: auto;
  ${scrollbar}

  form {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    ${FormGroupContainer} + ${FormGroupContainer} {
      margin-top: 16px;
    }

    > button {
      margin-top: 24px;
    }

    > span {
      color: ${colors.error};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
`;

interface AuthBoxProps {
  isScrolling?: boolean;
  isScrollable?: boolean;
}

export const AuthBox = styled.div<AuthBoxProps>`
  display: flex;
  flex-direction: column;
  width: 406px;
  min-height: 0;
  max-height: calc(94vh);
  border-radius: 24px;
  background-color: ${colors.white};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  ${AuthBoxHeader} {
    ${props =>
      props.isScrolling &&
      css`
        z-index: 1;
        box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
      `}
  }

  ${AuthBoxContent} {
    padding: 8px ${props => (props.isScrollable ? '2px' : '24px')} 24px 24px;
  }
`;
