import colors from '@/design/colors';
import { HeadingLarge, HeadingSmall, Text } from '@/design/typography';
import styled, { css } from 'styled-components';

interface StyledProps {
  waiting: boolean;
  disabled: boolean;
}

export const DefaultLabel = styled.span`
  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 100ms;
  }
`;

export const WaitingLabel = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out 200ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 50ms;
  }
`;

export const ButtonContainer = styled.div<StyledProps>`
  position: relative;
  min-width: 133px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray100};
  background-color: ${colors.white};
  padding: 8px 0;
  border-radius: 24px;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
    width 300ms ease-in-out;

  ${props =>
    !props.waiting &&
    (props.disabled
      ? css`
          cursor: not-allowed;

          ${DefaultLabel} {
            color: ${colors.blackAlpha50};
          }
        `
      : css`
          &:hover {
            cursor: pointer;
            background-color: ${colors.red50};
            border-color: ${colors.red100};

            ${DefaultLabel} {
              color: ${colors.red100};
            }
          }
        `)}

  @media (max-width: 1270px) {
    width: ${props => (props.waiting ? '100%' : '0%')};
  }
  @media (max-width: 970px) {
    width: ${props => (props.waiting ? '146px' : '0%')};
  }
  width: ${props => (props.waiting ? '55%' : '0%')};
`;

export const UndoHelpText = styled.span`
  flex: 1;
  padding: 2px 8px;
  text-align: start;

  ${Text(14)};

  @media (max-width: 970px) {
    display: none;
  }
  @media (max-width: 1110px) {
    ${Text(12)};
  }
`;

export const UndoButton = styled.button`
  display: flex;
  align-items: center;
  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};

  > svg {
    margin-right: 8px;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
`;

export const CircularProgressContainer = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
`;

export const CircularProgressLabel = styled.div`
  ${HeadingLarge(14)};
  color: ${colors.purple100};
`;
