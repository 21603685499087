export default {
  primaryTone: '#2047AE',
  primaryTint: '#4676FA',
  primaryShade: '#092061',
  secondaryTone: '#CCCCCC',
  secondaryTint: '#F8F8F8',
  secondaryLite: '#D9DBE3',
  secondaryShade: '#888888',
  secondaryHard: '#666666',
  terciaryTone: '#F0F1F4',
  white: '#FFFFFF',
  whiteShade: '#F9F9F9',
  black: '#27262A',
  blackAlpha200: '#2F3139',
  gray: '#A7A9B1',
  hardGray: '#75777F',
  mediumGray: '#5D5E64',
  error: '#da3849',
  errorLight: 'rgba(218, 56, 73, 0.3)',
  errorText: '#750f19',
  backgroundColor: '#F0F2F5',
};
