import React from 'react';

import ReactMarkdown from 'react-markdown';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import { ModalDialogContainer, Title, Fonte } from './styles';

const ModalFontesProtocolo: React.FC = () => {
  const [
    { exibirFontesProtocolo, protocoloExecutado },
    { dispatchExibirFontesProtocolo },
  ] = useExecucaoContext();

  const fontes = protocoloExecutado?.autoria?.fontes;

  return (
    <ModalDialogContainer
      title="Fontes"
      onAfterClose={() => dispatchExibirFontesProtocolo(false)}
      isOpen={exibirFontesProtocolo}
    >
      <Title>{protocoloExecutado?.nome}</Title>

      {fontes?.map(fonte => {
        return (
          <Fonte key={fonte}>
            <ReactMarkdown linkTarget="_blank">{fonte}</ReactMarkdown>
          </Fonte>
        );
      })}
    </ModalDialogContainer>
  );
};

export default ModalFontesProtocolo;
