import React from 'react';

import { usePerguntaContext } from '@/features/execucao/providers/PerguntaProvider';
import { RespostaPayload } from '@/features/execucao/types';
import { Pergunta } from '@/models/Pergunta';
import PlaceholderAllowNull from '../PlaceholderAllowNull';
import { InputPerguntaProps } from '../types';
import { Container } from './styles';

interface NullableInputProps extends InputPerguntaProps {
  inputElement: React.ComponentType<InputPerguntaProps>;
}

const NullableInput: React.FC<NullableInputProps> = ({
  onChange,
  pergunta,
  disabled,
  inputElement: InputElement,
}: NullableInputProps) => {
  const [
    ,
    { dispatchAddAllowNullItem, dispatchRemoveAllowNullItem },
  ] = usePerguntaContext();
  const [nullable, setNullable] = React.useState(false);

  const [inputDisabled, setInputDisabled] = React.useState(disabled);

  const [perguntaNullable, setPerguntaNullable] = React.useState<Pergunta>({
    ...pergunta,
    variavel: {
      ...pergunta.variavel,
      valor: pergunta.variavel.valor,
    },
  });

  const isDisabled = React.useMemo(() => {
    return disabled || inputDisabled;
  }, [disabled, inputDisabled]);

  const onAllowNullHandle = React.useCallback((state: string) => {
    setNullable(state === 'marked');
    setInputDisabled(state === 'marked');
  }, []);

  const handleNullableInputChange = React.useCallback(
    (payload: RespostaPayload) => {
      if (onChange) {
        onChange(payload);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    if (perguntaNullable.variavel.not_applicable) {
      setNullable(true);
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
  }, [perguntaNullable.variavel.not_applicable]);

  React.useEffect(() => {
    if (onChange) {
      if (nullable) {
        onChange({
          [pergunta.variavel.nome]: null,
        });

        setPerguntaNullable(prev => ({
          ...prev,
          variavel: {
            ...prev.variavel,
            valor: null,
          },
        }));
        dispatchAddAllowNullItem(pergunta.variavel.nome);
      } else {
        dispatchRemoveAllowNullItem(pergunta.variavel.nome);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullable]);

  return (
    <Container>
      <InputElement
        pergunta={perguntaNullable}
        disabled={isDisabled}
        onChange={handleNullableInputChange}
      />
      <PlaceholderAllowNull
        allow_null={perguntaNullable.variavel.allow_null}
        allow_null_placeholder={
          perguntaNullable.variavel.allow_null_placeholder
        }
        disabled={disabled}
        not_applicable={perguntaNullable.variavel.not_applicable}
        onClickHandle={onAllowNullHandle}
      />
    </Container>
  );
};

export default NullableInput;
