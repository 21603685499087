import capitalize from 'capitalize-pt-br';

export function isUpper(text: string): boolean {
  return text === text.toUpperCase();
}

export function toNameCase(text: string): string {
  if (isUpper(text)) {
    return capitalize(text);
  }

  return text;
}
