import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import colors from '@/design/colors';

const OpenDetailsTransitionCSS = css`
  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
`;

export const OpenDetailsIcon = styled(FiChevronDown).attrs({ size: 24 })``;

export const CloseDetailsIcon = styled(FiChevronUp).attrs({ size: 24 })``;

export const CollapseButton = styled.button<{ collapsed: boolean }>`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray100};
  width: 34px;
  height: 34px;
  border-radius: 24px;

  :hover:not(:disabled) {
    background-color: ${colors.gray50};
  }

  :disabled {
    color: ${colors.gray100};
    cursor: not-allowed;
  }
`;

export const IconContainer = styled.div`
  ${OpenDetailsTransitionCSS};

  position: absolute;
  display: flex;
  align-items: center;
`;
