import styled from 'styled-components';
/**
 * Conteúdo principal, que deverá ter o margin-bottom alterado para forçar
 * o último item da lista ao topo da página
 */
export const Container = styled.div`
  flex: 1;
`;

/**
 * Container usado apenas para calcular o tamanho total do conteúdo dos itens
 * da lista, não tem efeito visual prático
 */
export const ListContainer = styled.div``;

interface ItemWrapperStyledProps {
  gap: number;
}

export const ItemWrapper = styled.div<ItemWrapperStyledProps>`
  & + & {
    margin-top: ${({ gap }) => gap}px;
  }
`;
