/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { useAllowedMenu } from '../../helpers/permissions';

import PrescricoesButtonUI, {
  PrescricoesButtonUIProps,
} from '../PrescricoesButtonUI';
import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import { TipoItemComplementar } from '../../models';

type PrescricaoProps = Pick<
  PrescricoesButtonUIProps,
  'options' | 'onTipoSelect' | 'onClick'
>;

const PrescricoesButton: React.FC = () => {
  const options = useAllowedMenu();
  const {
    items,
    mode,
    toggleMenu,
    setGeneralType,
  } = useCondutaComplementarContext();

  const canDisplay = React.useMemo(() => !!options.length, [options.length]);
  const total = React.useMemo(() => items.length, [items.length]);
  const title = React.useMemo(
    () => (mode === 'active' ? 'Prescrever' : 'Prescrições'),
    [mode],
  );

  const handleMenuSelect = React.useCallback(
    (menu: TipoItemComplementar) => {
      setGeneralType(menu);
      toggleMenu();
    },
    [setGeneralType, toggleMenu],
  );

  const prescricaoProps: PrescricaoProps = React.useMemo(() => {
    // Em modo resumo, o clique no botão já abre o menu de conduta
    if (mode === 'read-only') {
      return {
        options: [],
        onClick: () => toggleMenu(),
      };
    }

    return {
      options,
      onTipoSelect: handleMenuSelect,
    };
  }, [handleMenuSelect, mode, options, toggleMenu]);

  if (canDisplay) {
    return (
      <PrescricoesButtonUI total={total} {...prescricaoProps}>
        {title}
      </PrescricoesButtonUI>
    );
  }

  return <></>;
};

export default PrescricoesButton;
