import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';
import MedflowLogo from '../Logo/MedflowLogo';

import { Side, Header, Content, Footer as FooterContainer } from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
  FooterComponent?: RenderableElement;
}

const SideBox: React.FC<Props> = ({
  children,
  className,
  style,
  FooterComponent,
}) => {
  const Footer = React.useMemo(
    () =>
      FooterComponent && (
        <FooterContainer>{enforceElement(FooterComponent)}</FooterContainer>
      ),
    [FooterComponent],
  );

  return (
    <Side className={className} style={style}>
      <Header>
        <MedflowLogo style={{ height: 32 }} />
      </Header>
      <Content>{children}</Content>
      {Footer}
    </Side>
  );
};

export default SideBox;
