import { enforceElement } from '@/utils/elements';
import React from 'react';
import { useEnterpriseContext } from '../../features/enterprise/contexts/EnterpriseContext';
import { AppModeSelect, EnterpriseModes } from './defs';

function select({
  enterprise: Enterprise,
  ...modes
}: AppModeSelect): React.ComponentType {
  return () => {
    const [{ mode }] = useEnterpriseContext();

    const Renderable = React.useMemo(() => {
      let ModeRenderable;

      if (EnterpriseModes.includes(mode) && Enterprise) {
        ModeRenderable = Enterprise;
      } else {
        ModeRenderable = modes[mode];
      }

      return ModeRenderable;
    }, [mode]);

    return <>{enforceElement(Renderable)}</>;
  };
}

export default {
  select,
};
