import { APIResult } from '@/types/Api';

export function getNextAPIResultPage<TModel>(
  lastPage: APIResult<TModel>,
): number | undefined {
  if (lastPage.next) {
    const urlParams = new URL(lastPage.next);

    return Number(urlParams.searchParams.get('page'));
  }

  return undefined;
}
