import React, { useCallback, useMemo } from 'react';

import Option from '@/types/Option';
import {
  InputPergunta,
  InputPerguntaProps,
} from '@/features/execucao/components/PerguntaDetail/types';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';

import SelectRadioButton from '@/components/SelectRadioButton';

const InputEscolhaUnica: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { payloadCalculadora } = useCalculadoraContext().state;

  const selectedOptionId = useMemo(() => {
    return payloadCalculadora[pergunta.variavel.nome];
  }, [payloadCalculadora, pergunta]);

  const options: Option[] = useMemo(() => {
    return pergunta.variavel.opcoes.map(opt => {
      return {
        key: opt.nome,
        title: opt.descricao,
        value: opt.id,
        selected: selectedOptionId === opt.id,
      };
    });
  }, [pergunta, selectedOptionId]);

  const handleChange = useCallback(
    (option: Option) => {
      if (onChange) {
        onChange({ [pergunta.variavel.nome]: option.value as number });
      }
    },
    [onChange, pergunta],
  );

  return (
    <SelectRadioButton
      options={options}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default InputEscolhaUnica;
