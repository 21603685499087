import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall } from '@/design/typography';

export const Menu = styled.div`
  position: relative;
`;

export const Button = styled.button`
  width: 100%;
  padding: 8px 16px 8px 10px;
  min-width: 192px;

  display: flex;
  align-items: center;
  column-gap: 8px;

  background-color: ${colors.white};

  border: 1px solid ${colors.gray100};
  border-radius: 100px;
  transition: box-shadow 100ms ease-in-out;

  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: ${shadows.mediumShadow};
  }
`;

export const Avatar = styled.img`
  object-fit: cover;
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1000;
  margin-top: 4px;
`;
