/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import FeedbackAPI from '@/features/feedbacks/services/FeedbackAPI';

import { SendFeedbackPayload } from '../../types';

export interface FeedbackState {
  isModalOpen: boolean;
  canSuggestFeedback: boolean;
}

export interface FeedbackActions {
  dispatchOpenModal: (timeoutInMS?: number) => void;
  dispatchCloseModal: (timeoutInMS?: number) => void;
  dispatchSuggestFeedback: (suggest?: boolean) => void;
  dispatchSendFeedback: (payload: SendFeedbackPayload) => Promise<void>;
}

export interface FeedbackContextData {
  state: FeedbackState;
  actions: FeedbackActions;
}

interface ProviderProps {
  children: React.ReactNode;
}

const initialValue: FeedbackContextData = {
  state: {
    isModalOpen: false,
    canSuggestFeedback: false,
  },
  actions: {} as FeedbackActions,
};

const FeedbackContext = React.createContext<FeedbackContextData>(initialValue);

export const FeedbackProvider: React.FC<ProviderProps> = ({ children }) => {
  // const [{ user }, { dispatchUserGaveScoreFeedback }] = useAuthContext();
  const { user } = useAuth();

  const [state, setState] = React.useState<FeedbackState>(initialValue.state);

  const actions: FeedbackActions = React.useMemo(() => {
    return {
      dispatchOpenModal: (timeoutInMS = 0) => {
        const openModal = () =>
          setState(prev => ({
            ...prev,
            isModalOpen: true,
            canSuggestFeedback: false,
          }));

        setTimeout(() => openModal(), timeoutInMS);
      },
      dispatchCloseModal: (timeoutInMS = 0) => {
        const closeModal = () =>
          setState(prev => ({ ...prev, isModalOpen: false }));

        setTimeout(() => closeModal(), timeoutInMS);
      },
      dispatchSuggestFeedback: (suggest = true) => {
        setState(prev => {
          let value = suggest;
          if (user && user.gave_score_feedback) {
            value = false;
          }

          return { ...prev, canSuggestFeedback: value };
        });
      },
      dispatchSendFeedback: async payload => {
        const feedback = await FeedbackAPI.sendFeedback(payload);
        // dispatchUserGaveScoreFeedback(feedback);
      },
    };
    // }, [dispatchUserGaveScoreFeedback, user]);
  }, [user]);

  const value: FeedbackContextData = React.useMemo(() => {
    return { state, actions };
  }, [state, actions]);

  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};

export function useFeedbackContext(): FeedbackContextData {
  return React.useContext(FeedbackContext);
}
