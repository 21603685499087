import styled from 'styled-components';

import colors from '@/design/colors';

export const Container = styled.ul`
  border-radius: 10px;
  background-color: ${colors.gray50};
  padding: 16px;
  color: ${colors.blackAlpha200};
  row-gap: 10px;
  display: flex;
  flex-direction: column;
`;
