import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';

import { ModalContent } from './styles';

export interface ModalDialogProps extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
  Header?: RenderableElement;
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  children,
  style,
  className,
  Header,
}) => {
  return (
    <ModalContent style={style} className={className}>
      {enforceElement(Header)}
      {children}
    </ModalContent>
  );
};

export default ModalDialog;
