import React from 'react';

import HeaderDefault from '@/components/HeaderDefault';

import { getUserDisplayName } from '@/features/auth/helpers';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

const HeaderHistoryList: React.FC = () => {
  const { user } = useAuth();

  return (
    <HeaderDefault
      title={`Olá, ${getUserDisplayName(user)}`}
      subtitle="Selecione um protocolo para visualizar o histórico."
    />
  );
};

export default HeaderHistoryList;
