/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { TabsContext } from '..';
import { TabLabel } from './styles';

export interface TabProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  value: React.Key;
}

const Tab: React.FC<TabProps> = ({
  value,
  children,

  ...buttonProps
}) => {
  const { value: tabsValue, setValue } = React.useContext(TabsContext);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setValue(value);
    },
    [setValue, value],
  );

  return (
    <TabLabel active={value === tabsValue} onClick={onClick} {...buttonProps}>
      {children}
    </TabLabel>
  );
};

export default Tab;
