import React from 'react';
import fhirpath from 'fhirpath';
import { FiPrinter } from 'react-icons/fi';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import {
  patientToJson,
  practitionerToJson,
} from '@/features/enterprise/helpers';

import IconButton from '@/components/IconButton';
import { FhirPedidoType } from '@/features/enterprise/models/types';
import { useEventsContext } from '@/features/tracking/context/EventsContext';
import { Container, Header, HeaderTitle, Actions } from './styles';
import { printEncaminhamento } from '../../helpers/print';

interface BoxEncaminhamentoProps {
  encaminhamento: FhirPedidoType;
}

const BoxEncaminhamento: React.FC<BoxEncaminhamentoProps> = ({
  encaminhamento,
}) => {
  const [onHover, setOnHover] = React.useState(false);

  const { user } = useAuth();
  const [{ encounter }] = useEnterpriseContext();

  const [{ dispatchEvent }] = useEventsContext();

  const showImprimirButton = React.useMemo(() => {
    if (user?.empresa) {
      return !!user?.empresa?.features?.find(
        feature => feature.codigo === 'IMPRIMIR_ENCAMINHAMENTO',
      );
    }
    return true;
  }, [user]);

  const paciente = React.useMemo(() => {
    if (encounter) {
      return patientToJson(encounter.data);
    }

    return { nome: '', genero: '', data: '', idade: '' };
  }, [encounter]);

  const profissional = React.useMemo(() => {
    if (encounter) {
      return practitionerToJson(encounter.data);
    }

    return {
      nome: '',
      identificador: '',
      especialidade: '',
      genero: '',
    };
  }, [encounter]);

  const entidadeDeSaude = React.useMemo(() => {
    if (encounter.data) {
      const entidade = fhirpath.evaluate(
        encounter,
        'Encounter.contained.ofType(Location).name.first()',
      )[0];

      return entidade;
    }

    return '';
  }, [encounter]);

  const titulo = React.useMemo(() => {
    return fhirpath.evaluate(
      encaminhamento,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [encaminhamento]);

  const indicacao = React.useMemo(() => {
    return fhirpath.evaluate(
      encaminhamento,
      'ServiceRequest.reasonCode.text',
    )[0];
  }, [encaminhamento]);

  const imprimirEncaminhamento = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      printEncaminhamento(
        titulo,
        user?.empresa?.logo || '',
        entidadeDeSaude,
        paciente,
        profissional,
        indicacao,
      );

      dispatchEvent('print-encaminhamento', 'print', titulo);
    },
    [
      titulo,
      user?.empresa?.logo,
      entidadeDeSaude,
      paciente,
      profissional,
      indicacao,
      dispatchEvent,
    ],
  );

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={onHover}>{titulo}</HeaderTitle>

        <Actions>
          {showImprimirButton && (
            <IconButton
              tooltip="Imprimir"
              onClick={imprimirEncaminhamento}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiPrinter />}
            />
          )}
        </Actions>
      </Header>
    </Container>
  );
};

export default BoxEncaminhamento;
