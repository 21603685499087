import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const Container = styled.div`
  row-gap: 8px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.blackAlpha200};

  h3 {
    flex: 1;
    ${HeadingSmall(16)};
  }

  button {
    ${HeadingSmall(14)};
    color: ${colors.blackAlpha200};
  }
`;
