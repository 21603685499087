import React from 'react';

import { ReactComponent as EmptyIllustration } from '@/assets/illustrations/feedback-graphics/empty-box.svg';

import { Container } from './styles';

export const Empty: React.FC = () => {
  return (
    <Container>
      <EmptyIllustration />

      <h3>Nenhum resultado encontrado</h3>

      <p>
        Não conseguimos encontrar a calculadora que você deseja. Tente buscar
        usando um termo diferente.
      </p>
    </Container>
  );
};

export default Empty;
