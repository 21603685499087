import styled, { css, keyframes } from 'styled-components';

import colors from '@/design/colors';
import { darken } from 'polished';

export const placeholderShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

export const shimerAnimation = css`
  background: linear-gradient(
    to right,
    ${colors.gray100} 4%,
    ${darken(0.05, colors.gray100)} 25%,
    ${colors.gray100} 36%
  );
  background-size: 80vw 100%;
  animation: ${placeholderShimmer} 2s infinite linear;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Box = styled.div`
  border-radius: 8px;
  background-color: ${colors.gray50};
  padding: 16px 24px;
`;

export const Circle = styled.span`
  border-radius: 50%;
  background-color: ${colors.gray100};
  ${shimerAnimation}
`;

export const Rect = styled.span`
  background-color: ${colors.gray100};
  border-radius: 2px;
  ${shimerAnimation}
`;
