/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import ReactModal from 'react-modal';

import Modal from '@/components/Modal';
import ModalDialog from '@/components/ModalDialog';
import ModalHeader from '@/components/ModalDialog/ModalHeader';
import ModalContext from '@/components/Modal/ModalContext';
import { Container } from './styles';

export interface ModalLayoutProps extends ReactModal.Props {
  title?: string;
  children?: React.ReactNode;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({
  title,
  children,
  ...modalProps
}) => {
  const ModalDialogHeader: React.FC<{ headerTitle: string }> = ({
    headerTitle,
  }): JSX.Element => {
    const context = React.useContext(ModalContext);

    const handleClose = useCallback(() => {
      context.onClose();
    }, [context]);

    return <ModalHeader title={headerTitle} onClose={handleClose} />;
  };

  return (
    <Modal {...modalProps}>
      <ModalDialog Header={<ModalDialogHeader headerTitle={title || ''} />}>
        <Container>{children}</Container>
      </ModalDialog>
    </Modal>
  );
};

ModalLayout.defaultProps = {
  children: <></>,
};

export default ModalLayout;
