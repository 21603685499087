import HeaderDefault from '@/components/HeaderDefault';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { getUserDisplayName } from '@/features/auth/helpers';
import React from 'react';
import { useCalculadoraContext } from '../../contexts/CalculadoraContext';

// import { Container } from './styles';

const HeaderCalculadora: React.FC = () => {
  const { user } = useAuth();

  const {
    state: { calculadora },
  } = useCalculadoraContext();

  const title = React.useMemo(() => {
    if (calculadora) {
      return calculadora.nome;
    }

    return `Olá, ${getUserDisplayName(user)}`;
  }, [calculadora, user]);

  return <HeaderDefault title={title} subtitle="Calculadora" />;
};

export default HeaderCalculadora;
