import api from '@/sevices/api';
import Feedback from '@/models/Feedback';

import { SendFeedbackPayload } from '../types';

async function sendFeedback({
  comment,
  score,
}: SendFeedbackPayload): Promise<Feedback> {
  return (
    await api.post<Feedback>('feedbacks/', {
      comment,
      score,
      platform: 'web',
    })
  ).data;
}

export default {
  sendFeedback,
};
