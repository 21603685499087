/**
 * Cores
 *
 * Figma: https://www.figma.com/file/FlcnudtXbx4goqAzXjc0E9/%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB-Medflow-%E2%80%A2-Design-Pattern?node-id=17%3A68
 */

export default {
  // Black & White
  black: '#000000',
  white: '#FFFFFF',

  // White Alpha
  whiteAlpha50: '#F8F8F8',
  whiteAlpha100: '#EEEEEE',

  // Black Alpha
  blackAlpha50: '#CCCCCC',
  blackAlpha100: '#666666',
  blackAlpha200: '#2F3139',

  // Gray
  gray50: '#F0F2F5',
  gray100: '#D9DBE3',
  gray200: '#A7A9B1',
  gray300: '#75777F',

  // Purple
  purple50: '#ECF1FF',
  purple100: '#4676FA',

  // Dark blue
  darkBlue50: '#EDF0F9',
  darkBlue100: '#2047AE',
  darkBlue200: '#092061',

  // Green
  green: '#20BF6B',

  // Yellow
  yellow: '#F7B731',
  yellow50: '#FEF8EA',

  // Red
  red50: '#FDEBEF',
  red100: '#EB3B5A',
};
