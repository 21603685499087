import colors from '@/design/colors';
import styled from 'styled-components';
import InputFieldInteger from '../../../../../components/InputFieldInteger';

export const Input = styled(InputFieldInteger)`
  text-align: center;
  padding-left: 34px;
  padding-right: 34px;
`;

export const IconContainerLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 8px;
  left: 8px;

  width: 24px;
  height: 24px;

  color: ${colors.gray300};

  :focus:not(:disabled),
  :hover:not(:disabled) {
    border-radius: 4px;
    background: ${colors.whiteAlpha100};
  }

  :disabled {
    color: ${colors.gray100};
    cursor: not-allowed;
  }
`;

export const IconContainerRight = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 8px;
  right: 8px;

  width: 24px;
  height: 24px;

  color: ${colors.gray300};

  :focus:not(:disabled),
  :hover:not(:disabled) {
    border-radius: 4px;
    background: ${colors.whiteAlpha100};
  }

  :disabled {
    color: ${colors.gray100};
    cursor: not-allowed;
  }
`;
