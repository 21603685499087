import React from 'react';

import PageLayout from '@/layouts/PageLayout';

import HeaderHistoryList from '../../components/HeaderHistoryList';
import PageContentHistoryList from '../../components/PageContentHistoryList';

const PageHistoryList: React.FC = () => {
  return (
    <PageLayout Nav={HeaderHistoryList}>
      <PageContentHistoryList />
    </PageLayout>
  );
};

export default PageHistoryList;
