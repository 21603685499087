import React from 'react';
import { FiCheck, FiEdit2, FiX } from 'react-icons/fi';

import colors from '@/design/colors';

import Tooltip from '@/components/Tooltip';
import {
  ContentEditableButton,
  ContentEditableText,
  ContentEditableTextarea,
  ContentEditableForm,
  ContentEditableFormControl,
  ContentEditableFormControlButton,
  ContentEditedIndicator,
  ContentEditablePanel,
} from './styles';

interface ContentEditableProps {
  content: string;
  edited?: boolean;
  disabled?: boolean;
  onContentEdit?: (content: string) => void;
}

export interface ContentEditableRef {
  cancelEdition(): void;
}

const ContentEditable = React.forwardRef<
  ContentEditableRef,
  ContentEditableProps
>(({ content, edited = false, disabled = false, onContentEdit }, ref) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const [onEdit, setOnEdit] = React.useState(false);

  const handleEditMode = React.useCallback(() => {
    setOnEdit(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, []);

  const handleEditCancel = React.useCallback(() => {
    setOnEdit(false);
  }, []);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const editVal = inputRef.current?.value || '';

      if (onContentEdit) {
        onContentEdit(editVal);
      }

      setOnEdit(false);
    },
    [onContentEdit],
  );

  const submitFormOnPressEnter = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (formRef.current) {
          formRef.current.requestSubmit();
        }
      }

      if (event.key === 'Escape') {
        handleEditCancel();
      }
    },
    [handleEditCancel],
  );

  const preventSpaceFromTriggerClick = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    [],
  );

  React.useImperativeHandle(
    ref,
    () => ({
      cancelEdition: () => {
        handleEditCancel();
      },
    }),
    [handleEditCancel],
  );

  if (onEdit) {
    return (
      <ContentEditableForm onSubmit={handleSubmit} ref={formRef}>
        <ContentEditableTextarea
          ref={inputRef}
          required
          rows={1}
          minLength={10}
          maxLength={220}
          onKeyDown={submitFormOnPressEnter}
          onKeyUp={preventSpaceFromTriggerClick}
        />
        <ContentEditableFormControl>
          <ContentEditableFormControlButton
            type="submit"
            onClick={event => event.stopPropagation()}
          >
            <FiCheck size={16} />
          </ContentEditableFormControlButton>
          <ContentEditableFormControlButton
            onClick={event => {
              event.stopPropagation();

              handleEditCancel();
            }}
          >
            <FiX size={16} />
          </ContentEditableFormControlButton>
        </ContentEditableFormControl>
      </ContentEditableForm>
    );
  }

  return (
    <Tooltip
      placement="bottom"
      title={content}
      delay={[2000, 300]}
      duration={300}
    >
      <ContentEditableButton onClick={handleEditMode} disabled={disabled}>
        <ContentEditableText>{content}</ContentEditableText>
        <ContentEditablePanel>
          {edited && <ContentEditedIndicator>editado</ContentEditedIndicator>}
          <FiEdit2 size={16} color={colors.gray300} />
        </ContentEditablePanel>
      </ContentEditableButton>
    </Tooltip>
  );
});

export default ContentEditable;
