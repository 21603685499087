import React from 'react';

import Button from '@/components/Button';

import { Container } from './styles';

interface Props {
  loading: boolean;
  handleSairProtocolo: () => void;
  onClose: () => void;
}

const B2CContent: React.FC<Props> = ({
  loading,
  handleSairProtocolo,
  onClose,
}) => {
  return (
    <Container>
      <span>
        <strong>Tem certeza que deseja sair do protocolo?</strong>
      </span>
      <p>As informações não poderão ser recuperadas</p>
      <Button loading={loading} onClick={handleSairProtocolo}>
        Sair do protocolo
      </Button>
      <Button theme="tertiary" onClick={onClose}>
        Voltar para a execução
      </Button>
    </Container>
  );
};

export default B2CContent;
