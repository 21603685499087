import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    line-height: 20px;
    font-size: 16px;
    align-self: center;
  }
`;

export const Icon = styled.div`
  display: flex;

  svg {
    font-size: 24px;
    margin-right: 12px;
  }
`;
