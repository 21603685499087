import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import Button from '@/components/Button';

export const TotalBadge = styled.span`
  ${HeadingSmall(14)};
  color: ${colors.white};

  border-radius: 30px;

  padding: 0 8px;

  background-color: ${colors.purple100};
`;

export const DropdownButton = styled(Button).attrs({
  theme: 'secondary',
})`
  height: 34px;
`;
