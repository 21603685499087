import React from 'react';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { useDoctorDisplay } from '@/features/enterprise/hooks/useEncounter';
import {
  DoctorContainer,
  DoctorImage,
  DoctorInfo,
  DoctorInfoItem,
  DoctorInfoItemTitle,
  DoctorInfoItemValue,
  DoctorName,
  DoctorSpecialty,
  DoctorBoxContent,
  HelloText,
  DoctorContainerTitle,
  DoctorBox,
  DoctorContent,
  DoctorActiveContent,
} from './styles';

const DoctorReadOnly: React.FC = () => {
  const { name, pronoun, avatar, specialty } = useDoctorDisplay();

  return (
    <DoctorContent>
      <DoctorContainerTitle>Médico responsável</DoctorContainerTitle>
      <DoctorBox>
        <DoctorBoxContent>
          <DoctorImage src={avatar} style={{ width: 46, height: 46 }} />
          <DoctorInfo>
            <DoctorInfoItem>
              <DoctorInfoItemTitle>{name || pronoun}</DoctorInfoItemTitle>
              <DoctorInfoItemValue>{specialty}</DoctorInfoItemValue>
            </DoctorInfoItem>
          </DoctorInfo>
        </DoctorBoxContent>
      </DoctorBox>
    </DoctorContent>
  );
};

const DoctorActive: React.FC = () => {
  const { name, pronoun, avatar, specialty } = useDoctorDisplay();

  return (
    <DoctorActiveContent>
      <DoctorImage src={avatar} />
      <DoctorInfo>
        <HelloText>Olá,</HelloText>
        <DoctorName>{name || pronoun}</DoctorName>
        <DoctorSpecialty>{specialty}</DoctorSpecialty>
      </DoctorInfo>
    </DoctorActiveContent>
  );
};

const DoctorSection: React.FC = () => {
  const [{ isProtocoloReadOnly }] = useExecucaoContext();
  const { name, specialty } = useDoctorDisplay();

  const shouldDisplay = React.useMemo(() => name || specialty, [
    name,
    specialty,
  ]);

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <DoctorContainer>
      {isProtocoloReadOnly ? <DoctorReadOnly /> : <DoctorActive />}
    </DoctorContainer>
  );
};

export default DoctorSection;
