import api from '@/sevices/api';

import { Calculadora } from '@/models/Calculadora';
import { RespostaPayload } from '@/features/execucao/types';

interface CalculadorasListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Calculadora[];
}

async function listCalculadoras(
  search = '',
  page = 1,
): Promise<CalculadorasListResponse> {
  return (
    await api.get<CalculadorasListResponse>(`/calculadoras/`, {
      params: {
        search,
        page,
        page_size: 20,
      },
    })
  ).data;
}

async function getCalculadora(codigo: string): Promise<Calculadora> {
  return (await api.get<Calculadora>(`/calculadoras/${codigo}`)).data;
}

async function calcular(
  calculadora: Calculadora,
  payload: RespostaPayload,
): Promise<Calculadora> {
  return (
    await api.post<Calculadora>(calculadora.calcular_url, {
      respostas: payload,
    })
  ).data;
}

export default {
  listCalculadoras,
  getCalculadora,
  calcular,
};
