export function withTimingTrack<TParams, TReturn>(
  fn: (params: TParams) => Promise<TReturn>,
  cb: (returnValue: TReturn, elapsedTimeInMS: number) => void,
) {
  return async (params: TParams): Promise<TReturn> => {
    // Feature detects Navigation Timing API support.
    if (window.performance) {
      const start = performance.now();
      const returnValue = await fn(params);
      const elapsedTimeInMS = Math.round(performance.now() - start);
      cb(returnValue, elapsedTimeInMS);
      return returnValue;
    }

    return fn(params);
  };
}
