import React from 'react';
import { Slide, toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import { FiChevronDown, FiChevronUp, FiCopy } from 'react-icons/fi';

import { Medicamento } from '@/models/Medicamento';

import MedicamentoDetails from './MedicamentoDetails';

import Toast from '../Toast';
import IconButton from '../IconButton';

import {
  Container,
  Header,
  HeaderTitle,
  HeaderSubTitle,
  InfoContainer,
  InfoContainerText,
  InfoContainerIcon,
  Actions,
} from './styles';
import { createNavItems } from './helpers';

interface BoxMedicamentoProps {
  medicamento: Medicamento;
  disableCopy?: boolean;
  isOpen?: boolean;
}

interface UIState {
  detailsOpened: boolean;
  onHover: boolean;
}

const BoxMedicamento: React.FC<BoxMedicamentoProps> = ({
  medicamento,
  disableCopy,
  isOpen,
}: BoxMedicamentoProps) => {
  const nodeRef = React.useRef(null);

  const copyMedicamento = React.useCallback(() => {
    let texto = `${medicamento.principio_ativo}`;
    if (medicamento.dosagem) {
      texto += `\n${medicamento.dosagem.texto}`;
    }
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Medicamento copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [medicamento]);

  const [state, setState] = React.useState<UIState>({
    detailsOpened: isOpen || false,
    onHover: false,
  });

  const updateState = React.useCallback((newState: Partial<UIState>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  const navItems = React.useMemo(() => {
    return createNavItems(medicamento);
  }, [medicamento]);

  const canExpand = React.useMemo(() => !!navItems.length, [navItems]);

  const handleCollapse = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      updateState({ detailsOpened: !state.detailsOpened });
    },
    [state, updateState],
  );

  if (!canExpand) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{medicamento.principio_ativo}</HeaderTitle>
        </Header>
        {medicamento.dosagem && (
          <HeaderSubTitle>{medicamento.dosagem.texto}</HeaderSubTitle>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={state.detailsOpened || state.onHover}>
          {medicamento.principio_ativo}
        </HeaderTitle>
        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar medicamento"
              onClick={copyMedicamento}
              style={{ marginRight: 16 }}
              onMouseOver={() => setState(prev => ({ ...prev, onHover: true }))}
              onMouseLeave={() =>
                setState(prev => ({ ...prev, onHover: false }))
              }
              icon={<FiCopy />}
            />
          )}

          <IconButton
            tooltip={state.detailsOpened ? 'Mostrar menos' : 'Mostrar mais'}
            onMouseEnter={() => setState(prev => ({ ...prev, onHover: true }))}
            onMouseLeave={() => setState(prev => ({ ...prev, onHover: false }))}
            icon={state.detailsOpened ? <FiChevronUp /> : <FiChevronDown />}
            onClick={handleCollapse}
          />
        </Actions>
      </Header>

      {medicamento.dosagem && (
        <HeaderSubTitle highlight={state.detailsOpened || state.onHover}>
          {medicamento.dosagem.texto}
        </HeaderSubTitle>
      )}

      {medicamento?.dosagem?.instrucoes_adicionais && (
        <InfoContainer>
          <InfoContainerIcon />
          <InfoContainerText>
            {medicamento.dosagem.instrucoes_adicionais}
          </InfoContainerText>
        </InfoContainer>
      )}

      <CSSTransition
        nodeRef={nodeRef}
        in={state.detailsOpened}
        timeout={400}
        appear
        unmountOnExit
      >
        <MedicamentoDetails innerRef={nodeRef} medicamento={medicamento} />
      </CSSTransition>
    </Container>
  );
};

BoxMedicamento.defaultProps = {
  disableCopy: false,
  isOpen: false,
};

export default BoxMedicamento;
