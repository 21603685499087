import React from 'react';

import api from '@/sevices/api';

import { EvolucaoProtocolo } from '@/models/EvolucaoProtocolo';
import {
  ContextoIntegracao,
  ProtocoloExecutado,
  ProtocoloExecutadoBase,
} from '@/models/ProtocoloExecutado';

import { APIResult } from '@/types/Api';

import { AppMode } from '@/business/AppMode/defs';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useFeatures } from '@/features/features/providers/FeaturesProvider';

interface ItemState<T> {
  status: 'on-hold' | 'active';
  data: T;
}

interface EnterpriseState {
  mode: AppMode;
  integration: ContextoIntegracao | null;
  encounter: ItemState<fhir4.Encounter | null>;
  bundle: ItemState<fhir4.Bundle | null>;
  history: ItemState<EvolucaoProtocolo[]>;
}

interface EnterpriseDispatch {
  dispatchStartFromExecution(execution: ProtocoloExecutado): Promise<void>;
}

type EnterpriseContextData = [EnterpriseState, EnterpriseDispatch];

const initialState: EnterpriseState = {
  mode: 'personal',
  integration: null,
  encounter: { data: null, status: 'on-hold' },
  bundle: { data: null, status: 'on-hold' },
  history: { data: [], status: 'on-hold' },
};

const initialDispatch = {} as EnterpriseDispatch;

// Context
export const EnterpriseContext = React.createContext<EnterpriseContextData>([
  initialState,
  initialDispatch,
]);

interface ProviderProps {
  children: React.ReactNode;
}

const EnterpriseProvider: React.FC<ProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const { hasFeature } = useFeatures();

  const [data, setData] = React.useState<ContextoIntegracao | null>(null);
  const [mode, setMode] = React.useState<AppMode>(initialState.mode);
  const [encounterState, setEncounterState] = React.useState(
    initialState.encounter,
  );
  const [bundleState, setBundleState] = React.useState(initialState.bundle);
  const [historyState, setHistoryState] = React.useState(initialState.history);

  const dispatchStartFromExecution = React.useCallback(
    async (execution: ProtocoloExecutadoBase) => {
      const { contexto_integracao } = execution;
      if (contexto_integracao) {
        setData(contexto_integracao);
        setMode('integrated');
      } else {
        setMode('not_integrated');
      }
    },
    [],
  );

  React.useEffect(() => {
    (async () => {
      if (data) {
        setEncounterState(prev => ({ ...prev, status: 'on-hold' }));

        const { data: encounter } = await api.get<fhir4.Encounter>(
          data.encounter_url,
        );

        setEncounterState({ data: encounter, status: 'active' });
      }
    })();
  }, [data]);

  React.useEffect(() => {
    (async () => {
      if (data) {
        setBundleState(prev => ({ ...prev, status: 'on-hold' }));

        const { data: bundle } = await api.get<fhir4.Bundle>(data.bundle_url);

        setBundleState({ data: bundle, status: 'active' });
      }
    })();
  }, [data]);

  React.useEffect(() => {
    (async () => {
      if (data) {
        setHistoryState(prev => ({ ...prev, status: 'on-hold' }));

        let history: EvolucaoProtocolo[] = [];

        if (hasFeature('EXIBIR_ULTIMAS_INTERACOES')) {
          const {
            data: { results: resultHistory },
          } = await api.get<APIResult<EvolucaoProtocolo>>(
            data.ultimas_interacoes_url,
          );

          history = resultHistory;
        }

        setHistoryState({ data: history, status: 'active' });
      }
    })();
  }, [data, hasFeature]);

  React.useEffect(() => {
    if (user && user.empresa) {
      setMode('not_integrated');
    } else {
      setMode('personal');
    }
  }, [user]);

  return (
    <EnterpriseContext.Provider
      value={[
        {
          mode,
          integration: data,
          encounter: encounterState,
          bundle: bundleState,
          history: historyState,
        },
        { dispatchStartFromExecution },
      ]}
    >
      {children}
    </EnterpriseContext.Provider>
  );
};

export function useEnterpriseContext(): EnterpriseContextData {
  return React.useContext(EnterpriseContext);
}

export default EnterpriseProvider;
