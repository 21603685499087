import React from 'react';
import BoxOrientacao from '@/features/recursos/orientacoes/components/BoxOrientacao';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { groupBy } from '@/utils/collections';

import CategoriaArtefato from '../../CategoriaArtefato';
import { Content, Wrapper } from './styles';

interface OrientacoesProps {
  orientacoes: FhirArtefatoType[];
}

const ListaOrientacoesDefault: React.FC<OrientacoesProps> = ({
  orientacoes,
}) => {
  const orientacoesHasCategorias = React.useMemo(() => {
    return orientacoes.filter(orientacao => !!orientacao.categoria).length > 0;
  }, [orientacoes]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      orientacoes,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(orientacao => (
          <BoxOrientacao
            key={orientacao.id.toString()}
            orientacao={orientacao.pedido}
          />
        ))}
      />
    ));
  }, [orientacoes]);

  return (
    <Wrapper>
      <Content>
        {orientacoesHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <>
            {orientacoes.map(orientacao => (
              <BoxOrientacao
                key={orientacao.id}
                orientacao={orientacao.pedido}
              />
            ))}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaOrientacoesDefault;
