import fhirpath from 'fhirpath';
import TurndownService from 'turndown';

import React from 'react';

import { FiPrinter } from 'react-icons/fi';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import { buildFullName } from '@/features/fhir/helpers/datatypes';
import IconButton from '@/components/IconButton';
import Markdown from '@/components/Markdown';

import { FhirPedidoType } from '@/features/enterprise/models/types';
import { useEventsContext } from '@/features/tracking/context/EventsContext';
import {
  Container,
  Header,
  HeaderTitle,
  CollapsedContent,
  Actions,
  CollapseButton,
} from './styles';
import { printOrientacaoMedica } from '../../helpers/print';

interface BoxOrientacaoProps {
  orientacao: FhirPedidoType;
}

const BoxOrientacao: React.FC<BoxOrientacaoProps> = ({ orientacao }) => {
  const contentRef = React.createRef<HTMLDivElement>();

  const { user } = useAuth();
  const [{ encounter }] = useEnterpriseContext();
  const [{ dispatchEvent }] = useEventsContext();

  const nomePaciente = React.useMemo(() => {
    if (encounter) {
      const humanName = fhirpath.evaluate(
        encounter,
        "Encounter.contained.ofType(Patient).name.where(use='usual').first()",
      )[0] as fhir4.HumanName | undefined;

      if (humanName) {
        return buildFullName(humanName);
      }
    }

    return '';
  }, [encounter]);

  const showImprimirButton = React.useMemo(() => {
    if (user?.empresa) {
      return !!user?.empresa?.features?.find(
        feature => feature.codigo === 'IMPRIMIR_ORIENTACAO_MEDICA',
      );
    }
    return true;
  }, [user]);

  const [contentHeight, setContentHeight] = React.useState<number>(0);
  const [isOpenedDetails, setIsOpenedDetails] = React.useState(false);
  const [onHover, setOnHover] = React.useState(false);

  const hasDescription = React.useMemo(() => !!orientacao.text?.div, [
    orientacao,
  ]);

  const descriptionToMarkdown = React.useMemo(() => {
    const turndownService = new TurndownService();

    return turndownService.turndown(orientacao.text?.div || '');
  }, [orientacao]);

  const toggleDetails = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (hasDescription) {
        setIsOpenedDetails(prev => !prev);
      }
    },
    [hasDescription],
  );

  const print = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      printOrientacaoMedica(
        orientacao.title || '',
        orientacao.text?.div || '',
        user?.empresa?.logo || '',
        nomePaciente,
      );

      dispatchEvent('print-orientacao', 'print', orientacao.title || '');
    },
    [orientacao, user?.empresa?.logo, nomePaciente, dispatchEvent],
  );

  React.useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef]);

  if (!hasDescription) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{orientacao.title}</HeaderTitle>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={isOpenedDetails || onHover}>
          {orientacao.title}
        </HeaderTitle>

        <Actions>
          {showImprimirButton && (
            <IconButton
              tooltip="Imprimir"
              onClick={print}
              // style={{ marginRight: 16 }}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiPrinter />}
            />
          )}
          <CollapseButton collapsed={isOpenedDetails} onClick={toggleDetails} />
        </Actions>
      </Header>

      <CollapsedContent
        ref={contentRef}
        highlight={isOpenedDetails || onHover}
        style={{
          maxHeight: isOpenedDetails ? contentHeight : 16,
          maxWidth: 'calc(100% - 100px',
        }}
      >
        <span style={{ textOverflow: 'ellipsis' }}>
          <Markdown text={descriptionToMarkdown} />
        </span>
      </CollapsedContent>
    </Container>
  );
};

export default BoxOrientacao;
