import React from 'react';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';
import { Pergunta } from '@/models/Pergunta';

import { Timeline, Line, LineText, CircleContainer } from './styles';
import CircleCheck from './CircleCheck';
import CircleDisabled from './CircleDisabled';
import CircleCurrent from './CircleCurrent';

const CalculadoraTimeLine: React.FC = () => {
  const { perguntas, payloadCalculadora } = useCalculadoraContext().state;

  const isLineChecked = React.useCallback(
    (pergunta: Pergunta) => {
      return payloadCalculadora[pergunta.variavel.nome] !== null;
    },
    [payloadCalculadora],
  );

  const getCircle = React.useCallback(
    (index: number) => {
      const perguntaAtual = perguntas[index];
      const perguntaAtualRespondida =
        payloadCalculadora[perguntaAtual.variavel.nome] !== null;

      if (index === 0) {
        return perguntaAtualRespondida ? <CircleCheck /> : <CircleCurrent />;
      }

      const perguntaAnterior = perguntas[index - 1];
      const perguntaAnteriorRespondida =
        payloadCalculadora[perguntaAnterior.variavel.nome] !== null;

      if (perguntaAtualRespondida) {
        return <CircleCheck />;
      }

      if (!perguntaAnteriorRespondida) {
        return <CircleDisabled />;
      }

      return <CircleCurrent />;
    },
    [perguntas, payloadCalculadora],
  );

  return (
    <Timeline>
      {perguntas.map((pergunta, index) => {
        return (
          <Line key={pergunta.id} isChecked={isLineChecked(pergunta)}>
            <CircleContainer>{getCircle(index)}</CircleContainer>
            <LineText>{pergunta.descricao}</LineText>
          </Line>
        );
      })}
    </Timeline>
  );
};

export default CalculadoraTimeLine;
