import React from 'react';

import { Container } from './styles';

interface ModalTitleProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const ModalBody: React.FC<ModalTitleProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};

export default ModalBody;
