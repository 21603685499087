import colors from '@/design/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom: 1px solid ${colors.gray100};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
`;

export const ListItem = styled.li`
  display: flex;
`;

export const ActiveIndicator = styled.span`
  height: 4px;
  background-color: ${colors.purple100};
  border-radius: 10px;
  transition: all 300ms cubic-bezier(0, 0.5, 0.06, 1);
`;
