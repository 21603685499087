/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIMask } from 'react-imask';
import InputField, { InputFieldProps } from '../InputField';

interface Props extends InputFieldProps {
  validationError?: string;
  onChangeText?: (value: string, typedValue: number) => void;
}

const InputFieldInteger: React.FC<Props> = ({
  onChangeText,
  validationError,
  ...fieldProps
}) => {
  const { ref } = useIMask(
    {
      mask: 'num',
      lazy: false, // make placeholder always visible

      blocks: {
        num: {
          mask: Number,
          expose: true,
        },
      },
    },
    {
      onAccept: value => {
        if (onChangeText) {
          const typedValue = parseInt(value, 10);
          onChangeText(value, typedValue);
        }
      },
    },
  );

  return <InputField ref={ref} {...fieldProps} error={validationError} />;
};

export default InputFieldInteger;
