import React from 'react';

import RadioAlternatives from '@/components/RadioAlternatives';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputSimOuNao: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const [internalValue, setInternalValue] = React.useState(
    pergunta.variavel.valor as boolean | null,
  );

  const wrapItems = React.useMemo(() => pergunta.variavel.multiplas_colunas, [
    pergunta.variavel,
  ]);

  const handleChange = React.useCallback(
    (value: boolean) => {
      setInternalValue(value);
      if (onChange) {
        onChange({ [pergunta.variavel.nome]: value });
      }
    },
    [onChange, pergunta],
  );

  return (
    <RadioAlternatives
      disabled={disabled}
      value={internalValue}
      options={[
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
      ]}
      horizontal={wrapItems}
      onChange={handleChange}
    />
  );
};

export default InputSimOuNao;
