import styled, { keyframes } from 'styled-components';

import colors from '@/styles/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    margin-bottom: 24px;
    animation: ${rotate} 600ms linear infinite;
  }

  span {
    color: ${colors.black};
    font-size: 18px;
    line-height: 23px;
  }
`;
