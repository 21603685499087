import colors from '@/design/colors';
import gaps from '@/design/gaps';
import { HeadingSmall } from '@/design/typography';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: ${gaps.defaultGap};
`;

export const Row = styled.div<{ maxHeight: number }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  row-gap: ${gaps.defaultGap};
  width: 100%;

  padding: 0px 24px;

  > :not(:last-child) {
    margin-right: 32px;
  }

  > div > div:first-child {
    min-height: ${props => props.maxHeight}px;
  }

  p {
    ${HeadingSmall(14)};
    color: ${colors.blackAlpha200};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0px 24px;

  row-gap: ${gaps.defaultGap};
`;
