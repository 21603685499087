import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 24px;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Black Alpha 200 */

  color: #2f3139;

  mix-blend-mode: normal;

  margin-bottom: 8px;
`;
