import styled from 'styled-components';
import colors from '@/styles/colors';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 17px;
  }

  h3 {
    color: ${colors.black};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  p {
    color: ${colors.hardGray};
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
    max-width: 400px;
    text-align: center;
  }
`;
