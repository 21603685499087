import React from 'react';

import PageLayout from '@/layouts/PageLayout';
import PageContentCalculadoraList from '@/features/calculadoras/components/PageContentCalculadoraList';

import HeaderCalculadoraList from '../../components/HeaderCalculadoraList';

const PageCalculadoraList: React.FC = () => {
  return (
    <PageLayout Nav={HeaderCalculadoraList}>
      <PageContentCalculadoraList />
    </PageLayout>
  );
};

export default PageCalculadoraList;
