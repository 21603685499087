import React from 'react';
import useResizeObserver from 'use-resize-observer';

import { getTop } from '@/utils/document';

import { Container, ListContainer, ItemWrapper } from './styles';

export interface ListRenderItem<ItemT> {
  item: ItemT;
  index: number;
}

interface AutoScrollOptions {
  topOffset: number;
  itemSeparatorGap: number;
  disableAutoScroll?: boolean;
}

interface Props<ItemT> {
  data: ItemT[];
  options: Partial<AutoScrollOptions>;
  renderItem: (info: ListRenderItem<ItemT>) => React.ReactElement | null;
  keyExtractor: (info: ListRenderItem<ItemT>) => React.Key | null;
}

export default function AutoScrollList<ItemT>({
  data,
  renderItem,
  keyExtractor,
  options: {
    topOffset = 0,
    itemSeparatorGap = 0,
    disableAutoScroll = false,
  } = {},
}: Props<ItemT>): React.ReactElement<Props<ItemT>> {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const itemsRef = React.useRef<HTMLDivElement[]>([]);

  const {
    ref: listRef,
    height: listHeight,
  } = useResizeObserver<HTMLDivElement>();

  const [marginBottom, setMarginBottom] = React.useState(0);
  // Para fazer o scroll até o último elemento, é necessário verificar se tanto
  // o tamanho da lista foi alterado quanto se o margin-bottom já foi calculado
  const [canScrollQueue, setCanScrollQueue] = React.useState<
    [boolean, boolean]
  >([false, false]);

  const getTopOffset = React.useCallback(() => {
    if (containerRef.current) {
      return getTop(containerRef.current) + topOffset;
    }
    return 0;
  }, [topOffset]);

  /**
   * Realiza o scroll automático para o último elemento da lista
   *
   * Callback executado toda vez que o número de itens da lista é alterado (ao
   * adicionar ou remover itens).
   *
   * Deve ser executado após o cálculo do `margin-bottom` para que surta efeito
   * visual
   */
  const scrollToLastNode = React.useCallback(() => {
    const totalHeightBeforeLastNode = data.reduce(
      (totalHeight, nodeItem, idx) => {
        const nodeElem = itemsRef.current[idx];

        // É o ultimo elemento da navegação, não será necessário usar seu
        // próprio height no cálculo
        if (idx === data.length - 1 || !nodeElem) {
          return totalHeight;
        }

        const { height: nodeHeight } = nodeElem.getBoundingClientRect();

        return totalHeight + nodeHeight + itemSeparatorGap;
      },
      0,
    );

    window.scrollTo({
      top: totalHeightBeforeLastNode,
      left: 0,
      behavior: 'smooth',
    });
  }, [data, itemSeparatorGap]);

  /**
   * O último elemento sempre deve ficar no topo da página quando a barra de
   * rolagem estiver no final. Para imprimir esse comportamento, é necessário
   * forçar um `margin-bottom` do conteúdo (container da lista) de tal forma que
   * "empurre" o último elemento para o topo.
   *
   * Este callback realiza esse cálculo baseado no tamanho da tela e do conteúdo
   * do último elemento da lista renderizado. É possível adicionar um offset a
   * este cálculo via propriedade `topOffset`
   */
  const calculateMarginBottom = React.useCallback(() => {
    let calculatedMarginBottom = 0;

    if (data.length > 1) {
      const lastNodeElem = itemsRef.current[data.length - 1];
      if (lastNodeElem) {
        const { height: nodeHeight } = lastNodeElem.getBoundingClientRect();
        const offset = getTopOffset();
        if (nodeHeight < window.innerHeight - offset) {
          calculatedMarginBottom = window.innerHeight - nodeHeight - offset;
        }
      }
    }

    setMarginBottom(calculatedMarginBottom > 0 ? calculatedMarginBottom : 0);
  }, [data.length, getTopOffset]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCanScrollQueue(([dataLengthChanged, marginBottomCalculated]) => [
      true,
      marginBottomCalculated,
    ]);
  }, [data.length]);

  React.useEffect(() => {
    calculateMarginBottom();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCanScrollQueue(([dataLengthChanged, marginBottomCalculated]) => [
      dataLengthChanged,
      true,
    ]);
  }, [listHeight, calculateMarginBottom]);

  React.useEffect(() => {
    if (canScrollQueue.every(val => val)) {
      if (!disableAutoScroll) {
        scrollToLastNode();
      }
      setCanScrollQueue([false, false]);
    }
  }, [canScrollQueue, disableAutoScroll, scrollToLastNode]);

  React.useLayoutEffect(() => {
    return function clear() {
      setMarginBottom(0);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };
  }, []);

  return (
    <Container ref={containerRef} style={{ marginBottom }}>
      <ListContainer ref={listRef}>
        {data.map((item, index) => (
          <ItemWrapper
            gap={itemSeparatorGap}
            key={keyExtractor({ item, index })}
            ref={elem => {
              if (elem) {
                itemsRef.current[index] = elem;
              }
            }}
          >
            {renderItem({ item, index })}
          </ItemWrapper>
        ))}
      </ListContainer>
    </Container>
  );
}
