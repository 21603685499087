import React, { useMemo } from 'react';

import { RespostaPayload } from '@/features/execucao/types';
import { Pergunta as PerguntaModel } from '@/models/Pergunta';
import { TipoVariavel } from '@/models/Expressao';

import { useCalculadoraContext } from '../../contexts/CalculadoraContext';

import Pergunta from './Pergunta';

import { Container } from './styles';
import Resultado from './Resultado';

const CalculadoraComponent: React.FC = () => {
  const { dispatchUpdatePayload } = useCalculadoraContext().actions;

  const {
    resultados,
    perguntas,
    payloadCalculadora,
  } = useCalculadoraContext().state;

  /** Ref para perguntas para efeitos de scroll */
  const perguntasRef = React.useRef<{
    [key: number]: HTMLDivElement;
  }>({});

  /** Ref para o resultado da calculadora para efeitos de scroll */
  const resultadoRef = React.useRef<HTMLDivElement>(null);

  /** Memo para facilitar acesso aos resultados da calculadora */
  const resultadosCalculadora = useMemo(() => {
    return resultados.filter(resultado => resultado.valor !== null);
  }, [resultados]);

  const isPerguntaEscolhaUnicaOrBooleana = React.useCallback(
    (pergunta: PerguntaModel) => {
      const isEscolhaUnica =
        !pergunta.variavel.multipla_escolha && pergunta.variavel.opcoes.length;
      const isBooleana = pergunta.variavel.tipo === TipoVariavel.BOOLEANA;

      return isEscolhaUnica || isBooleana;
    },
    [],
  );

  /** Handler para atualizar o payload da calculadora */
  const handlePerguntaValueChange = React.useCallback(
    (pergunta: PerguntaModel, index: number, resposta: RespostaPayload) => {
      dispatchUpdatePayload(resposta);

      if (
        perguntasRef.current[index + 1] &&
        isPerguntaEscolhaUnicaOrBooleana(pergunta)
      ) {
        /** Calcular altura do scroll */
        let scrollTop = 0;
        Object.values(perguntasRef.current)
          .slice(0, index + 1)
          .forEach(ref => {
            scrollTop += ref.getBoundingClientRect().height + 24;
          });

        window.scrollTo({
          top: scrollTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    [dispatchUpdatePayload, perguntasRef, isPerguntaEscolhaUnicaOrBooleana],
  );
  /** Scroll para resultados ou para o início (reset calculadora) */
  React.useEffect(() => {
    if (resultadoRef.current) {
      resultadoRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [resultados]);

  return (
    <Container>
      {perguntas.map((pergunta, index) => {
        const isPerguntaDisabled = () => {
          /** Primeira pergunta sempre desabilitada */
          if (index === 0) {
            return false;
          }

          const isPerguntaAnteriorRespondida =
            payloadCalculadora[perguntas[index - 1].variavel.nome] !== null;
          const isPerguntaAtualRespondida =
            payloadCalculadora[pergunta.variavel.nome] !== null;

          return !isPerguntaAnteriorRespondida && !isPerguntaAtualRespondida;
        };

        return (
          <Pergunta
            key={pergunta.id}
            ref={ref => {
              if (ref) {
                perguntasRef.current[index] = ref;
              }
            }}
            pergunta={pergunta}
            onChange={resposta =>
              handlePerguntaValueChange(pergunta, index, resposta)
            }
            disabled={isPerguntaDisabled()}
            isFirstPergunta={index === 0}
            isLastPergunta={index === perguntas.length - 1}
          />
        );
      })}

      {resultadosCalculadora.length > 0 && (
        <Resultado ref={resultadoRef} resultados={resultadosCalculadora} />
      )}
    </Container>
  );
};

export default CalculadoraComponent;
