import React from 'react';
import fhirpath from 'fhirpath';
import { FiCopy } from 'react-icons/fi';
import { Slide, toast } from 'react-toastify';

import Toast from '@/components/Toast';
import IconButton from '@/components/IconButton';
import { FhirPedidoType } from '@/features/enterprise/models/types';
import { Container, Header, HeaderTitle, Description, Actions } from './styles';

interface BoxFotoceuticoProps {
  fotoceutico: FhirPedidoType;
  disableCopy?: boolean;
}

const BoxFotoceutico: React.FC<BoxFotoceuticoProps> = ({
  fotoceutico,
  disableCopy,
}) => {
  const [onHover, setOnHover] = React.useState(false);

  const nome = React.useMemo(() => {
    return fhirpath.evaluate(
      fotoceutico,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [fotoceutico]);

  const aplicacoes = React.useMemo(() => {
    return fhirpath.evaluate(
      fotoceutico,
      'ServiceRequest.occurrenceTiming.repeat.count',
    )[0];
  }, [fotoceutico]);

  const description = React.useMemo(() => {
    const suffix = aplicacoes === 1 ? 'sessão' : 'sessões';
    return `${aplicacoes} ${suffix}`;
  }, [aplicacoes]);

  const copyfotoceutico = React.useCallback(() => {
    const texto = `${nome}`;
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Fotoceutico copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [nome]);

  if (!aplicacoes) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{nome}</HeaderTitle>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={onHover}>{nome}</HeaderTitle>
        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar fotoceutico"
              onClick={copyfotoceutico}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiCopy />}
            />
          )}
        </Actions>
      </Header>
      <Description>{description}</Description>
    </Container>
  );
};

BoxFotoceutico.defaultProps = {
  disableCopy: false,
};

export default BoxFotoceutico;
