/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import AnimateHeight from 'react-animate-height';

import { ReactComponent as CircleOff } from '@/assets/icons/circle-off.svg';
import { ReactComponent as SquareOff } from '@/assets/icons/square-off.svg';

import useOnClickOuside from '@/utils/hooks/useOnClickOutside';
import { Checkbox, Radio } from '../SelectionControl';
import { SelectionControlType } from '../SelectionControl/types';
import ContentEditable, { ContentEditableRef } from '../ContentEditable';

import {
  Container,
  MainContent,
  SelectionControlButton,
  Label,
  CollapseButton,
  DetailsContent,
  Details,
  ExcludentOptionIndicator,
} from './styles';

type LabelType =
  | string
  | React.ReactFragment
  | React.ReactElement
  | React.ReactPortal;

export interface SelectionControlOption<TValue> {
  label: LabelType;
  value: TValue;
  excludent?: boolean;
  editable?: boolean;
  edited?: boolean;
  details?: string;
  readonly?: boolean;
}

export interface SelectionControlInputProps<TValue>
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  option: SelectionControlOption<TValue>;
  marked?: boolean;
  disabled?: boolean;
  selectionType?: SelectionControlType;
  onOptionLabelChange?: (label: string) => void;
}

const SelectionControlTypeComponent = {
  radio: Radio,
  checkbox: Checkbox,
};

const SelectionControlTypeIcon = {
  radio: CircleOff,
  checkbox: SquareOff,
};

const SelectionControlInput = React.forwardRef(
  <TValue extends unknown>(
    {
      marked = false,
      disabled = false,
      option,
      style,
      className,
      selectionType = 'radio',
      onOptionLabelChange,
      onClick,
      ...props
    }: SelectionControlInputProps<TValue>,
    ref: React.Ref<HTMLButtonElement>,
  ): JSX.Element => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const editableRef = React.useRef<ContentEditableRef>(null);

    const [collapsed, setCollapsed] = React.useState(false);

    const controlState = React.useMemo(() => {
      return marked ? 'marked' : 'unmarked';
    }, [marked]);

    const SelectionControlComponent = React.useMemo(
      () => SelectionControlTypeComponent[selectionType],
      [selectionType],
    );

    const ExcludentOptionIcon = React.useMemo(
      () => SelectionControlTypeIcon[selectionType],
      [selectionType],
    );

    const Right = React.useMemo(() => {
      if (option.excludent === true) {
        return (
          <ExcludentOptionIndicator marked={marked}>
            <ExcludentOptionIcon />
          </ExcludentOptionIndicator>
        );
      }

      if (option.details) {
        return (
          <CollapseButton
            collapsed={collapsed}
            onClick={() => setCollapsed(state => !state)}
          />
        );
      }

      return <></>;
    }, [
      ExcludentOptionIcon,
      collapsed,
      marked,
      option.details,
      option.excludent,
    ]);

    const handleOptionEdit = React.useCallback(
      (label: string) => {
        if (onOptionLabelChange) {
          onOptionLabelChange(label);
        }
      },
      [onOptionLabelChange],
    );

    const LabelContent = React.useMemo(() => {
      if (React.isValidElement(option.label)) {
        return option.label;
      }

      if (option.editable) {
        return (
          <ContentEditable
            disabled={disabled}
            ref={editableRef}
            content={option.label as string}
            edited={option.edited}
            onContentEdit={handleOptionEdit}
          />
        );
      }

      return <Label collapsed={collapsed}>{option.label}</Label>;
    }, [collapsed, disabled, handleOptionEdit, option]);

    useOnClickOuside(containerRef, () => {
      if (editableRef.current) {
        editableRef.current.cancelEdition();
      }
    });

    return (
      <Container ref={containerRef}>
        <SelectionControlButton
          readonly={option.readonly}
          ref={ref}
          marked={marked}
          disabled={disabled}
          style={style}
          className={className}
          onClick={event => {
            if (!option.readonly && onClick) {
              onClick(event);
            }
          }}
          {...props}
        >
          <MainContent
            containsRightContent={!!(option.excludent || option.details)}
            isEditable={!!option.editable}
          >
            {!option.readonly && (
              <SelectionControlComponent
                state={controlState}
                disabled={disabled}
                size="small"
              />
            )}

            {LabelContent}
          </MainContent>

          {option.details && (
            <AnimateHeight duration={400} height={collapsed ? 'auto' : 0}>
              <DetailsContent collapsed={collapsed}>
                <Details>{option.details}</Details>
              </DetailsContent>
            </AnimateHeight>
          )}
        </SelectionControlButton>

        {Right}
      </Container>
    );
  },
);

export default SelectionControlInput;
