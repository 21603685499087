import React from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';

import IconButton from '@/components/IconButton';
import DetailsCollapseButton from '@/components/DetailsCollapseButton';

import ArtifactForm from '@/features/artifacts/components/ArtifactForm';

import { buildFormType } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';
import {
  ArtifactFormModel,
  ArtifactFormOverrides,
  ArtifactFormType,
  FormLoadingState,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';

import { ItemUIDisplayMode } from '../../models';

import { Box, MainContent, FormContent, Heading, Actions } from './styles';

type RType = fhir4.FhirResource;

interface Props {
  title: string;
  description?: string;
  resource: RType;
  formType?: ArtifactFormType;
  fields?: ArtifactFormOverrides;
  form?: ArtifactFormModel;
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit?: () => void;
  onEditCapture?: (resource: RType) => void;
  onEditCancel?: () => void;
  onDelete?: () => void;
  displayMode?: ItemUIDisplayMode;
  loading?: FormLoadingState;
  onDisplayModeChange?: (displayMode: ItemUIDisplayMode) => void;
  style?: React.CSSProperties;
  className?: string;
}

const ItemComplementarBox: React.FC<Props> = ({
  title,
  description = '',
  resource,
  formType,
  fields,
  form,
  canEdit = true,
  canDelete = true,
  loading = false,
  onDelete,
  onEdit,
  onEditCapture,
  onEditCancel,
  displayMode = 'item',
  onDisplayModeChange,
  style,
  className,
}) => {
  const descriptionRef = React.useRef<HTMLParagraphElement>(null);

  const [collapsed, setCollapsed] = React.useState(false);
  const [isEllipsized, setIsEllipsized] = React.useState(false);

  const canCollapse = React.useMemo(() => collapsed || isEllipsized, [
    collapsed,
    isEllipsized,
  ]);

  const itemForm = React.useMemo(() => {
    if (formType && fields) {
      return buildFormType(formType, fields);
    }

    if (form) {
      return form;
    }

    throw new Error(
      'ItemComplementarBox should either include a `formType` and `fields` properties or just `form`',
    );
  }, [fields, form, formType]);

  const display: ItemUIDisplayMode = React.useMemo(() => {
    if (!canEdit && displayMode === 'form') {
      return 'item';
    }

    return displayMode;
  }, [canEdit, displayMode]);

  const handleDisplayModeChange = React.useCallback(
    (changedDisplayMode: ItemUIDisplayMode) => {
      if (onDisplayModeChange) {
        onDisplayModeChange(changedDisplayMode);
      }
    },
    [onDisplayModeChange],
  );

  const onBeforeDelete = React.useCallback(() => {
    if (onDelete) {
      onDelete();
    }
  }, [onDelete]);

  const onBeforeEdit = React.useCallback(() => {
    handleDisplayModeChange('form');

    if (onEdit) {
      onEdit();
    }
  }, [handleDisplayModeChange, onEdit]);

  const onCancelEdit = React.useCallback(() => {
    handleDisplayModeChange('item');

    if (onEditCancel) {
      onEditCancel();
    }
  }, [handleDisplayModeChange, onEditCancel]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const element = descriptionRef.current;

    if (element) {
      const checkEllipsis = () => {
        const ellipsized = element.scrollHeight > element.clientHeight;
        setIsEllipsized(ellipsized);
      };

      // Initial check
      checkEllipsis();

      // Create a ResizeObserver to watch for changes in element size
      const resizeObserver = new ResizeObserver(() => {
        checkEllipsis();
      });

      // Observe the element
      resizeObserver.observe(element);

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [description, display]);

  return (
    <Box style={style} className={className}>
      {display === 'item' && (
        <MainContent>
          <Heading collapsed={collapsed}>
            <h1>{title}</h1>
            <p ref={descriptionRef}>{description}</p>
          </Heading>
          <Actions>
            <IconButton
              icon={<FiEdit2 size={16} />}
              disabled={!canEdit}
              onClick={onBeforeEdit}
            />
            <IconButton
              icon={<FiTrash2 size={16} />}
              disabled={!canDelete}
              onClick={onBeforeDelete}
            />
            <DetailsCollapseButton
              collapsed={collapsed}
              onClick={() => setCollapsed(prev => !prev)}
              disabled={!canCollapse}
            />
          </Actions>
        </MainContent>
      )}

      {display === 'form' && (
        <FormContent>
          <h1>{title}</h1>

          <ArtifactForm
            form={itemForm}
            resource={resource}
            onResourceSubmit={onEditCapture}
            onResourceSubmitCancel={onCancelEdit}
            loading={loading}
            enabledWhenDirty
          />
        </FormContent>
      )}
    </Box>
  );
};

export default ItemComplementarBox;
