import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import {
  Nav,
  NavScroll,
  NavItemListContainer,
  NavItemContainer,
  NavItem,
  ActiveIndicator,
  Control,
} from './styles';

export interface NavItem {
  key: string;
  title: string;
  content?: string | null | JSX.Element;
}

interface NavItemRefMap {
  [key: string]: React.RefObject<HTMLLIElement>;
}

interface NavProps {
  navItems: NavItem[];
  keyActived: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (navItem: NavItem) => void;
}

const NavTabs: React.FC<NavProps> = ({
  navItems,
  keyActived,
  onClick,
  style = {},
  className = undefined,
}) => {
  const scrollRef = React.createRef<HTMLDivElement>();
  const listRef = React.createRef<HTMLUListElement>();
  const indicatorRef = React.createRef<HTMLSpanElement>();

  const [activedKeyPosition, setActivedKeyPosition] = React.useState(0);
  const [hasScroll, setHasScroll] = React.useState(false);

  const navItemsRef = React.useMemo(() => {
    return navItems.reduce<NavItemRefMap>(
      (acc, current) => ({
        ...acc,
        [current.key]: React.createRef<HTMLLIElement>(),
      }),
      {},
    );
  }, [navItems]);

  React.useEffect(() => {
    if (scrollRef.current) {
      setHasScroll(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth,
      );
    }
  }, [scrollRef]);

  React.useEffect(() => {
    setActivedKeyPosition(prev => {
      const activedItemRef = navItemsRef[keyActived];
      if (activedItemRef.current && listRef.current && indicatorRef.current) {
        const activedItem = activedItemRef.current;
        const indicator = indicatorRef.current;
        const list = listRef.current;

        const { width: indicatorWidth } = indicator.getBoundingClientRect();
        const { width: activedItemWidth } = activedItem.getBoundingClientRect();

        const position = Math.abs(activedItem.offsetLeft - list.offsetLeft);
        const offset = (activedItemWidth - indicatorWidth) / 2;

        return position + offset;
      }

      return prev;
    });
  }, [keyActived, navItemsRef, listRef, indicatorRef]);

  const onScrollLeft = React.useCallback(
    (value: number) => {
      scrollRef.current?.scrollBy({
        left: value,
        behavior: 'smooth',
      });
    },
    [scrollRef],
  );

  return (
    <Nav style={style} className={className}>
      {hasScroll && (
        <Control onClick={() => onScrollLeft(-250)}>
          <FiChevronLeft />
        </Control>
      )}

      <NavScroll ref={scrollRef}>
        <NavItemListContainer ref={listRef}>
          {navItems.map(navItem => {
            return (
              <NavItemContainer
                key={navItem.key}
                ref={navItemsRef[navItem.key]}
              >
                <NavItem
                  actived={keyActived === navItem.key}
                  onClick={evt => {
                    evt.stopPropagation();
                    if (onClick) {
                      onClick(navItem);
                    }
                  }}
                >
                  {navItem.title}
                </NavItem>
              </NavItemContainer>
            );
          })}
        </NavItemListContainer>
        <ActiveIndicator position={activedKeyPosition} ref={indicatorRef} />
      </NavScroll>

      {hasScroll && (
        <Control onClick={() => onScrollLeft(+250)}>
          <FiChevronRight />
        </Control>
      )}
    </Nav>
  );
};

export default NavTabs;
