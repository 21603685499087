import React from 'react';
import fhirpath from 'fhirpath';
import { FiPlus } from 'react-icons/fi';
import debounce from 'lodash.debounce';

import { generateUniqueId } from '@/utils/coding';
import InputField from '@/components/InputField';
import FeedbackGraphic from '@/components/FeedbackGraphic';
import SearchPanel from '@/components/SearchPanel';

import {
  buildDevicePropertyType,
  deviceContainsPropertyCode,
  deviceName,
  devicePropertyCodePath,
  DevicePropertyType,
  DevicePropertyValue,
  deviceQuantityValue,
  removeDeviceProperty,
  setDeviceProperty,
} from '@/features/fhir/helpers/device';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import { serviceUpdateDevice } from '@/features/fhir/helpers/serviceRequest';
import Feature from '@/features/features/components/Feature';

import InputFieldQuantity from '../InputFieldQuantity';
import ModalDiscardOpme from '../ModalDiscardOpme';
import {
  ButtonAddOpme,
  Container,
  Header,
  HeaderSubTitle,
  HeaderTitle,
  OpmeList,
  OpmeRow,
  OpmesEmptyList,
  OpmesNaoPadroesContainer,
} from './styles';

interface Props {
  procedimento: fhir4.ServiceRequest;
  opmes: fhir4.Device[];
  invalidOpmes?: fhir4.Device[];
  isOpenForEdit?: boolean;
  onProcedimentoChange?: (changed: fhir4.ServiceRequest) => void;
  loading?: boolean;
  readOnly?: boolean;
}

const AdditionalStatus: fhir4.DeviceProperty = {
  type: buildDevicePropertyType('inclusion-status'),
  valueCode: [
    {
      coding: [
        {
          code: 'additional',
          display: 'Item adicional (não-padrão)',
        },
      ],
    },
  ],
};

const containsAdditionalStatus = (device: fhir4.Device) => {
  return deviceContainsPropertyCode(device, 'inclusion-status', 'additional');
};

const deviceID = (service: fhir4.ServiceRequest) => {
  const containedIDs = fhirpath.evaluate(
    service,
    'ServiceRequest.contained.id',
  );

  return generateUniqueId(containedIDs, 'device-');
};

const BoxOpmes: React.FC<Props> = ({
  opmes,
  invalidOpmes,
  isOpenForEdit,
  procedimento,
  onProcedimentoChange,
  loading = false,
  readOnly = false,
}) => {
  const [discartingOpmeId, setDiscartingOpmeId] = React.useState<
    string | undefined
  >(undefined);

  const [isDiscardOpmeModalOpen, setIsDiscardOpmeModalOpen] = React.useState(
    false,
  );
  const [showOpmesSearch, setShowOpmesSearch] = React.useState(false);
  const [loadingSearchResults, setLoadingSearchResults] = React.useState(false);
  const [opmesComplementares, setOpmesComplementares] = React.useState<
    FhirArtefatoType[] | undefined
  >(undefined);

  const openDiscardOpmeModal = React.useCallback(
    (opmeId: string | undefined) => {
      if (opmeId) {
        setDiscartingOpmeId(opmeId);
        setIsDiscardOpmeModalOpen(true);
      }
    },
    [],
  );

  const closeDiscardOpmeModal = () => {
    setIsDiscardOpmeModalOpen(false);
  };

  const getOpmeQuantity = React.useCallback((device: fhir4.Device) => {
    const quantity = deviceQuantityValue(device, 'quantity') || 1;
    return quantity;
  }, []);

  const getOpmeMinQuantity = React.useCallback((device: fhir4.Device) => {
    const quantity = deviceQuantityValue(device, 'min-quantity') || 1;
    return quantity;
  }, []);

  const getOpmeMaxQuantity = React.useCallback((device: fhir4.Device) => {
    const quantity = deviceQuantityValue(device, 'max-quantity');
    return quantity;
  }, []);

  const getOpmeJustificativa = React.useCallback((device: fhir4.Device) => {
    const text = fhirpath.evaluate(
      device,
      `${devicePropertyCodePath('inclusion-status-reason')}.valueCode.text`,
    )[0];
    return text;
  }, []);

  const getOpmeJustificativaError = React.useCallback(
    (device: fhir4.Device) => {
      // Hoje o único erro possível de um OPME (não-padrão) é que ele não contenha
      // justificativa. Caso futuramente exista outro tipo de erro de formulário,
      // essa lógica deve ser refinada

      if (invalidOpmes) {
        const invalidIDs = invalidOpmes.map(({ id }) => id);
        if (invalidIDs.includes(device.id)) {
          return 'Este campo é obrigatório.';
        }
      }

      return undefined;
    },
    [invalidOpmes],
  );

  const opmesPadroes = React.useMemo(
    () => opmes.filter(opme => !containsAdditionalStatus(opme)),
    [opmes],
  );

  const opmesNaoPadroes = React.useMemo(
    () => opmes.filter(opme => containsAdditionalStatus(opme)),
    [opmes],
  );

  const titulosOpmes = React.useMemo(
    () =>
      opmes.map(opme => {
        const name = deviceName(opme, ['user-friendly-name', 'model-name']);

        const quantity = getOpmeQuantity(opme);

        return `${quantity} ${name}`;
      }),
    [getOpmeQuantity, opmes],
  );

  const onAddOpmeClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setShowOpmesSearch(true);
    },
    [],
  );

  const handleChangeSearch = React.useCallback(async (term: string) => {
    // setOpmesComplementares(undefined);
    if (term !== '') {
      setLoadingSearchResults(true);

      try {
        const result = await IntegracaoService.getItemsComplementaresList(
          'material-opme',
          term,
        );
        if (result) {
          setOpmesComplementares(result);
        }
      } finally {
        setLoadingSearchResults(false);
      }
    } else {
      setOpmesComplementares(undefined);
    }
  }, []);

  const clearSearch = React.useCallback(() => {
    setShowOpmesSearch(false);
    setOpmesComplementares(undefined);
  }, []);

  const includeOPME = React.useCallback(
    (opme: fhir4.Device) => {
      clearSearch();

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { property = [], meta, id, ...device } = opme;

      const additionalDevice: fhir4.Device = {
        id: deviceID(procedimento),
        ...device,
      };

      if (!containsAdditionalStatus(opme)) {
        additionalDevice.property = [...property, AdditionalStatus];
      } else if (property.length) {
        additionalDevice.property = property;
      }

      const { contained = [], supportingInfo = [], ...service } = procedimento;
      const modifiedService: fhir4.ServiceRequest = {
        ...service,
        contained: [...contained, additionalDevice],
        supportingInfo: [
          ...supportingInfo,
          { reference: `#${additionalDevice.id}` },
        ],
      };

      if (onProcedimentoChange) {
        onProcedimentoChange(modifiedService);
      }
    },
    [clearSearch, onProcedimentoChange, procedimento],
  );

  const excludeOPME = React.useCallback(
    (deletingID: string) => {
      closeDiscardOpmeModal();

      const { contained = [], supportingInfo = [], ...service } = procedimento;

      const modifiedContained = contained.filter(({ id }) => id !== deletingID);
      const modifiedSupportinInfo = supportingInfo.filter(
        ({ reference }) => reference !== `#${deletingID}`,
      );

      const modifiedService: fhir4.ServiceRequest = { ...service };
      if (modifiedContained.length) {
        modifiedService.contained = modifiedContained;
      }

      if (modifiedSupportinInfo.length) {
        modifiedService.supportingInfo = modifiedSupportinInfo;
      }

      if (onProcedimentoChange) {
        // Atrasa envio do evento p/ que o usuário tenha tempo de visualizar o
        // item sendo excluído
        setTimeout(() => onProcedimentoChange(modifiedService), 300);
      }
    },
    [onProcedimentoChange, procedimento],
  );
  const handleOPMEPropertyChange = React.useCallback(
    (
      device: fhir4.Device,
      code: DevicePropertyType,
      value?: DevicePropertyValue,
    ) => {
      const editingDevice =
        value === undefined
          ? removeDeviceProperty(device, code)
          : setDeviceProperty(device, code, value);
      const modifiedService = serviceUpdateDevice(procedimento, editingDevice);

      if (onProcedimentoChange) {
        onProcedimentoChange(modifiedService);
      }
    },
    [onProcedimentoChange, procedimento],
  );

  const handleOPMEJustificativaChange = React.useMemo(() => {
    const onChange = (device: fhir4.Device, text: string) => {
      if (text) {
        handleOPMEPropertyChange(device, 'inclusion-status-reason', {
          valueCode: [{ text }],
        });
      } else {
        handleOPMEPropertyChange(device, 'inclusion-status-reason');
      }
    };

    return debounce(onChange, 300);
  }, [handleOPMEPropertyChange]);

  const searchResultItemTitleParser = React.useCallback(
    (item: FhirArtefatoType) => {
      return deviceName(item.recurso as fhir4.Device);
    },
    [],
  );

  const searchResultKeyExtractor = React.useCallback(
    (item: FhirArtefatoType, position: number) => {
      return `${item.recurso.id} - ${position + 1} `;
    },
    [],
  );

  const ButtonAddOpmes = React.useMemo(() => {
    return (
      !readOnly && (
        <ButtonAddOpme onClick={onAddOpmeClick}>
          <FiPlus size={16} />
          Adicionar OPME não padrão
        </ButtonAddOpme>
      )
    );
  }, [onAddOpmeClick, readOnly]);

  const OpmesNaoPadroes = React.useMemo(() => {
    return (
      <OpmesNaoPadroesContainer>
        {(opmesNaoPadroes.length > 0 || showOpmesSearch) && (
          <HeaderTitle highlight>OPME não padrões</HeaderTitle>
        )}
        {opmesNaoPadroes.map(opme => (
          <OpmeRow key={opme.id}>
            <InputField
              style={{ width: '100%' }}
              title="Item"
              defaultValue={deviceName(opme)}
              inputTitle={deviceName(opme)}
              disabled
            />
            <InputField
              style={{ width: '100%' }}
              title="Justificativa*"
              autoFocus
              disabled={loading || readOnly}
              onClick={e => e.stopPropagation()}
              defaultValue={getOpmeJustificativa(opme)}
              error={getOpmeJustificativaError(opme)}
              onChange={event =>
                handleOPMEJustificativaChange(opme, event.target.value)
              }
            />
            <InputFieldQuantity
              title="Quantidade"
              initialValue={getOpmeQuantity(opme)}
              minValue={getOpmeMinQuantity(opme)}
              maxValue={getOpmeMaxQuantity(opme)}
              disabled={loading || readOnly}
              onChange={value => {
                handleOPMEPropertyChange(opme, 'quantity', {
                  valueQuantity: [{ value }],
                });
              }}
              onRemoveItem={() => {
                openDiscardOpmeModal(opme?.id);
              }}
            />
          </OpmeRow>
        ))}
        {showOpmesSearch && (
          <SearchPanel
            autoFocus
            placeholder="Busque por OPME"
            searching={loadingSearchResults}
            results={opmesComplementares}
            resultLabel={({ item }) => searchResultItemTitleParser(item)}
            resultKeyExtractor={({ item, index }) =>
              searchResultKeyExtractor(item, index)
            }
            onSearch={handleChangeSearch}
            onSearchFocusOut={clearSearch}
            onResultsDismiss={clearSearch}
            onResultSelect={item => includeOPME(item.recurso as fhir4.Device)}
          />
        )}
      </OpmesNaoPadroesContainer>
    );
  }, [
    opmesNaoPadroes,
    showOpmesSearch,
    loadingSearchResults,
    opmesComplementares,
    clearSearch,
    handleChangeSearch,
    loading,
    readOnly,
    getOpmeJustificativa,
    getOpmeJustificativaError,
    getOpmeQuantity,
    getOpmeMinQuantity,
    getOpmeMaxQuantity,
    handleOPMEJustificativaChange,
    handleOPMEPropertyChange,
    openDiscardOpmeModal,
    searchResultItemTitleParser,
    searchResultKeyExtractor,
    includeOPME,
  ]);

  return (
    <Container>
      <ModalDiscardOpme
        opmeId={discartingOpmeId}
        isOpen={isDiscardOpmeModalOpen}
        onAfterClose={closeDiscardOpmeModal}
        handleDiscardOpme={excludeOPME}
      />
      <Header>
        {isOpenForEdit ? (
          <>
            <HeaderTitle highlight>OPME sugeridos</HeaderTitle>
            <OpmeList>
              {opmesPadroes.length > 0 ? (
                opmesPadroes.map(opme => (
                  <OpmeRow key={opme.id}>
                    <InputField
                      style={{ width: '100%' }}
                      title="Item"
                      defaultValue={deviceName(opme)}
                      inputTitle={deviceName(opme)}
                      disabled
                    />
                    <InputFieldQuantity
                      title="Quantidade"
                      initialValue={getOpmeQuantity(opme)}
                      minValue={getOpmeMinQuantity(opme)}
                      maxValue={getOpmeMaxQuantity(opme)}
                      disabled={loading || readOnly}
                      onChange={value => {
                        handleOPMEPropertyChange(opme, 'quantity', {
                          valueQuantity: [{ value }],
                        });
                      }}
                      onRemoveItem={() => {
                        openDiscardOpmeModal(opme?.id);
                      }}
                    />
                  </OpmeRow>
                ))
              ) : (
                <OpmesEmptyList>
                  <FeedbackGraphic
                    graphic="empty-box"
                    title="Nenhuma OPME sugerido por aqui"
                  />

                  <Feature name="prescricao.opme.nao_padrao">
                    {!showOpmesSearch && opmes.length === 0 && ButtonAddOpmes}
                  </Feature>
                </OpmesEmptyList>
              )}

              <Feature name="prescricao.opme.nao_padrao">
                {OpmesNaoPadroes}
                {opmes.length > 0 && !showOpmesSearch && ButtonAddOpmes}
              </Feature>
            </OpmeList>
          </>
        ) : (
          <>
            <HeaderTitle>OPME </HeaderTitle>
            <HeaderSubTitle>{titulosOpmes.join(', ')}</HeaderSubTitle>
          </>
        )}
      </Header>
    </Container>
  );
};

export default BoxOpmes;
