/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Tabs from '@/components/Tabs';
import Tab from '@/components/Tabs/Tab';
import TabList from '@/components/Tabs/TabList';

import { ArtifactFormComponentProps } from '@/features/artifacts/components/ArtifactForm';
import { ArtifactFormOverrides } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useArtifactFormConfig } from '@/features/artifacts/hooks/useArtifactFormConfig';
import ArtifactFieldset from '@/features/artifacts/components/ArtifactForm/ArtifactFieldset';
import ArtifactBaseForm from '@/features/artifacts/components/ArtifactBaseForm';
import ArtifactFormProvider, {
  ArtifactFormConsumer,
} from '@/features/artifacts/providers/ArtifactFormProvider';

import { FormModePanel } from './styles';

const ProcedimentoFormDefault: ArtifactFormOverrides = [
  { name: 'reasonCode', label: 'Indicação clínica', required: true },
  { name: 'bodySite', label: 'Local do Corpo' },
  { name: 'orderDetail', label: 'Observações' },
  { name: 'opmes', label: 'OPME Sugeridos' },
];

export interface FormProcedimentoProps extends ArtifactFormComponentProps {
  children?: React.ReactNode;
  mode?: FormProcedimentoMode;
  onModeChange?: (to: FormProcedimentoMode) => void;
}

export type FormProcedimentoMode = 'procedimento' | 'opme';

const FormProcedimento: React.FC<FormProcedimentoProps> = ({
  resource,
  onSubmit,
  onSubmitError,
  onCancel,
  disabled = false,
  loading = false,
  readOnly = false,
  mode = 'procedimento',
  onModeChange,
  children,
}) => {
  const form = useArtifactFormConfig('procedimento', ProcedimentoFormDefault);
  const handleModeChange = React.useCallback(
    (to?: React.Key) => {
      if (onModeChange) {
        onModeChange(to as FormProcedimentoMode);
      }
    },
    [onModeChange],
  );

  return (
    <ArtifactFormProvider form={form} resource={resource}>
      <ArtifactFormConsumer>
        {({ handleResourceSubmit }) => (
          <ArtifactBaseForm
            form={form}
            loading={loading}
            disabled={disabled}
            readOnly={readOnly}
            onCancel={() => onCancel(resource)}
            onSubmit={handleResourceSubmit(onSubmit, onSubmitError)}
            renderFieldset={({ loading: renderLoading, ...renderProps }) => {
              return (
                <Tabs value={mode} onChange={onModeChange && handleModeChange}>
                  <TabList>
                    <Tab value="procedimento">Procedimento</Tab>
                    <Tab value="opme">OPME</Tab>
                  </TabList>

                  <FormModePanel value="procedimento">
                    <ArtifactFieldset
                      {...{ loading: !!renderLoading, ...renderProps }}
                    />
                  </FormModePanel>
                  <FormModePanel value="opme">{children}</FormModePanel>
                </Tabs>
              );
            }}
          />
        )}
      </ArtifactFormConsumer>
    </ArtifactFormProvider>
  );
};

export default FormProcedimento;
