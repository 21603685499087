import { toInternalCodeableConceptValue } from '../components/ArtifactForm/ArtifactForm.business';
import { FieldProxy } from '../components/ArtifactForm/ArtifactForm.fields';

const ExameForm = [
  FieldProxy({
    name: 'category',
    label: 'Categoria',
    widget: 'short_answer',
    fhirPath: 'category.select(text | coding.display).first()',
    propertyPath: 'category',
    setValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'category', value, true),
  }),
  FieldProxy({
    name: 'orderDetail',
    label: 'Detalhes do pedido',
    widget: 'paragraph',
    fhirPath: 'orderDetail.select(text | coding.display).first()',
    propertyPath: 'orderDetail',
    setValue: (resource, value) => ({
      ...resource,
      orderDetail: [{ text: value }],
    }),
  }),
  FieldProxy({
    name: 'quantity',
    label: 'Quantidade',
    widget: 'number_integer',
    propertyPath: 'quantityQuantity',
    fhirPath: 'quantityQuantity.value',
    setValue: (resource, value) => ({
      ...resource,
      quantityQuantity: { value },
    }),
  }),
  FieldProxy({
    name: 'reasonCode',
    label: 'Indicação clínica',
    widget: 'short_answer',
    fhirPath: 'reasonCode.select(text | coding.display).first()',
    propertyPath: 'reasonCode',
    setValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'reasonCode', value, true),
  }),
  FieldProxy({
    name: 'bodySite',
    label: 'Local do corpo',
    widget: 'short_answer',
    fhirPath: 'bodySite.select(text | coding.display).first()',
    propertyPath: 'bodySite',
    setValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'bodySite', value, true),
  }),
  FieldProxy({
    name: 'note',
    label: 'Comentários',
    widget: 'short_answer',
    fhirPath: 'note.text',
    propertyPath: 'note',
    setValue: (resource, value) => ({
      ...resource,
      note: [{ text: value }],
    }),
  }),
  FieldProxy({
    name: 'patientInstruction',
    label: 'Instruções ao paciente',
    widget: 'short_answer',
    fhirPath: 'patientInstruction',
    propertyPath: 'patientInstruction',
    setValue: (resource, value) => ({
      ...resource,
      patientInstruction: value,
    }),
  }),
];

export default ExameForm;
