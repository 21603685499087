import styled from 'styled-components';

import {
  SectionEspecialidadesContainer,
  SectionEspecialidadesHeader,
} from '../SectionEspecialidades/styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 130px);

  /**
    Estilo necessário p/ não quebrar o box-shadow de CardEspecialidade durante
    hover sobre o primeiro item.

    O padding aplicado ao conteúdo principal de DefaultLayout cortava o
    box-shadow (no hover) nos extremos de CarouselEspecialidade, pois o mesmo
    extrapolava o conteúdo da div parent.

    Foi necessário remover o padding lateral (right e left) do conteúdo principal
    e aplicá-lo seção a seção, exceto no componente de especialidades, que
    aplica o padding internamente, evitando que o box-shadow extrapole o
    conteúdo do elemento-pai
  */
  ${SectionEspecialidadesContainer} {
    ${SectionEspecialidadesHeader} {
      padding: 0 24px;
    }
    .react-horizontal-scrolling-menu--scroll-container {
      padding: 16px 24px;
    }
  }

  // Todas as seções de conteúdo, exceto de SectionEspecialidades, tem padding
  // lateral de 24px
  > :not(${SectionEspecialidadesContainer}) {
    padding: 0 24px;
  }
`;

export const Header = styled.div`
  margin-bottom: 32px;
`;
