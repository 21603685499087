import React from 'react';

import ButtonBack from '@/components/ButtonBack';
import HeaderDefault from '@/components/HeaderDefault';
import { Especialidade } from '@/models/Especialidade';

import { LeftContent, ImageBg } from './styles';

interface Props {
  especialidade: Especialidade;
}

const HeaderEspecialidade: React.FC<Props> = ({ especialidade }) => {
  const { count, imagem_url, descricao } = especialidade;

  const countText = React.useMemo(() => {
    const { protocolos: total } = count;

    const text = `${total} protocolo`;
    const suffix = total > 1 ? 's' : '';

    return text + suffix;
  }, [count]);

  return (
    <HeaderDefault
      title={`Protocolos de ${descricao}`}
      subtitle={countText}
      Left={
        <LeftContent>
          <ButtonBack />

          <ImageBg>
            <img src={imagem_url} alt={descricao} />
          </ImageBg>
        </LeftContent>
      }
    />
  );
};

export default HeaderEspecialidade;
