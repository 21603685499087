import React from 'react';
import fhirpath from 'fhirpath';

import { buildFormType } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import { ItemComplementarState, ItemComplementarUIState } from '../../models';
import { itemToUIState } from '../../helpers/parsers';
import FormDefaults from '../../models/FormDefaults';

import ItemComplementarGroup from '../ItemComplementarGroup';

const itemForm = buildFormType('prescricao-medica', FormDefaults.Medicamento);

const getTitle = (item: ItemComplementarState) => {
  const name = fhirpath.evaluate(
    item.resource,
    `MedicationRequest.medicationCodeableConcept.text 
      | MedicationRequest.medicationCodeableConcept.coding.display `,
  )[0];

  return name;
};

const getDescription = (item: ItemComplementarState) => {
  const dosageInstruction = fhirpath.evaluate(
    item.resource,
    'MedicationRequest.dosageInstruction.text',
  );

  if (dosageInstruction.length) {
    return dosageInstruction[0];
  }

  return '';
};

const PrescricoesLivreMedicamento: React.FC = () => {
  const {
    mode,
    getItemsByType,
    getItemById,
    deleteItem,
    updateItem,
    updateItemState,
  } = useCondutaComplementarContext();

  const medicamentos = React.useMemo(() => {
    const items = getItemsByType('prescricao-medica');

    return items.map<ItemComplementarUIState>(item =>
      itemToUIState(item, {
        title: getTitle(item),
        description: getDescription(item),
      }),
    );
  }, [getItemsByType]);

  const canEditOrDelete = React.useMemo(() => mode === 'active', [mode]);

  const handleUpdate = React.useCallback(
    ({ key: updatingID, resource }: ItemComplementarUIState) => {
      const { itemState, type = 'prescricao-medica' } =
        getItemById(updatingID) || {};

      updateItem({
        id: updatingID,
        type,
        resource,
        itemState,
      });
    },
    [getItemById, updateItem],
  );
  const handleItemEditCancel = React.useCallback(
    ({ key: id }: ItemComplementarUIState) => {
      const item = getItemById(id);

      // Encaminhamentos adicionados via frontend (ao invés de carregados da API)
      // devem ser excluídos se o usuário cancelar sua primeira edição
      if (item?.itemState?.isAdding) {
        deleteItem({ id });
      }
    },
    [deleteItem, getItemById],
  );

  if (medicamentos.length > 0) {
    return (
      <ItemComplementarGroup
        tipo="prescricao-medica"
        itemForm={itemForm}
        items={medicamentos}
        onChangeItemCancel={handleItemEditCancel}
        onChangeItem={handleUpdate}
        onDeleteItem={({ key: id }) => deleteItem({ id })}
        onItemDisplayModeChange={(item, display) =>
          updateItemState({ id: item.key }, { isEditing: display === 'form' })
        }
        hasItemChangePermission={canEditOrDelete}
        hasItemDeletePermission={canEditOrDelete}
      />
    );
  }

  return <></>;
};

export default PrescricoesLivreMedicamento;
