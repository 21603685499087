import React from 'react';

import Button from '@/components/Button';
import FeedbackGraphic from '@/components/FeedbackGraphic';

import { Modal, ModalContent, ModalActions } from './styles';
import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';

const ModalCondutaDiscardChanges: React.FC = () => {
  const {
    discardChanges,
    cancelDiscardChanges,
    toggleMenu,
    state: { shouldDiscardChanges },
  } = useCondutaComplementarContext();

  const handleDiscard = React.useCallback(() => {
    // Close side menu
    toggleMenu();
    // Delete adding objects
    discardChanges();
  }, [discardChanges, toggleMenu]);

  return (
    <Modal
      isOpen={shouldDiscardChanges}
      title="Fechar prescrição de itens"
      onAfterClose={cancelDiscardChanges}
    >
      <ModalContent>
        <FeedbackGraphic
          graphic="alert"
          title="Descartar alterações?"
          subtitle="As alterações feitas não serão salvas."
        />

        <ModalActions>
          <Button theme="primary" onClick={handleDiscard}>
            Descartar
          </Button>
          <Button theme="tertiary" onClick={cancelDiscardChanges}>
            Cancelar
          </Button>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default ModalCondutaDiscardChanges;
