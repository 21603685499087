import Flexbox from '@/components/Flexbox';
import PlaceholderLoading from '@/components/PlaceholderLoading';
import React from 'react';

import { Container } from './styles';

interface Props {
  length?: number;
  style?: React.CSSProperties;
  className?: string;
}

const PlaceholderProtocolo: React.FC = () => (
  <PlaceholderLoading.Box>
    <Flexbox.Row columnGap={16} alignItems="center">
      <PlaceholderLoading.Circle />
      <PlaceholderLoading.Rect />
    </Flexbox.Row>
  </PlaceholderLoading.Box>
);

const PlaceholderFlatListProtocolo: React.FC<Props> = ({
  length = 15,
  className,
  style,
}) => {
  const objects = Array.from(Array(length).keys());

  return (
    <Container className={className} style={style}>
      {objects.map(obj => (
        <PlaceholderProtocolo key={obj} />
      ))}
    </Container>
  );
};

export default PlaceholderFlatListProtocolo;
