import styled from 'styled-components';

import Btn from '@/components/Button';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px 0px;

  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  img {
    height: 104px;
  }

  h3 {
    margin: 16px 0px;
  }

  p {
    font-size: 16px;
    color: #a7a9b1;
    max-width: 235px;
    text-align: center;
  }
`;

export const Button = styled(Btn)`
  margin-top: 24px;
`;
