import colors from '@/design/colors';
import styled from 'styled-components';

const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid ${colors.gray100};
  column-gap: 16px;
  margin-top: 22px;
`;

export default StepFooter;
