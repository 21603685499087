export function groupBy<T>(
  array: T[],
  keyGetter: (element: T) => string,
): { [key: string]: T[] } {
  return array.reduce((previousValue, currentValue) => {
    const group = previousValue;
    if (!group[keyGetter(currentValue)]) {
      group[keyGetter(currentValue)] = [];
    }
    group[keyGetter(currentValue)].push(currentValue);
    return previousValue;
  }, {} as { [key: string]: T[] });
}

export function chunks<T>(array: T[], size: number): T[][] {
  return Array.from(new Array(Math.ceil(array.length / size)), (_, i) =>
    array.slice(i * size, i * size + size),
  );
}
