import httpStatus from 'http-status';

import {
  IAuthHandler,
  JWTCookieHttpOnlyHandler,
  JWTStorageHandler,
} from '@/features/auth/services/AuthHandlers';
import AuthService from '@/features/auth/services/AuthService';
import { LoginResponse } from '@/features/auth/types/login';

import StoreManager from '@/business/StoreManager';
import { isHttpStatusError } from '@/utils/http';
import {
  AuthStatus,
  AuthStrategy,
  LoginInput,
  LoginPayload,
  LoginProvider,
} from './types';

type LoginService = (payload: LoginPayload) => Promise<LoginResponse>;

type LoginServiceMappingType = {
  [provider in LoginProvider]: LoginService;
};

const LoginServiceMapping: LoginServiceMappingType = {
  email: AuthService.signIn as LoginService,
  apple: AuthService.signInWithApple as LoginService,
  google: AuthService.signInWithGoogle as LoginService,
  integrated: AuthService.signInWithAuthorizationCode as LoginService,
};

export function getAuthHandler(
  strategy: AuthStrategy,
  store: StoreManager,
): IAuthHandler {
  if (strategy === 'httponly') {
    return JWTCookieHttpOnlyHandler;
  }

  return JWTStorageHandler(store);
}

export async function doLogin({
  provider,
  payload,
}: LoginInput): Promise<LoginResponse> {
  const authLogin = LoginServiceMapping[provider];

  try {
    const data = await authLogin(payload);

    return data;
  } catch (err) {
    if (isHttpStatusError(err as Error, httpStatus.BAD_REQUEST)) {
      throw new Error('Login incorreto.');
    }

    throw new Error('Não foi possível realizar o login.');
  }
}

export function getStore(strategy: AuthStrategy): StoreManager {
  return new StoreManager(
    strategy === 'session' ? sessionStorage : localStorage,
  );
}

export function isSessionExpired(status: AuthStatus): boolean {
  return ['cancelled', 'revoked'].includes(status);
}
