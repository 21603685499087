/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Button from '@/components/Button';

import shuffleIcon from '@/assets/shuffle.svg';
import { Container, Icon, ModalDialogContainer } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRedirecionar: () => Promise<void>;
}

const ModalRedirecionarProtocolo: React.FC<Props> = ({
  isOpen,
  onClose,
  onRedirecionar,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleRedirecionar = React.useCallback(async () => {
    try {
      setLoading(true);
      await onRedirecionar();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [onRedirecionar]);

  return (
    <ModalDialogContainer
      title="Redirecionamento"
      isOpen={isOpen}
      onAfterClose={onClose}
    >
      <Container>
        <Icon src={shuffleIcon} alt="Redirecionamento" />
        <h1>Você está sendo redirecionado para outro protocolo.</h1>
        <p>
          Não se preocupe, as informações que você preencheu já foram enviadas
          para o seu prontuário eletrônico e ficarão salvas.
        </p>
        <Button loading={loading} onClick={handleRedirecionar}>
          Ir para o próximo protocolo
        </Button>
        <Button theme="tertiary" onClick={onClose}>
          Voltar
        </Button>
      </Container>
    </ModalDialogContainer>
  );
};

export default ModalRedirecionarProtocolo;
