/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Protocolo } from '@/models/Protocolo';
import Tabs from '@/components/Tabs';
import TabList from '@/components/Tabs/TabList';
import FlatList, { FlatListProps } from '@/components/FlatList';

import { TabPanelTipoProtocolo, TabTipoProtocolo } from './styles';

type Props = Omit<FlatListProps<Protocolo>, 'ListHeaderComponent'>;

const TiposProtocolo = ['all', 'enfermidade', 'sintoma'] as const;
type TipoProtocolo = typeof TiposProtocolo[number];

const filterType = (all: Protocolo[], type: TipoProtocolo) => {
  if (type !== 'all') {
    return all.filter(({ tipo = '' }) => tipo && tipo.toLowerCase() === type);
  }

  return all;
};

const TabGroupProtocolo: React.FC<Props> = ({ data, ...flatListProps }) => {
  return (
    <Tabs value="all" style={{ flex: 1 }}>
      <TabList>
        <TabTipoProtocolo value="all">Todos os protocolos</TabTipoProtocolo>
        <TabTipoProtocolo value="enfermidade">Enfermidades</TabTipoProtocolo>
        <TabTipoProtocolo value="sintoma">Sintomas</TabTipoProtocolo>
      </TabList>

      {TiposProtocolo.map(key => (
        <TabPanelTipoProtocolo value={key} key={key}>
          <FlatList key={key} data={filterType(data, key)} {...flatListProps} />
        </TabPanelTipoProtocolo>
      ))}
    </Tabs>
  );
};

export default TabGroupProtocolo;
