export function toInternalCodeableConceptValue<
  RType extends fhir4.FhirResource
>(
  resource: RType,
  element: string,
  value: string | fhir4.ValueSetExpansionContains,
  oneToMany = false,
): RType {
  let valueCodeableConcept: fhir4.CodeableConcept;
  if (typeof value === 'string') {
    valueCodeableConcept = {
      text: value,
    };
  } else {
    valueCodeableConcept = { coding: [value] };
  }

  const valueWithCardinality = oneToMany
    ? [valueCodeableConcept]
    : valueCodeableConcept;

  return {
    ...resource,
    [element]: valueWithCardinality,
  };
}
