import styled from 'styled-components';
import { FiGitCommit } from 'react-icons/fi';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;

export const Icon = styled(FiGitCommit).attrs({
  size: 16,
})`
  display: flex;
  transform: rotate(90deg);
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  h4 {
    ${HeadingSmall(14)};
  }

  small {
    ${Text(14)};
    color: ${colors.gray300};
  }
`;
