import { PassoExecutado } from '@/models/PassoExecutado';
import {
  ProtocoloExecutado,
  ProtocoloExecutadoResumo,
} from '@/models/ProtocoloExecutado';

import { ProximoCallback, RespostaPayload, WorkflowStatus } from '../../types';

export const ABRIR_EXECUCAO = 'ABRIR_EXECUCAO';
export const ABRIR_RESUMO = 'ABRIR_RESUMO';
export const PROSSEGUIR_PROXIMO_PASSO = 'PROSSEGUIR_PROXIMO_PASSO';
export const PARAR_PASSO = 'PARAR_PASSO';
export const FINALIZAR_PROTOCOLO = 'FINALIZAR_PROTOCOLO';
export const REFAZER_PASSO = 'REFAZER_PASSO';
export const SOLICITAR_SAIDA = 'SOLICITAR_SAIDA';
export const CANCELAR_SAIDA = 'CANCELAR_SAIDA';
export const SOLICITAR_FINALIZACAO = 'SOLICITAR_FINALIZACAO';
export const CANCELAR_FINALIZACAO = 'CANCELAR_FINALIZACAO';
export const EXIBIR_FONTES_PROTOCOLO = 'EXIBIR_FONTES_PROTOCOLO';
export const INTERROMPER_PROTOCOLO = 'INTERROMPER_PROTOCOLO';
export const ALTERAR_STATUS = 'ALTERAR_STATUS';

export interface AbrirExecucaoAction {
  type: typeof ABRIR_EXECUCAO;
  result: ProtocoloExecutado;
  isProtocoloExecutadoRetomado?: boolean;
}

export interface AbrirResumoAction {
  type: typeof ABRIR_RESUMO;
  result: ProtocoloExecutadoResumo;
  isProtocoloReadOnly?: boolean;
}

export interface ProsseguirProximoPassoAction {
  type: typeof PROSSEGUIR_PROXIMO_PASSO;
  origem: PassoExecutado;
  result: ProtocoloExecutado;
}

export interface PararPassoAction {
  type: typeof PARAR_PASSO;
  origem: PassoExecutado;
  result: ProtocoloExecutado;
}

export interface RefazerPassoAction {
  type: typeof REFAZER_PASSO;
  origem: PassoExecutado;
}

export interface FinalizarProtocolo {
  type: typeof FINALIZAR_PROTOCOLO;
}

export interface SolicitarSaidaProtocolo {
  type: typeof SOLICITAR_SAIDA;
  proximo: ProximoCallback;
}

export interface CancelarSaidaProtocolo {
  type: typeof CANCELAR_SAIDA;
}

export interface SolicitarFinalizacaoProtocolo {
  type: typeof SOLICITAR_FINALIZACAO;
  respostas: RespostaPayload;
}

export interface CancelarFinalizacaoProtocolo {
  type: typeof CANCELAR_FINALIZACAO;
}

export interface ExibirFontesProtocolo {
  type: typeof EXIBIR_FONTES_PROTOCOLO;
  value: boolean;
}

export interface InterromperProtocolo {
  type: typeof INTERROMPER_PROTOCOLO;
}

export interface AlterarStatus {
  type: typeof ALTERAR_STATUS;
  status: WorkflowStatus;
}

export type ExecucaoAction =
  | AbrirExecucaoAction
  | AbrirResumoAction
  | ProsseguirProximoPassoAction
  | PararPassoAction
  | RefazerPassoAction
  | FinalizarProtocolo
  | SolicitarSaidaProtocolo
  | CancelarSaidaProtocolo
  | SolicitarFinalizacaoProtocolo
  | CancelarFinalizacaoProtocolo
  | ExibirFontesProtocolo
  | InterromperProtocolo
  | AlterarStatus;
