import { Especialidade } from '@/models/Especialidade';
import { Protocolo } from '@/models/Protocolo';
import api from '@/sevices/api';
import { APIRequestParams, APIResult } from '@/types/Api';

async function list(
  params: APIRequestParams,
): Promise<APIResult<Especialidade>> {
  return (
    await api.get<APIResult<Especialidade>>('/especialidades/', { params })
  ).data;
}

async function listProtocolos(
  especialidadeId: number,
  params: APIRequestParams = {},
): Promise<APIResult<Protocolo>> {
  return (
    await api.get<APIResult<Protocolo>>('/protocolos/', {
      params: {
        especialidades: especialidadeId,
        ...params,
      },
    })
  ).data;
}

export default {
  list,
  listProtocolos,
};
