/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory } from 'react-router-dom';

import InputProtocoloExecutado from '../InputProtocoloExecutado';

type Props = Omit<
  React.ComponentProps<typeof InputProtocoloExecutado>,
  'onClick'
>;

const InputLinkProtocoloExecutado: React.FC<Props> = ({
  protocolo,
  ...props
}) => {
  const history = useHistory();

  const navigateTo = React.useCallback(() => {
    const params = new URLSearchParams({
      protocolo_nome: protocolo.nome,
      execucao_id: protocolo.id.toString(),
    });

    history.push(`/abrir-execucao/?${params.toString()}`);
  }, [history, protocolo.id, protocolo.nome]);

  return (
    <InputProtocoloExecutado
      protocolo={protocolo}
      onClick={navigateTo}
      {...props}
    />
  );
};

export default InputLinkProtocoloExecutado;
