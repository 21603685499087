/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ListRenderItem } from '@/components/FlatList';

import {
  ArtifactFormData,
  ArtifactFormModel,
  FormLoadingState,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import ArtifactFormProvider from '@/features/artifacts/providers/ArtifactFormProvider';
import { applyFormData } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';
import { useArtifactFormContext } from '@/features/artifacts/providers/ArtifactFormProvider.context';

import {
  ItemComplementarUIState,
  ItemUIDisplayMode,
  TipoItemComplementar,
} from '../../models';

import TipoArtefatoLabel from '../TipoArtefatoLabel';
import ItemComplementarBox from '../ItemComplementarBox';
import {
  ItemComplementarList,
  ItemComplementarGroupForm,
  ListContent,
  ListTitle,
} from './styles';

type RType = fhir4.FhirResource;
type Form = ArtifactFormModel;
type ItemFormCallback = (item: ItemComplementarUIState, index: number) => Form;

const isItemFormCallback = (
  item: Form | ItemFormCallback,
): item is ItemFormCallback => typeof item === 'function';

interface Props {
  tipo: TipoItemComplementar;
  items: ItemComplementarUIState[];
  itemForm: Form | ItemFormCallback;
  groupForm?: Form;
  groupFormLoading?: FormLoadingState;
  groupFormAutoFocus?: boolean;
  groupFormDefaultValues?: ArtifactFormData;
  style?: React.CSSProperties;
  className?: string;
  hasItemChangePermission?: boolean;
  onChangeItem?: (item: ItemComplementarUIState) => void;
  onChangeItemCancel?: (item: ItemComplementarUIState) => void;
  hasItemDeletePermission?: boolean;
  onDeleteItem?: (item: ItemComplementarUIState) => void;
  hasGroupChangePermission?: boolean;
  onGroupChange?: (
    data: ArtifactFormData,
    items: ItemComplementarUIState[],
  ) => void;
  onItemDisplayModeChange?: (
    item: ItemComplementarUIState,
    displayMode: ItemUIDisplayMode,
  ) => void;
}

const ItemComplementarGroup: React.FC<Props> = ({
  tipo,
  items,
  itemForm,
  groupForm,
  groupFormLoading = false,
  groupFormAutoFocus = false,
  groupFormDefaultValues = {},
  hasItemChangePermission = true,
  onChangeItem,
  onChangeItemCancel,
  hasItemDeletePermission = true,
  onDeleteItem,
  hasGroupChangePermission = true,
  onGroupChange,
  onItemDisplayModeChange,
  style,
  className,
}) => {
  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    setFocus,
  } = useArtifactFormContext();
  const watchValues = watch();

  const numberOfItems = React.useRef<number>(0);

  const [innerValues, setInnerValues] = React.useState<ArtifactFormData>(
    groupFormDefaultValues,
  );

  const isDirty = React.useMemo(() => {
    return JSON.stringify(watchValues) !== JSON.stringify(innerValues);
  }, [watchValues, innerValues]);

  const handleEditItem = React.useCallback(
    (item: ItemComplementarUIState, resource: RType) => {
      if (onChangeItem) {
        onChangeItem({
          ...item,
          resource,
        });
      }
    },
    [onChangeItem],
  );

  const handleDeleteItem = React.useCallback(
    (item: ItemComplementarUIState) => {
      if (onDeleteItem) {
        onDeleteItem(item);
      }
    },
    [onDeleteItem],
  );

  const handleGroupChange = React.useCallback(
    (data: ArtifactFormData) => {
      setInnerValues(data);

      if (groupForm && groupForm.length) {
        const submittedGroup = items.map(item => ({
          ...item,
          resource: applyFormData(groupForm, data, item.resource),
        }));

        if (onGroupChange) {
          onGroupChange(data, submittedGroup);
        }
      }
    },
    [groupForm, items, onGroupChange],
  );

  const handleGroupChangeCancel = React.useCallback(() => {
    reset(innerValues);
  }, [innerValues, reset]);

  const handleItemDisplayModeChange = React.useCallback(
    (item: ItemComplementarUIState, displayMode: ItemUIDisplayMode) => {
      if (onItemDisplayModeChange) {
        onItemDisplayModeChange(item, displayMode);
      }
    },
    [onItemDisplayModeChange],
  );

  const handleItemEditCancel = React.useCallback(
    (item: ItemComplementarUIState) => {
      if (onChangeItemCancel) {
        onChangeItemCancel(item);
      }
    },
    [onChangeItemCancel],
  );

  const ItemBox = React.useCallback(
    ({
      item: { itemState = {}, ...item },
      index,
    }: ListRenderItem<ItemComplementarUIState>) => {
      let renderItemForm = itemForm;
      if (isItemFormCallback(renderItemForm)) {
        renderItemForm = renderItemForm({ ...item, itemState }, index);
      }

      return (
        <ItemComplementarBox
          title={item.title}
          description={item.description}
          form={renderItemForm}
          resource={item.resource}
          displayMode={itemState.displayMode}
          loading={itemState.loading}
          canEdit={!isDirty && hasItemChangePermission}
          canDelete={!isDirty && hasItemDeletePermission}
          onEditCapture={resource => handleEditItem(item, resource)}
          onEditCancel={() => handleItemEditCancel(item)}
          onDelete={() => handleDeleteItem(item)}
          onDisplayModeChange={displayMode =>
            handleItemDisplayModeChange(item, displayMode)
          }
        />
      );
    },
    [
      handleDeleteItem,
      handleEditItem,
      handleItemDisplayModeChange,
      handleItemEditCancel,
      hasItemChangePermission,
      hasItemDeletePermission,
      isDirty,
      itemForm,
    ],
  );

  const ItemGroupForm = React.useMemo(() => {
    if (groupForm && groupForm.length) {
      return (
        <ItemComplementarGroupForm
          form={groupForm}
          onSubmit={handleSubmit(handleGroupChange)}
          onCancel={handleGroupChangeCancel}
          showCancelButton={isDirty || groupFormLoading === 'cancel'}
          showSubmitButton={
            (isDirty && hasGroupChangePermission) ||
            groupFormLoading === true ||
            groupFormLoading === 'confirm'
          }
          loading={groupFormLoading}
          disabled={!!groupFormLoading || !hasGroupChangePermission}
        />
      );
    }

    return <></>;
  }, [
    groupForm,
    groupFormLoading,
    handleGroupChange,
    handleGroupChangeCancel,
    handleSubmit,
    hasGroupChangePermission,
    isDirty,
  ]);

  React.useEffect(() => {
    if (groupForm && groupForm.length) {
      setInnerValues(groupFormDefaultValues);

      Object.entries(groupFormDefaultValues).forEach(([name, value]) =>
        setValue(name, value),
      );
    }
  }, [groupFormDefaultValues, groupForm, setValue]);

  React.useEffect(() => {
    const prevLength = numberOfItems.current;
    const hasGroupForm = groupForm && groupForm.length > 0;
    const wasFirstItemAdded = prevLength === 0 && items.length === 1;

    if (hasGroupForm && groupFormAutoFocus && wasFirstItemAdded) {
      setFocus(groupForm[0].name);
    }

    numberOfItems.current = items.length;
  }, [groupForm, groupFormAutoFocus, items.length, setFocus]);

  return (
    <ItemComplementarList
      style={style}
      className={className}
      data={items}
      keyExtractor={({ item }) => item.key}
      renderItem={ItemBox}
      ListContentContainerElement={ListContent}
      ListHeaderComponent={
        <ListTitle>
          <TipoArtefatoLabel tipo={tipo} />
        </ListTitle>
      }
      ListFooterComponent={ItemGroupForm}
    />
  );
};

const ItemComplementarGroupWrapper: React.FC<Props> = ({
  groupForm = [],
  ...props
}) => {
  return (
    <ArtifactFormProvider form={groupForm}>
      <ItemComplementarGroup groupForm={groupForm} {...props} />
    </ArtifactFormProvider>
  );
};

export default ItemComplementarGroupWrapper;
