import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type PerguntaContextData = [PerguntaState, PerguntaDispatchType];

interface PerguntaProviderProps {
  children: ReactNode;
}

const initialState: PerguntaState = {
  allowNullItems: [],
};

interface PerguntaState {
  allowNullItems: string[];
}

interface PerguntaDispatchType {
  dispatchAddAllowNullItem: (key: string) => void;
  dispatchRemoveAllowNullItem: (key: string) => void;
}

const PerguntaContext = createContext<PerguntaContextData>(
  {} as PerguntaContextData,
);

export const PerguntaProvider: React.FC<PerguntaProviderProps> = ({
  children,
}: PerguntaProviderProps) => {
  const [state, setState] = useState(initialState);

  const dispatchAddAllowNullItem = useCallback((variavel: string) => {
    setState(prev => {
      return { ...prev, allowNullItems: [...prev.allowNullItems, variavel] };
    });
  }, []);

  const dispatchRemoveAllowNullItem = useCallback((variavel: string) => {
    setState(prev => {
      if (prev.allowNullItems.includes(variavel)) {
        return {
          ...prev,
          allowNullItems: prev.allowNullItems.filter(item => item !== variavel),
        };
      }
      return prev;
    });
  }, []);

  return (
    <PerguntaContext.Provider
      value={[state, { dispatchAddAllowNullItem, dispatchRemoveAllowNullItem }]}
    >
      {children}
    </PerguntaContext.Provider>
  );
};

export function usePerguntaContext(): PerguntaContextData {
  return useContext(PerguntaContext);
}
