import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { PassoExecutado } from '@/models/PassoExecutado';
import React, { useMemo } from 'react';
import ListaOrientacoes from '../ListaOrientacoes';

import ListaEncaminhamentos from '../ListaEncaminhamentos';
import ListaExames from '../ListaExames';
import ListaFotoceuticos from '../ListaFotoceuticos';
import ListaMedicamentos from '../ListaMedicamentos';
import ListaProcedimentos from '../ListaProcedimentos';
import { Container } from './styles';

interface BoxSolicitacoesProps {
  passoExecutado: PassoExecutado;
  solicitacoes: FhirArtefatoType[];
  integracaoEnterpriseAtiva: boolean;
}

const BoxSolicitacoes: React.FC<BoxSolicitacoesProps> = ({
  passoExecutado,
  solicitacoes,
  integracaoEnterpriseAtiva,
}: BoxSolicitacoesProps) => {
  const orientacoesMedicas = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'orientacoes-medicas'),
    [solicitacoes],
  );
  const medicamentos = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'prescricao-medica'),
    [solicitacoes],
  );
  const encaminhamentos = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'encaminhamento'),
    [solicitacoes],
  );
  const exames = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'solicitacao-exame'),
    [solicitacoes],
  );
  const procedimentos = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'procedimento'),
    [solicitacoes],
  );
  const fotoceuticos = useMemo(
    () => solicitacoes.filter(sol => sol.tipo === 'fotoceutico'),
    [solicitacoes],
  );

  return (
    <Container>
      {orientacoesMedicas.length > 0 && (
        <ListaOrientacoes
          orientacoes={orientacoesMedicas}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
      {medicamentos.length > 0 && (
        <ListaMedicamentos
          medicamentos={medicamentos}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
      {encaminhamentos.length > 0 && (
        <ListaEncaminhamentos
          encaminhamentos={encaminhamentos}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
      {exames.length > 0 && (
        <ListaExames
          exames={exames}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
      {procedimentos.length > 0 && (
        <ListaProcedimentos
          procedimentos={procedimentos}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
      {fotoceuticos.length > 0 && (
        <ListaFotoceuticos
          fotoceuticos={fotoceuticos}
          passoExecutado={passoExecutado}
          integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
        />
      )}
    </Container>
  );
};

export default BoxSolicitacoes;
