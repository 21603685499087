import styled, { css } from 'styled-components';

export const Menu = styled.div`
  position: relative;
`;

interface MenuStyledProps {
  alignment: 'left' | 'right';
}

function menuAligmentCSS({ alignment }: MenuStyledProps) {
  if (alignment === 'left') {
    return css`
      left: 0;
    `;
  }

  if (alignment === 'right') {
    return css`
      right: 0;
    `;
  }

  return css``;
}

export const DropdownContainer = styled.div<MenuStyledProps>`
  position: absolute;
  z-index: 1000;
  margin-top: 4px;

  ${menuAligmentCSS};
`;
