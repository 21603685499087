import React from 'react';
import { useFeatures } from '../../providers/FeaturesProvider';

// import { Container } from './styles';

interface FeatureProps extends React.PropsWithChildren {
  name: string;
  fallback?: React.ReactElement;
}

const Feature: React.FC<FeatureProps> = ({ name, children, fallback }) => {
  const { hasFeature } = useFeatures();

  const displayElement = React.useMemo(() => {
    if (hasFeature(name)) {
      return children;
    }

    return fallback;
  }, [children, fallback, hasFeature, name]);

  if (displayElement) {
    return <>{displayElement}</>;
  }

  return <></>;
};

export default Feature;
