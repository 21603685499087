/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IMaskMixin, IMask } from 'react-imask';

import InputField, { InputFieldProps } from '@/components/InputField';

import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../../types';

const MaskedInputField = IMaskMixin<
  IMask.AnyMaskedOptions,
  false,
  string,
  HTMLInputElement,
  InputFieldProps
>(({ inputRef, ...props }) => (
  <InputField
    {...props}
    ref={inputRef as React.RefCallback<HTMLInputElement>}
  />
));

const MaskInputString: InputPergunta = ({
  pergunta,
  disabled,
  nullable,
  onChange,
}: InputPerguntaProps) => {
  const {
    nome,
    meta: {
      placeholder = '',
      mask = '',
      max_length,
      min_length,
      pattern_message = 'Insira um valor válido.',
    },
  } = pergunta.variavel;

  const [masked, setMasked] = React.useState(false);
  const [error, setError] = React.useState('');
  const [value, setValue] = useAutoUpdateInput(pergunta);

  const displayError = React.useCallback(() => {
    if (!masked) {
      setError(pattern_message);
    } else {
      setError('');
    }
  }, [masked, pattern_message]);

  const handleMaskValueChange = React.useCallback(
    (val: string) => {
      setValue(val);

      // Clear error during typing
      setError('');
      setMasked(false);

      if (onChange) {
        onChange({ [nome]: null });
      }
    },
    [setValue, onChange, nome],
  );

  const handleMaskCompletelyFilled = React.useCallback(
    (val: string) => {
      setMasked(true);

      if (onChange) {
        onChange({ [nome]: val });
      }
    },
    [onChange, nome],
  );

  React.useEffect(() => {
    if (onChange && typeof nullable === 'boolean') {
      onChange({ [nome]: nullable ? null : '' });
      setValue('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullable]);

  return (
    <MaskedInputField
      // input props
      type="text"
      placeholder={placeholder}
      maxLength={max_length}
      minLength={min_length}
      disabled={disabled}
      value={value}
      onBlur={displayError}
      style={{
        width: 400,
      }}
      // IMaskInput props
      mask={mask}
      onAccept={handleMaskValueChange}
      onComplete={handleMaskCompletelyFilled}
      // InputField props
      error={error}
    />
  );
};

export default MaskInputString;
