import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import styled from 'styled-components';

export const MainContent = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const TextContent = styled.div`
  margin-left: 8px;

  color: ${colors.blackAlpha100};
  ${HeadingSmall(12)};
`;
