/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useState } from 'react';

import Button from '@/components/Button';

import api from '@/sevices/api';
import { Container, Title, Info } from './styles';

interface ResetPasswordSentProps {
  email: string;
}

const ResetPasswordSent: React.FC<ResetPasswordSentProps> = ({ email }) => {
  const [loading, setLoading] = useState(false);

  const handleResentEmail = useCallback(async () => {
    try {
      setLoading(true);
      await api.post('/auth/password/reset/link/', { email });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [email]);

  return (
    <Container>
      <Title>Por favor, confira seu e-mail.</Title>
      <Info>
        Enviamos um e-mail para <strong>{email}</strong>, contendo intruções de
        redefinição da senha. Confira também a pasta de spam caso não encontre o
        e-mail na caixa de entrada.
      </Info>

      <Button theme="secondary" onClick={handleResentEmail} loading={loading}>
        Enviar e-mail novamente
      </Button>
    </Container>
  );
};

export default ResetPasswordSent;
