import React from 'react';

import Alert from '@/assets/feedbacks/alert.svg';
import Arrival from '@/assets/feedbacks/arrival.svg';
import Check from '@/assets/feedbacks/check.svg';
import EmptyBox from '@/assets/feedbacks/empty-box.svg';
import Key from '@/assets/feedbacks/key.svg';
import Out from '@/assets/feedbacks/out.svg';
import Search from '@/assets/feedbacks/search.svg';

import { Container } from './styles';

type FeedbackGraphicType =
  | 'alert'
  | 'arrival'
  | 'check'
  | 'empty-box'
  | 'key'
  | 'out'
  | 'search';

const FeedbackGraphicImage: Record<FeedbackGraphicType, string> = {
  alert: Alert,
  arrival: Arrival,
  check: Check,
  'empty-box': EmptyBox,
  key: Key,
  out: Out,
  search: Search,
};

interface Props {
  graphic: FeedbackGraphicType;
  title: string;
  subtitle?: string;
}

export const FeedbackGraphic: React.FC<Props> = ({
  graphic,
  title,
  subtitle,
}) => {
  return (
    <Container>
      <img src={FeedbackGraphicImage[graphic]} alt={graphic} />

      <h3>{title}</h3>

      {subtitle && <p>{subtitle}</p>}
    </Container>
  );
};

export default FeedbackGraphic;
