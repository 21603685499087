import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  padding: 18px 24px 16px;
  border-bottom: 1px solid ${colors.gray100};

  color: ${colors.blackAlpha200};
`;

export const ModalTitle = styled.div`
  ${HeadingSmall(24)};

  flex: 1;
`;

export const ModalClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
`;
