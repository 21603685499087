import styled from 'styled-components';

import ModalLayout from '@/layouts/ModalLayout';
import colors from '@/styles/colors';

export const ModalDialogContainer = styled(ModalLayout)`
  width: 480px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: ${colors.black};
    font-weight: 600;
    font-size: 18px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 36px;
  }

  button {
    width: 300px;

    + button {
      margin-top: 16px;
    }
  }
`;
