import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { RenderableElement } from '@/utils/elements';

import {
  BoxList,
  LoadingItem,
  EmptyItem,
  ResultList,
  ResultListItem,
  Fade,
} from './styles';

export interface SearchResultItem {
  value: string;
  label: string;
}

export interface SearchResultsProps {
  results?: SearchResultItem[];
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  ResultsFooter?: RenderableElement;
  activeIndex?: number;
  onResultItemClick?: (resultItem: SearchResultItem) => void;
  onResultItemHover?: (
    resultIndex: number,
    resultItem: SearchResultItem,
  ) => void;
}

const SearchResultBox: React.FC<SearchResultsProps> = ({
  results,
  loading = false,
  className,
  style,
  ResultsFooter,
  activeIndex,
  onResultItemClick,
  onResultItemHover,
}) => {
  const resultsRef = React.useRef<(HTMLLIElement | null)[]>([]);

  const handleItemClick = React.useCallback(
    (item: SearchResultItem) => {
      if (onResultItemClick) {
        onResultItemClick(item);
      }
    },
    [onResultItemClick],
  );

  const handleItemMouseEnter = React.useCallback(
    (item: SearchResultItem, index: number) => {
      if (onResultItemHover) {
        onResultItemHover(index, item);
      }
    },
    [onResultItemHover],
  );

  const isHighlightedResult = React.useCallback(
    (resultIndex: number) => {
      if (activeIndex !== undefined) {
        return activeIndex === resultIndex;
      }

      return activeIndex;
    },
    [activeIndex],
  );

  const showResults = React.useMemo(() => loading || results !== undefined, [
    loading,
    results,
  ]);

  React.useEffect(() => {
    if (activeIndex !== undefined && resultsRef.current) {
      const itemRef = resultsRef.current[activeIndex];
      if (itemRef) {
        itemRef.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [activeIndex]);

  return (
    <CSSTransition
      in={showResults}
      classNames="fade"
      timeout={150}
      unmountOnExit
    >
      <Fade>
        <BoxList
          className={className}
          style={style}
          data={results || []}
          ListContentContainerElement={ResultList}
          keyExtractor={({ item }) => item.value}
          renderItem={({ item, index }) => (
            <ResultListItem
              highlighted={isHighlightedResult(index)}
              ref={elem => {
                resultsRef.current[index] = elem;
              }}
            >
              <button
                type="button"
                onMouseEnter={() => handleItemMouseEnter(item, index)}
                onMouseDown={event => {
                  event.preventDefault();
                  handleItemClick(item);
                }}
              >
                {item.label}
              </button>
            </ResultListItem>
          )}
          ListHeaderComponent={() =>
            loading ? <LoadingItem>Buscando...</LoadingItem> : <></>
          }
          ListEmptyComponent={() =>
            !loading ? (
              <EmptyItem>Nenhum resultado encontrado</EmptyItem>
            ) : (
              <></>
            )
          }
          ListFooterComponent={ResultsFooter}
        />
      </Fade>
    </CSSTransition>
  );
};

export default SearchResultBox;
