import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { Content } from './styles';

interface Props {
  loading: boolean;
  children: React.ReactNode;
  LoadingComponent: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  unmountOnExit?: boolean;
}

const LoadingSwitchTransition: React.FC<Props> = ({
  loading = true,
  unmountOnExit = true,
  children,
  LoadingComponent,
  style,
  className,
}) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={loading ? 'loading' : 'idle'}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        unmountOnExit={unmountOnExit}
      >
        <Content style={style} className={className}>
          {loading ? LoadingComponent : children}
        </Content>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default LoadingSwitchTransition;
