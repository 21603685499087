/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';

import Modal from '@/layouts/ModalLayout';
import { ModalProps } from '@/components/Modal';

import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSent from './ResetPasswordSent';
import { CloseButton } from './styles';

type ResetPasswordModalProps = Omit<ModalProps, 'children' | 'onAfterOpen'>;

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  onAfterClose,
  ...modalProps
}) => {
  const [sentToEmail, setSentToEmail] = useState('');

  const resetState = () => {
    setSentToEmail('');
  };

  const Component = useMemo(() => {
    if (sentToEmail) {
      return <ResetPasswordSent email={sentToEmail} />;
    }

    return (
      <ResetPasswordForm onSuccess={({ email }) => setSentToEmail(email)} />
    );
  }, [sentToEmail]);

  return (
    <Modal
      title="Redefinir senha"
      onAfterOpen={resetState}
      onAfterClose={onAfterClose}
      {...modalProps}
    >
      <>
        {Component}
        <CloseButton theme="tertiary" onClick={onAfterClose}>
          Voltar para o login
        </CloseButton>
      </>
    </Modal>
  );
};

export default ResetPasswordModal;
