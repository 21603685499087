import React, { useCallback, useState } from 'react';
import { wysiwygPreset } from 'remirror/extensions';
import {
  EditorComponent,
  OnChangeHTML,
  Remirror,
  useHelpers,
  useRemirror,
} from '@remirror/react';

import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import { BottomToolbar } from './Toolbar';

import { ReactEditorProps } from '../../features/recursos/encaminhamentos/types';

import {
  ButtonContainer,
  Container,
  EditorContent,
  EditorFooter,
  FooterButtons,
  InfoContainerIcon,
} from './styles';

export type WysiwygEditorProps = Partial<ReactEditorProps>;

interface MedflowEditorType {
  content: string;
  onChange: (html: string) => void;
  onCancelClick?: () => void;
  onConfirmClick?: (texto: string) => void;
}

const MedflowEditor = ({
  content,
  onChange,
  onCancelClick,
  onConfirmClick,
}: MedflowEditorType): JSX.Element => {
  const extensions = useCallback(() => [...wysiwygPreset()], []);

  const { manager, state } = useRemirror({
    extensions,
    content,
    stringHandler: 'html',
  });

  const Buttons = (): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const { getHTML } = useHelpers();

    const onCancel = useCallback(() => {
      if (onCancelClick) {
        onCancelClick();
      }
    }, []);

    const onConfirm = useCallback(() => {
      if (onConfirmClick) {
        setLoading(true);
        onConfirmClick(getHTML());
      }
    }, [getHTML]);

    return (
      <FooterButtons>
        <Tooltip
          title="As alterações ficarão salvas somente para este atendimento."
          placement="left"
          maxWidth="none"
        >
          <ButtonContainer>
            <InfoContainerIcon />
          </ButtonContainer>
        </Tooltip>

        <Button onClick={onCancel} theme="secondary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} loading={loading}>
          Confirmar
        </Button>
      </FooterButtons>
    );
  };

  return (
    <Container
      onClick={(event: React.MouseEvent<Element>) => {
        event.stopPropagation();
      }}
    >
      <Remirror manager={manager} initialContent={state} autoFocus="end">
        <EditorContent>
          <EditorComponent />
          <OnChangeHTML onChange={onChange} />

          <EditorFooter>
            <BottomToolbar />
            <Buttons />
          </EditorFooter>
        </EditorContent>
      </Remirror>
    </Container>
  );
};

export default MedflowEditor;
