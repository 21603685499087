import fhirpath from 'fhirpath';
import { DeviceDefinitionPropertyCode } from './device';

export function serviceAdditionalDevicesWithNoStatusReason(
  serviceRequest: fhir4.ServiceRequest,
): fhir4.Device[] {
  return fhirpath.evaluate(
    serviceRequest,
    `
    contained.where(
      resourceType = 'Device'
      and property.where(
        type.coding.system = '${DeviceDefinitionPropertyCode}'
        and type.coding.code = 'inclusion-status' 
        and valueCode.coding.code = 'additional'
      ).exists() 
      and property.where(
        type.coding.system = '${DeviceDefinitionPropertyCode}'
        and type.coding.code = 'inclusion-status-reason'
      ).empty()
    )
`,
  );
}

export function serviceUpdateDevice(
  serviceRequest: fhir4.ServiceRequest,
  updatedDevice: fhir4.Device,
): fhir4.ServiceRequest {
  const { contained = [], ...rest } = serviceRequest;

  return {
    ...rest,
    contained: contained.map(currentDevice => {
      if (currentDevice.id === updatedDevice.id) {
        return updatedDevice;
      }

      return currentDevice;
    }),
  };
}
