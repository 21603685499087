import PlaceholderLoading from '@/components/PlaceholderLoading';
import React from 'react';

// import { Container } from './styles';

const PlaceholderGuidelineStep: React.FC = () => {
  return (
    <PlaceholderLoading.Rect
      height={513}
      width="100%"
      style={{
        display: 'flex',
        borderRadius: 8,
      }}
    />
  );
};

export default PlaceholderGuidelineStep;
