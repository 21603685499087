import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@/components/Button';
import FormGroup from '@/components/FormGroup';
import { ButtonLink } from '@/components/Link';
import ErrorMessage from '@/components/ErrorMessage';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import AuthLayout from '../../containers/AuthLayout';
import ResetPasswordModal from '../../components/ResetPasswordModal';
import { SignInOptions } from './styles';

interface RouteState {
  next?: string;
}

interface FormState {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { login, status } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    email: '',
    password: '',
  });
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  useEffect(() => setError(''), [formState]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Prevents React from resetting its properties:
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    },
    [],
  );

  const handleSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const { next } = (location.state || {}) as RouteState;

      try {
        setError('');
        setLoading(true);

        await login({ provider: 'email', payload: formState });

        // Lógica pra retomar a última URL após o usuário perder o acesso
        // Só deve ser executada se o usuário realmente perdeu acesso a tela, ou
        // seja, não realizou o logout manualmente (status 'cancelled')
        if (next && status !== 'cancelled') {
          history.replace(next);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
          setLoading(false);
        }
      }
    },
    [location.state, login, formState, status, history],
  );

  const canLogin = useMemo(() => {
    return formState.email && formState.password;
  }, [formState]);

  return (
    <AuthLayout title="Acesse sua conta">
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onAfterClose={() => setResetPasswordModalOpen(false)}
      />

      <form onSubmit={handleSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}

        <FormGroup
          label="E-mail"
          id="email"
          name="email"
          onChange={handleInputChange}
        />

        <FormGroup
          label="Senha"
          name="password"
          type="password"
          onChange={handleInputChange}
        />

        <SignInOptions>
          <ButtonLink onClick={() => setResetPasswordModalOpen(true)}>
            Esqueceu a senha?
          </ButtonLink>
        </SignInOptions>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={!canLogin}
          loading={loading}
        >
          Entrar
        </Button>
      </form>
    </AuthLayout>
  );
};

export default SignIn;
