/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import ArtifactFormProvider, {
  ArtifactFormConsumer,
} from '../../providers/ArtifactFormProvider';
import ArtifactBaseForm from '../ArtifactBaseForm';
import {
  ArtifactFormModel,
  FormLoadingState,
  ResourceSubmitErrorHandler,
  ResourceSubmitHandler,
} from './ArtifactForm.types';

type RType = fhir4.FhirResource;

interface Props {
  form: ArtifactFormModel;
  resource: RType;
  disabled?: boolean;
  enabledWhenDirty?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  onResourceSubmit?: ResourceSubmitHandler;
  onResourceSubmitError?: ResourceSubmitErrorHandler;
  onResourceSubmitCancel?: ResourceSubmitHandler;
}

export interface ArtifactFormComponentProps {
  resource: RType;
  disabled?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  onSubmit: ResourceSubmitHandler;
  onSubmitError?: ResourceSubmitErrorHandler;
  onCancel: ResourceSubmitHandler;
}

function ArtifactForm({
  form,
  resource,
  loading = false,
  disabled = false,
  readOnly = false,
  enabledWhenDirty = false,
  onResourceSubmit,
  onResourceSubmitError,
  onResourceSubmitCancel,
}: Props): React.ReactElement<Props> {
  const onCancel = React.useCallback(() => {
    if (onResourceSubmitCancel) {
      onResourceSubmitCancel(resource);
    }
  }, [onResourceSubmitCancel, resource]);

  return (
    <ArtifactFormProvider form={form} resource={resource}>
      <ArtifactFormConsumer>
        {({ handleResourceSubmit }) => (
          <ArtifactBaseForm
            form={form}
            onCancel={onCancel}
            onSubmit={
              onResourceSubmit &&
              handleResourceSubmit(onResourceSubmit, onResourceSubmitError)
            }
            loading={loading}
            disabled={disabled}
            readOnly={readOnly}
            enabledWhenDirty={enabledWhenDirty}
          />
        )}
      </ArtifactFormConsumer>
    </ArtifactFormProvider>
  );
}

export default ArtifactForm;
