import React from 'react';

import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';
import { RenderableElement, enforceElement } from '@/utils/elements';
import { Bar, Content } from './styles';

interface Props {
  protocoloExecutado: ProtocoloExecutado;
  helperInfo?: string;
  Right?: RenderableElement;
}

const HeaderBarProtocoloExecutado: React.FC<Props> = ({
  protocoloExecutado,
  helperInfo,
  Right,
}) => {
  const label = React.useMemo(() => {
    const items = protocoloExecutado.tags.map(tag => tag.valor);
    if (helperInfo) {
      items.push(helperInfo);
    }

    return items.join(' | ');
  }, [helperInfo, protocoloExecutado.tags]);

  return (
    <Bar>
      <Content>
        {label}
        {enforceElement(Right)}
      </Content>
    </Bar>
  );
};

export default HeaderBarProtocoloExecutado;
