import {
  ArtifactFormModel,
  ArtifactFormType,
} from '../components/ArtifactForm/ArtifactForm.types';

import ExameForm from './Exame';
import MedicamentoForm from './Medicamento';
import EncaminhamentoForm from './Encaminhamento';
import ProcedimentoForm from './Procedimento';

const FormModel: Record<ArtifactFormType, ArtifactFormModel> = {
  encaminhamento: EncaminhamentoForm,
  'prescricao-medica': MedicamentoForm,
  'solicitacao-exame': ExameForm,
  procedimento: ProcedimentoForm,
};

export function getForm(formType: ArtifactFormType): ArtifactFormModel {
  const form = FormModel[formType];

  if (form === undefined) {
    throw new Error(
      `Não existe uma configuração de formulário disponível para o tipo "${formType}".`,
    );
  }

  return form;
}

export default FormModel;
