import React, { HTMLAttributes } from 'react';

import { Container } from './styles';

type ModalBodyProps = HTMLAttributes<HTMLDivElement>;

const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};

export default ModalBody;
