import React from 'react';
import fhirpath from 'fhirpath';

import { buildForm } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';
import ManipuladoForm from '@/features/artifacts/forms/Manipulado';
import { medicationReferenceFHIRPath } from '@/features/fhir/helpers/medicationRequest';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import { ItemComplementarState, ItemComplementarUIState } from '../../models';
import { itemToUIState } from '../../helpers/parsers';
import FormDefaults from '../../models/FormDefaults';

import PermissaoReceitaCheckbox from '../PermissaoReceitaCheckbox';
import ItemComplementarGroup from '../ItemComplementarGroup';
import { Container } from './styles';

const itemForm = buildForm(ManipuladoForm, FormDefaults.MedicamentoManipulado);

const getTitle = (item: ItemComplementarState) => {
  const name = fhirpath.evaluate(
    item.resource,
    `${medicationReferenceFHIRPath(
      item.resource as fhir4.MedicationRequest,
    )}.code.select(text | coding.display)`,
  )[0];

  return name;
};

const getDescription = (item: ItemComplementarState) => {
  const ingredient = fhirpath.evaluate(
    item.resource,
    `${medicationReferenceFHIRPath(item.resource as fhir4.MedicationRequest)}
        .ingredient
        .itemCodeableConcept
        .select((text | coding.display).first())`,
  );

  if (ingredient.length) {
    return ingredient.join(' + ');
  }

  return '';
};

const PrescricoesLivreManipulado: React.FC = () => {
  const {
    mode,
    getItemsByType,
    getItemById,
    deleteItem,
    updateItem,
    updateItemState,
  } = useCondutaComplementarContext();

  const medicamentos = React.useMemo(() => {
    const items = getItemsByType('manipulado');

    return items.map<ItemComplementarUIState>(item =>
      itemToUIState(item, {
        title: getTitle(item),
        description: getDescription(item),
      }),
    );
  }, [getItemsByType]);

  const canEditOrDelete = React.useMemo(() => mode === 'active', [mode]);

  const handleUpdate = React.useCallback(
    ({ key: updatingID, resource }: ItemComplementarUIState) => {
      const { itemState, type = 'manipulado' } = getItemById(updatingID) || {};

      updateItem({
        id: updatingID,
        type,
        resource,
        itemState,
      });
    },
    [getItemById, updateItem],
  );

  const handleItemEditCancel = React.useCallback(
    ({ key: id }: ItemComplementarUIState) => {
      const item = getItemById(id);

      // Manipulados adicionados via frontend (ao invés de carregados da API)
      // devem ser excluídos se o usuário cancelar sua primeira edição
      if (item?.itemState?.isAdding) {
        deleteItem({ id });
      }
    },
    [deleteItem, getItemById],
  );

  if (medicamentos.length > 0) {
    return (
      <Container>
        <ItemComplementarGroup
          tipo="manipulado"
          itemForm={itemForm}
          items={medicamentos}
          onChangeItemCancel={handleItemEditCancel}
          onChangeItem={handleUpdate}
          onDeleteItem={({ key: id }) => deleteItem({ id })}
          onItemDisplayModeChange={(item, display) =>
            updateItemState({ id: item.key }, { isEditing: display === 'form' })
          }
          hasItemChangePermission={canEditOrDelete}
          hasItemDeletePermission={canEditOrDelete}
        />

        <PermissaoReceitaCheckbox disabled={!canEditOrDelete} />
      </Container>
    );
  }

  return <></>;
};

export default PrescricoesLivreManipulado;
