import styled from 'styled-components';

const ButtonArea = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 26px;
  min-height: 26px;
  transition: all 100ms linear;

  :disabled {
    cursor: not-allowed;
  }
`;

export default ButtonArea;
