import styled from 'styled-components';
import colors from '@/styles/colors';

export const ResultadoContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 26px;

  margin-top: 16px;

  width: 100%;
`;

export const ResultadoTitle = styled.h1`
  color: ${colors.black};
  font-size: 20px;
  font-weight: 600;
`;

export const ExpressaoContainer = styled.div`
  width: 100%;
  margin: 16px 0px;
`;

export const ExpressaoName = styled.span`
  color: ${colors.black};
  font-weight: 600;
  font-size: 14px;

  display: block;
  margin-bottom: 9px;
`;

export const ExpressaoValue = styled.div`
  background-color: #f0f2f5;
  padding: 18px 16px;

  font-size: 16px;
  font-weight: 600;
  color: #2f3139;
  border-radius: 8px;
`;

export const ResultadoActions = styled.div`
  border-top: 1px solid ${colors.secondaryLite};
  width: 100%;
  padding-top: 16px;

  display: flex;
  justify-content: flex-end;
`;

export const ResultadoAction = styled.div`
  &:not(:last-child) {
    margin-right: 24px;
  }
`;
