import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const DadosContainer = styled.div`
  position: relative;
`;

export const DadosContainerTitle = styled.div`
  ${HeadingSmall(16)}
  margin-bottom: 8px;
`;

export const DadosBox = styled.div`
  background: ${colors.gray50};
  padding: 16px;
  border-radius: 10px;
`;

export const DadosBoxContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DadosImage = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const DadosInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DadosInfoItem = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const DadosInfoTitle = styled.span`
  ${HeadingSmall(14)}
`;

export const DadosInfoValue = styled.span`
  ${Text(14)};
  color: ${colors.gray300};
`;
