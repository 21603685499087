import React, { useCallback } from 'react';

import { preventNumberInputChangeOnWheel } from '@/utils/events';
import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../types';
import { NumberInput } from './styles';

const InputNumerico: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const {
    nome,
    unidade,
    meta: { placeholder = 'Insira' },
  } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValueString = event.target.value;

      setValue(inputValueString);

      if (onChange) {
        onChange({
          [nome]: inputValueString ? +inputValueString : null,
        });
      }
    },
    [setValue, onChange, nome],
  );

  return (
    <NumberInput
      style={{ width: 240 }}
      placeholder={placeholder}
      value={value}
      addon={unidade}
      disabled={disabled}
      onChange={handleChange}
      onWheelCapture={preventNumberInputChangeOnWheel}
    />
  );
};

export default InputNumerico;
