import styled from 'styled-components';
import colors from '@/styles/colors';
import { FiAlertCircle } from 'react-icons/fi';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.secondaryLite};
  padding: 24px 0;
  min-height: 70px;
  position: relative;

  padding-left: 16px;
  padding-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 300ms ease;
  color: ${props => (props.highlight ? colors.black : colors.hardGray)};
`;

export const HeaderSubTitle = styled.h3<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-self: left;
  color: ${colors.hardGray};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  height: 32px;
  margin: 12px 0;
  padding: 8px;

  background: #ecf1ff;
  /* Purple 100 */

  border: 1px solid #4676fa;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const InfoContainerIcon = styled(FiAlertCircle).attrs({
  color: '#4676fa',
})``;

export const InfoContainerText = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  /* Purple 100 */

  color: #4676fa;

  /* Inside Auto Layout */
  align-self: center;
  flex-grow: 1;
  margin: 0px 8px;
`;

export const Actions = styled.div`
  display: flex;
`;
