import React, { HTMLAttributes, useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import ModalContext from '../ModalContext';

import { Container, CloseButton } from './styles';

interface ModalHeaderProps extends HTMLAttributes<HTMLDivElement> {
  closeButton?: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  closeButton,
  children,
  className,
  style,
}) => {
  const context = React.useContext(ModalContext);

  const handleClose = useCallback(() => {
    context.onClose();
  }, [context]);

  return (
    <Container className={className} style={style}>
      {children}
      {closeButton && (
        <CloseButton onClick={handleClose}>
          <FiX size="25" />
        </CloseButton>
      )}
    </Container>
  );
};

export default ModalHeader;
