/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Button } from './styles';

type ButonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonExtra = React.forwardRef<HTMLButtonElement, ButonProps>(
  (props, ref) => {
    return <Button {...props} ref={ref} />;
  },
);

export default ButtonExtra;
