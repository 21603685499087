import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';
import styled from 'styled-components';

interface StyledProps {
  activeClassName: string;
}

export const MenuItemClickable = styled.a<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  padding: 14px 18px 14px 24px;
  cursor: pointer;

  width: 100%;

  ${Text(16)};
  background-color: ${colors.white};
  color: ${colors.blackAlpha100};

  &.${props => props.activeClassName} {
    ${HeadingSmall(16)};
    border-right: 4px solid ${colors.purple100};
    background-color: ${colors.purple50};
    color: ${colors.purple100};
  }

  &:hover:not(:disabled) {
    color: ${colors.purple100};
  }
`;
