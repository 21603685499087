import colors from '@/design/colors';
import { HeadingLarge, HeadingSmall, Text } from '@/design/typography';
import { ArtifactFieldLayout } from '@/features/artifacts/components/ArtifactForm/styles';
import styled, { css } from 'styled-components';

export const Box = styled.div`
  border-radius: 8px;
  padding: 16px;

  background-color: ${colors.gray50};
  border: 1px solid ${colors.gray100};
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

export const Heading = styled.div<{ collapsed: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 8px;

  h1 {
    color: ${colors.blackAlpha100};
    ${HeadingSmall(14)};
  }

  p {
    padding: 4px 0;

    ${Text(14)};
    color: ${colors.gray300};

    ${({ collapsed }) =>
      !collapsed &&
      css`
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      `}
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  h1 {
    color: ${colors.blackAlpha200};
    ${HeadingLarge(18)};
  }

  ${ArtifactFieldLayout} {
    flex: 0 1 calc(100%);
  }
`;
