import { User } from '@/models/User';
import api from '@/sevices/api';

async function loadUser(): Promise<User | null> {
  try {
    return (await api.get<User>('/auth/user/')).data;
  } catch (err) {
    return null;
  }
}

function isAuthenticated(user: User | null): boolean {
  return !!user;
}

function isAuthenticatedForEnterpriseUse(user: User | null): boolean {
  return !!(user && user.empresa);
}

export default {
  loadUser,
  isAuthenticated,
  isAuthenticatedForEnterpriseUse,
};
