import Card from '@/components/Card';
import InputField from '@/components/InputField';
import { HeadingSmall } from '@/design/typography';
import styled from 'styled-components';

export const Container = styled(Card)`
  h2 {
    ${HeadingSmall(18)};
    margin-bottom: 22px;
  }
`;

export const TagForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 16px;
`;

export const TagField = styled(InputField)`
  min-width: 317px;
`;
