import React from 'react';

import {
  ArtifactFieldOverrides,
  ArtifactFormModel,
  ArtifactFormOverrides,
  ArtifactFormType,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { buildForm } from '../components/ArtifactForm/ArtifactForm.helpers';
import { getForm } from '../forms';

export function useArtifactFormConfig(
  formType: ArtifactFormType,
  overrides: ArtifactFormOverrides,
): ArtifactFormModel {
  const { user } = useAuth();

  const modelForm = React.useMemo(() => getForm(formType), [formType]);

  const config = React.useMemo(() => {
    let fields = overrides;

    if (user && user.empresa) {
      const form = user.empresa.forms.find(
        ({ artifact_type }) => artifact_type === formType,
      );

      if (form) {
        fields = form.fields.map<ArtifactFieldOverrides>(
          ({ fieldname: name, label, readonly, required }) => ({
            name,
            label,
            readOnly: readonly,
            required,
          }),
        );
      }
    }

    return buildForm(modelForm, fields);
  }, [formType, overrides, modelForm, user]);

  return config;
}
