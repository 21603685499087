import React from 'react';
import { toast, Slide } from 'react-toastify';

import Toast from '@/components/Toast';
import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

export function notifyFinalizacao(protocolo: ProtocoloExecutado): void {
  toast.dark(
    () => (
      <Toast>
        <p>
          Você finalizou o protocolo de <strong>{protocolo.nome}</strong>
        </p>
      </Toast>
    ),
    {
      closeButton: false,
      transition: Slide,
      position: 'bottom-center',
    },
  );
}
