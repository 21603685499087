import styled from 'styled-components';

import colors from '@/design/colors';

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  column-gap: 16px;
`;

export const ImageBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: ${colors.purple50};

  width: 48px;
  height: 48px;

  > img {
    width: 32px;
    height: 32px;
  }
`;
