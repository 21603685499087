import { WheelEvent } from 'react';

type NumberInputWheelEvent = WheelEvent<HTMLInputElement> & {
  target: HTMLInputElement;
};
export function preventNumberInputChangeOnWheel(
  event: NumberInputWheelEvent,
): void {
  // Prevent the input value change
  event.target.blur();

  // Prevent the page/container scrolling
  event.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    event.target.focus({ preventScroll: true });
  }, 0);
}
