import React from 'react';
import { FiMaximize2, FiMinimize2, FiMinus, FiPlus } from 'react-icons/fi';
import {
  FullScreen,
  FullScreenProps,
  useFullScreenHandle,
} from 'react-full-screen';
import { isSafari } from 'react-device-detect';

import Tooltip from '@/components/Tooltip';
import Modal, { ModalLayoutProps } from '@/layouts/ModalLayout';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import ProtocoloFlowchart from '@/features/execucao/services/ProtocoloFlowchart';

import MedflowLogo from '@/components/Logo/MedflowLogo';
import {
  Container,
  Info,
  GoToPassoCorrenteButton,
  FlowchartContainer,
  MermaidContainer,
  ControlPanel,
  ControlButton,
  ZoomPercentage,
  ControlDivider,
  FullScreenInfo,
  FullScreenHeader,
  FullScreenHeaderDivider,
  FullScreenLogo,
  FullScreenTitle,
  FullScreenInfoProtocolo,
  FullScreenExitAlert,
} from './styles';

ProtocoloFlowchart.initialize();

// Work around in order to use this library with React 17+
// https://github.com/snakesilk/react-fullscreen/issues/85
const FullScreenWithChildren = FullScreen as React.FC<
  React.PropsWithChildren<FullScreenProps>
>;

interface Props
  extends Omit<ModalLayoutProps, 'title' | 'style' | 'onAfterOpen'> {
  content: string;
}

const ModalFluxograma: React.FC<Props> = ({ content = '', ...modalProps }) => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const mermaidRef = React.useRef<HTMLDivElement>(null);
  const [zoomPercentage, setZoomPercentage] = React.useState(100);

  const [
    protocoloFlowchart,
    setProtocoloFlowchart,
  ] = React.useState<ProtocoloFlowchart | null>(null);

  const fullScreen = useFullScreenHandle();
  const [
    showFullScreenExitMessage,
    setShowFullScreenExitMessage,
  ] = React.useState(false);

  /** Exibir full screen exit message caso o navegador não seja Safari */
  React.useEffect(() => {
    if (fullScreen.active && !isSafari) {
      setShowFullScreenExitMessage(true);
    } else {
      setShowFullScreenExitMessage(false);
    }
  }, [fullScreen.active]);

  const renderFlowchart = React.useCallback(() => {
    if (mermaidRef.current) {
      const flowchartRef = new ProtocoloFlowchart({
        mermaidElem: mermaidRef.current,
        svgId: 'svg-fluxograma',
        content,
        panZoomOptions: {
          minZoom: 0.8, // 80%
          maxZoom: 10, // 1000 %
          zoomScaleSensitivity: 0.2,
          onZoom: newScale => {
            const percentage = Math.round(newScale * 100);
            setZoomPercentage(percentage);
          },
        },
      });

      // É necessário chamar a função `.render()` primeiro para ter acesso a
      // propriedade `.panZoomInstance` e controlar o zoom
      flowchartRef.render();
      setProtocoloFlowchart(flowchartRef);
    }
  }, [content]);

  const centerOnPassoCorrente = React.useCallback(() => {
    if (protocoloFlowchart) {
      protocoloFlowchart.centerOnPassoCorrente();
    }
  }, [protocoloFlowchart]);

  const handleZoomIn = React.useCallback(() => {
    if (protocoloFlowchart) {
      protocoloFlowchart.zoomIn();
    }
  }, [protocoloFlowchart]);

  const handleZoomOut = React.useCallback(() => {
    if (protocoloFlowchart) {
      protocoloFlowchart.zoomOut();
    }
  }, [protocoloFlowchart]);

  const handleFullScreen = React.useCallback(() => {
    if (fullScreen.active) {
      fullScreen.exit();
      return;
    }

    fullScreen.enter();
  }, [fullScreen]);

  const FlowContainer = (
    <FlowchartContainer isFullScreen={fullScreen.active}>
      <MermaidContainer ref={mermaidRef} />

      {/* Painel de controle */}
      <ControlPanel>
        <Tooltip
          placement="top"
          title={fullScreen.active ? 'Sair da tela cheia' : 'Tela cheia'}
        >
          <ControlButton onClick={handleFullScreen}>
            {fullScreen.active ? <FiMinimize2 /> : <FiMaximize2 />}
          </ControlButton>
        </Tooltip>

        <ControlDivider />
        <ControlButton onClick={handleZoomOut} disabled={zoomPercentage <= 80}>
          <FiMinus />
        </ControlButton>
        <ControlButton onClick={handleZoomIn} disabled={zoomPercentage >= 1000}>
          <FiPlus />
        </ControlButton>
        <ZoomPercentage>{zoomPercentage}%</ZoomPercentage>
      </ControlPanel>
    </FlowchartContainer>
  );

  const FullScreenInfoContainer = fullScreen.active ? (
    <FullScreenInfo>
      <FullScreenHeader>
        <FullScreenLogo>
          <MedflowLogo color="primary" version="primary" />
        </FullScreenLogo>
        <FullScreenHeaderDivider />
        <FullScreenTitle>Fluxograma do protocolo</FullScreenTitle>
      </FullScreenHeader>
      <FullScreenInfoProtocolo>
        <h2>{protocoloExecutado?.nome}</h2>
        <p>
          Você está em:
          <GoToPassoCorrenteButton onClick={centerOnPassoCorrente}>
            {protocoloExecutado?.passo_corrente?.nome}
          </GoToPassoCorrenteButton>
        </p>
      </FullScreenInfoProtocolo>
    </FullScreenInfo>
  ) : (
    <></>
  );

  return (
    <Modal
      style={{ content: { width: '74%' } }}
      title="Fluxograma do protocolo"
      onAfterOpen={() => renderFlowchart()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
    >
      <Container>
        <Info>
          <h2>{protocoloExecutado?.nome}</h2>
          <p>
            Você está em:
            <GoToPassoCorrenteButton onClick={centerOnPassoCorrente}>
              {protocoloExecutado?.passo_corrente?.nome}
            </GoToPassoCorrenteButton>
          </p>
        </Info>
        <FullScreenWithChildren handle={fullScreen}>
          {FullScreenInfoContainer}
          {FlowContainer}
          {fullScreen.active && showFullScreenExitMessage && (
            <FullScreenExitAlert>
              Aperte a tecla ESC para sair do modo tela cheia.
            </FullScreenExitAlert>
          )}
        </FullScreenWithChildren>
      </Container>
    </Modal>
  );
};

export default ModalFluxograma;
