import services from '@/sevices/microServices';
import { MedflowEvent } from '../types/basic';

async function createEventRecord(payload: MedflowEvent): Promise<unknown> {
  const result = (
    await services.post<MedflowEvent>('/create-event-tracking', payload, {
      headers: { InvocationType: 'Event' },
    })
  ).data;

  return result;
}

export default {
  createEventRecord,
};
