import React from 'react';

import DoctorSection from './DoctorSection';
import PatientSection from './PatientSection';
import OrganizationSection from './OrganizationSection';
import EncounterSection from './EncounterSection';
import { Container } from './styles';

const DadosAtendimento: React.FC = () => {
  return (
    <Container>
      <DoctorSection />
      <EncounterSection />
      <PatientSection />
      <OrganizationSection />
    </Container>
  );
};

export default DadosAtendimento;
