import colors from '@/design/colors';
import styled, { css } from 'styled-components';

import {
  CheckboxMarkerProps,
  RadioMarkerProps,
  SelectionControlProps,
} from './types';

/**
 * Selection Control default attributes and behaviours
 */
const SelectionControlSizes = {
  normal: 22,
  small: 16,
};

const SelectionControlPaddings = {
  normal: 4,
  small: 2,
};

export const SelectionControlContainer = styled.div<SelectionControlProps>`
  border: 1px solid ${colors.gray100};
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: ${({ size }) => SelectionControlPaddings[size]}px;
  height: ${({ size }) => SelectionControlSizes[size]}px;
  width: ${({ size }) => SelectionControlSizes[size]}px;

  > span {
    background-color: ${({ disabled }) =>
      disabled ? colors.gray100 : colors.purple100};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
          :hover {
            border-color: ${colors.purple100};
          }
        `}
`;

/**
 * Radio
 */

export const RadioContainer = styled(SelectionControlContainer)`
  border-radius: 100%;
`;

export const RadioMarker = styled.span<RadioMarkerProps>`
  flex: 1;
  border-radius: 100%;
  height: 100%;
`;

/**
 * Checkbox
 */
export const CheckboxContainer = styled(SelectionControlContainer)`
  border-radius: 2px;
`;

const CheckboxHalfMarked = css<CheckboxMarkerProps>`
  height: ${({ size }) => (size === 'normal' ? '6px' : '4px')};
`;

const CheckboxMarked = css<CheckboxMarkerProps>`
  height: 100%;
`;

const CheckboxMarkerStates = {
  marked: CheckboxMarked,
  'half-marked': CheckboxHalfMarked,
};

export const CheckboxMarker = styled.span<CheckboxMarkerProps>`
  flex: 1;
  border-radius: 1px;
  ${({ state }) => CheckboxMarkerStates[state]}
`;
