import styled, { css } from 'styled-components';
import colors from '@/design/colors';
import { HeadingSmall, TextSmall } from '@/design/typography';
import DetailsCollapseButton from '@/components/DetailsCollapseButton';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  ${HeadingSmall(14)};

  transition: color 0.3s ease;
  color: ${props => (props.highlight ? colors.black : colors.gray300)};
`;

export const CollapsedContent = styled.div`
  position: 'relative';
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

export const TextSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${TextSmall(14)};

  /* Gray 300 */

  color: ${colors.gray300};

  margin-right: 8px !important;
`;
export const DescriptionContainer = styled.div<{
  highlight?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  padding: 4px 8px;
  margin-top: 8px;
  border-radius: 4px;

  ${props =>
    props.selected && props.highlight
      ? css`
          background: ${colors.gray100} !important;
          border: 1px solid ${colors.gray100};
        `
      : !props.disabled &&
        props.highlight &&
        css`
          border: 1px solid ${colors.gray50};
          background: ${colors.gray50};
        `}
`;

export const Description = styled.div`
  ${HeadingSmall(14)};

  color: ${colors.gray200};
`;

export const Actions = styled.div`
  display: flex;

  position: relative;

  min-height: 32px;
  min-width: 40px;

  > :not(:last-child) {
    margin-right: 16px;
  }

  > :nth-last-child(2) {
    margin-right: 48px;
  }
`;

export const CollapseButton = styled(DetailsCollapseButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const ButtonHover = styled.div<{
  hover?: boolean;
}>`
  border-radius: 24px;

  ${props =>
    props.hover &&
    css`
      background-color: ${colors.gray50};
    `}

  button {
    &:hover {
      background-color: ${colors.gray50};
    }
  }
`;
