import colors from '@/design/colors';
import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 26px;
  min-height: 26px;

  color: ${colors.blackAlpha200};
  border-radius: 4px;

  transition: all 100ms linear;

  :hover:not(:disabled),
  :focus:not(:disabled) {
    color: ${colors.purple100};
    background-color: ${colors.darkBlue50};
  }

  :disabled {
    color: ${colors.gray100};
    cursor: not-allowed;
  }
`;
