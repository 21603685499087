import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconExame from '@/assets/exame-icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import ListaExamesDefault from './index.default';
import ListaExamesFhir from './index.fhir';

import { ItemList, SectionTitle } from './styles';

interface ListaExamesProps {
  exames: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaExames: React.FC<ListaExamesProps> = ({
  exames,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  return (
    <ItemList>
      <SectionTitle>
        <img src={IconExame} alt="Exame Icone" />

        <h2>Exames sugeridos</h2>
      </SectionTitle>

      {integracaoEnterpriseAtiva ? (
        <ListaExamesFhir exames={exames} passo={passoExecutado} />
      ) : (
        <ListaExamesDefault exames={exames} />
      )}
    </ItemList>
  );
};

export default ListaExames;
