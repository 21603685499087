type MedicationOverrides = Partial<
  Omit<fhir4.Medication, 'id' | 'resourceType'>
>;

type MedicationDefaults = Partial<Omit<fhir4.Medication, 'resourceType'>>;

export function medicationReferenceFHIRPath(
  request: fhir4.MedicationRequest,
): string {
  const { medicationReference: { reference } = { reference: '' } } = request;

  const referenceID = reference?.substring(1);
  return `MedicationRequest
    .contained
    .ofType(Medication)
    .where(id='${referenceID}')`;
}

export function updateOrCreateMedicationReference(
  request: fhir4.MedicationRequest,
  data: MedicationOverrides,
  defaults: MedicationDefaults = { id: 'medication-reference' },
): fhir4.MedicationRequest {
  const { contained = [], medicationReference, ...medicationRequest } = {
    ...request,
  };

  let medication: fhir4.Medication | null = null;
  let medicationContained: fhir4.FhirResource[] = [];

  if (medicationReference) {
    medicationContained = contained.map(inner => {
      if (
        inner.resourceType === 'Medication' &&
        inner.id === medicationReference.reference?.substring(1)
      ) {
        medication = { ...inner, ...data };
        return medication;
      }

      return inner;
    });
  }

  if (medication === null) {
    medication = {
      resourceType: 'Medication',
      ...defaults,
      ...data,
    };

    medicationContained.push(medication);
  }

  return {
    ...medicationRequest,
    contained: medicationContained,
    medicationReference: { reference: `#${medication.id}` },
  };
}
