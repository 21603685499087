import fhirpath from 'fhirpath';

import React from 'react';
import { FiPrinter } from 'react-icons/fi';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import {
  patientToJson,
  practitionerToJson,
} from '@/features/enterprise/helpers';
import { Procedimento } from '@/models/Procedimento';
import IconButton from '../IconButton';

import { Container, Header, HeaderTitle, Actions } from './styles';
import { printEncaminhamento } from './helpers';

interface BoxEncaminhamentoProps {
  encaminhamento: Procedimento;
}

const BoxEncaminhamento: React.FC<BoxEncaminhamentoProps> = ({
  encaminhamento,
}) => {
  const [onHover, setOnHover] = React.useState(false);

  const { user } = useAuth();
  const [{ encounter }] = useEnterpriseContext();

  const showImprimirButton = React.useMemo(() => {
    if (user?.empresa) {
      return !!user?.empresa?.features?.find(
        feature => feature.codigo === 'IMPRIMIR_ENCAMINHAMENTO',
      );
    }
    return true;
  }, [user]);

  const paciente = React.useMemo(() => {
    if (encounter) {
      return patientToJson(encounter.data);
    }

    return { nome: '', genero: '', data: '', idade: '' };
  }, [encounter]);

  const profissional = React.useMemo(() => {
    if (encounter) {
      return practitionerToJson(encounter.data);
    }

    return {
      nome: '',
      identificador: '',
      especialidade: '',
      genero: '',
    };
  }, [encounter]);

  const entidadeDeSaude = React.useMemo(() => {
    if (encounter) {
      const entidade = fhirpath.evaluate(
        encounter,
        'Encounter.contained.ofType(Location).name.first()',
      )[0];

      return entidade;
    }

    return '';
  }, [encounter]);

  const dadosAtendimento = React.useMemo(() => {
    if (encounter) {
      const observations = fhirpath
        .evaluate(encounter, 'Encounter.contained.ofType(Observation)')
        .map(
          (obs: fhir4.Observation) =>
            `${obs.code.text}: ${obs.valueQuantity?.value} ${obs.valueQuantity?.unit}`,
        );
      const reason = fhirpath.evaluate(
        encounter,
        'Encounter.reasonCode.text',
      )[0] as string;

      return { motivo: reason, observacoes: observations };
    }

    return { motivo: '', observacoes: [] };
  }, [encounter]);

  const imprimirEncaminhamento = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      printEncaminhamento(
        encaminhamento,
        user?.empresa?.logo || '',
        entidadeDeSaude,
        paciente,
        profissional,
        dadosAtendimento,
      );
    },
    [
      encaminhamento,
      user,
      entidadeDeSaude,
      paciente,
      profissional,
      dadosAtendimento,
    ],
  );

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={onHover}>{encaminhamento.nome}</HeaderTitle>

        <Actions>
          {showImprimirButton && (
            <IconButton
              tooltip="Imprimir"
              onClick={imprimirEncaminhamento}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiPrinter />}
            />
          )}
        </Actions>
      </Header>
    </Container>
  );
};

export default BoxEncaminhamento;
