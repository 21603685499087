import styled from 'styled-components';

import Card from '@/components/Card';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  padding: 24px 0px;

  img {
    height: 104px;
  }

  h3 {
    ${HeadingSmall(20)};
  }

  p {
    ${Text(16)};
    color: ${colors.gray200};
    max-width: 235px;
    text-align: center;
  }
`;
