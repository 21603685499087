import React from 'react';

import { FiPlus } from 'react-icons/fi';
import { ButtonPlusContainer, PlusCircle } from './styles';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonPlus: React.FC<Props> = ({
  children,
  type = 'button',
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonPlusContainer type={type} {...props}>
      <PlusCircle>
        <FiPlus size={18} />
      </PlusCircle>
      {children}
    </ButtonPlusContainer>
  );
};

export default ButtonPlus;
