import styled from 'styled-components';

import colors from '@/design/colors';
import ButtonExtra from '@/components/ButtonExtra';

export const ButtonExtraContainer = styled(ButtonExtra)`
  height: 38px;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-image: repeating-linear-gradient(
      90deg,
      ${colors.purple100},
      ${colors.purple100} 15px,
      transparent 15px,
      transparent 30px,
      ${colors.purple100} 30px
    );
    background-size: 100% 1px;
    background-position: 0 0;
  }
`;
