import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  row-gap: 21px;
  column-gap: 9px;
`;

interface PickerProps {
  active: boolean;
}

export const MonthlyPicker = styled.button<PickerProps>`
  width: 51px;
  height: 47px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${HeadingSmall(12)};
  text-transform: capitalize;

  color: ${colors.blackAlpha200};
  border-radius: 8px;

  transition: all 100ms linear;

  ${props =>
    props.active
      ? css`
          background-color: ${colors.purple100};
          color: ${colors.white};
        `
      : css`
          :focus:not(:disabled),
          :hover:not(:disabled) {
            background-color: ${colors.purple50};
            color: ${colors.purple100};
          }
        `}
`;
