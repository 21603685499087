import React, { useState } from 'react';

import Option from '@/types/Option';

import { Container, RadioItem, RadioItemTitle, RadioItemCheck } from './styles';

interface SelectRadioButtonProps {
  options: Option[];
  disabled?: boolean;
  onChange?: (selectedOption: Option) => void;
}

const SelectRadioButton: React.FC<SelectRadioButtonProps> = ({
  options,
  disabled = false,
  onChange,
}: SelectRadioButtonProps) => {
  const [selected, setSelected] = useState<Option | undefined>();

  React.useEffect(() => {
    setSelected(options.find(opt => opt.selected));
  }, [options]);

  function isSelectedOption(option: Option) {
    return Object.is(selected, option);
  }

  function handleOptionSelection(option: Option) {
    if (disabled) return;

    setSelected(option);

    if (onChange) {
      onChange(option);
    }
  }

  return (
    <Container>
      {options.map((opt, index) => (
        <RadioItem
          key={opt.key || index.toString()}
          onClick={() => handleOptionSelection(opt)}
          selected={isSelectedOption(opt)}
          disabled={disabled}
        >
          <RadioItemCheck>
            <span />
          </RadioItemCheck>
          <RadioItemTitle>{opt.title}</RadioItemTitle>
        </RadioItem>
      ))}
    </Container>
  );
};

SelectRadioButton.defaultProps = {
  disabled: false,
  onChange: undefined,
};

export default SelectRadioButton;
