import React, { useState, useEffect } from 'react';

import Option from '@/types/Option';

import {
  Container,
  CheckboxItem,
  CheckboxItemTitle,
  CheckboxItemCheck,
} from './styles';

interface SelectCheckboxProps {
  options: Option[];
  disabled?: boolean;
  onChange?: (selectedOptions: Option[]) => void;
}

const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  options,
  disabled = false,
  onChange,
}: SelectCheckboxProps) => {
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    setSelectOptions(
      options.map((opt, index) => ({
        ...opt,
        key: opt.key || index.toString(),
        selected: opt.selected || false,
      })),
    );
  }, [options]);

  function handleOptionSelection(option: Option) {
    const newOptions = selectOptions.map(opt => {
      if (opt.key === option.key) {
        return { ...opt, selected: !opt.selected };
      }

      return opt;
    });

    setSelectOptions(newOptions);

    if (onChange) {
      onChange(newOptions.filter(opt => opt.selected));
    }
  }

  return (
    <Container>
      {selectOptions.map(opt => (
        <CheckboxItem
          key={opt.key}
          onClick={() => handleOptionSelection(opt)}
          selected={!!opt.selected}
          disabled={disabled}
        >
          <CheckboxItemCheck>
            <span />
          </CheckboxItemCheck>
          <CheckboxItemTitle>{opt.title}</CheckboxItemTitle>
        </CheckboxItem>
      ))}
    </Container>
  );
};

SelectCheckbox.defaultProps = {
  onChange: undefined,
  disabled: false,
};

export default SelectCheckbox;
