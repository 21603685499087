import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

import SideBox from '@/components/SideBox';

export const DraggableSideBox = styled(SideBox)<{ isOpen: boolean }>`
  position: fixed;
  left: -348px;

  width: 348px;

  /* z-index  superior ao sidebox principal */
  z-index: 10000;

  transition: translateX, ease-out, 300ms;
  transform: translateX(${props => (props.isOpen ? '100%' : 0)});

  /* Remove box-shadow pra não duplicar com o sidebox principal */
  box-shadow: none;
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 24px;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.blackAlpha200};

  h3 {
    flex: 1;
    ${HeadingSmall(16)};
  }
`;

export const CloseSideBoxButton = styled.button`
  display: flex;
`;
