import Card from '@/components/Card';
import InputField from '@/components/InputField';
import styled from 'styled-components';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 24px;

  width: 100%;

  /* min-height: 200px; */

  & + div {
    margin-top: 24px;
  }
`;

export const Header = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Black */

  color: #27262a;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 16px;
  margin-top: 32px;
`;

export const FormInput = styled(InputField)`
  min-width: 317px;
`;

export const Divider = styled.div`
  margin-top: 24px;
  width: 100%;
  border-top: 1px solid #d9dbe3;
`;

export const Footer = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: row;

  align-self: flex-end;

  button:last-child {
    margin-left: 24px;
  }
`;
