import React from 'react';

import Button from '@/components/Button';
import { useCalculadoraContext } from '../../contexts/CalculadoraContext';

import { ModalDialogContainer, Container } from './styles';

const ModalSairCalculadora: React.FC = () => {
  const { sairCalculadora } = useCalculadoraContext().state;
  const {
    dispatchCancelarSaidaCalculadora,
    dispatchConfirmarSaidaCalculadora,
  } = useCalculadoraContext().actions;

  return (
    <ModalDialogContainer
      title="Sair da calculadora"
      isOpen={sairCalculadora.emAndamento}
      onAfterClose={dispatchCancelarSaidaCalculadora}
    >
      <Container>
        <p>Deseja mesmo finalizar a calculadora?</p>

        <Button onClick={dispatchConfirmarSaidaCalculadora}>
          Sair da calculadora
        </Button>
        <Button onClick={dispatchCancelarSaidaCalculadora} theme="tertiary">
          Voltar
        </Button>
      </Container>
    </ModalDialogContainer>
  );
};

export default ModalSairCalculadora;
