import styled from 'styled-components';
import { Input as InputEspecialidade } from '../InputEspecialidade/styles';

export const GridListContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;

  > ${InputEspecialidade} {
    width: calc(25% - 16px);

    @media (max-width: 768px) {
      width: calc(50% - 16px);
    }

    margin-bottom: 16px;
    margin-right: 16px;

    :nth-child(4n) {
      margin-right: 0;
    }
  }
`;
