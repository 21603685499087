/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@/components/Button';
import { useApiErrors } from '@/utils/forms';
import api from '@/sevices/api';

import { APIValidationError } from '@/types/Api';

import axios from 'axios';
import {
  Container,
  Title,
  Info,
  Form,
  InputsContainer,
  PasswordInput,
} from './styles';

const schema = Yup.object().shape({
  new_password1: Yup.string()
    .min(6, 'A senha precisa ter pelo menos 6 caracteres.')
    .test(
      'is-numeric',
      'A senha precisa conter pelo menos uma letra.',
      value => !/^[0-9]*$/.test(value || ''),
    ),
  new_password2: Yup.string().oneOf(
    [Yup.ref('new_password1')],
    'As senhas não correspondem. Por favor, verifique e tente novamente.',
  ),
});

interface FormData {
  new_password1: string;
  new_password2: string;
}

interface LinkData {
  uid: string;
  token: string;
}

interface NewPasswordFormProps {
  onSuccess?: (data: FormData) => void;
  onFailure?: (error: APIValidationError<LinkData>) => void;
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  onSuccess,
  onFailure,
}) => {
  const [loading, setLoading] = useState(false);
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema) as Resolver<FormData>,
  });

  const { setApiErrors } = useApiErrors<FormData>({}, { setError });

  const onSubmit = useCallback(
    async ({ new_password1, new_password2 }: FormData) => {
      try {
        setLoading(true);
        await api.post(`/auth/password/reset/confirm/${uid}/${token}/`, {
          new_password1,
          new_password2,
          uid,
          token,
        });

        if (onSuccess) {
          onSuccess({ new_password1, new_password2 });
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 400) {
            const { uid: uidError, token: tokenError, ...rest } = err.response
              .data as APIValidationError<LinkData>;

            const isInvalidLink =
              (uidError && uidError.length > 0) ||
              (tokenError && tokenError.length > 0);

            if (onFailure && isInvalidLink) {
              onFailure(err.response.data as APIValidationError<LinkData>);
            } else {
              setApiErrors(rest);
            }
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [onFailure, onSuccess, setApiErrors, token, uid],
  );

  return (
    <Container>
      <Title>Nova senha</Title>

      <Info>Crie uma nova senha para acessar</Info>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <PasswordInput
            id="new_password1"
            label="Nova senha"
            errors={
              errors.new_password1 && errors.new_password1.message
                ? [errors.new_password1.message]
                : []
            }
            {...register('new_password1')}
          />

          <PasswordInput
            id="new_password2"
            label="Confirme a senha"
            errors={
              errors.new_password2 && errors.new_password2.message
                ? [errors.new_password2.message]
                : []
            }
            {...register('new_password2')}
          />
        </InputsContainer>

        <Button type="submit" loading={loading}>
          Salvar senha
        </Button>
      </Form>
    </Container>
  );
};

export default NewPasswordForm;
