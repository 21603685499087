import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const OrganizationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrganizationBoxContent = styled.div`
  background: ${colors.gray50};
  display: flex;
  align-items: center;
`;

export const OrganizationContainerTitle = styled.span`
  ${HeadingSmall(16)};
  margin-bottom: 8px;
`;

export const OrganizationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${colors.gray50};
  border-radius: 10px;
  position: relative;
  padding: 16px;
`;

export const OrganizationImage = styled.img`
  height: 46px;
  width: 46px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 16px;
`;

export const OrganizationName = styled.span`
  ${HeadingSmall(14)};
`;
