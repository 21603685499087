import { SelectionControlProps } from '@/components/SelectionControl/types';
import OptionIntegracao from '@/types/OptionIntegracao';

export const humanize: Record<string, string> = {
  Procedimento: 'Procedimento',
  Exame: 'Exame',
  Medicamento: 'Medicamento',
  Encaminhamento: 'Encaminhamento',
  Orientacao: 'Orientação',
  Fotoceutico: 'Fotocêuticos',
};

export const humanizeAndPluralize: Record<string, string> = {
  Procedimento: 'os Procedimentos',
  Exame: 'os Exames',
  Medicamento: 'os Medicamentos',
  Encaminhamento: 'os Encaminhamentos',
  Orientacao: 'as Orientações',
  Fotoceutico: 'os Fotocêuticos',
};

export const singularize: Record<string, string> = {
  Procedimento: 'Procedimento solicitado',
  Exame: 'Exame solicitado',
  Medicamento: 'Medicamento solicitado',
  Encaminhamento: 'Encaminhamento solicitado',
  Orientacao: 'Orientação solicitada',
  Fotoceutico: 'Fotocêutico solicitado',
};

export const pluralize: Record<string, string> = {
  Procedimento: 'Procedimentos solicitados',
  Exame: 'Exames solicitados',
  Medicamento: 'Medicamentos solicitados',
  Encaminhamento: 'Encaminhamentos solicitados',
  Orientacao: 'Orientações solicitadas',
  Fotoceutico: 'Fotocêuticos solicitados',
};

export function getSelectionControlState(
  items: OptionIntegracao[],
): SelectionControlProps['state'] {
  const selectedItemsNumber = items.filter(item => item.selected).length;

  if (selectedItemsNumber) {
    return selectedItemsNumber === items.length ? 'marked' : 'half-marked';
  }

  return 'unmarked';
}
