import React from 'react';
import styled from 'styled-components';

import colors from '@/styles/colors';

const LineCircleCurrent = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${colors.secondaryLite};
  border-radius: 50%;
  border: 2px solid ${colors.primaryTint};
  margin-left: -8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LineCircleCurrentInside = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.primaryTint};
  border-radius: 50%;
`;

const CircleCurrent: React.FC = () => {
  return (
    <LineCircleCurrent>
      <LineCircleCurrentInside />
    </LineCircleCurrent>
  );
};

export default CircleCurrent;
