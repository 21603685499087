import styled from 'styled-components';
import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 34px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  ${HeadingSmall(14)};
  transition: color 0.3s ease;
  color: ${props => (props.highlight ? colors.blackAlpha200 : colors.gray300)};
`;

export const CollapsedContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

export const Description = styled.p`
  ${Text(14)};
  color: ${colors.gray300};
  font-weight: normal;
`;

export const Actions = styled.div`
  display: flex;
`;
