/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { Container } from './styles';

interface TabsProps extends React.PropsWithChildren {
  value?: React.Key;
  onChange?: (changed?: React.Key) => void;
  style?: React.CSSProperties;
  className?: string;
}

interface ITabsContext {
  value?: React.Key;
  setValue: (value: React.Key) => void;
}

export const TabsContext = React.createContext<ITabsContext>({
  setValue: () => {},
});

const Tabs: React.FC<TabsProps> = ({
  children,
  value: selectedValue,
  onChange,
  style,
  className,
}) => {
  const [value, setValue] = React.useState<React.Key | undefined>();

  const setValueMiddleware = React.useCallback(
    (changed: React.Key | undefined) => {
      // If `onChange` listener provided, parent should control the value state
      if (onChange) {
        onChange(changed);
      } else {
        setValue(changed);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    if (selectedValue !== undefined) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <TabsContext.Provider value={{ value, setValue: setValueMiddleware }}>
      <Container style={style} className={className}>
        {children}
      </Container>
    </TabsContext.Provider>
  );
};

export default Tabs;
