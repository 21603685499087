/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  ArtifactFormLayout,
  ArtifactFormTitle,
} from '@/features/artifacts/components/ArtifactForm/styles';
import { codeableConceptDisplay } from '@/features/fhir/helpers/fhirpath';

import FormProcedimento, { FormProcedimentoProps } from '../FormProcedimento';

type Props = FormProcedimentoProps;

const AddCondutaProcedimento: React.FC<Props> = ({ ...formProps }) => {
  const title = React.useMemo(
    () => codeableConceptDisplay(formProps.resource, 'code'),
    [formProps.resource],
  );

  return (
    <ArtifactFormLayout>
      <ArtifactFormTitle>{title}</ArtifactFormTitle>
      <FormProcedimento {...formProps} />
    </ArtifactFormLayout>
  );
};

export default AddCondutaProcedimento;
