/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Button } from './styles';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;
type Ref = HTMLButtonElement;

const ButtonSmall = React.forwardRef<Ref, Props>((props, ref) => {
  return <Button ref={ref} {...props} />;
});

export default ButtonSmall;
