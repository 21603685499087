/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { InputPergunta, InputPerguntaProps } from '../types';

import LargeInputString from './LargeInputString';
import MaskInputString from './MaskInputString';
import PatternInputString from './PatternInputString';

const InputString: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { mask = '', large_response = false } = pergunta.variavel.meta || {};

  const Input: InputPergunta = React.useMemo(() => {
    if (large_response) {
      return LargeInputString;
    }

    if (mask) {
      return MaskInputString;
    }

    return PatternInputString;
  }, [large_response, mask]);

  return <Input pergunta={pergunta} disabled={disabled} onChange={onChange} />;
};

export default InputString;
