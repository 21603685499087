import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import colors from '@/styles/colors';

export const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FullScreenInfo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 472px;
`;

const fadeOutFullScreenExitAlert = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, 0px);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 80px);
  }
`;

export const FullScreenExitAlert = styled.span`
  display: inline-block;
  background: ${rgba(colors.black, 0.7)};
  padding: 13px;
  opacity: 1;

  border-radius: 4px;

  animation-name: ${fadeOutFullScreenExitAlert};
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-delay: 4s;
  animation-fill-mode: forwards;

  color: ${colors.white};
  font-size: 18px;
  font-weight: 400;

  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translate(-50%, 0px);
`;

export const FullScreenHeaderDivider = styled.div`
  width: 2px;
  background-color: ${colors.secondaryLite};
  transform: rotate(0deg);
  height: 100%;
`;

export const FullScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  background-color: ${colors.white};
  box-shadow: 0px 2px 5px rgba(25, 25, 25, 0.2);
  border-radius: 4px;
  height: 80px;
`;

export const FullScreenLogo = styled.div`
  display: flex;
  padding: 25px;

  img {
    height: 32px;
  }
`;

export const FullScreenTitle = styled.div`
  color: ${colors.black};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  margin: auto;
  margin-left: 24px;
`;

export const FullScreenInfoProtocolo = styled.div`
  position: relative;
  width: 298px;
  min-height: 70px;
  margin-left: auto;
  z-index: 1;

  padding: 12px 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${colors.white};
  box-shadow: 0px 2px 5px rgba(25, 25, 25, 0.2);
  border-radius: 0 0 4px 4px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const GoToPassoCorrenteButton = styled.button.attrs({
  type: 'button',
})`
  font-weight: normal;
  color: ${colors.primaryTone};
  font-size: 14px;
  transition: color 100ms ease-in-out;
  display: contents;

  :hover {
    color: ${colors.primaryTint};
  }

  ::before {
    margin-left: 6px;
    content: '';
  }
`;

interface ScreenProps {
  isFullScreen?: boolean;
}

export const MermaidContainer = styled.div`
  overflow: hidden;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-size: 16px 16px;
  background-color: ${colors.whiteShade};
  background-image: linear-gradient(
      to right,
      ${colors.terciaryTone} 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, ${colors.terciaryTone} 1px, transparent 1px);

  :active {
    cursor: grabbing;
  }

  width: 100%;
  height: 100%;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const fullScreenStyle = css`
  height: 100%;
  width: 100%;
  position: absolute;

  ${MermaidContainer} {
    border-radius: 0;
  }
`;

const defaultStyle = css`
  height: min(45vh, 540px);
  width: 100%;
  position: relative;

  ${MermaidContainer} {
    border-radius: 16px;
    border: 1px solid ${colors.gray};
  }
`;

export const FlowchartContainer = styled.div<ScreenProps>`
  ${props => (props.isFullScreen ? fullScreenStyle : defaultStyle)}
`;

export const ControlPanel = styled.div`
  position: absolute;
  right: 17px;
  bottom: 17px;
  min-width: 120px;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  box-shadow: 0px 2px 5px rgba(25, 25, 25, 0.2);
  padding: 5px 10px;
  border-radius: 4px;
`;

export const ControlDivider = styled.div`
  width: 2px;
  background-color: ${colors.secondaryLite};
  transform: rotate(0deg);
  margin-left: 10px;
  margin-right: 10px;
`;

export const ControlButton = styled.button`
  width: 23px;
  height: 23px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-in-out;

  :hover:not(:disabled) {
    background-color: ${rgba(colors.secondaryLite, 0.4)};
    color: ${colors.primaryTint};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.secondaryLite};
  }

  + button {
    margin-left: 5px;
  }
`;

export const ZoomPercentage = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.primaryTint};
  margin-left: 9px;
  display: flex;
  align-items: center;
`;
