import React from 'react';

import { ReactComponent as Stethoscope } from '@/assets/icons/stethoscope.svg';
import { ReactComponent as Scissors } from '@/assets/icons/scissors.svg';
import { ReactComponent as Medicine } from '@/assets/icons/medicine.svg';
import { ReactComponent as Referral } from '@/assets/icons/referral.svg';
import { ReactComponent as Document } from '@/assets/icons/document.svg';
import { ReactComponent as Drop } from '@/assets/icons/drop.svg';

import { TipoItemComplementar } from '../../models';
import { TipoLabel } from './styles';

const TipoArtefatoRenderMapping: {
  [key in TipoItemComplementar]: React.ReactElement;
} = {
  'solicitacao-exame': (
    <>
      <Stethoscope /> <TipoLabel>Exames</TipoLabel>
    </>
  ),
  procedimento: (
    <>
      <Scissors /> <TipoLabel>Procedimentos</TipoLabel>
    </>
  ),
  'prescricao-medica': (
    <>
      <Medicine /> <TipoLabel>Medicamentos</TipoLabel>
    </>
  ),
  encaminhamento: (
    <>
      <Referral /> <TipoLabel>Encaminhamentos</TipoLabel>
    </>
  ),
  orientacao: (
    <>
      <Document /> <TipoLabel>Orientações</TipoLabel>
    </>
  ),
  manipulado: (
    <>
      <Drop /> <TipoLabel>Manipulados</TipoLabel>
    </>
  ),
};

interface Props {
  tipo: TipoItemComplementar;
}

const TipoArtefatoLabel: React.FC<Props> = ({ tipo }) => {
  return TipoArtefatoRenderMapping[tipo];
};

export default TipoArtefatoLabel;
