import styled from 'styled-components';

import ModalLayout from '@/layouts/ModalLayout';
import colors from '@/styles/colors';

export const ModalDialogContainer = styled(ModalLayout)`
  width: 926px;
`;

export const Title = styled.h2`
  font-size: 18px;
`;

export const Fonte = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #d9dbe3;
  font-size: 14px;
  color: #75777f;
  padding-bottom: 25px;

  &:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }

  a {
    font-size: 14px;
    color: ${colors.primaryTone};
    font-weight: 600;

    &:hover {
      color: ${colors.primaryTint};
    }
  }
`;
