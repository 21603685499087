import fhirpath from 'fhirpath';
import React from 'react';
import { FiCopy } from 'react-icons/fi';
import { Slide, toast } from 'react-toastify';

import IconButton from '@/components/IconButton';
import Toast from '@/components/Toast';
import { FhirPedidoType } from '@/features/enterprise/models/types';

import BoxOpmes from '../BoxOpmes';
import {
  Actions,
  CollapseButton,
  Container,
  Header,
  HeaderSubTitle,
  HeaderTitle,
} from './styles';

interface BoxProcedimentoProps {
  procedimento: FhirPedidoType;
  disableCopy?: boolean;
}

const BoxProcedimento: React.FC<BoxProcedimentoProps> = ({
  procedimento,
  disableCopy,
}) => {
  const subtitleRef = React.createRef<HTMLHeadingElement>();

  const [isOpenedDetails, setIsOpenedDetails] = React.useState(false);
  const [showCollapse, setShowCollape] = React.useState(false);
  const [onHover, setOnHover] = React.useState(false);

  const nome = React.useMemo(() => {
    return fhirpath.evaluate(
      procedimento,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [procedimento]);

  const descricao = React.useMemo(() => {
    return fhirpath.evaluate(procedimento, 'ServiceRequest.note.text')[0];
  }, [procedimento]);

  const hasDescription = React.useMemo(() => !!descricao, [descricao]);

  const opmes = React.useMemo(() => {
    return fhirpath.evaluate(
      procedimento,
      "ServiceRequest.contained.where(resourceType='Device')",
    );
  }, [procedimento]);

  const toggleDetails = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (hasDescription) {
        setIsOpenedDetails(prev => !prev);
      }
    },
    [hasDescription],
  );

  const copyProcedimento = React.useCallback(() => {
    const texto = `${nome}`;
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Procedimento copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [nome]);

  React.useEffect(() => {
    if (subtitleRef?.current) {
      setShowCollape(
        subtitleRef?.current?.scrollHeight > subtitleRef?.current?.clientHeight,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasDescription) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{nome}</HeaderTitle>
        </Header>

        <BoxOpmes
          opmes={opmes}
          procedimento={procedimento as fhir4.ServiceRequest}
        />
      </Container>
    );
  }

  return (
    <Container detailsOpen={isOpenedDetails}>
      <Header>
        <HeaderTitle highlight={isOpenedDetails || onHover}>{nome}</HeaderTitle>

        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar procedimento"
              onClick={copyProcedimento}
              onMouseOver={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              icon={<FiCopy />}
            />
          )}

          {showCollapse && (
            <CollapseButton
              collapsed={isOpenedDetails}
              onClick={toggleDetails}
            />
          )}
        </Actions>
      </Header>

      <HeaderSubTitle ref={subtitleRef} highlight={isOpenedDetails}>
        {descricao}
      </HeaderSubTitle>

      <BoxOpmes
        opmes={opmes}
        procedimento={procedimento as fhir4.ServiceRequest}
      />
    </Container>
  );
};

BoxProcedimento.defaultProps = {
  disableCopy: false,
};

export default BoxProcedimento;
