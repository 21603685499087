import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconMedicamento from '@/assets/medicamentos-icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import Button from '@/components/Button';
import TipoArtefatoLabel from '@/features/complementares/components/TipoArtefatoLabel';
import { useCondutaComplementarContext } from '@/features/complementares/providers/CondutaComplementarProvider';
import Feature from '@/features/features/components/Feature';
import ListaMedicamentosDefault from './index.default';
import ListaMedicamentosFhir from './index.fhir';

import { ItemList, SectionTitle, SectionHeader } from './styles';

interface ListaMedicamentosProps {
  medicamentos: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaMedicamentos: React.FC<ListaMedicamentosProps> = ({
  medicamentos,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  const { toggleMenu, setGeneralType } = useCondutaComplementarContext();

  const handleOpenManipuladosMenu = React.useCallback(() => {
    setGeneralType('manipulado');
    toggleMenu(true);
  }, [setGeneralType, toggleMenu]);

  return (
    <ItemList>
      <SectionHeader>
        <SectionTitle>
          <img src={IconMedicamento} alt="Medicamento Icone" />

          <h2>Medicamentos sugeridos</h2>
        </SectionTitle>

        <Feature name="prescricaolivre.medicamentos.manipulados">
          <Button theme="tertiary" onClick={handleOpenManipuladosMenu}>
            <TipoArtefatoLabel tipo="manipulado" />
          </Button>
        </Feature>
      </SectionHeader>

      {integracaoEnterpriseAtiva ? (
        <ListaMedicamentosFhir
          medicamentos={medicamentos}
          passo={passoExecutado}
        />
      ) : (
        <ListaMedicamentosDefault medicamentos={medicamentos} />
      )}
    </ItemList>
  );
};

export default ListaMedicamentos;
