import styled from 'styled-components';
import { transparentize } from 'polished';
import colors from '@/design/colors';
import { HeadingLarge } from '@/design/typography';

export const Side = styled.aside<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  position: fixed;
  top: 0;
  right: 0;

  background-color: ${colors.white};

  border-radius: 10px 0 0 10px;

  padding: 24px;

  width: min(45%, 554px);
  height: 100vh;

  z-index: 100000;

  transition-property: transform;
  transition-duration: 200ms;
  transition-delay: 100ms;
  transition-timing-function: ease-out;

  transform: translateX(${props => (props.isOpen ? '0' : '100%')});

  overflow-y: scroll;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    flex: 1;
    ${HeadingLarge(18)};
    color: ${colors.blackAlpha200};
  }
`;

export const CloseMenuButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  transform: translateX(6px);
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 10000;

  background-color: ${transparentize(0.7, colors.blackAlpha200)};

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;
