import colors from '@/styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  input {
    width: 100%;
    padding-right: 34px;
  }
`;

export const ShowPasswordButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  height: 100%;

  svg {
    color: ${colors.hardGray};
    width: 18px;
    height: 18px;
    border-radius: 3px;
    padding: 3px;
    box-sizing: content-box;
    transition: all 0.1s ease-in-out;
  }

  :hover,
  :focus-visible {
    svg {
      background-color: ${colors.secondaryLite};
    }
  }
`;
