/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

import colors from '@/design/colors';
import { enforceElement, RenderableElement } from '@/utils/elements';

import { Menu, Button, Avatar, Actions, DropdownContainer } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  avatar?: string;
  DropdownMenu?: RenderableElement;
}

const DropdownToggleIcon = () => (
  <Actions>
    <FiChevronDown size={20} color={colors.gray200} />
  </Actions>
);

const SecondaryMenu: React.FC<Props> = ({
  avatar,
  children,
  DropdownMenu,
  style,
  className,
  ...buttonProps
}) => {
  const [menuShow, setMenuShow] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const Dropdown = React.useMemo(() => {
    if (DropdownMenu && menuShow) {
      return (
        <DropdownContainer>{enforceElement(DropdownMenu)}</DropdownContainer>
      );
    }

    return undefined;
  }, [DropdownMenu, menuShow]);

  const handleToggle = React.useCallback(() => {
    setMenuShow(prev => !prev);
  }, []);

  const handleClickOutside = React.useCallback((evt: MouseEvent) => {
    if (!dropdownRef || !dropdownRef.current) {
      return;
    }

    if (!dropdownRef.current.contains(evt.target as Node)) {
      setMenuShow(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return (
    <Menu style={style} className={className} ref={dropdownRef}>
      <Button onClick={handleToggle} {...buttonProps}>
        <Avatar src={avatar} />
        {children}

        <DropdownToggleIcon />
      </Button>
      {Dropdown}
    </Menu>
  );
};

export default SecondaryMenu;
