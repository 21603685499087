import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Button } from './styles';

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

const ButtonBack: React.FC<Props> = ({ style, className }) => {
  const history = useHistory();

  return (
    <Button
      style={style}
      className={className}
      onClick={() => history.goBack()}
    >
      <FiArrowLeft size={24} />
    </Button>
  );
};

export default ButtonBack;
