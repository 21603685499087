import fhirpath from 'fhirpath';

const CategoryURL =
  'http://healthit.medflowapp.com/fhir/CodeSystem/consentcategory';

export function containsConsentWithCategory(
  bundle: fhir4.Bundle,
  categories: string[],
): boolean {
  const categoriesCollection = categories.map(cat => `'${cat}'`).join(' | ');

  const result = fhirpath.evaluate(
    bundle,
    `Bundle.entry.resource.ofType(Consent)
      .category
      .coding
      .where(system='${CategoryURL}')
      .exists(
        (${categoriesCollection}).supersetOf(code)
      )`,
  );

  return !!(result.length && result[0]);
}

export function consentCategories(bundle: fhir4.Bundle): string[] {
  const path = `Bundle.entry.resource.ofType(Consent)
      .category
      .coding
      .where(system='${CategoryURL}')
      .code`;
  return fhirpath.evaluate(bundle, path);
}
