/* eslint-disable react/jsx-props-no-spreading */
import { Especialidade } from '@/models/Especialidade';
import React from 'react';

import { Input, InputContent, InputIcon, InputTitle } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  especialidade: Especialidade;
}

const InputEspecialidade: React.FC<Props> = ({ especialidade, ...props }) => {
  return (
    <Input {...props}>
      <InputContent>
        <InputIcon src={especialidade.imagem_url} alt="" />
        <InputTitle>{especialidade.descricao}</InputTitle>
      </InputContent>
    </Input>
  );
};

export default InputEspecialidade;
