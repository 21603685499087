import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 459px;
    margin-top: 138px;
    align-self: center;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
`;

export const Info = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
`;
