/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Header, { HeaderProps } from '../Header';
import SecondaryMenuUser from '../SecondaryMenuUser';

type Props = Omit<HeaderProps, 'Right'>;

const HeaderDefault: React.FC<Props> = props => {
  return <Header {...props} Right={SecondaryMenuUser} />;
};

export default HeaderDefault;
