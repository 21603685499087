import styled from 'styled-components';

import colors from '@/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${colors.hardGray};

  img {
    height: 105px;
    margin-bottom: 16px;
  }

  h3 {
    color: ${colors.black};
    font-size: 16px;
    margin-bottom: 8px;
  }

  p {
    color: ${colors.hardGray};
    line-height: 20px;
    max-width: 410px;
    text-align: center;
  }
`;
