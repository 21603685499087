import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0.1; }
  to { opacity: 1; }
`;

export const Container = styled.div<{ imageStatus: string }>`
  display: flex;

  span {
    display: flex;
    background: #edeff2;
  }

  img {
    opacity: 0.1;

    ${props =>
      props.imageStatus === 'loaded' &&
      css`
        opacity: 1;
        animation: ${fadeIn} 300ms ease-in-out;
      `}

    ${props =>
      props.imageStatus === 'failed' &&
      css`
        opacity: 0;
      `}
  }
`;
