import styled, { keyframes } from 'styled-components';

import colors from '@/styles/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  margin: auto;
  height: calc(100vh - 83px - 48px);

  img {
    width: 32px;
    margin-bottom: 16px;
    animation: ${rotate} 600ms linear infinite;
  }

  span {
    color: ${colors.black};
    font-size: 18px;
    line-height: 23px;
    font-weight: normal;
  }
`;
