/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

import AuthGuard from '@/features/auth/components/AuthGuard';
import UserService from '@/features/auth/services/UserService';

interface Props extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps>;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AuthGuard
            canActivate={UserService.isAuthenticated}
            location={props.location}
          >
            <Component {...props} />
          </AuthGuard>
        );
      }}
    />
  );
};

export default PrivateRoute;
