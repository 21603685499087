import React from 'react';

import SideBox from '@/components/SideBox';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import DadosAtendimento from '../DadosAtendimento';
import UltimaInteracao from '../UltimaInteracao';
import SideBoxHistoricoInteracoes from '../SideBoxHistoricoInteracoes';

import { Container, SideBoxWrapper, SideBoxFooter } from './styles';
import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import PlaceholderSidebar from '../PlaceholderSidebar';

const EnterpriseSidebar: React.FC = () => {
  const { user } = useAuth();
  const [{ history, encounter }] = useEnterpriseContext();

  const [isHistorySideOpen, setHistorySideOpen] = React.useState(false);

  const loading = React.useMemo(
    // Apesar de loadings diferentes é visualmente melhor exibir a sidebar
    // apenas quando ambos os dados (atendimento e histórico) estejam carregados
    () => [history.status, encounter.status].includes('on-hold'),
    [encounter.status, history.status],
  );

  const Footer = React.useMemo(() => {
    if (user && user.empresa && user.empresa.logo) {
      return (
        <SideBoxFooter>
          <img src={user.empresa.logo} alt={user.empresa.nome} />
        </SideBoxFooter>
      );
    }

    return undefined;
  }, [user]);

  return (
    <Container>
      <SideBoxHistoricoInteracoes
        isOpen={isHistorySideOpen}
        onCloseSideBox={() => setHistorySideOpen(false)}
      />
      <SideBox style={{ width: 348 }} FooterComponent={Footer}>
        <LoadingSwitchTransition
          loading={loading}
          LoadingComponent={<PlaceholderSidebar />}
        >
          <SideBoxWrapper>
            <DadosAtendimento />

            <UltimaInteracao onExpandClick={() => setHistorySideOpen(true)} />
          </SideBoxWrapper>
        </LoadingSwitchTransition>
      </SideBox>
    </Container>
  );
};

export default EnterpriseSidebar;
