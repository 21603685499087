import React, { useEffect } from 'react';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

const Logout: React.FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <></>;
};

export default Logout;
