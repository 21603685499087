/* eslint-disable no-param-reassign */
import React from 'react';
import produce from 'immer';

import { PassoExecutado } from '@/models/PassoExecutado';
import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

import {
  ABRIR_EXECUCAO,
  ABRIR_RESUMO,
  ExecucaoAction,
  FINALIZAR_PROTOCOLO,
  PARAR_PASSO,
  PROSSEGUIR_PROXIMO_PASSO,
  REFAZER_PASSO,
  SOLICITAR_SAIDA,
  CANCELAR_SAIDA,
  SOLICITAR_FINALIZACAO,
  CANCELAR_FINALIZACAO,
  EXIBIR_FONTES_PROTOCOLO,
  INTERROMPER_PROTOCOLO,
  ALTERAR_STATUS,
} from './actionTypes';
import { RespostaPayload, WorkflowStatus } from '../../types';

export interface ExecucaoState {
  status: WorkflowStatus;
  /**
   * Instância do protocolo que está sendo executado no momento
   *
   * Se nulo, não há protocolo sendo executado
   */
  protocoloExecutado?: ProtocoloExecutado | null;
  /**
   * Lista dos passos que foram carregados em memória durante a execução
   *
   * Toda vez que a API retorna o próximo passo do fluxo do protocolo, ele deve
   * ser anexado nesta lista
   */
  passosExecutados: PassoExecutado[];
  /**
   * Indica se o protocolo em execução foi retomado ou não
   */
  isProtocoloExecutadoRetomado: boolean | undefined;
  /**
   * Indica se o protocolo em execução foi retomado ou não
   */
  isProtocoloReadOnly: boolean | undefined;
  /**
   * Número de vezes que o usuário prosseguiu um passo, seja durante o fluxo
   * tradicional do protocolo ou reexecuções do mesmo
   */
  execucoesCount: number;
  /**
   * Contexto da confirmaçao de saída de protocolo
   */
  confirmacaoSair: {
    // Indica se a confirmação está em andamento (popup deve ser aberto)
    emAndamento: boolean;
    // URL de localização ou função callback a ser chamada após confirmação
    proximo: string | (() => void);
  };
  /**
   * Contexto de confirmação de finalização de protocolo
   */
  confirmacaoFinalizar: {
    // Indica se a confirmação está em andamento (popup deve ser aberto)
    emAndamento: boolean;
    // Respostas, se houverem, do último passo do protocolo
    respostas: RespostaPayload;
  };
  /**
   * Contexto para exibição das fontes do protocolo
   */
  exibirFontesProtocolo: boolean;
}

export const initialExecucaoState: ExecucaoState = {
  status: 'active',
  protocoloExecutado: null,
  passosExecutados: [],
  isProtocoloExecutadoRetomado: false,
  isProtocoloReadOnly: false,
  execucoesCount: 0,
  confirmacaoSair: {
    emAndamento: false,
    proximo: '/',
  },
  confirmacaoFinalizar: {
    emAndamento: false,
    respostas: {},
  },
  exibirFontesProtocolo: false,
};

function execucaoReducer(
  state = initialExecucaoState,
  action: ExecucaoAction,
): ExecucaoState {
  return produce(state, draft => {
    switch (action.type) {
      case ABRIR_EXECUCAO: {
        const { result, isProtocoloExecutadoRetomado } = action;
        draft.protocoloExecutado = result;
        draft.isProtocoloExecutadoRetomado = isProtocoloExecutadoRetomado;

        if (result.passo_corrente) {
          draft.passosExecutados = [result.passo_corrente];
        } else if (result.passos?.length) {
          draft.passosExecutados = result.passos;
          draft.protocoloExecutado.passo_corrente =
            result.passos[result.passos.length - 1] || null;
        }
        break;
      }

      case ABRIR_RESUMO: {
        const { result, isProtocoloReadOnly } = action;
        draft.protocoloExecutado = result;
        draft.passosExecutados = [
          ...result.passos.map(passo => ({ ...passo, ...{ executado: true } })),
        ];
        draft.isProtocoloReadOnly = isProtocoloReadOnly;

        break;
      }

      case PROSSEGUIR_PROXIMO_PASSO: {
        const { origem, result } = action;
        const { passosExecutados } = draft;

        const origemState = draft.passosExecutados.find(
          passo =>
            passo.id === origem.id &&
            passo.ordem_execucao === origem.ordem_execucao,
        );

        if (origemState) {
          origemState.executado = true;
        }

        draft.protocoloExecutado = result;
        if (result.passo_corrente) {
          passosExecutados.push({
            ...result.passo_corrente,
            // Força que o próximo passo nunca esteja executado
            // Isso acontece via API quando o protocolo foi modelado com um loop
            executado: false,
          });
        }

        draft.execucoesCount += 1;
        draft.status = 'active';

        break;
      }

      case PARAR_PASSO: {
        const { origem, result } = action;

        const origemState = draft.passosExecutados.find(
          passo =>
            passo.id === origem.id &&
            passo.ordem_execucao === origem.ordem_execucao,
        );

        if (origemState) {
          origemState.executado = true;
        }

        draft.protocoloExecutado = result;

        break;
      }

      case REFAZER_PASSO: {
        const { origem } = action;
        const { passosExecutados, protocoloExecutado } = draft;

        const passoRefazerIndex = passosExecutados.findIndex(
          passo =>
            passo.id === origem.id &&
            passo.ordem_execucao === origem.ordem_execucao,
        );

        if (passoRefazerIndex !== -1) {
          const passoRefazer = passosExecutados[passoRefazerIndex];
          passoRefazer.executado = false;

          if (protocoloExecutado) {
            protocoloExecutado.passo_corrente = passoRefazer;
          }

          passosExecutados.splice(passoRefazerIndex + 1);
        }

        break;
      }

      case FINALIZAR_PROTOCOLO: {
        Object.assign(draft, initialExecucaoState);

        break;
      }

      case SOLICITAR_SAIDA: {
        const { proximo } = action;
        draft.confirmacaoSair = {
          emAndamento: true,
          proximo,
        };

        break;
      }

      case CANCELAR_SAIDA: {
        draft.confirmacaoSair = {
          emAndamento: false,
          proximo: '/',
        };

        break;
      }

      case SOLICITAR_FINALIZACAO: {
        const { respostas } = action;
        draft.confirmacaoFinalizar = {
          emAndamento: true,
          respostas,
        };

        break;
      }

      case CANCELAR_FINALIZACAO: {
        draft.confirmacaoFinalizar = {
          emAndamento: false,
          respostas: {},
        };

        break;
      }

      case EXIBIR_FONTES_PROTOCOLO: {
        const { value } = action;
        draft.exibirFontesProtocolo = value;
        break;
      }

      case INTERROMPER_PROTOCOLO: {
        if (draft.protocoloExecutado) {
          draft.protocoloExecutado = {
            ...draft.protocoloExecutado,
            status: 'CANCELADO',
            executado: true,
            data_execucao: new Date().toISOString(),
            passo_corrente: null,
          };
        }

        draft.passosExecutados.forEach(passo => {
          passo.executado = true;
        });

        break;
      }

      case ALTERAR_STATUS: {
        const { status } = action;
        draft.status = status;
        break;
      }

      default:
    }
  });
}
export function useExecucaoReducer(): [
  ExecucaoState,
  React.Dispatch<ExecucaoAction>,
] {
  const [state, dispatch] = React.useReducer(
    execucaoReducer,
    initialExecucaoState,
  );
  return [state, dispatch];
}

export default execucaoReducer;
