import React from 'react';

// Logo primary (default)
import primary from '@/assets/logos/medflow-logo.svg';
import alternative from '@/assets/logos/medflow-logo-alternative.svg';
import positive from '@/assets/logos/medflow-logo-positive.svg';
import negative from '@/assets/logos/medflow-logo-negative.svg';

// Logo white
import whitePrimary from '@/assets/logos/medflow-logo-white.svg';
import whiteAlternative from '@/assets/logos/medflow-logo-white-alternative.svg';
import whitePositive from '@/assets/logos/medflow-logo-white-positive.svg';
import whiteNegative from '@/assets/logos/medflow-logo-white-negative.svg';

import {
  MedflowLogoColor,
  MedflowLogoVersion,
  MedflowVersionMapping,
} from '../types';

const LogoSourceMapping: {
  [key in MedflowLogoColor]: MedflowVersionMapping;
} = {
  primary: {
    primary,
    alternative,
    positive,
    negative,
  },
  white: {
    primary: whitePrimary,
    alternative: whiteAlternative,
    positive: whitePositive,
    negative: whiteNegative,
  },
};

interface Props {
  version?: MedflowLogoVersion;
  color?: MedflowLogoColor;
  style?: React.CSSProperties;
  className?: string;
}

const MedflowLogo: React.FC<Props> = ({
  color = 'primary',
  version = 'primary',
  style = {
    height: 32,
  },
  className,
}) => {
  const src = React.useMemo(() => LogoSourceMapping[color][version], [
    color,
    version,
  ]);

  return <img src={src} alt="Medflow" style={style} className={className} />;
};

export default MedflowLogo;
