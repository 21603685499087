import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxItemTitle = styled.strong`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
  flex: 1;
  margin-left: 16px;
`;

export const CheckboxItemCheck = styled.div`
  width: 16px;
  height: 16px;
  color: ${colors.black};
  border: 1px solid ${colors.gray};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  span {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
  }
`;

const defaultStyle = css`
  opacity: 1;
  color: ${colors.secondaryHard};
  background-color: ${colors.white};
`;

const selectedStyle = css`
  background-color: ${colors.backgroundColor};
  color: ${colors.black};

  ${CheckboxItemCheck} {
    span {
      background: ${colors.primaryTone};
    }
  }
`;

export const disableSelectedStyle = css`
  background-color: ${colors.backgroundColor};
  color: ${colors.hardGray};
  cursor: not-allowed;

  ${CheckboxItemCheck} {
    background-color: ${colors.white};
    span {
      background: ${colors.hardGray};
    }
  }
`;

const disableStyle = css`
  opacity: 0.4;
  cursor: not-allowed;

  ${CheckboxItemCheck} {
    display: none;
  }
`;

const getStyle = (props: { selected: boolean; disabled: boolean }) => {
  if (props.selected && props.disabled) {
    return disableSelectedStyle;
  }

  if (props.selected) {
    return selectedStyle;
  }

  if (props.disabled) {
    return disableStyle;
  }

  return defaultStyle;
};

interface CheckboxItemProps {
  selected: boolean;
  disabled: boolean;
}

export const CheckboxItem = styled.div<CheckboxItemProps>`
  padding: 16px;
  border: 1px solid ${colors.secondaryLite};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease-in-out;
  align-items: center;

  cursor: pointer;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:hover,
  &:focus-visible {
    ${props =>
      !props.disabled &&
      css`
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        color: ${colors.primaryTint};
      `}
  }

  ${getStyle}
`;
