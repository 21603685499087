import styled from 'styled-components';

import ModalLayout from '@/layouts/ModalLayout';

export const ModalDialogContainer = styled(ModalLayout)`
  width: 480px;
`;

export const Icon = styled.img`
  margin-top: 24px;
  margin-bottom: 24px;

  width: 40px;
  height: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  button {
    width: 299px;
    align-self: center;

    + button {
      margin-top: 16px;
    }
  }
`;
