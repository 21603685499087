/* eslint-disable react/jsx-props-no-spreading */
import fhirpath from 'fhirpath';
import React from 'react';
import { FhirPedidoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';

import { serviceAdditionalDevicesWithNoStatusReason } from '@/features/fhir/helpers/serviceRequest';
import useEllipsis from '@/utils/hooks/useExpandableRef';
import BoxOpmes from '../BoxOpmes';
import FormProcedimento, { FormProcedimentoMode } from '../FormProcedimento';
import {
  Actions,
  BoxContent,
  Container,
  EditButton,
  EditedLabel,
  EditTools,
  ExpandButton,
  Header,
  HeaderSubTitle,
  HeaderTitle,
  HeaderSection,
} from './styles';

interface BoxProcedimentoEditavelProps {
  procedimento: FhirPedidoType;
  detailsOpened?: boolean;
  disabled: boolean;
  onBeforeEdit?: () => void;
  onAfterEdit?: () => void;
  onAfterEditCancel?: () => void;
}

type ProcedureRequest = fhir4.ServiceRequest & {
  reportedBoolean: boolean;
};

const BoxProcedimentoEditavel: React.FC<BoxProcedimentoEditavelProps> = ({
  procedimento,
  detailsOpened,
  disabled,
  onBeforeEdit,
  onAfterEdit,
  onAfterEditCancel,
}) => {
  const [mode, setMode] = React.useState<FormProcedimentoMode>('procedimento');
  const [flagDeviceErrors, setFlagDeviceErrors] = React.useState(false);
  const [isOpenForEdit, setIsOpenForEdit] = React.useState(
    detailsOpened || false,
  );
  const [original, setOriginal] = React.useState<ProcedureRequest>(
    procedimento as ProcedureRequest,
  );
  const [draft, setDraft] = React.useState<ProcedureRequest>({
    ...(procedimento as ProcedureRequest),
  });

  const [isSubtitleExpanded, setIsSubtitleExpanded] = React.useState(false);
  const [loadingConfirmation, setloadingConfirmation] = React.useState(false);

  const nome = React.useMemo(() => {
    return fhirpath.evaluate(
      draft,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [draft]);

  const comentario = React.useMemo(() => {
    const note = fhirpath.evaluate(draft, 'ServiceRequest.note.text');
    if (note.length) {
      return note[0];
    }

    return '';
  }, [draft]);

  const [subtitleRef, subtitileHasEllipis] = useEllipsis<HTMLHeadingElement>([
    comentario,
  ]);

  const opmes = React.useMemo(() => {
    return fhirpath.evaluate(
      draft,
      "ServiceRequest.contained.where(resourceType='Device')",
    );
  }, [draft]);

  const invalidOpmes = React.useMemo(() => {
    return serviceAdditionalDevicesWithNoStatusReason(draft);
  }, [draft]);

  const devicesWithError = React.useMemo(() => {
    if (flagDeviceErrors) {
      return invalidOpmes;
    }

    return [];
  }, [flagDeviceErrors, invalidOpmes]);

  const edited = React.useMemo(() => !!draft.reportedBoolean, [
    draft.reportedBoolean,
  ]);

  const canExpand = React.useMemo(() => {
    // Se desabilitado, pode expandir e abrir formulário
    if (disabled) {
      return true;
    }
    return isSubtitleExpanded || subtitileHasEllipis;
  }, [disabled, isSubtitleExpanded, subtitileHasEllipis]);

  const canEdit = React.useMemo(() => !(disabled || isOpenForEdit), [
    disabled,
    isOpenForEdit,
  ]);

  const onEditClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      event.stopPropagation();

      setIsOpenForEdit(true);

      if (onBeforeEdit) {
        onBeforeEdit();
      }
    },
    [onBeforeEdit],
  );

  const toggleCollapse = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      // If disabled, collapse should display the edit form
      if (disabled) {
        setIsOpenForEdit(prev => !prev);
      }

      setIsSubtitleExpanded(prev => !prev);
    },
    [disabled],
  );

  const saveProcedimento = React.useCallback(
    async (item: fhir4.DomainResource) => {
      if (invalidOpmes.length) {
        setMode('opme');
        setFlagDeviceErrors(true);
        return;
      }

      try {
        setloadingConfirmation(true);
        const result = await IntegracaoService.updateFhirResource(
          item as FhirPedidoType,
        );

        if (result) {
          setIsOpenForEdit(false);
          setIsSubtitleExpanded(false);
          setDraft(result as ProcedureRequest);
          setOriginal(result as ProcedureRequest);
        }
      } finally {
        setloadingConfirmation(false);
        if (onAfterEdit) {
          onAfterEdit();
        }
      }
    },
    [invalidOpmes.length, onAfterEdit],
  );

  const saveProcedimentoFail = React.useCallback(() => {
    // Havendo erros no formulário de OPME, não há necessidade de redirecionar
    // p/ o formulário de procedimento
    if (mode === 'opme' && invalidOpmes.length > 0) {
      setFlagDeviceErrors(true);
    } else {
      setMode('procedimento');
    }
  }, [invalidOpmes.length, mode]);

  const editProcedimento = React.useCallback(
    (changed: fhir4.ServiceRequest) => {
      setDraft(changed as ProcedureRequest);
      setFlagDeviceErrors(false);
    },
    [],
  );

  const onEditCancel = React.useCallback(async () => {
    setDraft(original);
    setFlagDeviceErrors(false);
    setIsOpenForEdit(false);
    setIsSubtitleExpanded(false);
    setMode('procedimento');

    if (onAfterEditCancel) {
      onAfterEditCancel();
    }
  }, [onAfterEditCancel, original]);

  const onFormModeChange = React.useCallback(
    (to: FormProcedimentoMode) => {
      if (to === 'procedimento' && invalidOpmes.length) {
        setFlagDeviceErrors(true);
        setMode('opme');
      } else {
        setMode(to);
      }
    },
    [invalidOpmes.length],
  );

  return (
    <Container>
      <BoxContent>
        <HeaderSection>
          <Header>
            <HeaderTitle highlight={isOpenForEdit}>{nome}</HeaderTitle>

            <Actions>
              <EditTools>
                {edited && <EditedLabel>editado</EditedLabel>}
                {canEdit && (
                  <EditButton onClick={onEditClick} disabled={isOpenForEdit} />
                )}
              </EditTools>

              <ExpandButton
                disabled={!canExpand}
                collapsed={isSubtitleExpanded}
                onClick={toggleCollapse}
              />
            </Actions>
          </Header>

          {comentario && (
            <HeaderSubTitle ref={subtitleRef} highlight={isSubtitleExpanded}>
              {comentario}
            </HeaderSubTitle>
          )}
        </HeaderSection>

        {isOpenForEdit ? (
          <FormProcedimento
            resource={draft}
            onSubmit={saveProcedimento}
            onSubmitError={saveProcedimentoFail}
            onCancel={onEditCancel}
            loading={loadingConfirmation}
            readOnly={disabled}
            mode={mode}
            onModeChange={onFormModeChange}
          >
            <BoxOpmes
              opmes={opmes}
              invalidOpmes={devicesWithError}
              isOpenForEdit
              procedimento={draft}
              loading={loadingConfirmation}
              readOnly={disabled}
              onProcedimentoChange={editProcedimento}
            />
          </FormProcedimento>
        ) : (
          opmes.length > 0 && <BoxOpmes opmes={opmes} procedimento={draft} />
        )}
      </BoxContent>
    </Container>
  );
};

export default BoxProcedimentoEditavel;
