import styled from 'styled-components';

export const FlatListContainer = styled.div``;

export const FlatListContentContainer = styled.div`
  /* Layout */
  display: flex;
  flex-direction: column;
  flex: 1;

  row-gap: 16px;
`;
