import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import colorsDescotinuated from '@/styles/colors';
import styled, { css, keyframes } from 'styled-components';

export const BoxSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px;
  margin-bottom: 6px;

  background: ${colors.gray100};
  border-radius: 8px;
`;

export const BoxSelectionTitle = styled.div`
  ${HeadingSmall(14)}
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Black */

  color: ${colors.black};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 16px;
`;

export const CheckboxItemTitle = styled.strong`
  ${HeadingSmall(14)}

  flex: 1;
  text-align: start;
  margin-left: 16px;

  color: ${colors.blackAlpha200};
`;

const defaultStyle = css`
  opacity: 1;
  color: ${colorsDescotinuated.secondaryHard};
`;

const selectedStyle = css`
  color: ${colors.black};
`;

export const disableSelectedStyle = css`
  background-color: ${colorsDescotinuated.backgroundColor};
  color: ${colorsDescotinuated.hardGray};
  cursor: default;
`;

const disableStyle = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

const getStyle = (props: { selected: boolean; disabled: boolean }) => {
  if (props.selected && props.disabled) {
    return disableSelectedStyle;
  }

  if (props.selected) {
    return selectedStyle;
  }

  if (props.disabled) {
    return disableStyle;
  }

  return defaultStyle;
};

interface CheckboxItemProps {
  selected: boolean;
  disabled: boolean;
}

export const CheckboxItem = styled.button<CheckboxItemProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease-in-out;
  align-items: center;

  cursor: pointer;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:hover:not(:disabled),
  &:focus-visible {
    color: ${colorsDescotinuated.primaryTint};
  }

  ${getStyle}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  margin-left: 8px;

  img {
    animation: ${rotate} 600ms linear infinite;
    display: block;
  }
`;
