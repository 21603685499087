import React from 'react';

import Search from '@/components/Search';

import SectionProtocolos from '../SectionProtocolos';
import { Container, Header } from './styles';

const PageContentProtocolos: React.FC = () => {
  const [termOfSearch, setTermOfSearch] = React.useState('');

  return (
    <Container>
      <Header>
        <Search
          placeholder="Busque por protocolos ou sintomas"
          debounceTimeInMS={600}
          onChange={setTermOfSearch}
        />
      </Header>
      <SectionProtocolos search={termOfSearch} />
    </Container>
  );
};

export default PageContentProtocolos;
