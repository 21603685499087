import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { HeadingLarge, HeadingSmall } from '@/design/typography';

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 12px;
  row-gap: 9px;
`;

export const WeeklyLabel = styled.span`
  ${HeadingLarge(14)};
  color: ${colors.gray200};
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PickerProps {
  today: boolean;
  active: boolean;
}

export const DailyPicker = styled.button<PickerProps>`
  ${HeadingSmall(12)};
  color: ${colors.blackAlpha200};

  width: 23px;
  height: 23px;
  border-radius: 50%;

  transition: all 100ms linear;

  ${props => {
    if (props.active) {
      return css`
        background-color: ${colors.purple50};
        color: ${colors.purple100};
      `;
    }

    if (props.today) {
      return css`
        background-color: ${colors.purple100};
        color: ${colors.white};
      `;
    }

    return css`
      :focus:not(:disabled),
      :hover:not(:disabled) {
        color: ${colors.purple100};
      }
    `;
  }}
`;
