import React from 'react';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { groupBy } from '@/utils/collections';
import BoxFotoceutico from '@/features/recursos/fotoceutico/components/BoxFotoceutico';
import { Content, Wrapper } from './styles';
import CategoriaArtefato from '../../CategoriaArtefato';

interface FotoceuticosProps {
  fotoceuticos: FhirArtefatoType[];
}

const ListaFotoceuticosDefault: React.FC<FotoceuticosProps> = ({
  fotoceuticos,
}) => {
  const fotoceuticosHasCategorias = React.useMemo(() => {
    return (
      fotoceuticos.filter(fotoceutico => !!fotoceutico.categoria).length > 0
    );
  }, [fotoceuticos]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      fotoceuticos,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(fotoceutico => (
          <BoxFotoceutico
            key={fotoceutico.id.toString()}
            fotoceutico={fotoceutico.pedido}
          />
        ))}
      />
    ));
  }, [fotoceuticos]);
  return (
    <Wrapper>
      <Content>
        {fotoceuticosHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <>
            {fotoceuticos.map(fotoceutico => (
              <BoxFotoceutico
                key={fotoceutico.id.toString()}
                fotoceutico={fotoceutico.pedido}
              />
            ))}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaFotoceuticosDefault;
