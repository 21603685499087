import React, { useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import ProtocoloExecutadoDetail from '@/features/execucao/components/ProtocoloExecutadoDetail';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import HeaderDefault from '@/components/HeaderDefault';
import PageLayout from '@/layouts/PageLayout';

import { isSessionExpired } from '@/features/auth/providers/AuthProvider/helpers';
import BarInfoProtocoloExecutado from '@/features/dashboard/components/BarInfoProtocoloExecutado';
import HeaderTitleProtocoloExecutado from '../../components/HeaderTitleProtocoloExecutado';

const ExecucaoProtocolo: React.FC = () => {
  const history = useHistory();
  const { status } = useAuth();
  const [
    { protocoloExecutado, confirmacaoSair, isProtocoloExecutadoRetomado },
    { dispatchSolicitarSaida },
  ] = useExecucaoContext();

  const HeaderTitle = React.useMemo(() => {
    if (protocoloExecutado) {
      return <HeaderTitleProtocoloExecutado protocolo={protocoloExecutado} />;
    }

    return undefined;
  }, [protocoloExecutado]);

  const HeaderContent = React.useMemo(() => {
    if (
      protocoloExecutado &&
      isProtocoloExecutadoRetomado &&
      protocoloExecutado.tags &&
      protocoloExecutado.tags.length
    ) {
      return <BarInfoProtocoloExecutado />;
    }

    return undefined;
  }, [isProtocoloExecutadoRetomado, protocoloExecutado]);

  const canLeave = React.useMemo(() => {
    return (
      !protocoloExecutado ||
      confirmacaoSair.emAndamento ||
      isSessionExpired(status)
    );
  }, [confirmacaoSair.emAndamento, protocoloExecutado, status]);

  useEffect(() => {
    if (!protocoloExecutado) {
      history.replace('/');
    }
  }, [history, protocoloExecutado]);

  return (
    <PageLayout
      Nav={<HeaderDefault Title={HeaderTitle}>{HeaderContent}</HeaderDefault>}
    >
      <Prompt
        when={!canLeave}
        message={(location, action) => {
          dispatchSolicitarSaida({
            proximo: () => {
              history[action.toLowerCase() as 'push' | 'replace'](location);
            },
          });
          return false;
        }}
      />
      <ProtocoloExecutadoDetail />
    </PageLayout>
  );
};

export default ExecucaoProtocolo;
