/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import {
  ListFieldset,
  ListTitle,
  ListInputs,
  ListInput,
  ListInputDelete,
} from './styles';

import InputField from '../InputField';
import ButtonPlus from '../ButtonPlus';

interface InputFieldListProps {
  title?: string;
  style?: React.CSSProperties;
  className?: string;
  initialValues?: string[];
  plusText?: string;
  disabled?: boolean;
  errors?: string[];
  autoFocusNextOnEnter?: boolean;
  onChange?: (value: string[]) => void;
  onAutoFocusEndReached?: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
}

interface ItemValueState {
  id: string;
  value: string;
}

const InputFieldList: React.FC<InputFieldListProps> = ({
  title,
  style,
  className,
  errors = [],
  initialValues = [''],
  plusText = 'Adicionar',
  disabled = false,
  autoFocusNextOnEnter = false,
  onChange,
  onAutoFocusEndReached,
}) => {
  const [values, setValues] = React.useState<ItemValueState[]>(() =>
    initialValues.map(value => ({ id: uuidv4(), value })),
  );

  const inputsRef = React.useRef<(HTMLInputElement | null)[]>([]);

  const handleValuesChange = React.useCallback(
    (items: ItemValueState[]) => {
      if (onChange) {
        onChange(items.map(({ value }) => value));
      }
    },
    [onChange],
  );

  const handleInputCreate = React.useCallback(() => {
    const updatedValues = [...values, { id: uuidv4(), value: '' }];

    setValues(updatedValues);
    handleValuesChange(updatedValues);

    setTimeout(() => {
      const lastInput = inputsRef.current[updatedValues.length - 1];
      if (lastInput) {
        lastInput.focus();
      }
    }, 0);
  }, [handleValuesChange, values]);

  const handleInputDelete = React.useCallback(
    (itemID: string, index: number) => {
      if (index > 0) {
        const updatedValues = values.filter(({ id }) => id !== itemID);

        setValues(updatedValues);
        handleValuesChange(updatedValues);
      }
    },
    [handleValuesChange, values],
  );

  const handleInputChange = React.useCallback(
    (itemID: string, newValue: string) => {
      const updatedValues = values.map(({ id, value }) => {
        if (itemID === id) {
          return { id, value: newValue };
        }

        return { id, value };
      });

      setValues(updatedValues);
      handleValuesChange(updatedValues);
    },
    [handleValuesChange, values],
  );

  const handleInputEnter = React.useCallback(
    (
      event: React.KeyboardEvent<HTMLInputElement>,
      itemID: string,
      index: number,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (autoFocusNextOnEnter) {
          const isLastInput = index === values.length - 1;

          if (!isLastInput) {
            const nextInput = inputsRef.current[index + 1];
            if (nextInput) {
              nextInput.focus();
            }
          } else if (onAutoFocusEndReached) {
            onAutoFocusEndReached(event);
          }
        }
      }
    },
    [autoFocusNextOnEnter, onAutoFocusEndReached, values.length],
  );

  return (
    <ListFieldset style={style} className={className}>
      {title && <ListTitle>{title}</ListTitle>}
      <ListInputs>
        {values.map(({ value, id: itemID }, index) => (
          <ListInput key={itemID}>
            <InputField
              ref={elem => {
                inputsRef.current[index] = elem;
              }}
              type="text"
              value={value}
              disabled={disabled}
              onChange={event => handleInputChange(itemID, event.target.value)}
              error={errors && errors[index]}
              onKeyDown={event => handleInputEnter(event, itemID, index)}
            >
              {index !== 0 && (
                <ListInputDelete
                  type="button"
                  tabIndex={-1}
                  onClick={() => handleInputDelete(itemID, index)}
                >
                  <FiTrash2 size={18} />
                </ListInputDelete>
              )}
            </InputField>
          </ListInput>
        ))}
      </ListInputs>
      {plusText && (
        <ButtonPlus onClick={handleInputCreate}>{plusText}</ButtonPlus>
      )}
    </ListFieldset>
  );
};

export default InputFieldList;
