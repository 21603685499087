import styled from 'styled-components';

import colors from '@/styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.secondaryLite};
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 24px;
  margin-bottom: 16px;
  width: 100%;

  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: ${colors.black};

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    color: ${colors.primaryTint};
  }

  &:active {
    background: ${colors.terciaryTone};
  }

  img {
    width: 32px;
  }

  span {
    font-size: 18px;
    line-height: 23px;
    margin-left: 16px;
  }
`;
