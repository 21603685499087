/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import InputFieldDecimal from '@/components/InputFieldDecimal';
import InputFieldInteger from '@/components/InputFieldInteger';
import InputFieldList from '@/components/InputFieldList';
import InputField, {
  InputFieldProps,
  TextField,
  TextFieldProps,
} from '@/components/InputField';

import {
  ArtifactFieldInputType,
  ArtifactFieldWidget,
  ArtifactWidgetProps,
} from './ArtifactForm.types';

// Widget DOM attributes helpers

const renderInputHTMLAttributes = ({
  control: { field: controlField },
  field,
  fieldState,
}: ArtifactWidgetProps): InputHTMLAttributes<HTMLInputElement> => ({
  ...controlField,
  name: field.name,
  disabled: fieldState.disabled,
  readOnly: field.readOnly,
  ...field.attrs,
});

const renderInputFieldAttributes = ({
  control,
  field,
  fieldState,
  form,
}: ArtifactWidgetProps): InputFieldProps => ({
  ...renderInputHTMLAttributes({
    control,
    field,
    fieldState,
    form,
  }),
  title: fieldState.label,
  error: control.fieldState.error?.message,
});

const renderTextFieldAttributes = ({
  control: { field: controlField, fieldState: controlFieldState },
  field,
  fieldState,
}: ArtifactWidgetProps): TextFieldProps => ({
  // Input common atributes
  ...controlField,
  name: field.name,
  readOnly: field.readOnly,
  disabled: fieldState.disabled,
  // InputField attributes
  title: fieldState.label,
  error: controlFieldState.error?.message,
  // Textarea attributes
  rows: 1,
  // ...field.attrs,
});

const ShortAnwser: ArtifactFieldWidget = props => {
  return <InputField {...renderInputFieldAttributes(props)} />;
};

const Paragraph: ArtifactFieldWidget = props => {
  return <TextField {...renderTextFieldAttributes(props)} />;
};

const NumberDecimal: ArtifactFieldWidget = ({
  control,
  field,
  fieldState,
  form,
}) => {
  return (
    <InputFieldDecimal
      {...renderInputFieldAttributes({ control, field, fieldState, form })}
      onChangeText={(value: string) => control.field.onChange(value)}
      decimalPlaces={2}
    />
  );
};

export const NumberInteger: ArtifactFieldWidget = ({
  control,
  field,
  fieldState,
  form,
}) => {
  return (
    <InputFieldInteger
      {...renderInputFieldAttributes({ control, field, fieldState, form })}
      onChangeText={(value: string) => control.field.onChange(value)}
    />
  );
};

export const MultiTextWidget: React.ComponentType<
  ArtifactWidgetProps & {
    plusText: string;
  }
> = ({
  control: { field, fieldState: controlState },
  fieldState,
  form,
  plusText,
}) => {
  const { setFocus } = useFormContext();

  const errors = React.useMemo(() => {
    const fieldErrors = (controlState.error as unknown) as
      | FieldError[]
      | undefined;

    if (fieldErrors !== undefined) {
      return fieldErrors.map(({ message }) => message || '');
    }

    return [];
  }, [controlState.error]);

  const setFocusOnNext = React.useCallback(() => {
    const index = form.findIndex(({ name }) => field.name === name);
    const shouldFocus = index >= 0 && index !== form.length - 1;

    if (shouldFocus) {
      setFocus(form[index + 1].name);
    }
  }, [field.name, form, setFocus]);

  return (
    <InputFieldList
      plusText={plusText}
      initialValues={field.value}
      title={fieldState.label}
      disabled={fieldState.disabled}
      onChange={field.onChange}
      errors={errors}
      autoFocusNextOnEnter
      onAutoFocusEndReached={setFocusOnNext}
    />
  );
};

export const FieldTypeWidget: Record<
  ArtifactFieldInputType,
  ArtifactFieldWidget
> = {
  short_answer: ShortAnwser,
  paragraph: Paragraph,
  number_decimal: NumberDecimal,
  number_integer: NumberInteger,
};
