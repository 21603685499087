import { Box, Control } from '@/components/SelectionTextBox/styles';
import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const OptionsContainer = styled.div`
  position: relative;
  padding-left: 30px;
`;

const rotate180cw = keyframes`
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
`;

const rotate180reverse = keyframes`
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
`;

const swingOutTop = keyframes`
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
    display: none;
  }
`;

const swingInTop = keyframes`
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    display: none;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const swingInRight = keyframes`
  0% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
`;

const swingOutRight = keyframes`
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: auto;

  column-gap: 8px;
`;

export const CollapseSpan = styled.span<{
  hover?: boolean;
}>`
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  border-radius: 30px;

  color: ${colors.white};
  background: ${colors.gray300};

  ${({ hover }) =>
    hover &&
    css`
      background: ${colors.blackAlpha200};
    `}
`;

export const CollapseSelectedContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  column-gap: 2px;

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    animation: ${swingInRight} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    animation: ${swingOutRight} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
  }
`;

export const CollapseSelectedSpan = styled(CollapseSpan)`
  color: ${colors.white};
  background: ${colors.purple100};

  :hover {
    background: ${colors.purple100};
  }
`;

export const CollapseDivisor = styled.span`
  color: ${colors.blackAlpha100};
  ${HeadingSmall(14)}
  margin-right: -6px;
`;

export const CollapseButton = styled.div<{
  collapsed: boolean;
  hover?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray100};
  color: ${colors.gray300};

  animation: ${({ collapsed }) => (collapsed ? rotate180cw : rotate180reverse)}
    0.4s linear forwards;

  ${({ hover }) =>
    hover &&
    css`
      color: ${colors.blackAlpha200};
    `}
`;

export const CategoriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${Box} {
    background-color: ${colors.gray100};
  }

  ${Control} {
    color: ${colors.blackAlpha200};

    display: flex;
    flex-direction: row;

    justify-content: left;

    width: 100%;
  }
`;

export const CategoriaTitle = styled.span`
  color: #a7a9b1;
  font-weight: 700;
  font-size: 14px;
`;

export const CategoriaTitleLink = styled.div<{ collapsed: boolean }>`
  position: absolute;
  width: 30px;
  height: calc(50% + 16px);
  left: 0;
  bottom: -16px;
  border-left: 2px solid #d9dbe3;
  border-top: 2px solid #d9dbe3;
  border-top-left-radius: 10px;

  ${({ collapsed }) =>
    collapsed
      ? css`
          animation: ${fadeIn} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        `
      : css`
          animation: ${fadeOut} 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        `}
`;

export const CategoriaLastLink = styled.div`
  position: absolute;
  width: 30px;
  height: 50%;
  left: 0;
  top: 0;
  border-left: 2px solid #d9dbe3;
  border-bottom: 2px solid #d9dbe3;
  border-bottom-left-radius: 10px;
`;

export const CategoriaLink: React.FC = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: '2px',
          height: 'calc(100% + 16px)',
          left: 0,
          top: 0,
          borderLeft: '2px solid #D9DBE3',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '30px',
          height: '50%',
          borderLeft: '2px solid #D9DBE3',
          borderBottom: '2px solid #D9DBE3',
          borderBottomLeftRadius: '10px',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '24px',
        }}
      />
    </>
  );
};

export const CategoriaContent = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${({ collapsed }) =>
    collapsed
      ? css`
          display: flex;
          flex-direction: column;
          animation: ${swingInTop} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
            both;
        `
      : css`
          animation: ${swingOutTop} 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53)
            both;
        `}
`;
