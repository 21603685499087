import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as KeyIllustration } from '@/assets/illustrations/feedback-graphics/key.svg';

import MedflowLogo from '@/components/Logo/MedflowLogo';
import { ErrorContainer, Button, Content, Footer } from './styles';

const Unauthorized: React.FC = () => {
  const history = useHistory();

  const voltar = () => {
    history.goBack();
  };

  return (
    <ErrorContainer>
      <Content>
        <KeyIllustration />

        <h3>Você precisa ter acesso.</h3>

        <p>Por favor, mude para uma conta que tenha acesso.</p>

        <Button theme="subtle" onClick={() => voltar()}>
          Voltar
        </Button>
      </Content>

      <Footer>
        <MedflowLogo color="primary" version="primary" />
      </Footer>
    </ErrorContainer>
  );
};

export default Unauthorized;
