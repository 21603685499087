import React, { useState, useMemo, useCallback, useEffect } from 'react';

import SelectCheckboxIntegracao from '@/features/enterprise/components/SelectCheckboxIntegracao';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import { Content, Wrapper } from './styles';

interface ExamesProps {
  exames: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaExamesFhir: React.FC<ExamesProps> = ({ exames, passo }) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const [enabledOptions, setEnabledOptions] = useState<OptionIntegracao[]>([]);

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: exames, passoExecutado: passo });

  const [{ examesStatus }, , { updateExamesStatus }] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map((exame, index) => {
      const option = {
        key: `${index}`,
        title: exame.titulo,
        detail: exame.descricao,
        categoria: exame.categoria,
        value: exame.id,
        edited: exame.editado,
        included: exame.solicitacao_incluida,
        pedido: exame.pedido,
      } as OptionIntegracao;

      return toOptionIntegracao(option);
    });
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback(
    (selOpts: OptionIntegracao[], enOpts?: OptionIntegracao[]) => {
      if (enOpts) {
        setEnabledOptions(enOpts);
      }
      setSelectedOptions(selOpts);
    },
    [],
  );

  useEffect(() => {
    updateExamesStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions && integrationStatus !== 'disabled') {
      changeIntegrationStatus(selectedOptions.length, enabledOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    setEnabledOptions(options);
  }, [options]);

  return (
    <Wrapper>
      <Content>
        <SelectCheckboxIntegracao
          type="Exame"
          options={options}
          generalStatus={passo.executado ? 'disabled' : examesStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaExamesFhir;
