import React from 'react';
import { FiInfo } from 'react-icons/fi';

import { ToastContentLink } from '@/components/ToastContent/styles';

import { ContentClickable, Toast } from './styles';

interface Props {
  numInvalidItems: number;
  onLinkClick?: React.MouseEventHandler<HTMLDivElement>;
}

const ToastDisabledFeedback: React.FC<Props> = ({
  numInvalidItems,
  onLinkClick,
}) => {
  const placeholderText = React.useMemo(() => {
    let placeholder = `${numInvalidItems} pergunta`;
    if (numInvalidItems > 1) {
      placeholder += 's';
    }

    return placeholder;
  }, [numInvalidItems]);

  return (
    <ContentClickable onClick={onLinkClick}>
      <Toast Left={<FiInfo size={22} />}>
        <p>
          Você ainda não respondeu {placeholderText}.{' '}
          <ToastContentLink>Clique aqui</ToastContentLink> para responder.
        </p>
      </Toast>
    </ContentClickable>
  );
};

export default ToastDisabledFeedback;
