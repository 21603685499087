/* eslint-disable @typescript-eslint/no-empty-function */
import httpStatus from 'http-status';
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { LoginResponse } from '@/features/auth/types/login';

import StoreManager from '@/business/StoreManager';

import {
  configureAccessToken,
  configureRefreshToken,
  isSignInRequest,
  shouldIncludeRefreshToken,
} from '../helpers';
import { JWTPair } from '../types/token';

export interface IAuthHandler {
  /**
   * Função para ser chamada antes de qualquer requisição a API
   */
  onBeforeRequest: (req: AxiosRequestConfig) => AxiosRequestConfig;
  /**
   * Função para ser chamada após uma requisição ser feita com sucesso e
   * retornado resposta
   */
  onRequestSucess: (res: AxiosResponse) => AxiosResponse;
  /**
   * Função para ser chamada que recebe um erro de servidor e retorna um
   * booleano indicando que o processo de refresh de token deve ser feito
   */
  onShouldRefresh: (err: AxiosError) => boolean;
  /**
   * Função para ser executada quando a requsisição de refresh de token foi
   * respondida com sucesso
   */
  onRefreshSuccess: (res: AxiosResponse, prevReq: AxiosRequestConfig) => void;
  /**
   * Função para ser executada quando a requisição de refresh de token foi
   * respondida com erro
   */
  onRefreshFail: (err: AxiosError) => void;
}

export interface IAuthConfig {
  api: AxiosInstance;
  handlers: IAuthHandler;
}

export const DefaultAuthHandler: IAuthHandler = {
  onBeforeRequest: config => config,
  onRequestSucess: res => res,
  onShouldRefresh: err =>
    !!(
      err.response &&
      err.response.status === httpStatus.UNAUTHORIZED &&
      err.response.config.url &&
      !err.response.config?.url.includes('refresh')
    ),
  onRefreshSuccess: () => {},
  onRefreshFail: () => {},
};

/**
 * Handler de autenticação padrão da API por Cookie HttpOnly
 *
 * Não é necessário fazer nehuma ação diferente das definidas por
 * DefaultAuthHandler, pois toda autenticação é feita internamente usando os
 * cookies
 */
export const JWTCookieHttpOnlyHandler: IAuthHandler = DefaultAuthHandler;

/**
 * Handler para salvar tokens (access e refresh) no storage do browser (local ou
 * session) e enviá-los via HTTP Header Authorization
 *
 */
export function JWTStorageHandler(store: StoreManager): IAuthHandler {
  return {
    ...DefaultAuthHandler,
    onBeforeRequest: config => {
      if (!isSignInRequest(config) && store.jwtPair) {
        configureAccessToken(config, store.jwtPair.access);
      }

      if (shouldIncludeRefreshToken(config) && store.jwtPair) {
        configureRefreshToken(config, store.jwtPair.refresh);
      }

      return config;
    },

    onRequestSucess: res => {
      if (isSignInRequest(res.config)) {
        const {
          access_token: access,
          refresh_token: refresh,
        } = res.data as LoginResponse;

        store.setJwtPair({ access, refresh });
      }

      return res;
    },

    onRefreshSuccess: (res, prevReq) => {
      store.setJwtPair(res.data as JWTPair);

      if (store.jwtPair) {
        configureAccessToken(prevReq, store.jwtPair.access);
      }
    },
  };
}

export interface AuthInterceptors {
  request: number;
  response: number;
}

export function configureAuthHandlers({
  api,
  handlers,
}: IAuthConfig): AuthInterceptors {
  const requestInterceptor = api.interceptors.request.use(
    handlers.onBeforeRequest,
  );

  let isRefreshing = false;
  let refreshQueue: {
    resolve: (res: AxiosResponse<unknown>) => void;
    reject: (err: AxiosError<unknown>) => void;
  }[] = [];

  const responseInterceptor = api.interceptors.response.use(
    handlers.onRequestSucess,
    error => {
      if (!handlers.onShouldRefresh(error)) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */

      api.interceptors.response.eject(responseInterceptor);

      if (!isRefreshing) {
        isRefreshing = true;

        api
          .post('/auth/token/refresh/')
          .then(res => {
            refreshQueue.forEach(v => v.resolve(res));
            refreshQueue = [];
          })
          .catch(err => {
            refreshQueue.forEach(v => v.reject(err));
            refreshQueue = [];
          })
          .finally(() => {
            configureAuthHandlers({ api, handlers });
            isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        refreshQueue.push({
          resolve: (res: AxiosResponse<unknown>) => {
            handlers.onRefreshSuccess(res, originalRequest);
            resolve(api(originalRequest));
          },
          reject: (err: AxiosError<unknown>) => {
            handlers.onRefreshFail(err);
            reject(err);
          },
        });
      });
    },
  );

  return {
    request: requestInterceptor,
    response: responseInterceptor,
  };
}
