import React from 'react';
import { FiGitPullRequest } from 'react-icons/fi';

import Tooltip from '@/components/Tooltip';

import { ButtonContainer } from './styles';
import { useProtocoloFlowchart } from '../../providers/FlowchartProvider';

interface ButtonOpenFlowchart {
  style?: React.CSSProperties;
}

const OpenFlowchartButton: React.FC<ButtonOpenFlowchart> = ({ style }) => {
  const { dispatchFlowchartDisplay } = useProtocoloFlowchart();

  return (
    <Tooltip title="Ver protocolo completo" placement="right">
      <ButtonContainer style={style} onClick={dispatchFlowchartDisplay}>
        <FiGitPullRequest />
      </ButtonContainer>
    </Tooltip>
  );
};

export default OpenFlowchartButton;
