/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiMinus, FiPlus, FiTrash } from 'react-icons/fi';
import { IconContainerLeft, IconContainerRight, Input } from './styles';

export interface Props {
  title: string;
  initialValue: number;
  maxValue?: number;
  minValue: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
  onRemoveItem?: () => void;
}

const InputFieldQuantity: React.FC<Props> = ({
  title,
  initialValue,
  maxValue,
  minValue,
  disabled,
  onChange,
  onRemoveItem,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const disableMinus = React.useMemo(() => value <= minValue || value <= 1, [
    minValue,
    value,
  ]);

  const disablePlus = React.useMemo(
    () => maxValue !== undefined && value >= maxValue,
    [maxValue, value],
  );

  const onValidate = React.useCallback(
    (toValidateValue: number) => {
      const hasValidation =
        (maxValue !== undefined || minValue !== undefined) &&
        toValidateValue !== undefined;

      if (onChange) {
        onChange(toValidateValue);
      }

      if (hasValidation && minValue && toValidateValue < minValue) {
        if (onChange) {
          onChange(toValidateValue);
        }
      }

      if (hasValidation && maxValue && toValidateValue > maxValue) {
        if (onChange) {
          onChange(toValidateValue);
        }
      }
    },
    [maxValue, minValue, onChange],
  );

  const onMinusClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (disableMinus) {
        if (onRemoveItem) onRemoveItem();
      } else {
        setValue(vl => {
          const newValue = vl - 1;
          onValidate(newValue);
          return newValue;
        });
      }
    },
    [disableMinus, onRemoveItem, onValidate],
  );

  const onPlusClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setValue(vl => {
        const newValue = vl + 1;
        onValidate(newValue);
        return newValue;
      });
    },
    [onValidate],
  );

  const handleChange = React.useCallback(
    (textValue: string, typedValue: number) => {
      setValue(+textValue);
      onValidate(typedValue);
    },
    [onValidate, setValue],
  );

  return (
    <Input
      title={title}
      style={{ width: 100 }}
      disabled={disabled}
      value={value}
      onChangeText={handleChange}
    >
      <IconContainerLeft onClick={onMinusClick} disabled={disabled}>
        {disableMinus ? <FiTrash size={14} /> : <FiMinus size={14} />}
      </IconContainerLeft>
      <IconContainerRight
        onClick={onPlusClick}
        disabled={disabled || disablePlus}
      >
        <FiPlus size={14} />
      </IconContainerRight>
    </Input>
  );
};

export default InputFieldQuantity;
