import fhirpath from 'fhirpath';

export const DeviceDefinitionPropertyCode =
  'http://healthit.medflowapp.com/fhir/CodeSystem/device-definition-property-code';

export type DeviceInclusionStatusCode = 'additional' | 'planned';

export type DevicePropertyType =
  | 'quantity'
  | 'min-quantity'
  | 'max-quantity'
  | 'inclusion-status'
  | 'inclusion-status-reason';

export type DeviceQuantityPropertyType = Exclude<
  DevicePropertyType,
  'inclusion-status' | 'inclusion-status-reason'
>;

export type DevicePropertyValue = Omit<fhir4.DeviceProperty, 'type'>;

export type DeviceNameType =
  | 'udi-label-name'
  | 'user-friendly-name'
  | 'patient-reported-name'
  | 'manufacturer-name'
  | 'model-name'
  | 'other';

const DevicePropertyDisplay: Record<DevicePropertyType, string> = {
  'inclusion-status': 'Status de inclusão de item',
  'inclusion-status-reason': 'Justificativa do status de inclusão',
  quantity: 'Quantidade',
  'max-quantity': 'Quantidade máxima permitida',
  'min-quantity': 'Quantidade mínima permitida',
};

export const devicePropertyCodePath = (
  code: DevicePropertyType,
  comparator = '=',
): string =>
  `property.where(type.coding.system='${DeviceDefinitionPropertyCode}' and type.coding.code${comparator}'${code}')`;

export const buildDevicePropertyType = (
  code: DevicePropertyType,
): fhir4.CodeableConcept => ({
  coding: [
    {
      system: DeviceDefinitionPropertyCode,
      code,
      display: DevicePropertyDisplay[code],
    },
  ],
});

export const buildDeviceProperty = (
  code: DevicePropertyType,
  value: DevicePropertyValue,
): fhir4.DeviceProperty => {
  return {
    type: buildDevicePropertyType(code),
    ...value,
  };
};

export const buildDeviceQuantity = (
  code: DeviceQuantityPropertyType,
  value: number,
): fhir4.DeviceProperty => {
  return buildDeviceProperty(code, { valueQuantity: [{ value }] });
};

export const deviceName = (
  device: fhir4.Device,
  nameTypePriority: DeviceNameType[] = ['model-name'],
): string => {
  const deviceNamePath = nameTypePriority
    .map(nameType => `Device.deviceName.where(type='${nameType}').name`)
    .join(' | ');

  return fhirpath.evaluate(device, deviceNamePath)[0];
};

export const deviceProperty = (
  device: fhir4.Device,
  code: DevicePropertyType,
): fhir4.DeviceProperty[] => {
  return fhirpath.evaluate(device, devicePropertyCodePath(code));
};

export const deviceQuantityValue = (
  device: fhir4.Device,
  code: DeviceQuantityPropertyType,
): number | undefined => {
  return fhirpath.evaluate(
    device,
    `${devicePropertyCodePath(code)}.valueQuantity.value`,
  )[0];
};

export const deviceContainsProperty = (
  device: fhir4.Device,
  code: DevicePropertyType,
): boolean =>
  fhirpath
    .evaluate(device, `${devicePropertyCodePath(code)}.exists()`)
    .some(exists => exists);

export const deviceContainsPropertyCode = (
  device: fhir4.Device,
  propertyType: DevicePropertyType,
  propertyCode: string,
): boolean =>
  fhirpath
    .evaluate(
      device,
      `${devicePropertyCodePath(
        propertyType,
      )}.where(valueCode.coding.code='${propertyCode}').exists()`,
    )
    .some(exists => exists);

export const setDeviceProperty = (
  device: fhir4.Device,
  code: DevicePropertyType,
  value: DevicePropertyValue,
): fhir4.Device => {
  const changed = { ...device };

  const properties = fhirpath.evaluate(
    device,
    devicePropertyCodePath(code, '!='),
  );

  const changedProperty = buildDeviceProperty(code, value);
  changed.property = [...properties, changedProperty];
  return changed;
};

export const removeDeviceProperty = (
  device: fhir4.Device,
  code: DevicePropertyType,
): fhir4.Device => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { property = [], ...changed } = device;

  const properties = fhirpath.evaluate(
    device,
    devicePropertyCodePath(code, '!='),
  );

  if (properties.length > 0) {
    return {
      ...changed,
      property: properties,
    };
  }

  return changed;
};

export const setDevicePropertyQuantity = (
  device: fhir4.Device,
  code: DeviceQuantityPropertyType,
  quantity: number,
): fhir4.Device => {
  return setDeviceProperty(device, code, {
    valueQuantity: [{ value: quantity }],
  });
};
