import React from 'react';

import { TipoItemComplementar, TipoItemComplementarList } from '../../models';
import TipoArtefatoLabel from '../TipoArtefatoLabel';
import { TipoArtefatoBoxMenu, TipoArtefatoOption } from './styles';

export interface TipoArtefatoMenuProps {
  options?: TipoItemComplementar[];
  onTipoSelect?: (tipo: TipoItemComplementar) => void;
  style?: React.CSSProperties;
  className?: string;
}

const TipoArtefatoMenu: React.FC<TipoArtefatoMenuProps> = ({
  options = TipoItemComplementarList,
  onTipoSelect,
  style,
  className,
}) => {
  return (
    <TipoArtefatoBoxMenu style={style} className={className}>
      {options.map(tipo => (
        <TipoArtefatoOption
          key={tipo}
          onClick={() => onTipoSelect && onTipoSelect(tipo)}
        >
          <TipoArtefatoLabel tipo={tipo} />
        </TipoArtefatoOption>
      ))}
    </TipoArtefatoBoxMenu>
  );
};

export default TipoArtefatoMenu;
