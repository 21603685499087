/* eslint-disable react/jsx-props-no-spreading */
import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';
import { InputFieldProps } from '../InputField';

import { Input, IconContainer } from './styles';

type Props = InputFieldProps & { Icon: RenderableElement };
type Ref = HTMLInputElement;

const InputFieldWithIcon = React.forwardRef<Ref, Props>(
  ({ Icon, ...props }, ref) => {
    return (
      <Input ref={ref} {...props}>
        <IconContainer>{enforceElement(Icon)}</IconContainer>
      </Input>
    );
  },
);

export default InputFieldWithIcon;
