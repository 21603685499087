import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { ArtifactField, ArtifactFormModel } from './ArtifactForm.types';

interface Props {
  form: ArtifactFormModel;
  field: ArtifactField;
  control: Control;
  disabled?: boolean;
}

const ArtifactFieldControl: React.FC<Props> = ({
  form,
  field,
  control,
  disabled = false,
}) => {
  const label = React.useMemo(() => {
    let title = field.label;

    if (!field.readOnly && field.required) {
      title += '*';
    }

    return title;
  }, [field.label, field.readOnly, field.required]);

  const Widget = React.memo(field.widget);

  return (
    <Controller
      name={field.name}
      control={control}
      render={controlRenderProps => (
        <Widget
          control={controlRenderProps}
          field={field}
          fieldState={{ disabled, label }}
          form={form}
        />
      )}
    />
  );
};

export default ArtifactFieldControl;
