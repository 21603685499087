import styled from 'styled-components';

import shadows from '@/design/shadows';
import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;

  padding: 24px 0px;

  background-color: ${colors.white};
  box-shadow: ${shadows.minimumShadows};
  border-radius: 10px;
`;

export const InfoContent = styled.div`
  max-width: 334px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;

  img {
    height: 104px;
  }

  h3 {
    ${HeadingSmall(20)}
  }

  p {
    ${Text(16)};
    color: ${colors.gray200};
    text-align: center;
  }
`;

export const FeedbackQuestion = styled.div`
  width: -webkit-fill-available;
  align-self: start;
  margin: 0 24px;

  p {
    ${HeadingSmall(14)};
    margin-bottom: 8px;
    color: ${colors.blackAlpha200};
  }
`;

export const StatusReason = styled.div`
  min-width: 235px;
  max-width: 334px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${colors.whiteAlpha50};
  color: ${colors.blackAlpha200};
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    ${HeadingSmall(14)};
    margin-bottom: 4px;
  }

  p {
    ${Text(14)};
    text-align: center;
  }
`;
