import { SelectionControlOption } from '@/components/SelectionControlInput';
import { Pergunta } from '@/models/Pergunta';

export function toSelectionControlOptions(
  pergunta: Pergunta,
): SelectionControlOption<number>[] {
  return pergunta.variavel.opcoes.map(opcao => ({
    value: opcao.id,
    label: opcao.descricao,
    details: opcao.informacoes,
    excludent: opcao.excludente,
  }));
}

export function toSelectionControlValues(pergunta: Pergunta): number[] {
  return pergunta.variavel.opcoes
    .filter(opcao => opcao.selecionado)
    .map(opcao => opcao.id);
}
