import React from 'react';

import { EvolucaoProtocolo } from '@/models/EvolucaoProtocolo';

import InteracaoProtocolo from '../InteracaoProtocolo';

import { Container } from './styles';

interface Props {
  evolucoes: EvolucaoProtocolo[];
}

const InteracaoProtocoloList: React.FC<Props> = ({ evolucoes }) => {
  return (
    <Container>
      {evolucoes.map(evolucao => (
        <InteracaoProtocolo key={evolucao.id} evolucao={evolucao} />
      ))}
    </Container>
  );
};

export default InteracaoProtocoloList;
