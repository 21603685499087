import React from 'react';
import PlaceholderLoading from '@/components/PlaceholderLoading';

import Flexbox from '@/components/Flexbox';
import { Container } from './styles';

const objTotalWidth = 232; // 200 de width + 16 de margin (left-right)

const range = (stopIndex: number) => Array.from(Array(stopIndex).keys());

const SkeletonObject: React.FC = () => (
  <PlaceholderLoading.Box style={{ width: 200 }}>
    <Flexbox.Column alignItems="center" rowGap={8}>
      <PlaceholderLoading.Circle />
      <PlaceholderLoading.Rect width="80%" height={18} />
    </Flexbox.Column>
  </PlaceholderLoading.Box>
);

const SectionEspecialidadesSkeleton: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [objects, setObjects] = React.useState<number[]>(range(3));

  React.useEffect(() => {
    // Exibe em tela apenas a quantidade de objetos que cabem
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();

      setObjects(range(Math.floor(width / objTotalWidth)));
    }
  }, []);

  return (
    <Container ref={ref}>
      {objects.map(obj => (
        <SkeletonObject key={obj} />
      ))}
    </Container>
  );
};

export default SectionEspecialidadesSkeleton;
