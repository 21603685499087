/**
 * Tipografia
 *
 * Link: https://www.figma.com/file/FlcnudtXbx4goqAzXjc0E9/%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB-Medflow-%E2%80%A2-Design-Pattern?node-id=27%3A408
 */

import { css, CSSProp, DefaultTheme } from 'styled-components';

type TextSizeMapping = {
  [key: number]: { fontSize: number; lineHeight: number };
};

const TextSizes: TextSizeMapping = {
  40: { fontSize: 40, lineHeight: 50 },
  32: { fontSize: 32, lineHeight: 40 },
  24: { fontSize: 24, lineHeight: 30 },
  20: { fontSize: 20, lineHeight: 25 },
  18: { fontSize: 18, lineHeight: 23 },
  16: { fontSize: 16, lineHeight: 20 },
  14: { fontSize: 14, lineHeight: 18 },
  12: { fontSize: 12, lineHeight: 15 },
  11: { fontSize: 11, lineHeight: 14 },
};

const TextSize = (size: number) => {
  const { fontSize, lineHeight } = TextSizes[size];
  return css`
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  `;
};

/**
 * Títulos grandes
 */
export const HeadingLarge = (size: number): CSSProp<DefaultTheme> => {
  return css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;

    ${TextSize(size)};
  `;
};

/**
 * Títulos pequenos
 */
export const HeadingSmall = (size: number): CSSProp<DefaultTheme> => {
  return css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: ${size === 12 ? '400' : '600'};
    text-transform: ${size === 11 ? 'uppercase' : 'none'};

    ${TextSize(size)};
  `;
};

/**
 * Texto
 */
export const Text = (size: number): CSSProp<DefaultTheme> => {
  return css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;

    ${TextSize(size)};
  `;
};

/**
 * Texto pequeno (Text 14 small)
 */
export const TextSmall = (size: number): CSSProp<DefaultTheme> => {
  return css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;

    ${TextSize(size)};
  `;
};
