import InputField from '@/components/InputField';
import React from 'react';
import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta } from '../../types';

const PatternInputString: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}) => {
  const {
    nome,
    meta: {
      pattern,
      pattern_message = 'Insira um valor vlálido.',
      placeholder,
      max_length,
      min_length,
    },
  } = pergunta.variavel;

  const [invalid, setInvalid] = React.useState(false);
  const [error, setError] = React.useState('');
  const [value, setValue] = useAutoUpdateInput(pergunta);

  const regex = React.useMemo(() => RegExp(String.raw`${pattern}`), [pattern]);

  const isValidInput = React.useCallback(
    (inputValue: string) => {
      if (pattern) {
        return regex.test(inputValue);
      }

      return true;
    },
    [pattern, regex],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: inputValue },
      } = event;

      // Hide error during typing
      setError('');
      setValue(inputValue);

      const isValid = isValidInput(inputValue);
      setInvalid(!isValid);

      if (onChange) {
        onChange({
          [nome]: isValid ? inputValue : null,
        });
      }
    },
    [nome, isValidInput, onChange, setValue],
  );

  const displayError = React.useCallback(() => {
    if (invalid) {
      setError(pattern_message);
    } else {
      setError('');
    }
  }, [invalid, pattern_message]);

  return (
    <InputField
      type="text"
      placeholder={placeholder}
      maxLength={max_length}
      minLength={min_length}
      value={value}
      onBlur={displayError}
      onChange={handleChange}
      disabled={disabled}
      error={error}
      style={{
        width: 400,
      }}
    />
  );
};

export default PatternInputString;
