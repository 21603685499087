/* eslint-disable import/no-duplicates */
import { ptBR } from 'date-fns/locale';
import {
  parseISO,
  subDays,
  formatRelative,
  isWeekend,
  differenceInHours,
} from 'date-fns';

export const naturalTime = (dateString: string, prefix = ''): string => {
  /**
   * Formata por extenso uma data passada em string em uma represetação de
   * palavras relativas a data atual.
   * Ex.:
   * dateString: 2022-02-21T15:06:59.931789Z
   * return "Iniciado ontem às 12:06".
   * Obs: Levando em consideração que hoje é 2022-02-22
   *
   * @param {String} dateString dataTime em string
   * @return {String} Representação da data por extenso relativas a data atual
   */

  const dateObject = subDays(parseISO(dateString), 0);

  const diffHours = differenceInHours(new Date(), dateObject);
  const diffDays = diffHours / 24;

  let formatPrefix;

  if (diffHours < 2) {
    formatPrefix = prefix;
  } else if (diffDays >= 2 && diffDays <= 6) {
    formatPrefix = prefix + (isWeekend(dateObject) ? ' no' : ' na'); // se for sabado ou domingo
  } else {
    formatPrefix = `${prefix} em`;
  }

  return `${formatPrefix} ${formatRelative(dateObject, new Date(), {
    locale: ptBR,
  })}`;
};
