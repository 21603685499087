/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ArtifactFieldWidget } from '../components/ArtifactForm/ArtifactForm.types';
import { MultiTextWidget } from '../components/ArtifactForm/ArtifactField.widgets';

export const PrincipioAtivo: ArtifactFieldWidget = ({ ...widgetProps }) => {
  return (
    <MultiTextWidget plusText="Adicionar princípio ativo" {...widgetProps} />
  );
};
