import React from 'react';
import fhirpath from 'fhirpath';
import { Slide, toast } from 'react-toastify';
import { FiCheck, FiCopy, FiEdit2, FiX } from 'react-icons/fi';

import { FhirPedidoType } from '@/features/enterprise/models/types';

import Toast from '@/components/Toast';
import IconButton from '@/components/IconButton';
import Tooltip from '@/components/Tooltip';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderSubTitle,
  Actions,
  InfoContainer,
  InfoContainerIcon,
  InfoContainerText,
  HeaderSubTitleContainer,
  HeaderSubTitleButtons,
  Button,
  TextSpan,
  HeaderSubTitleInputContainer,
  Input,
} from './styles';

interface BoxMedicamentoEditavelProps {
  medicamento: FhirPedidoType;
  disableCopy?: boolean;
  selected?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
}

interface DosagemState {
  hoverContainer: boolean;
  status: null | 'disabled' | 'editing' | 'disabled-edited' | 'edited';
  initial: string;
  editavel: string;
}

const BoxMedicamentoEditavel: React.FC<BoxMedicamentoEditavelProps> = ({
  medicamento,
  disableCopy,
  selected,
  disabled,
  isOpen,
}: BoxMedicamentoEditavelProps) => {
  const principioAtivo = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.medicationCodeableConcept.coding.display' +
        '| MedicationRequest.medicationCodeableConcept.text',
    )[0];
  }, [medicamento]);

  const dosagem = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.dosageInstruction.text',
    )[0];
  }, [medicamento]);

  const instrucoesAdicionais = React.useMemo(() => {
    return fhirpath.evaluate(
      medicamento,
      'MedicationRequest.dosageInstruction.additionalInstruction.text',
    )[0];
  }, [medicamento]);

  const canEditDosagem = React.useMemo(() => {
    return medicamento.status === 'draft' && !disabled;
  }, [medicamento, disabled]);

  const [state, setState] = React.useState({
    detailsOpened: isOpen || false,
    onHover: false,
  });

  const [dosageState, setDosageState] = React.useState<DosagemState>({
    hoverContainer: false,
    status: medicamento.reportedBoolean ? 'edited' : null,
    initial: dosagem,
    editavel: dosagem,
  });

  React.useEffect(() => {
    setDosageState({
      hoverContainer: false,
      status: medicamento.reportedBoolean ? 'edited' : null,
      initial: dosagem,
      editavel: dosagem,
    });
  }, [medicamento, dosagem]);

  const copyMedicamento = React.useCallback(() => {
    let texto = `${principioAtivo}`;
    if (dosagem) {
      texto += `\n${dosagem}`;
    }
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Medicamento copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [principioAtivo, dosagem]);

  const dispatchCustomData = React.useCallback(
    async (pedido: FhirPedidoType) => {
      const retorno = await IntegracaoService.updateFhirResource(pedido);

      if (retorno) {
        const initialDosage = fhirpath.evaluate(
          retorno,
          'MedicationRequest.dosageInstruction.text',
        )[0];

        setDosageState(prev => ({
          ...prev,
          initial: initialDosage,
          status: 'edited',
        }));
      }
    },
    [],
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const editDosagem = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (selected) {
        event.stopPropagation();
      }
      if (canEditDosagem) {
        setDosageState(prev => ({ ...prev, status: 'editing' }));
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    },
    [selected, canEditDosagem],
  );

  const saveClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const dosageInstruction = fhirpath.evaluate(
        medicamento,
        'MedicationRequest.dosageInstruction',
      )[0];

      dispatchCustomData({
        ...medicamento,
        ...{
          dosageInstruction: [
            {
              ...dosageInstruction,
              ...{ text: dosageState.editavel },
            },
          ],
        },
      });
    },
    [dispatchCustomData, medicamento, dosageState.editavel],
  );

  const cancelClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setDosageState(prev => ({
        ...prev,
        status: null,
        editavel: prev.initial,
        hoverContainer: false,
      }));
    },
    [],
  );

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={state.detailsOpened || state.onHover}>
          {principioAtivo}
        </HeaderTitle>
        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar medicamento"
              onClick={copyMedicamento}
              onMouseOver={() => setState(prev => ({ ...prev, onHover: true }))}
              onMouseLeave={() =>
                setState(prev => ({ ...prev, onHover: false }))
              }
              icon={<FiCopy />}
            />
          )}
        </Actions>
      </Header>

      {dosagem && dosageState.status === 'editing' && (
        <HeaderSubTitleInputContainer selected={selected}>
          <Input
            type="text"
            maxLength={320}
            ref={inputRef}
            value={dosageState.editavel}
            onClick={e => e.stopPropagation()}
            autoFocus
            onFocus={e => {
              const pos = dosageState.editavel.length * 2;
              e.currentTarget.setSelectionRange(pos, pos);
            }}
            onBlur={cancelClick}
            onChange={e => {
              const { value } = e.target;
              setDosageState(prev => ({
                ...prev,
                editavel: value,
              }));
            }}
          />
          <HeaderSubTitleButtons>
            <Button
              onMouseDown={saveClick}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <FiCheck />
            </Button>
            <Button
              onMouseDown={cancelClick}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <FiX />
            </Button>
          </HeaderSubTitleButtons>
        </HeaderSubTitleInputContainer>
      )}

      {dosagem && dosageState.status !== 'editing' && (
        <HeaderSubTitleContainer
          highlight={dosageState.hoverContainer}
          disabled={disabled}
          selected={selected}
          onClick={editDosagem}
          onMouseOver={() => {
            if (canEditDosagem) {
              setDosageState(prev => ({ ...prev, hoverContainer: true }));
            }
          }}
          onMouseLeave={() => {
            if (canEditDosagem) {
              setDosageState(prev => ({ ...prev, hoverContainer: false }));
            }
          }}
        >
          <HeaderSubTitle highlight={state.detailsOpened || state.onHover}>
            {dosageState.editavel}
          </HeaderSubTitle>
          <HeaderSubTitleButtons>
            {(dosageState.status === 'edited' ||
              dosageState.status === 'disabled-edited') && (
              <TextSpan>editado</TextSpan>
            )}

            {canEditDosagem && dosageState.hoverContainer && (
              <Tooltip placement="bottom" title="Editar dosagem">
                <Button onClick={editDosagem}>
                  <FiEdit2 />
                </Button>
              </Tooltip>
            )}
          </HeaderSubTitleButtons>
        </HeaderSubTitleContainer>
      )}

      {instrucoesAdicionais && (
        <InfoContainer>
          <InfoContainerIcon />
          <InfoContainerText>{instrucoesAdicionais}</InfoContainerText>
        </InfoContainer>
      )}
    </Container>
  );
};

BoxMedicamentoEditavel.defaultProps = {
  disableCopy: false,
  selected: false,
  disabled: false,
  isOpen: false,
};

export default BoxMedicamentoEditavel;
