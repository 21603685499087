import React, { useCallback } from 'react';

import InputFieldInteger from '@/components/InputFieldInteger';

import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputInteiro: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const {
    nome,
    descricao,
    unidade,
    meta: { placeholder = 'Insira', min_value: minValue, max_value: maxValue },
  } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const [hasValidationError, setHasValidationError] = React.useState<
    null | 'min' | 'max'
  >(null);

  const [validationError, setValidationError] = React.useState<
    string | undefined
  >();

  const onDisplayError = useCallback(() => {
    setValidationError(undefined);

    if (hasValidationError === 'max') {
      setValidationError(
        `O valor de ${descricao || nome} não pode ser maior que ${maxValue}.`,
      );
    }

    if (hasValidationError === 'min') {
      setValidationError(
        `O valor de ${descricao || nome} não pode ser menor que ${minValue}`,
      );
    }
  }, [hasValidationError, maxValue, minValue, nome, descricao]);

  const onValidate = useCallback(
    (toValidateValue: number) => {
      const hasValidation =
        (maxValue !== undefined || minValue !== undefined) &&
        toValidateValue !== undefined;

      setHasValidationError(null);
      if (onChange) {
        onChange({
          [nome]: Number.isNaN(toValidateValue) ? null : toValidateValue,
        });
      }

      if (hasValidation && minValue && toValidateValue < minValue) {
        setHasValidationError('min');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }

      if (hasValidation && maxValue && toValidateValue > maxValue) {
        setHasValidationError('max');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }
    },
    [maxValue, minValue, nome, onChange],
  );

  const handleChange = useCallback(
    (textValue: string, typedValue: number) => {
      setValue(textValue);
      onValidate(typedValue);
    },
    [onValidate, setValue],
  );

  return (
    <InputFieldInteger
      style={{ width: 240 }}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      addon={unidade}
      onChangeText={handleChange}
      onBlur={onDisplayError}
      validationError={validationError}
    />
  );
};

export default InputInteiro;
