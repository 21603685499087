import React from 'react';
import { TabsContext } from '..';

import { Panel } from './styles';

export interface TabPanelProps extends React.PropsWithChildren {
  value: React.Key;
  style?: React.CSSProperties;
  className?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  value,
  children,
  style,
  className,
}) => {
  const { value: tabsValue } = React.useContext(TabsContext);

  if (tabsValue === value) {
    return (
      <Panel style={style} className={className}>
        {children}
      </Panel>
    );
  }

  return <></>;
};

export default TabPanel;
