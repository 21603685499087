import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';

import { HeaderNav, HeaderContent, HeaderTitle } from './styles';

type HeaderTitleProps = {
  title?: string;
  subtitle?: string;
  Title?: RenderableElement;
};

export type HeaderProps = React.PropsWithChildren & {
  Left?: RenderableElement;
  Right?: RenderableElement;
} & HeaderTitleProps;

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  Title,
  Left,
  Right,
  children,
}) => {
  const LeftElement = React.useMemo(() => {
    if (Left) {
      return enforceElement(Left);
    }

    return undefined;
  }, [Left]);

  const RightElement = React.useMemo(() => {
    if (Right) {
      return enforceElement(Right);
    }

    return undefined;
  }, [Right]);

  const TitleElement = React.useMemo(() => {
    if (Title) {
      return enforceElement(Title);
    }

    return (
      <>
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
      </>
    );
  }, [title, subtitle, Title]);

  return (
    <HeaderNav id="medflow-header">
      <HeaderContent>
        {LeftElement}

        <HeaderTitle>{TitleElement}</HeaderTitle>

        {RightElement}
      </HeaderContent>

      {children}
    </HeaderNav>
  );
};

export default Header;
