import styled, { css } from 'styled-components';
import colors from '@/styles/colors';
import DetailsCollapseButton from '@/components/DetailsCollapseButton';

export const Container = styled.div<{ detailsOpen?: boolean }>`
  min-width: 0;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.secondaryLite};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  ${props =>
    props.detailsOpen &&
    css`
      max-height: 100rem;
      transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s ease;
  color: ${props => (props.highlight ? colors.black : colors.hardGray)};
`;

export const HeaderSubTitle = styled.h3<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-self: left;
  color: ${colors.hardGray};

  ${props =>
    !props.highlight &&
    css`
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      word-break: keep-all;
      overflow: hidden;
    `};
`;

export const Actions = styled.div`
  position: relative;

  min-height: 32px;
  min-width: 40px;

  > :nth-last-child(2) {
    margin-right: 48px;
  }
`;

export const CollapseButton = styled(DetailsCollapseButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;
