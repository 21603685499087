/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormProvider } from 'react-hook-form';

import {
  ArtifactFormModel,
  ArtifactFormData,
  UseArtifactFormMethods,
} from '../components/ArtifactForm/ArtifactForm.types';
import { useArtifactForm } from '../components/ArtifactForm/ArtifactForm.helpers';

type RType = fhir4.FhirResource;

export type IArtifactFormContext = UseArtifactFormMethods & {
  form: ArtifactFormModel;
};

export const ArtifactContext = React.createContext<
  IArtifactFormContext | undefined
>(undefined);

interface ProviderProps extends React.PropsWithChildren {
  form: ArtifactFormModel;
  resource?: RType;
  defaultValues?: ArtifactFormData;
}
const ArtifactFormProvider: React.FC<ProviderProps> = ({
  children,
  form,
  resource,
  defaultValues,
}) => {
  const { handleResourceSubmit, ...methods } = useArtifactForm({
    form,
    resource,
    defaultValues,
  });

  return (
    <ArtifactContext.Provider
      value={{ form, handleResourceSubmit, ...methods }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </ArtifactContext.Provider>
  );
};

export const ArtifactFormConsumer: React.FC<
  React.ConsumerProps<IArtifactFormContext>
> = ({ children }) => {
  return (
    <ArtifactContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('Provider not found.');
        }

        return children(context);
      }}
    </ArtifactContext.Consumer>
  );
};

export default ArtifactFormProvider;
