import styled from 'styled-components';

import Card from '@/components/Card';
import { FormGroupContainer } from '@/components/FormGroup/styles';

export const CardProfile = styled(Card)`
  min-width: 390px;
  padding: 20px 24px;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
  }

  img,
  span {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
  }

  form {
    ${FormGroupContainer} + ${FormGroupContainer} {
      margin-top: 16px;
    }
  }
`;

export const PasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
  }
`;

export const PasswordCreate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    max-width: 175px;
  }
`;

export const PasswordChange = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${FormGroupContainer} {
    width: 100%;
    margin-bottom: 8px;
  }
`;
