import FormGroup from '@/components/FormGroup';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 380px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
`;

export const Info = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`;

export const Form = styled.form`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;

  button[type='submit'] {
    min-width: 320px;
    align-self: center;
  }
`;

export const InputsContainer = styled.div`
  flex: 1;
`;

export const PasswordInput = styled(FormGroup).attrs({
  type: 'password',
})`
  & + & {
    margin-top: 16px;
  }
`;
