import React from 'react';

import Button from '@/components/Button';
import CheckboxAlternatives from '@/components/CheckboxAlternatives';
import groupBy from 'lodash.groupby';
import { codeableConceptDisplay } from '@/features/fhir/helpers/fhirpath';
import { CondicaoSolicitacaoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import { PassoExecutado } from '@/models/PassoExecutado';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import {
  AlertBox,
  AlertBoxInfo,
  AlertBoxActions,
  AlertBoxResume,
} from './styles';

interface ConditionAlertPros {
  step: PassoExecutado;
  conditions: CondicaoSolicitacaoType[];
  readonly?: boolean;
  onConditionsConfirmed?: (conditions: CondicaoSolicitacaoType[]) => void;
  onConditionsRefuted?: () => void;
}
interface ConditionMessages {
  title: string;
  note: string;
  confirmHelper: string;
  confirmQuestion: string;
  refuteButton: string;
  confirmButton: string;
  confirmed: string;
  refuted: string;
}

const SingularMessages: ConditionMessages = {
  title: 'Condição identificada',
  note: 'Seu paciente apresenta sinais para a seguinte condição',
  confirmQuestion: 'Você confirma esta condição?',
  confirmHelper: '',
  confirmed: 'Condição confirmada',
  refuted: 'Condição negada',
  refuteButton: 'Negar',
  confirmButton: 'Confirmar',
};

const PluralMessages: ConditionMessages = {
  title: 'Condições identificadas',
  note: 'Seu paciente apresenta sinais para a seguintes condições',
  confirmQuestion: 'Você confirma as condições selecionadas?',
  confirmHelper: 'Selecione uma das opções acima para confirmar uma condição',
  confirmed: 'Condições confirmadas',
  refuted: 'Condições negadas',
  refuteButton: 'Negar todas',
  confirmButton: 'Confirmar',
};

const ConditionAlert: React.FC<ConditionAlertPros> = ({
  step,
  conditions,
  readonly = false,
  onConditionsConfirmed,
  onConditionsRefuted,
}) => {
  const [confirmed, setConfirmed] = React.useState<number[]>(() => {
    const requested = conditions.filter(req => req.data_solicitacao !== null);
    // Caso exibido em modo resumo ou haja itens que foram solicitados, apenas
    // eles deveme estar marcados
    if (readonly || requested.length) {
      return requested.map(({ id }) => id);
    }

    // Em modo de execução, todas as condições devem aparecer marcadas
    return conditions.map(({ id }) => id);
  });

  const [confirming, setConfirming] = React.useState(false);

  const conditionGroups = React.useMemo(() => {
    return groupBy(conditions, ({ pedido }) =>
      codeableConceptDisplay(pedido, 'category'),
    );
  }, [conditions]);

  const isSingularCondition = React.useMemo(() => {
    return conditions.length === 1;
  }, [conditions.length]);

  const messages: ConditionMessages = React.useMemo(() => {
    const value = {
      ...[PluralMessages, SingularMessages][+isSingularCondition],
    };

    if (step.final) {
      value.confirmButton = `Confirmar e finalizar`;
    }

    if (!isSingularCondition && confirmed.length === 1) {
      value.confirmed = 'Uma condição confirmada';
    }

    return value;
  }, [confirmed.length, isSingularCondition, step.final]);

  const confirmationMessage = React.useMemo(() => {
    if (!isSingularCondition && !confirmed.length) {
      return messages.confirmHelper;
    }

    return messages.confirmQuestion;
  }, [
    confirmed.length,
    isSingularCondition,
    messages.confirmHelper,
    messages.confirmQuestion,
  ]);

  const confirmConditions = React.useCallback(async () => {
    if (confirmed.length) {
      setConfirming(true);

      try {
        const { results } = await IntegracaoService.requestSolicitarArtefatos(
          step.id,
          {
            solicitacoes: confirmed,
          },
        );

        if (onConditionsConfirmed) {
          onConditionsConfirmed(results as CondicaoSolicitacaoType[]);
        }
      } finally {
        setConfirming(false);
      }
    }
  }, [confirmed, onConditionsConfirmed, step.id]);

  const refuteConditions = React.useCallback(() => {
    setConfirmed([]);
    if (onConditionsRefuted) {
      onConditionsRefuted();
    }
  }, [onConditionsRefuted]);

  return (
    <AlertBox>
      <AlertBoxInfo>
        <h1>{messages.title}</h1>
        <p>{messages.note}</p>

        {Object.entries(conditionGroups).map(([category, requests]) => (
          <React.Fragment key={category}>
            <h3>{category}</h3>

            <CheckboxAlternatives
              disabled={confirming || readonly}
              horizontal={requests.length > 1}
              value={confirmed}
              onChange={setConfirmed}
              options={requests.map(({ id, pedido: condition }) => ({
                value: id,
                label: codeableConceptDisplay(condition, 'code'),
                readonly: isSingularCondition,
              }))}
            />
          </React.Fragment>
        ))}
      </AlertBoxInfo>

      {readonly ? (
        <AlertBoxResume>
          {confirmed.length ? (
            <>
              <FiCheckCircle /> {messages.confirmed}
            </>
          ) : (
            <>
              <FiXCircle /> {messages.refuted}
            </>
          )}
        </AlertBoxResume>
      ) : (
        <AlertBoxActions>
          <span>{confirmationMessage}</span>

          <Button
            theme="secondary"
            disabled={confirming}
            onClick={refuteConditions}
          >
            {messages.refuteButton}
          </Button>
          <Button
            style={{ minWidth: 186 }}
            disabled={confirmed.length === 0}
            loading={confirming}
            onClick={confirmConditions}
          >
            {messages.confirmButton}
          </Button>
        </AlertBoxActions>
      )}
    </AlertBox>
  );
};

export default ConditionAlert;
