import styled from 'styled-components';

import ModalLayout from '@/layouts/ModalLayout';

export const Modal = styled(ModalLayout)`
  width: 480px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  width: 300px;

  margin: 0 auto;
`;
