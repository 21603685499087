import colors from '@/styles/colors';
import { rgba } from 'polished';
import { FiAlertCircle } from 'react-icons/fi';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.secondaryLite};
  padding: 24px 0;
  min-height: 64px;
  position: relative;

  padding-left: 16px;
  padding-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 300ms ease;
  color: ${props => (props.highlight ? colors.black : colors.hardGray)};
`;

export const HeaderSubTitleContainer = styled.div<{
  highlight?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: -8px;
  min-height: 32px;

  ${props =>
    props.selected && props.highlight
      ? css`
          :hover {
            background: #d9dbe3 !important;
            border: 1px solid #d9dbe3;
          }
        `
      : !props.disabled &&
        props.highlight &&
        css`
          :hover {
            border: 1px solid ${colors.backgroundColor};
            background: ${colors.backgroundColor};
          }
        `}
`;

export const HeaderSubTitleInputContainer = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  margin-left: -8px;

  border-radius: 4px;

  min-height: 32px;

  ${props =>
    props.selected
      ? css`
          background: ${colors.white};
          border: 1px solid ${colors.white};
          input {
            background: ${colors.white};
          }
        `
      : css`
          background: ${colors.backgroundColor};
          border: 1px solid ${colors.backgroundColor};

          input {
            background: ${colors.backgroundColor};
          }
        `}
`;

export const Input = styled.input`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  /* Black Alpha 200 */

  color: #2f3139;

  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const HeaderSubTitleButtons = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const TextSpan = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  /* Gray 300 */

  color: #75777f;

  margin-right: 8px;
`;

export const Button = styled.button`
  width: 23px;
  height: 23px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-in-out;

  :hover:not(:disabled) {
    background-color: ${rgba(colors.secondaryLite, 0.4)};
    color: ${colors.primaryTint};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.secondaryLite};
  }

  + button {
    margin-left: 5px;
  }
`;

export const HeaderSubTitle = styled.h3<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-self: left;
  color: ${colors.hardGray};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  height: 32px;
  margin: 12px 0;
  padding: 8px;

  background: #ecf1ff;
  /* Purple 100 */

  border: 1px solid #4676fa;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const InfoContainerIcon = styled(FiAlertCircle).attrs({
  color: '#4676fa',
})``;

export const InfoContainerText = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  /* Purple 100 */

  color: #4676fa;

  /* Inside Auto Layout */
  align-self: center;
  flex-grow: 1;
  margin: 0px 8px;
`;

export const Actions = styled.div`
  display: flex;
`;
