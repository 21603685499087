import React from 'react';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { usePatientDisplay } from '@/features/enterprise/hooks/useEncounter';
import {
  PatientContainer,
  PatientContainerTitle,
  PatientImage,
  PatientInfo,
  PatientInfoItem,
  PatientInfoItemTitle,
  PatientInfoItemValue,
  PatientBox,
  PatientBoxContent,
} from './styles';

const PatientSection: React.FC = () => {
  const [{ isProtocoloReadOnly }] = useExecucaoContext();
  const { usualName, officialName, age, gender, avatar } = usePatientDisplay();

  const shouldDisplay = React.useMemo(() => officialName || usualName || age, [
    age,
    officialName,
    usualName,
  ]);

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <PatientContainer>
      <PatientContainerTitle>
        {isProtocoloReadOnly ? 'Dados do paciente' : 'Dados do seu paciente'}
      </PatientContainerTitle>
      <PatientBox>
        <PatientBoxContent>
          <PatientImage src={avatar} />
          <PatientInfo>
            {(usualName || officialName) && (
              <PatientInfoItem>
                <PatientInfoItemTitle>
                  {usualName || 'Nome'}
                </PatientInfoItemTitle>
                <PatientInfoItemValue>{officialName}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
            {age && (
              <PatientInfoItem>
                <PatientInfoItemTitle>Idade</PatientInfoItemTitle>
                <PatientInfoItemValue>{age}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
            {gender && (
              <PatientInfoItem>
                <PatientInfoItemTitle>Sexo</PatientInfoItemTitle>
                <PatientInfoItemValue>{gender}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
          </PatientInfo>
        </PatientBoxContent>
      </PatientBox>
    </PatientContainer>
  );
};

export default PatientSection;
