import FlatList from '@/components/FlatList';
import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall, Text } from '@/design/typography';
import styled, { css } from 'styled-components';

export const PreviewItem = styled.div<{ detailsOpen?: boolean }>`
  min-width: 0;
  background: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  border: 2px dashed ${colors.gray100};
  background-color: ${colors.gray50};

  /* Button Shadows */
  box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.06);

  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  ${props =>
    props.detailsOpen &&
    css`
      max-height: 100rem;
      transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 16px;
`;

export const PreviewItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SearchResultBox = styled(FlatList)`
  display: flex;
  flex: 1;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid ${colors.purple100};
  background: ${colors.white};

  /* Small Shadow */
  box-shadow: ${shadows.smallShadow};
` as typeof FlatList;

export const SearchResultContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const SearchResultItemStyle = css`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;

  color: ${colors.blackAlpha100};
  ${Text(14)}
`;

export const SearchResultLoading = styled.div`
  ${SearchResultItemStyle}
  opacity: 0.8;
`;

export const SearchResultEmpty = styled.div`
  ${SearchResultItemStyle}
`;

export const SearchResultItem = styled.button`
  ${SearchResultItemStyle}

  :hover:not(:disabled) {
    color: ${colors.purple100};
    background: ${colors.purple50};
  }
`;

export const AddItemComplementarButton = styled.button`
  width: 100%;

  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  color: ${colors.gray100};
  border-radius: 4px;

  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray100};

  &:hover:not(:disabled) {
    background: ${colors.purple50};
    color: ${colors.purple100};
    border-color: ${colors.purple100};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.gray50};
    border-color: ${colors.gray50};
  }

  /* Heading Small 14 */
  ${HeadingSmall(14)}

  svg {
    margin-right: 8px;
  }
`;
