import styled from 'styled-components';

import { FormGroupContainer } from '@/components/FormGroup/styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${FormGroupContainer} + ${FormGroupContainer} {
    margin-top: 32px;
  }
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  align-items: center;

  button {
    width: 459px;

    + button {
      margin-top: 14px;
    }
  }
`;

export const Feedback = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
  }

  button {
    margin-top: 32px;
    width: 318px;
    align-self: center;
  }
`;
