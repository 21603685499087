import styled from 'styled-components';

import colors from '@/design/colors';
import gaps from '@/design/gaps';
import { HeadingSmall } from '@/design/typography';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: ${gaps.defaultGap};
`;

export const Header = styled.h1`
  ${HeadingSmall(16)};
  margin-bottom: 16px;
  color: ${colors.blackAlpha200};
`;
