import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { Feature } from '@/models/Feature';

import React from 'react';

interface IFeaturesContext {
  flags: Feature[];
  hasFeature: (name: string) => boolean;
}

const FeaturesContext = React.createContext<IFeaturesContext>({
  flags: [],
  hasFeature: () => false,
});

const FeaturesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  const [flags, setFlags] = React.useState<Feature[]>([]);

  const initFeatures = React.useCallback(() => {
    if (user && user.empresa) {
      setFlags(user.empresa.features);
    }
  }, [user]);

  const hasFeature = React.useCallback(
    (name: string) => {
      return flags.findIndex(flag => flag.codigo === name) !== -1;
    },
    [flags],
  );

  React.useEffect(() => {
    initFeatures();
  }, [initFeatures]);

  return (
    <FeaturesContext.Provider value={{ flags, hasFeature }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export function useFeatures(): IFeaturesContext {
  return React.useContext<IFeaturesContext>(FeaturesContext);
}

export default FeaturesProvider;
