import React from 'react';

import { useFeatures } from '@/features/features/providers/FeaturesProvider';

import { TipoItemComplementar } from '../models';

type ITipoFeatureMap = Record<TipoItemComplementar, string>;

export const TipoItemFeatureMap: ITipoFeatureMap = {
  'solicitacao-exame': 'prescricaolivre.exames',
  'prescricao-medica': 'prescricaolivre.medicamentos',
  manipulado: 'prescricaolivre.medicamentos.manipulados',
  orientacao: 'prescricaolivre.orientacoes',
  encaminhamento: 'prescricaolivre.encaminhamentos',
  procedimento: 'prescricaolivre.procedimentos',
};

export function useAllowedMenu(): TipoItemComplementar[] {
  const { hasFeature } = useFeatures();

  const menus = React.useMemo(
    () =>
      Object.entries(TipoItemFeatureMap)
        .filter(([, featureName]) => hasFeature(featureName))
        .map(([tipo]) => tipo as TipoItemComplementar),
    [hasFeature],
  );

  return menus;
}

export function useIsCondutaComplementarEnabled(): boolean {
  const menus = useAllowedMenu();

  return menus.length > 0;
}
