import Button from '@/components/Button';
import colors from '@/styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid ${colors.secondaryLite};
  border-width: 0 0 1px 0;
`;

export const CloseButton = styled(Button).attrs({ theme: 'tertiary' })`
  display: flex;
  border-radius: 50%;
  min-width: auto;
  padding: 2px;

  svg {
    box-sizing: content-box;
    color: ${colors.black};
  }
`;
