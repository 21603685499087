import styled from 'styled-components';

import colors from '@/design/colors';
import { Text } from '@/design/typography';
import ButtonSmall from '@/components/ButtonSmall';
import TextareaAutoResizable from '@/components/TextareaAutoResizable';

export const ContentEditableButton = styled.button`
  padding: 5px 9px;
  border-radius: 4px;
  margin-left: 7px;
  flex: 1;
  transition: all 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Text(14)};
  color: ${colors.blackAlpha200};

  svg {
    display: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background-color: ${colors.gray50};
    color: ${colors.purple100};

    svg {
      display: block;
    }
  }
`;

export const ContentEditableText = styled.span`
  flex: 1;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
  padding-right: 8px;
  text-align: start;
`;

export const ContentEditablePanel = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const ContentEditedIndicator = styled.span`
  ${Text(14)};
  color: ${colors.gray200};
`;

export const ContentEditableForm = styled.form`
  position: relative;
  display: flex;
  flex: 1;
  margin-left: 7px;
`;

export const ContentEditableTextarea = styled(TextareaAutoResizable)`
  padding: 5px 76px 5px 8px;
  background-color: ${colors.white};
  border-radius: 4px;
  flex: 1;
  ${Text(14)};
  color: ${colors.gray300};
  resize: none;
`;

export const ContentEditableFormControl = styled.div`
  position: absolute;
  right: 8px;
  top: 4px;
  display: flex;
  column-gap: 8px;
`;

export const ContentEditableFormControlButton = styled(ButtonSmall)`
  min-width: 20px;
  min-height: 20px;
`;
