/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiPlus } from 'react-icons/fi';

import Dropdown, { DropdownRef } from '@/components/Dropdown';

import { TipoItemComplementar } from '../../models';
import TipoArtefatoMenu, { TipoArtefatoMenuProps } from '../TipoArtefatoMenu';

import { DropdownButton, TotalBadge } from './styles';

type ButtonClickHandler = React.MouseEventHandler<HTMLButtonElement>;

export interface PrescricoesButtonUIProps
  extends React.PropsWithChildren<
    Pick<TipoArtefatoMenuProps, 'options' | 'onTipoSelect'>
  > {
  total?: number;
  style?: React.CSSProperties;
  className?: string;
  onClick?: ButtonClickHandler;
}

const PrescricoesButtonUI: React.FC<PrescricoesButtonUIProps> = ({
  total,
  style,
  className,
  children,
  options,
  onClick,
  onTipoSelect,
}) => {
  const dropdownRef = React.useRef<DropdownRef>(null);

  const handleTipoSelect = React.useCallback(
    (tipoItem: TipoItemComplementar) => {
      if (dropdownRef.current) {
        dropdownRef.current.hide();
      }

      if (onTipoSelect) {
        onTipoSelect(tipoItem);
      }
    },
    [onTipoSelect],
  );

  return (
    <Dropdown
      className={className}
      ref={dropdownRef}
      style={style}
      menuAlignment="right"
      onClick={onClick}
      DropdownToggle={props => <DropdownButton {...props} />}
      DropdownMenu={
        <TipoArtefatoMenu options={options} onTipoSelect={handleTipoSelect} />
      }
    >
      <FiPlus size={26} />
      {children}
      {!!total && <TotalBadge>{total}</TotalBadge>}
    </Dropdown>
  );
};

export default PrescricoesButtonUI;
