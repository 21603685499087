import colors from '@/design/colors';
import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonContainer = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 24px;
  border: 1px solid ${colors.gray100};

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};

  cursor: pointer;

  &:disabled {
    color: ${colors.gray50};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.gray50};
  }
`;
