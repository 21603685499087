/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';

import Modal from '@/layouts/ModalLayout';
import FormGroup from '@/components/FormGroup';
import Button from '@/components/Button';
import ErrorMessage from '@/components/ErrorMessage';
import { useApiErrors } from '@/utils/forms';

import { ModalProps } from '@/components/Modal';
import { APIValidationError } from '@/types/Api';
import { Form, FormActions, Feedback } from './styles';

interface FormData {
  old_password?: string;
  new_password1: string;
  new_password2: string;
}

interface ChangePasswordModalProps
  extends Omit<ModalProps, 'children' | 'onAfterOpen'> {
  create: boolean;
}

const schema = Yup.object().shape({
  new_password1: Yup.string()
    .min(6, 'A senha precisa ter pelo menos 6 caracteres.')
    .test(
      'is-numeric',
      'A senha precisa conter pelo menos uma letra.',
      value => !/^[0-9]*$/.test(value || ''),
    ),
  new_password2: Yup.string().oneOf(
    [Yup.ref('new_password1')],
    'As senhas não correspondem. Por favor, verifique e tente novamente.',
  ),
});

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  create = false,
  onAfterClose,
  ...modalProps
}) => {
  // const [, { dispatchChangePassword }] = useAuthContext();

  const [isCreatePassword, setCreatePassword] = useState(create);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetState = useCallback(() => {
    setLoading(false);
    setSuccess(false);
    setCreatePassword(create);
  }, [create]);

  const title = useMemo(() => {
    return isCreatePassword ? 'Criar senha' : 'Alteração de senha';
  }, [isCreatePassword]);

  const feedbackMessage = useMemo(() => {
    return isCreatePassword
      ? 'Senha criada com sucesso!'
      : 'Sua senha foi alterada com sucesso!';
  }, [isCreatePassword]);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(schema) as Resolver<FormData>,
    criteriaMode: 'all',
  });

  const {
    apiErrors: { non_field_errors },
    setApiErrors,
    clearApiErrors,
  } = useApiErrors<FormData>({}, { setError });

  const onSubmit = async () => {
    try {
      setLoading(true);
      clearApiErrors();

      // await dispatchChangePassword(data);

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          setApiErrors(err.response.data as APIValidationError<FormData>);
        } else {
          setApiErrors({
            non_field_errors: ['Não foi possível alterar a senha.'],
          });
        }
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      onAfterClose={onAfterClose}
      onAfterOpen={resetState}
      {...modalProps}
    >
      {success ? (
        <Feedback>
          <p>{feedbackMessage}</p>
          <Button theme="secondary" onClick={onAfterClose}>
            OK
          </Button>
        </Feedback>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {non_field_errors && non_field_errors.length > 0 && (
            <ErrorMessage>{non_field_errors[0]}</ErrorMessage>
          )}

          {!isCreatePassword && (
            <FormGroup
              id="old_password"
              type="password"
              label="Senha atual"
              errors={
                errors.old_password && errors.old_password.message
                  ? [errors.old_password.message]
                  : []
              }
              {...register('old_password', { required: true })}
            />
          )}

          <FormGroup
            id="new_password1"
            type="password"
            label="Nova senha"
            errors={
              errors.new_password1 && errors.new_password1.message
                ? [errors.new_password1.message]
                : []
            }
            {...register('new_password1', { required: true })}
          />

          <FormGroup
            id="new_password2"
            type="password"
            label="Confirme a senha"
            errors={
              errors.new_password2 && errors.new_password2.message
                ? [errors.new_password2.message]
                : []
            }
            {...register('new_password2', { required: true })}
          />

          <FormActions>
            <Button theme="primary" type="submit" loading={loading}>
              Confirmar
            </Button>
            <Button theme="tertiary" type="button" onClick={onAfterClose}>
              Cancelar
            </Button>
          </FormActions>
        </Form>
      )}
    </Modal>
  );
};

export default ChangePasswordModal;
