import React from 'react';
import styled from 'styled-components';

import colors from '@/styles/colors';

import { FiCheck } from 'react-icons/fi';

const LineCircleStyle = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${colors.primaryTint};
  border-radius: 50%;
  border: 2px solid ${colors.primaryTint};
  margin-left: -8px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};
`;

const LineCircle: React.FC = () => {
  return (
    <LineCircleStyle>
      <FiCheck />
    </LineCircleStyle>
  );
};

export default LineCircle;
