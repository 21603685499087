import { css } from 'styled-components';

export const scrollbar = css`
  ::-webkit-scrollbar {
    width: 22px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 16px;
    background-color: #c4c4c4c4;
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
