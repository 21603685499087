import React from 'react';

type KeyDownHandler = React.KeyboardEventHandler<HTMLElement>;
type KeyDown = React.KeyboardEvent<HTMLElement>;

interface UseKeyboardNavReturn {
  position: number;
  setPosition: (value: number) => void;
  handleKeyDown: KeyDownHandler;
}

interface UseKeyboardNavProps {
  total: number;
  initialPosition?: number;
  onLeave?: (positon: number) => void;
  onEnter?: (position: number) => void;
}

const useKeyboardNavigation = ({
  total,
  initialPosition = -1,
  onEnter,
  onLeave,
}: UseKeyboardNavProps): UseKeyboardNavReturn => {
  const [position, setPosition] = React.useState(initialPosition);

  const handleKeyDown = React.useCallback(
    (event: KeyDown) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setPosition(prevIndex =>
          prevIndex === total - 1 ? prevIndex : (prevIndex + 1) % total,
        );
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setPosition(prevIndex =>
          prevIndex === 0 ? prevIndex : (prevIndex - 1 + total) % total,
        );
      } else if (event.key === 'Enter') {
        if (onEnter) {
          event.preventDefault();

          onEnter(position);
        }
      } else if (event.key === 'Escape') {
        if (onLeave) {
          event.preventDefault();

          onLeave(position);
        }
      }
    },
    [onEnter, onLeave, position, total],
  );

  React.useEffect(() => {
    setPosition(-1);
  }, [total]);

  return {
    position,
    setPosition,
    handleKeyDown,
  };
};

export default useKeyboardNavigation;
