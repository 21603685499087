import styled from 'styled-components';

import shadows from '@/design/shadows';
import colors from '@/design/colors';

const Card = styled.div`
  border-radius: 8px;
  padding: 24px;
  background-color: ${colors.white};
  box-shadow: ${shadows.minimumShadows};
`;

export default Card;
