import React from 'react';
import { Content, PerguntaList, Title } from './styles';

interface CategoriaContentProps extends React.PropsWithChildren {
  categoria: string;
}

const CategoriaContent: React.FC<CategoriaContentProps> = ({
  categoria,
  children,
}: CategoriaContentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [headerOnTop, setHeaderOnTop] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    const currScroll = window.pageYOffset;
    const offsetTop = (ref.current?.offsetTop || 0) + 24;
    const componentSize =
      (ref.current?.getBoundingClientRect().height || 0) + 24;

    setHeaderOnTop(
      currScroll >= offsetTop && currScroll <= offsetTop + componentSize,
    );
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content ref={ref}>
      <Title sticky={headerOnTop}>{categoria}</Title>

      <PerguntaList>{children}</PerguntaList>
    </Content>
  );
};

export default CategoriaContent;
