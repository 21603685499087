import fhirpath from 'fhirpath';
import unset from 'lodash.unset';
import * as yup from 'yup';

import {
  medicationReferenceFHIRPath,
  updateOrCreateMedicationReference,
} from '@/features/fhir/helpers/medicationRequest';

import { FieldProxy } from '../components/ArtifactForm/ArtifactForm.fields';

import MedicamentoForm from './Medicamento';
import { PrincipioAtivo } from './Manipulado.widgets';

function unsetMedicationReference(
  request: fhir4.MedicationRequest,
  propertyPath: string,
  unsetReference = false,
) {
  const { contained = [], medicationReference, ...medicationRequest } = {
    ...request,
  };

  let medicationContained: fhir4.FhirResource[] = [];
  if (medicationReference) {
    medicationContained = contained.map(inner => {
      if (
        inner.resourceType === 'Medication' &&
        inner.id === medicationReference.reference?.substring(1)
      ) {
        unset(inner, propertyPath);
        return inner;
      }

      return inner;
    });
  }

  const updatedRequest: fhir4.MedicationRequest = { ...medicationRequest };
  if (medicationContained.length) {
    updatedRequest.contained = medicationContained;
  }

  // Restore medicationReference if should not unset
  if (!unsetReference && medicationReference) {
    updatedRequest.medicationReference = medicationReference;
  }

  return updatedRequest;
}

const ManipuladoForm = [
  ...MedicamentoForm,
  FieldProxy({
    name: 'medicationReference',
    label: 'Nome do manipulado',
    widget: 'short_answer',
    getValue: fhirData => {
      const request = fhirData as fhir4.MedicationRequest;
      const pathPrefix = medicationReferenceFHIRPath(request);
      const fhirPath = `${pathPrefix}.code.select(text | coding.display)`;

      return fhirpath.evaluate(fhirData, fhirPath)[0] || '';
    },
    unsetValue: fhirData => {
      const request = fhirData as fhir4.MedicationRequest;
      return unsetMedicationReference(request, 'code');
    },
    setValue: (fhirData, value: string) => {
      const request = fhirData as fhir4.MedicationRequest;
      return updateOrCreateMedicationReference(request, {
        code: { text: value },
      });
    },
  }),
  FieldProxy({
    name: 'ingredient',
    label: 'Composição',
    getValue: fhirData => {
      const request = fhirData as fhir4.MedicationRequest;

      const ingredient = fhirpath.evaluate(
        fhirData,
        `${medicationReferenceFHIRPath(request)}
          .ingredient
          .itemCodeableConcept
          .select((text | coding.display).first())`,
      );

      if (!ingredient.length) {
        return [''];
      }

      return ingredient;
    },
    unsetValue: fhirData => {
      const request = fhirData as fhir4.MedicationRequest;
      return unsetMedicationReference(request, 'ingredient');
    },
    setValue: (fhirData, values: string[]) => {
      const request = fhirData as fhir4.MedicationRequest;
      const ingredient = values.map<fhir4.MedicationIngredient>(text => ({
        itemCodeableConcept: { text },
      }));

      return updateOrCreateMedicationReference(request, { ingredient });
    },
    widget: PrincipioAtivo,
    validator: yup
      .array()
      .of(yup.string().required('Este campo é obrigatório')),
  }),
];

export default ManipuladoForm;
