// eslint-disable-next-line import/no-extraneous-dependencies
import api from '@/sevices/api';
import { APIRequestParams, APIResult } from '@/types/Api';
import {
  ProtocoloExecutadoListItem,
  StatusExecucao,
} from '@/models/ProtocoloExecutado';

interface ProtocoloExecutadoListFilter extends APIRequestParams {
  codigo_protocolo?: string;
  user?: string | string[];
  empresa?: string | string[];
  status?: StatusExecucao | StatusExecucao[];
  tagged?: boolean;
  integrado?: boolean;
  executado?: boolean;
  data_criacao_after?: string;
  data_criacao_before?: string;
  data_execucao_after?: string;
  data_execucao_before?: string;
}

async function list(
  params: ProtocoloExecutadoListFilter,
): Promise<APIResult<ProtocoloExecutadoListItem>> {
  return api
    .get<APIResult<ProtocoloExecutadoListItem>>(
      '/protocolos-executados/list/',
      { params },
    )
    .then(res => res.data);
}

export default {
  list,
};
