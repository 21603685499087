/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Protocolo } from '@/models/Protocolo';

import { Input, InputContent, InputIcon, InputText } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  protocolo: Protocolo;
}

const InputProtocolo: React.FC<Props> = ({ protocolo, ...props }) => {
  return (
    <Input {...props}>
      <InputContent>
        <InputIcon src={protocolo.imagem_url} alt="" />
        <InputText>{protocolo.nome}</InputText>
      </InputContent>
    </Input>
  );
};

export default InputProtocolo;
