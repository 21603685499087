import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getNextAPIResultPage } from '@/utils/query';
import { Protocolo } from '@/models/Protocolo';
import { Especialidade } from '@/models/Especialidade';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import { SpinnerInfinite } from '@/components/Spinner/variations';
import FlatList from '@/components/FlatList';
import EspecialidadesAPI from '@/features/dashboard/services/EspecialidadesAPI';

import PageLayout from '@/layouts/PageLayout';
import PlaceholderFlatListProtocolo from '../../components/PlaceholderFlatListProtocolo';

import InputProtocolo from '../../components/InputProtocolo';
import HeaderEspecialidade from '../../components/HeaderEspecialidade';

type LocationProps = {
  especialidade: Especialidade;
};

const PageEspecialidadeProtocoloList: React.FC = () => {
  const location = useLocation<LocationProps>();
  const history = useHistory();

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['especialidade-protocolo-list', location.state.especialidade.id],
    ({ pageParam = 1, queryKey: [, especialidadeId] }) =>
      EspecialidadesAPI.listProtocolos(especialidadeId as number, {
        page: pageParam,
        page_size: 25,
      }),
    {
      getNextPageParam: getNextAPIResultPage,
    },
  );

  const protocolos: Protocolo[] = React.useMemo(() => {
    if (data) {
      return data.pages.map(group => group.results).flat();
    }

    return [];
  }, [data]);

  const handleMore = React.useCallback(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching]);

  const handleOpen = React.useCallback(
    ({ nome: protocolo_nome, codigo: codigo_protocolo }: Protocolo) => {
      const params = new URLSearchParams({
        protocolo_nome,
        codigo_protocolo,
      });

      history.push(`/abrir-execucao/?${params.toString()}`);
    },
    [history],
  );

  const renderFooter = React.useCallback(() => {
    if (isFetchingNextPage) {
      return <SpinnerInfinite />;
    }

    return null;
  }, [isFetchingNextPage]);

  return (
    <PageLayout
      Nav={<HeaderEspecialidade especialidade={location.state.especialidade} />}
    >
      <LoadingSwitchTransition
        style={{
          flex: 1,
        }}
        loading={isLoading}
        LoadingComponent={<PlaceholderFlatListProtocolo length={6} />}
      >
        <FlatList
          data={protocolos}
          keyExtractor={({ item: { id } }) => id}
          renderItem={({ item }) => (
            <InputProtocolo protocolo={item} onClick={() => handleOpen(item)} />
          )}
          hasMore={hasNextPage}
          onEndReached={handleMore}
          ListFooterComponent={renderFooter}
        />
      </LoadingSwitchTransition>
    </PageLayout>
  );
};

export default PageEspecialidadeProtocoloList;
