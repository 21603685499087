import React from 'react';

import SelectionControlAlternatives, {
  SelectionControlAlternativesProps,
} from '../SelectionControlAlternatives';
import { SelectionControlOption } from '../SelectionControlInput';

export interface Props<TValue>
  extends Omit<
    SelectionControlAlternativesProps<TValue>,
    'selectionType' | 'selected'
  > {
  value?: TValue[];
  onChange?: (value: TValue[]) => void;
}

const CheckboxAlternatives = <TValue extends unknown>({
  options,
  value = [],
  onChange,
  onAlternativeSelect,
  ...props
}: Props<TValue>): JSX.Element => {
  const excludentIDs = React.useMemo(
    () => options.filter(opt => opt.excludent).map(opt => opt.value),
    [options],
  );

  const handleClick = React.useCallback(
    (option: SelectionControlOption<TValue>) => {
      if (onAlternativeSelect) {
        onAlternativeSelect(option);
      }

      if (!onChange) {
        return;
      }

      if (value.includes(option.value)) {
        // Unmark when select an already selected option
        onChange(value.filter(val => val !== option.value));
      } else if (option.excludent) {
        // Excludent option marked, others options should be unmarked
        onChange([option.value]);
      } else {
        // Attach new option, and unmark possible excludent option marked
        onChange(
          [...value, option.value].filter(val => !excludentIDs.includes(val)),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, value, excludentIDs],
  );

  return (
    <SelectionControlAlternatives
      options={options}
      selected={value}
      onAlternativeSelect={handleClick}
      selectionType="checkbox"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CheckboxAlternatives;
