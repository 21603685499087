/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { FiLogOut, FiMessageSquare, FiUser } from 'react-icons/fi';

import DoctorUndefined from '@/assets/illustrations/ui/doctors/undefined.svg';
import BoxMenu from '@/components/BoxMenu';
import BoxMenuOption from '@/components/BoxMenu/BoxMenuOption';
import SecondaryMenu from '@/components/SecondaryMenu';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { getUserDisplayName } from '@/features/auth/helpers';
import { useFeedbackContext } from '@/features/feedbacks/contexts/FeedbackContext';

const MenuOption: React.FC<LinkProps> = ({ children, ...props }) => (
  <Link
    {...props}
    component={({ navigate }) => (
      <BoxMenuOption onClick={navigate}>{children}</BoxMenuOption>
    )}
  />
);

const UserDropdownMenu = () => {
  const {
    actions: { dispatchOpenModal },
  } = useFeedbackContext();

  return (
    <BoxMenu style={{ minWidth: 258 }}>
      <MenuOption to="/perfil">
        <FiUser size={16} />
        Ver Perfil
      </MenuOption>
      <BoxMenuOption onClick={() => dispatchOpenModal()}>
        <FiMessageSquare size={16} />
        Envie seu feedback
      </BoxMenuOption>
      <MenuOption to="/sair">
        <FiLogOut size={16} />
        Sair
      </MenuOption>
    </BoxMenu>
  );
};

const SecondaryMenuUser: React.FC = () => {
  const { user } = useAuth();

  const avatar = React.useMemo(() => {
    if (user && user.avatar_url) {
      return user.avatar_url;
    }
    return DoctorUndefined;
  }, [user]);

  const title = React.useMemo(() => getUserDisplayName(user), [user]);

  return (
    <SecondaryMenu avatar={avatar} DropdownMenu={UserDropdownMenu}>
      {title}
    </SecondaryMenu>
  );
};

export default SecondaryMenuUser;
