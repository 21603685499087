import React from 'react';

import Button from '@/components/Button';
import { ReactComponent as AlertIllustration } from '@/assets/illustrations/feedback-graphics/alert.svg';

import { Container } from './styles';

type ComponentProps = {
  onReload: () => void;
};

const Error: React.FC<ComponentProps> = ({ onReload }) => {
  return (
    <Container>
      <AlertIllustration />

      <h3>Ops! Algo inesperado aconteceu</h3>

      <p>
        Ocorreu um erro ao carregar a lista de calculadoras disponíveis. Por
        favor, tente novamente.
      </p>

      <Button theme="secondary" onClick={onReload}>
        Carregar calculadoras
      </Button>
    </Container>
  );
};

export default Error;
