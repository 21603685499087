import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconEncaminhamento from '@/assets/encaminhamento-icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import ListaEncaminhamentosDefault from './index.default';
import ListaEncaminhamentosFhir from './index.fhir';

import { ItemList, SectionTitle } from './styles';

interface ListaEncaminhamentosProps {
  encaminhamentos: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaEncaminhamentos: React.FC<ListaEncaminhamentosProps> = ({
  encaminhamentos,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  return (
    <ItemList>
      <SectionTitle>
        <img src={IconEncaminhamento} alt="Encaminhamento Icone" />

        <h2>Encaminhamento</h2>
      </SectionTitle>

      {integracaoEnterpriseAtiva ? (
        <ListaEncaminhamentosFhir
          encaminhamentos={encaminhamentos}
          passo={passoExecutado}
        />
      ) : (
        <ListaEncaminhamentosDefault encaminhamentos={encaminhamentos} />
      )}
    </ItemList>
  );
};

export default ListaEncaminhamentos;
