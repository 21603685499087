import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconProcedimento from '@/assets/procedimentos-icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';

import ListaProcedimentosDefault from './index.default';
import ListaProcedimentosFhir from './index.fhir';
import { ItemList, SectionTitle } from './styles';

interface ListaProcedimentosProps {
  procedimentos: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaProcedimentos: React.FC<ListaProcedimentosProps> = ({
  procedimentos,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  return (
    <ItemList>
      <SectionTitle>
        <img src={IconProcedimento} alt="Procedimento Icone" />

        <h2>Procedimentos sugeridos</h2>
      </SectionTitle>

      {integracaoEnterpriseAtiva ? (
        <ListaProcedimentosFhir
          procedimentos={procedimentos}
          passo={passoExecutado}
        />
      ) : (
        <ListaProcedimentosDefault procedimentos={procedimentos} />
      )}
    </ItemList>
  );
};

export default ListaProcedimentos;
