/**
 * Shadows
 *
 * Figma: https://www.figma.com/file/FlcnudtXbx4goqAzXjc0E9/%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB-Medflow-%E2%80%A2-Design-Pattern?node-id=60%3A166
 */

export default {
  minimumShadows: '0px 2px 2px rgba(0, 0, 0, 0.05)',
  smallShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
  mediumShadow: '0px 2px 12px rgba(0, 0, 0, 0.16)',
  mediumShadow2: '0px 2px 8px rgba(0, 0, 0, 0.24)',
  shadowModal: '0px 8px 24px 4px rgba(0, 0, 0, 0.24)',
  colorButtonShadows: '0px 2px 6px 4px rgba(70, 118, 250, 0.72)',
  buttonShadows: '0px 2px 6px 4px rgba(0, 0, 0, 0.16)',
  shadowSideBox: '2px 2px 15px rgba(0, 0, 0, 0.1)',
};
