import { FiAlertCircle } from 'react-icons/fi';
import styled from 'styled-components';
import listCircle from '@/assets/list-circle.svg';
import colors from '@/design/colors';
import { Text } from '@/design/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 16px;
`;

export const EditorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  cursor: default;

  .remirror-editor-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    /* White */

    width: 100%;
    height: 100%;

    background: ${colors.white};

    border: 1px solid #4676fa;
    border-radius: 4px 4px 0px 0px;

    margin: -1px 0px;

    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;
    overflow-y: none;

    ${Text(14)};

    color: ${colors.blackAlpha200};
  }

  .remirror-editor {
    width: 100%;

    > * {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    table {
      border: 1px solid #d9dbe3;
      border-radius: 8px;
      border-spacing: 0px;
      margin: 8px 0px;
      margin-top: 8px !important;

      th {
        padding: 12px 8px;
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }

        text-align: left;
      }

      th,
      td {
        border: 1px solid #d9dbe3;
        padding: 8px 32px 8px 8px;
      }

      tbody {
        tr {
          &:nth-child(2n + 1) {
            background: ${colors.whiteAlpha50};
          }
        }
      }

      tbody {
        tr {
          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 8px;
              }
              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;
      list-style-position: inside;
      color: ${colors.gray300};

      p {
        display: inline;
      }

      ul,
      ol {
        margin-left: 1.5em;
        margin-top: 0.8em;
      }
    }

    ul[data-task-list] {
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 0.8em;
        }
      }
    }

    ul {
      list-style-image: url(${listCircle});
    }
  }
`;

export const EditorFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  /* White */

  width: 100%;

  background: ${colors.white};
  /* Purple 100 */

  border: 1px solid ${colors.purple100};
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    + button {
      margin-left: 16px;
    }
  }
`;

export const ButtonContainer = styled.button`
  width: 16px;
  height: 16px;

  border-radius: 16px;

  cursor: default;
`;

export const InfoContainerIcon = styled(FiAlertCircle).attrs({
  color: colors.blackAlpha200,
})`
  width: 16px;
  height: 16px;
  margin-right: 16px;
`;
