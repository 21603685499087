import { RenderableElement } from '@/utils/elements';

export type AppMode = 'integrated' | 'not_integrated' | 'personal';

export type AppModeMapping = {
  [key in AppMode]?: RenderableElement;
};

export interface AppModeSelect extends AppModeMapping {
  default?: RenderableElement;
  enterprise?: RenderableElement;
}

export const EnterpriseModes: AppMode[] = ['integrated', 'not_integrated'];
