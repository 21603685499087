/* eslint-disable prefer-destructuring */
import { parse } from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { validate as isValidUUID } from 'uuid';

import api from '@/sevices/api';

import { StatusIntegracao } from '@/models/ProtocoloExecutado';

import FeedbackGraphic from '@/components/FeedbackGraphic';
import Preloader from '@/components/Preloader';

import { useAuth } from '../../providers/AuthProvider/hooks';

import { AuthorizationPayload } from '../../types/integrated';

import { FeedbackContainer } from './styles';

interface IntegracaoProtocolo {
  instance: { id: string } | null;
  status_integracao: StatusIntegracao;
}

const retryFetchTimeoutInMS = 300;

const PageAuthorization: React.FC = () => {
  const history = useHistory();

  const { login } = useAuth();

  const { hash } = useLocation();

  const [done, setDone] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);

  const [integracaoID, setIntegracaoID] = React.useState<string | undefined>(
    undefined,
  );

  const [execucaoID, setExecucaoID] = React.useState<string | undefined>(
    undefined,
  );

  const [integracao, setIntegracao] = React.useState<IntegracaoProtocolo>();

  const [retries, setRetries] = React.useState(0);

  React.useEffect(() => {
    if (!integracaoID && !execucaoID) {
      const auth = (parse(hash) as unknown) as AuthorizationPayload;

      (async () => {
        const result = await Promise.allSettled([
          login({ provider: 'integrated', payload: auth }),
        ]);

        if (result.some(settled => settled.status === 'rejected')) {
          setDone(true);
          setAuthError(true);
        } else {
          const url = new URL(auth.redirect_uri);

          const [, , , id] = url.pathname.split('/');

          const hasIntegracaoId = isValidUUID(id);

          if (hasIntegracaoId) {
            setIntegracaoID(id);
          } else {
            setExecucaoID(id);
          }
        }
      })();
    }
  }, [integracaoID, execucaoID, hash, login]);

  React.useEffect(() => {
    let retryTimeout: NodeJS.Timeout | undefined;

    if (integracaoID) {
      (async () => {
        const { data } = await api.get<IntegracaoProtocolo>(
          `/integracoes/${integracaoID}/`,
        );

        setIntegracao(data);

        if (data.status_integracao === 'active') {
          setDone(true);
        } else if (data.status_integracao === 'on-hold') {
          if (retries >= 200) {
            setDone(true);
            setAuthError(true);
          } else {
            retryTimeout = setTimeout(
              () => setRetries(retries + 1),
              retryFetchTimeoutInMS,
            );
          }
        }
      })();
    }

    if (execucaoID) {
      setDone(true);
    }

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [integracaoID, execucaoID, retries]);

  const redirectToInstance = React.useCallback(() => {
    if (integracao && integracao.instance) {
      history.replace(`/enterprise/protocolos/${integracao.instance.id}/`);
    }
  }, [history, integracao]);

  const redirectToExecucao = React.useCallback(() => {
    const auth = (parse(hash) as unknown) as AuthorizationPayload;
    const url = new URL(auth.redirect_uri);

    history.replace(url.pathname);
  }, [hash, history]);

  const onLoadingDoneAnimationEnd = React.useCallback(() => {
    if (integracao) {
      redirectToInstance();
    } else {
      redirectToExecucao();
    }
  }, [integracao, redirectToExecucao, redirectToInstance]);

  const status = React.useMemo(
    () => integracao && integracao.status_integracao,
    [integracao],
  );

  return (
    <Preloader
      loading={status !== 'entered-in-error' && !authError}
      done={done}
      style={{ flex: 1 }}
      onLoadingDoneAnimationEnd={onLoadingDoneAnimationEnd}
    >
      <FeedbackContainer>
        <FeedbackGraphic
          graphic="alert"
          title="Ops, algo deu errado."
          subtitle="Por favor, tente novamente em alguns instantes."
        />
      </FeedbackContainer>
    </Preloader>
  );
};

export default PageAuthorization;
