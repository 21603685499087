import api from '@/sevices/api';
import { ArtifactType } from '@/models/Solicitacao';
import { EncaminhamentoIntegracao } from '../models/encaminhamento';
import { ExameIntegracao } from '../models/exame';
import { MedicamentoIntegracao } from '../models/medicamento';
import { FhirArtefatoType, FhirPedidoType } from '../models/types';

interface RequestArtefatosPayload {
  solicitacoes: number[];
}

interface RequestExamesPayload {
  solicitacoes_exame: number[];
}
interface RequestMedicamentosPayload {
  solicitacoes_medicamento: number[];
}
interface RequestEncaminhamentosPayload {
  solicitacoes_encaminhamento: number[];
}

type ExamesResponse = ExameIntegracao[];
type MedicamentoResponse = MedicamentoIntegracao[];
type EncaminhamentoResponse = EncaminhamentoIntegracao[];

type SolicitacoesResponse = {
  results: FhirArtefatoType[];
  solicitacoes: number[];
};

type ItemsComplementaresResponse = {
  results: FhirArtefatoType[];
};

async function requestExames(
  passoExecutadoId: number,
  payload: RequestExamesPayload,
): Promise<ExameIntegracao[]> {
  const exames = (
    await api.post<ExamesResponse>(
      `passos-executados/${passoExecutadoId}/solicitar-exames`,
      payload,
    )
  ).data;

  return exames;
}

async function requestMedicamentos(
  passoExecutadoId: number,
  payload: RequestMedicamentosPayload,
): Promise<MedicamentoIntegracao[]> {
  const medicamentos = (
    await api.post<MedicamentoResponse>(
      `passos-executados/${passoExecutadoId}/solicitar-medicamentos`,
      payload,
    )
  ).data;

  return medicamentos;
}

async function requestEncaminhamentos(
  passoExecutadoId: number,
  payload: RequestEncaminhamentosPayload,
): Promise<EncaminhamentoIntegracao[]> {
  const exames = (
    await api.post<EncaminhamentoResponse>(
      `passos-executados/${passoExecutadoId}/solicitar-encaminhamentos`,
      payload,
    )
  ).data;

  return exames;
}

async function getItemsComplementaresList(
  type: string,
  term: string,
): Promise<FhirArtefatoType[] | undefined> {
  const result = (
    await api.get<ItemsComplementaresResponse>(
      `/itens-complementares/${type}/?search=${term}`,
    )
  ).data;

  return result.results;
}

interface IncluirSolicitacaoPayload {
  tipo: ArtifactType;
  pedido: fhir4.Resource;
}

async function requestAdicionarArtefatos(
  passoExecutadoId: number,
  { tipo, pedido }: IncluirSolicitacaoPayload,
): Promise<FhirArtefatoType> {
  const artefato = (
    await api.post<FhirArtefatoType>(
      `passos-executados/${passoExecutadoId}/solicitacoes/`,
      {
        tipo,
        pedido,
      },
    )
  ).data;

  return artefato;
}

async function requestSolicitarArtefatos(
  passoExecutadoId: number,
  payload: RequestArtefatosPayload,
): Promise<SolicitacoesResponse> {
  const artefatos = (
    await api.post<SolicitacoesResponse>(
      `passos-executados/${passoExecutadoId}/solicitar-artefatos/`,
      payload,
    )
  ).data;

  return artefatos;
}

async function requestCancelarArtefatos(
  passoExecutadoId: number,
  payload: RequestArtefatosPayload,
): Promise<SolicitacoesResponse> {
  const artefatos = (
    await api.post<SolicitacoesResponse>(
      `passos-executados/${passoExecutadoId}/cancelar-artefatos/`,
      payload,
    )
  ).data;

  return artefatos;
}

async function updateFhirResource(
  resource: FhirPedidoType,
): Promise<FhirPedidoType> {
  const response = (
    await api.put<FhirPedidoType>(
      `solicitacoes/fhir/${resource.resourceType}/${resource.id}/`,
      resource,
    )
  ).data;

  return response;
}

export default {
  requestExames,
  requestMedicamentos,
  requestEncaminhamentos,
  getItemsComplementaresList,
  requestAdicionarArtefatos,
  requestSolicitarArtefatos,
  requestCancelarArtefatos,
  updateFhirResource,
};
