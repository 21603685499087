import React from 'react';

import { Box, Title, Control } from './styles';
import { Checkbox } from '../SelectionControl';
import Tooltip from '../Tooltip';
import { SelectionControlProps } from '../SelectionControl/types';

export interface SelectionTextBoxProps extends React.PropsWithChildren {
  children: React.ReactNode;
  title?: string;
  tooltip?: string;
  controlProps?: {
    state: SelectionControlProps['state'];
    disabled?: boolean;
    onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  };
}

const SelectionTextBox: React.FC<SelectionTextBoxProps> = ({
  title,
  children,
  tooltip,
  controlProps,
}) => {
  const FeedbackElement = React.useMemo(() => {
    if (!controlProps) {
      return children;
    }

    const { onClick, disabled = false, state = 'unmarked' } = controlProps;

    const FeedbackContent = (
      <Control onClick={onClick} disabled={disabled}>
        <Checkbox size="small" state={state} disabled={disabled} />
        {children}
      </Control>
    );

    if (tooltip) {
      return (
        <Tooltip
          title={tooltip}
          placement="right"
          delay={[2000, 0]}
          offset={[0, 24]}
        >
          {FeedbackContent}
        </Tooltip>
      );
    }

    return FeedbackContent;
  }, [children, controlProps, tooltip]);

  return (
    <Box>
      {title && <Title>{title}</Title>}

      {FeedbackElement}
    </Box>
  );
};

export default SelectionTextBox;
