import styled, { css, keyframes } from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const slideRight = keyframes`
  0% {
    transform: translateX(0);
    z-index: 1;
  }

  50% {
    transform: translateX(26px);
    z-index: 1;
  }

  75% {
    z-index: 0;
  }

  100% {
    transform: translateX(0);
    z-index: 0;
  }
`;

const slideLeft = keyframes`
  0% {
    transform: translateX(0);
    z-index: 0;

  }

  50% {
    transform: translateX(-26px);
    z-index: 0;
  }

  75% {
    z-index: 1;
  }

  100% {
    transform: translateX(-0);
    z-index: 1;

  }
`;

const fadeInRight = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(100%);
  }
`;

const fadeInLeft = keyframes`
  from {
    transform: scale(0) rotateY(180deg) ;
  }

  to {
    transform: scale(100%) rotateY(180deg) ;
  }
`;

export const LogoIcon = styled.div`
  width: 50px;
  height: 50px;

  position: relative;

  background: ${colors.darkBlue100};
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LogoIconBall = styled.div<{ side: string }>`
  position: absolute;
  width: 8px;
  height: 8px;

  clip-path: circle(30% at 50% 50%);

  ${({ side }) =>
    side === 'left' &&
    css`
      left: 8px;
      background: ${colors.purple100};
      animation-name: ${slideRight};
    `}

  ${({ side }) =>
    side === 'right' &&
    css`
      right: 8px;
      background: ${colors.whiteAlpha50};
      animation-name: ${slideLeft};
    `}

  animation-iteration-count: infinite;
  animation-duration: 1.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

const LogoIconTriangleStyle = css`
  width: 22px;
  height: 25px;
  position: absolute;

  border-radius: 3px;
  clip-path: polygon(
    1.52407px 23.4997px,
    20.2505px 23.4997px,
    21.5005px 22.2497px,
    21.5005px 1.72445px,
    19.3271px 0.881955px,
    0.600649px 21.4072px,
    1.52407px 23.4997px
  );
`;

export const LogoIconTriangleLeft = styled.div`
  ${LogoIconTriangleStyle}

  left: 12px;

  background-color: ${colors.purple100};
  transform: rotateY(180deg);
  animation: ${fadeInLeft} 1s ease-in-out;
`;

export const LogoIconTriangleRight = styled.div`
  ${LogoIconTriangleStyle}

  right: 12px;

  background-color: ${colors.whiteAlpha50};
  animation: ${fadeInRight} 1s ease-in-out;
`;

export const LogoText = styled.div`
  display: flex;
  align-items: center;
  align-items: center;

  ${HeadingSmall(32)};
  color: ${colors.blackAlpha200};

  margin-left: 15px;
`;

export const TrianglesWrapper = styled.div<{ done: boolean }>`
  opacity: ${({ done }) => (done ? 1 : 0)};
  transition: opacity 1s ease-out;
  transition-delay: 300ms;
  display: flex;
  align-items: center;
`;

export const BallsWrapper = styled.div<{ done: boolean }>`
  opacity: ${({ done }) => (done ? 0 : 1)};
  transition: opacity 300ms ease-out;
  display: flex;
  align-items: center;
`;
