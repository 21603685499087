/**
 * Component with different behaviour from execucao's one
 *
 *  - width: 100%
 *  - placeholder: Via props
 *  - value: value + variavel.unidade
 */

import React, { useState, useCallback } from 'react';

import {
  InputPergunta,
  InputPerguntaProps,
} from '@/features/execucao/components/PerguntaDetail/types';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';

import { InputContainer, Input, InputUnidade } from './styles';

const InputNumerico: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const { payloadCalculadora } = useCalculadoraContext().state;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValueString = event.target.value;

      if (onChange) {
        onChange({
          [pergunta.variavel.nome]: inputValueString || null,
        });
      }
    },
    [pergunta, onChange],
  );

  return (
    <InputContainer isFocused={isFocused}>
      <Input
        type="number"
        value={(payloadCalculadora[pergunta.variavel.nome] as string) || ''}
        disabled={disabled}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {pergunta.variavel.unidade && (
        <InputUnidade>{pergunta.variavel.unidade}</InputUnidade>
      )}
    </InputContainer>
  );
};

export default InputNumerico;
