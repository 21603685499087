import React, { useCallback, useMemo } from 'react';

import Option from '@/types/Option';

import {
  InputPergunta,
  InputPerguntaProps,
} from '@/features/execucao/components/PerguntaDetail/types';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';

import SelectRadioButton from '@/components/SelectRadioButton';

const InputSimOuNao: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { payloadCalculadora } = useCalculadoraContext().state;
  const { variavel } = pergunta;

  const options: Option[] = useMemo(
    () => [
      {
        title: 'Sim',
        value: true,
        selected: payloadCalculadora[variavel.nome] === true,
      },
      {
        title: 'Não',
        value: false,
        selected: payloadCalculadora[variavel.nome] === false,
      },
    ],
    [payloadCalculadora, variavel],
  );

  const handleChange = useCallback(
    (option: Option) => {
      if (onChange) {
        onChange({ [pergunta.variavel.nome]: option.value as boolean });
      }
    },
    [onChange, pergunta],
  );

  return (
    <SelectRadioButton
      options={options}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default InputSimOuNao;
