import React from 'react';
import { useQuery } from '@tanstack/react-query';

import FlatList from '@/components/FlatList';
import Link from '@/components/Link';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { naturalTime } from '@/utils/datetime';
import ProtocoloExecutadoAPI from '../../services/ProtocoloExecutadoAPI';
import PlaceholderFlatListProtocolo from '../PlaceholderFlatListProtocolo';
import InputLinkProtocoloExecutado from '../InputLinkProtocoloExecutado';

import { Header } from './styles';

interface Props {
  search?: string;
}

const SectionProtocolosEmExecucao: React.FC<Props> = ({ search }) => {
  const { user } = useAuth();

  const { data = [], isLoading } = useQuery(
    ['protocolo-executado-list-dashboard'],
    () =>
      ProtocoloExecutadoAPI.list({
        page_size: 3,
        status: ['ABERTO', 'EM_ANDAMENTO'],
        tagged: true,
        ordering: '-data_criacao',
      }).then(res => res.results),
  );

  const shouldDisplay = React.useMemo(() => {
    if (search) {
      return false;
    }

    if (user) {
      return (
        // Empresa possui tags cadastradas
        user.empresa && user.empresa.tags_protocolo.length > 0
      );
    }
    return false;
  }, [search, user]);

  const renderHeader = React.useCallback(() => {
    if (data.length > 0) {
      return (
        <Header>
          <h1>Protocolos em execução</h1>
          <Link to="/protocolos-execucao">Ver todos</Link>
        </Header>
      );
    }

    return null;
  }, [data.length]);

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <LoadingSwitchTransition
      loading={isLoading}
      LoadingComponent={
        <PlaceholderFlatListProtocolo
          length={3}
          style={{
            // Espaço ocupado pelo header "Protocolos em execução"
            marginTop: 36,
            marginBottom: 32,
          }}
        />
      }
    >
      <FlatList
        data={data}
        keyExtractor={({ item: { id } }) => id}
        ListHeaderComponent={renderHeader}
        renderItem={({ item, last }) => (
          <InputLinkProtocoloExecutado
            helperInfo={naturalTime(item.data_criacao, 'Iniciado')}
            protocolo={item}
            // Força o marginBottom, que deveria ser no container da seção,
            // no último item da lista, evitando o efeito de "espaço fantasma"
            // em listagens vazias
            style={{ marginBottom: Number(last) && 32 }}
          />
        )}
      />
    </LoadingSwitchTransition>
  );
};

export default SectionProtocolosEmExecucao;
