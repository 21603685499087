import React from 'react';

import { TextField } from '@/components/InputField';

import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta } from '../../types';

const LargeInputString: InputPergunta = ({
  pergunta,
  disabled,
  nullable,
  onChange,
}) => {
  const {
    nome,
    meta: { placeholder, max_length, min_length },
  } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value: inputValue },
      } = event;

      setValue(inputValue);

      if (onChange) {
        onChange({
          [nome]: inputValue,
        });
      }
    },
    [nome, onChange, setValue],
  );

  React.useEffect(() => {
    if (onChange && typeof nullable === 'boolean') {
      onChange({ [nome]: nullable ? null : '' });

      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullable]);

  return (
    <TextField
      placeholder={placeholder}
      maxLength={max_length}
      minLength={min_length}
      value={value}
      rows={6}
      style={{
        width: 400,
      }}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default LargeInputString;
