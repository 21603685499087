import React from 'react';
import BoxExame from '@/features/recursos/exames/components/BoxExame';
import { FhirArtefatoType } from '@/features/enterprise/models/types';
import { groupBy } from '@/utils/collections';
import { Content, Wrapper } from './styles';
import CategoriaArtefato from '../../CategoriaArtefato';

interface ExamesProps {
  exames: FhirArtefatoType[];
}

const ListaExamesDefault: React.FC<ExamesProps> = ({ exames }) => {
  const examesHasCategorias = React.useMemo(() => {
    return exames.filter(exame => !!exame.categoria).length > 0;
  }, [exames]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      exames,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(exame => (
          <BoxExame key={exame.id.toString()} exame={exame.pedido} />
        ))}
      />
    ));
  }, [exames]);
  return (
    <Wrapper>
      <Content>
        {examesHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <>
            {exames.map(exame => (
              <BoxExame key={exame.id.toString()} exame={exame.pedido} />
            ))}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaExamesDefault;
