import React, {
  createRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import VersionInfo from '@/components/VersionInfo';

import MedflowLogo from '@/components/Logo/MedflowLogo';
import {
  Container,
  AuthContainer,
  LogoContainerHeader,
  AuthBox,
  AuthBoxHeader,
  AuthBoxContent,
} from './styles';

interface AuthLayoutProps {
  title: string;
  children?: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
}: AuthLayoutProps) => {
  const ref = createRef<HTMLDivElement>();

  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsScrollable(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, [ref]);

  const handleContentScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const {
        currentTarget: { scrollTop },
      } = event;

      setIsScrolling(scrollTop > 0);
    },
    [],
  );

  return (
    <Container>
      <AuthContainer>
        <AuthBox isScrollable={isScrollable} isScrolling={isScrolling}>
          <AuthBoxHeader>
            <LogoContainerHeader>
              <MedflowLogo color="primary" version="primary" />
              <VersionInfo />
            </LogoContainerHeader>

            <h1>{title}</h1>
          </AuthBoxHeader>
          <AuthBoxContent ref={ref} onScroll={handleContentScroll}>
            {children}
          </AuthBoxContent>
        </AuthBox>
      </AuthContainer>
    </Container>
  );
};

AuthLayout.defaultProps = {
  children: undefined,
};

export default AuthLayout;
