import React from 'react';
import { useEnterpriseContext } from '../contexts/EnterpriseContext';
import {
  doctorDisplayModel,
  DoctorDisplayModel,
  organizationDisplayModel,
  OrganizationDisplayModel,
  PatientDisplayModel,
  patientDisplayModel,
} from '../helpers';

export function usePatientDisplay(): PatientDisplayModel {
  const [{ encounter }] = useEnterpriseContext();

  const patientDisplay = React.useMemo(() => {
    return patientDisplayModel(encounter.data);
  }, [encounter]);

  return patientDisplay;
}

export function useDoctorDisplay(): DoctorDisplayModel {
  const [{ encounter }] = useEnterpriseContext();

  const doctorDisplay = React.useMemo(() => {
    return doctorDisplayModel(encounter.data);
  }, [encounter]);

  return doctorDisplay;
}

export function useOrganizationDisplay(): OrganizationDisplayModel {
  const [{ encounter }] = useEnterpriseContext();

  const organizationDisplay = React.useMemo(() => {
    return organizationDisplayModel(encounter.data);
  }, [encounter]);

  return organizationDisplay;
}
