import fhirpath from 'fhirpath';
import React from 'react';

import { buildFormType } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';

import { itemToUIState } from '../../helpers/parsers';
import { ItemComplementarState, ItemComplementarUIState } from '../../models';
import FormDefaults from '../../models/FormDefaults';
import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import ItemComplementarGroup from '../ItemComplementarGroup';

const itemForm = buildFormType('procedimento', FormDefaults.Procedimento);

const getTitle = (item: ItemComplementarState) => {
  return fhirpath.evaluate(
    item.resource,
    'ServiceRequest.code.text | ServiceRequest.code.coding.display',
  )[0];
};

const getDescription = (item: ItemComplementarState) => {
  const orderDetail = fhirpath.evaluate(
    item.resource,
    'ServiceRequest.orderDetail.text',
  );
  if (orderDetail.length) {
    return orderDetail[0];
  }

  return '';
};

const PrescricoesLivreProcedimento: React.FC = () => {
  const {
    mode,
    getItemsByType,
    getItemById,
    updateItemState,
    deleteItem,
    updateItem,
  } = useCondutaComplementarContext();

  const procedimentos = React.useMemo(() => {
    const items = getItemsByType('procedimento');

    return items.map<ItemComplementarUIState>(item =>
      itemToUIState(item, {
        title: getTitle(item),
        description: getDescription(item),
      }),
    );
  }, [getItemsByType]);

  const canEditOrDelete = React.useMemo(() => mode === 'active', [mode]);

  const handleUpdate = React.useCallback(
    ({ key: updatingID, resource }: ItemComplementarUIState) => {
      const { itemState, type = 'procedimento' } =
        getItemById(updatingID) || {};

      updateItem({
        id: updatingID,
        type,
        resource,
        itemState,
      });
    },
    [getItemById, updateItem],
  );

  const handleItemEditCancel = React.useCallback(
    ({ key: id }: ItemComplementarUIState) => {
      const item = getItemById(id);

      // Procedimentos adicionados via frontend (ao invés de carregados da API)
      // devem ser excluídos se o usuário cancelar sua primeira edição
      if (item?.itemState?.isAdding) {
        deleteItem({ id });
      }
    },
    [deleteItem, getItemById],
  );

  if (procedimentos.length) {
    return (
      <ItemComplementarGroup
        tipo="procedimento"
        items={procedimentos}
        itemForm={itemForm}
        onChangeItem={handleUpdate}
        onDeleteItem={({ key: id }) => deleteItem({ id })}
        onChangeItemCancel={handleItemEditCancel}
        onItemDisplayModeChange={(item, display) =>
          updateItemState({ id: item.key }, { isEditing: display === 'form' })
        }
        hasItemDeletePermission={canEditOrDelete}
        hasItemChangePermission={canEditOrDelete}
      />
    );
  }

  return <></>;
};

export default PrescricoesLivreProcedimento;
