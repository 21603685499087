import colors from '@/design/colors';
import { Text } from '@/design/typography';
import styled from 'styled-components';
import Spinner from '.';

/**
 * Spinner para uso de loadings de página (conteúdo completo)
 */
export const SpinnerWindow = styled(Spinner).attrs({ size: 32 })`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Text(16)};
  color: ${colors.blackAlpha200};

  row-gap: 16px;
`;

/**
 * Spinner para uso de loadings em scroll infinito
 */
export const SpinnerInfinite = styled(Spinner).attrs({ size: 32 })`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
