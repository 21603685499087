const pad = (a: number, size: number): string => {
  let s = String(a);
  while (s.length < (size || 2)) {
    s = `0${s}`;
  }
  return s;
};

export const formatTime = (timeValue: string): string => {
  return timeValue
    .replace(/\b(\d{2})(\d{2})$/, (_, hh, mm) => {
      return `${pad(Math.min(hh, 23), 2)}:${pad(Math.min(mm, 59), 2)}`;
    })
    .slice(0, 5);
};

export const formatDate = (dateValue: string): string => {
  return dateValue
    .replace(/\D/g, '')
    .replace(/\b(\d{2})(\d{2})(\d{4})$/, (_, dd, mm, yyyy) => {
      return `${pad(Math.min(dd, 31), 2)}/${pad(
        Math.min(mm, 12),
        2,
      )}/${Math.max(yyyy, 1800)}`;
    });
};
