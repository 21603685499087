import React from 'react';

import { ReactComponent as CheckIllustration } from '@/assets/illustrations/feedback-graphics/check.svg';

import Button from '@/components/Button';
import { closeMedflowApp } from '@/utils/window';

import { useExecucaoContext } from '../../providers/ExecucaoProvider';
import ExecucaoAPI from '../../services/ExecucaoAPI';

import { Container, Content, Actions } from './styles';

interface PassoExecutadoBreakpointProps {
  handleOnProximaEtapa(): void;
}

const PassoExecutadoBreakpoint: React.FC<PassoExecutadoBreakpointProps> = ({
  handleOnProximaEtapa,
}) => {
  const [{ protocoloExecutado }] = useExecucaoContext();
  const [loading, setLoading] = React.useState(false);

  const closeTab = React.useCallback(async () => {
    if (protocoloExecutado) {
      try {
        setLoading(true);
        await ExecucaoAPI.pausarExecucao(protocoloExecutado);
      } finally {
        setLoading(false);
        closeMedflowApp(protocoloExecutado);
      }
    }
  }, [protocoloExecutado]);

  return (
    <Container>
      <CheckIllustration />

      <Content>
        <h3>Etapa concluída.</h3>

        <p>
          Você já pode sair. As informações que você preencheu ficaram salvas
          para a próxima etapa.
        </p>
      </Content>

      <Actions>
        <Button
          theme="tertiary"
          onClick={() => {
            handleOnProximaEtapa();
          }}
        >
          Seguir para próxima etapa
        </Button>

        <Button theme="secondary" onClick={closeTab} loading={loading}>
          Salvar e sair
        </Button>
      </Actions>
    </Container>
  );
};

export default PassoExecutadoBreakpoint;
