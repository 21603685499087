import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const WrapperCalculadora = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 130px);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CalculadoraColumn = styled.div`
  width: 100%;
  height: 100%;
`;

export const CalculadoraProgressColumn = styled.div`
  width: 35%;
  height: 100%;
  position: relative;

  margin-left: 25px;

  @media (max-width: 1440px) {
    width: 40%;
  }
`;

export const WrapperLoading = styled(WrapperCalculadora)`
  align-items: center;
  justify-content: center;
`;
