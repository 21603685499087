import React from 'react';
import fhirpath from 'fhirpath';
import { Slide, toast } from 'react-toastify';
import { FiCheck, FiCopy, FiEdit2, FiX } from 'react-icons/fi';

import { FhirPedidoType } from '@/features/enterprise/models/types';

import Toast from '@/components/Toast';
import IconButton from '@/components/IconButton';
import Tooltip from '@/components/Tooltip';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import { useIMask } from 'react-imask';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderSubTitle,
  Actions,
  HeaderSubTitleContainer,
  HeaderSubTitleButtons,
  Button,
  TextSpan,
  HeaderSubTitleInputContainer,
  Input,
} from './styles';

interface BoxFotoceuticoEditavelProps {
  fotoceutico: FhirPedidoType;
  disableCopy?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

interface AplicacoesState {
  hoverContainer: boolean;
  status: null | 'disabled' | 'editing' | 'disabled-edited' | 'edited';
  initial: number;
  editavel: number;
}

const BoxFotoceuticoEditavel: React.FC<BoxFotoceuticoEditavelProps> = ({
  fotoceutico,
  disableCopy,
  selected,
  disabled,
}: BoxFotoceuticoEditavelProps) => {
  const nome = React.useMemo(() => {
    return fhirpath.evaluate(
      fotoceutico,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [fotoceutico]);

  const aplicacoes = React.useMemo(() => {
    return fhirpath.evaluate(
      fotoceutico,
      'ServiceRequest.occurrenceTiming.repeat.count',
    )[0];
  }, [fotoceutico]);

  const description = React.useCallback((applicationsNumber: number) => {
    const suffix = applicationsNumber === 1 ? 'sessão' : 'sessões';
    return `${applicationsNumber} ${suffix}`;
  }, []);

  const canEditAplicacoes = React.useMemo(() => {
    return fotoceutico.status === 'draft' && !disabled;
  }, [fotoceutico, disabled]);

  const [state, setState] = React.useState({
    onHover: false,
  });

  const [aplicacoesState, setAplicacoesState] = React.useState<AplicacoesState>(
    {
      hoverContainer: false,
      status: fotoceutico.reportedBoolean ? 'edited' : null,
      initial: aplicacoes,
      editavel: aplicacoes,
    },
  );

  const copyFotoceutico = React.useCallback(() => {
    const texto = `${nome}`;
    navigator.clipboard.writeText(texto);

    toast.dark(
      () => (
        <Toast>
          <p>Fotoceutico copiado</p>
        </Toast>
      ),
      {
        position: 'bottom-center',
        transition: Slide,
      },
    );
  }, [nome]);

  const { ref: inputRef } = useIMask(
    {
      mask: /^([0-9]{0,3})?$/,
      autofix: true,
    },
    {
      onAccept: value => {
        setAplicacoesState(prev => ({
          ...prev,
          editavel: +value,
        }));
      },
    },
  );

  React.useEffect(() => {
    setAplicacoesState({
      hoverContainer: false,
      status: fotoceutico.reportedBoolean ? 'edited' : null,
      initial: aplicacoes,
      editavel: aplicacoes,
    });
  }, [fotoceutico, aplicacoes]);

  const dispatchCustomData = React.useCallback(
    async (pedido: FhirPedidoType) => {
      const retorno = await IntegracaoService.updateFhirResource(pedido);

      if (retorno) {
        const initialAplicacoes = fhirpath.evaluate(
          retorno,
          'ServiceRequest.occurrenceTiming.repeat.count',
        )[0];

        setAplicacoesState(prev => ({
          ...prev,
          initial: initialAplicacoes,
          status: 'edited',
        }));
      }
    },
    [],
  );

  const handleEditSave = React.useCallback(() => {
    const occurrenceTiming = fhirpath.evaluate(
      fotoceutico,
      'ServiceRequest.occurrenceTiming',
    )[0];

    dispatchCustomData({
      ...fotoceutico,
      ...{
        occurrenceTiming: {
          ...occurrenceTiming,
          ...{ repeat: { count: aplicacoesState.editavel } },
        },
      },
    });
  }, [fotoceutico, aplicacoesState.editavel, dispatchCustomData]);

  const handleEditCancel = React.useCallback(() => {
    setAplicacoesState(prev => ({
      ...prev,
      status: null,
      editavel: prev.initial,
      hoverContainer: false,
    }));
  }, []);

  const editDosagem = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (selected) {
        event.stopPropagation();
      }
      if (canEditAplicacoes) {
        setAplicacoesState(prev => ({ ...prev, status: 'editing' }));
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    },
    [selected, canEditAplicacoes, inputRef],
  );

  const submitFormOnPressEnter = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleEditSave();
      }

      if (event.key === 'Escape') {
        handleEditCancel();
      }
    },
    [handleEditSave, handleEditCancel],
  );

  const saveClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      handleEditSave();
    },
    [handleEditSave],
  );

  const cancelClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      handleEditCancel();
    },
    [handleEditCancel],
  );

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={state.onHover}>{nome}</HeaderTitle>
        <Actions>
          {!disableCopy && (
            <IconButton
              tooltip="Copiar fotoceutico"
              onClick={copyFotoceutico}
              onMouseOver={() => setState(prev => ({ ...prev, onHover: true }))}
              onMouseLeave={() =>
                setState(prev => ({ ...prev, onHover: false }))
              }
              icon={<FiCopy />}
            />
          )}
        </Actions>
      </Header>

      {aplicacoes !== null && aplicacoesState.status === 'editing' && (
        <HeaderSubTitleInputContainer selected={selected}>
          <Input
            type="text"
            autoFocus
            defaultValue={aplicacoesState.editavel}
            ref={inputRef}
            onClick={e => e.stopPropagation()}
            onBlur={cancelClick}
            onKeyDown={submitFormOnPressEnter}
          />
          <HeaderSubTitleButtons>
            <Button
              onMouseDown={saveClick}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <FiCheck />
            </Button>
            <Button
              onMouseDown={cancelClick}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <FiX />
            </Button>
          </HeaderSubTitleButtons>
        </HeaderSubTitleInputContainer>
      )}

      {aplicacoes !== null && aplicacoesState.status !== 'editing' && (
        <HeaderSubTitleContainer
          highlight={aplicacoesState.hoverContainer}
          disabled={disabled}
          selected={selected}
          onClick={editDosagem}
          onMouseOver={() => {
            if (canEditAplicacoes) {
              setAplicacoesState(prev => ({ ...prev, hoverContainer: true }));
            }
          }}
          onMouseLeave={() => {
            if (canEditAplicacoes) {
              setAplicacoesState(prev => ({ ...prev, hoverContainer: false }));
            }
          }}
        >
          <HeaderSubTitle highlight={state.onHover}>
            {description(aplicacoesState.editavel)}
          </HeaderSubTitle>
          <HeaderSubTitleButtons>
            {(aplicacoesState.status === 'edited' ||
              aplicacoesState.status === 'disabled-edited') && (
              <TextSpan>editado</TextSpan>
            )}

            {canEditAplicacoes && aplicacoesState.hoverContainer && (
              <Tooltip placement="bottom" title="Editar sessões">
                <Button onClick={editDosagem}>
                  <FiEdit2 />
                </Button>
              </Tooltip>
            )}
          </HeaderSubTitleButtons>
        </HeaderSubTitleContainer>
      )}
    </Container>
  );
};

BoxFotoceuticoEditavel.defaultProps = {
  disableCopy: false,
  selected: false,
  disabled: false,
};

export default BoxFotoceuticoEditavel;
