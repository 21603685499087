export interface Expressao {
  id: number;
  expressao_id: number;
  ordem: number;
  nome: string;
  descricao: string;
  unidade: string;
  valor: boolean | number | string | null;
  valor_formatado: string;
}

export interface VariavelMeta {
  max_digits?: number;
  decimal_places?: number;
  min_value?: number;
  max_value?: number;
  max_length?: number;
  min_length?: number;
  placeholder?: string;
  pattern?: string;
  pattern_message?: string;
  mask?: string;
  large_response?: boolean;
}

export interface Variavel extends Expressao {
  tipo: TipoVariavel;
  tipo_display: string;
  multipla_escolha: boolean;
  multiplas_colunas: boolean;
  allow_null: boolean;
  allow_null_placeholder: string;
  not_applicable: boolean;
  opcoes: OpcaoVariavel[];
  meta: VariavelMeta;
}

export interface OpcaoVariavel {
  id: number;
  ordem: number;
  nome: string;
  descricao: string;
  informacoes: string;
  valor: boolean | number;
  selecionado: boolean;
  excludente: boolean;
}

export enum TipoVariavel {
  BOOLEANA = 'B',
  NUMERICA = 'N',
  INTEIRO = 'IT',
  DECIMAL = 'DC',
  STRING = 'ST',
  DATA_HORA = 'DH',
  DATA = 'DT',
  HORA = 'HR',
}
