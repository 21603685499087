import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

interface TabLabelProps {
  active: boolean;
}

export const TabLabel = styled.button<TabLabelProps>`
  ${HeadingSmall(14)};
  color: ${colors.gray200};

  ${props =>
    props.active
      ? css`
          color: ${colors.purple100};
        `
      : css`
          :not(:disabled) {
            :hover,
            :focus {
              color: ${colors.blackAlpha200};
            }
          }
        `};
`;
