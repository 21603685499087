import { format } from 'date-fns';
import React from 'react';

import InputFieldDate from '@/components/InputFieldDate';
import InputFieldTime from '@/components/InputFieldTime';
import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta, InputPerguntaProps } from '../types';
import { Container, Group } from './styles';

const getDatetimeParts = (datetimeString: string | null) => {
  if (datetimeString) {
    const parts = datetimeString.toString().split(' ');
    if (parts.length === 2) {
      return parts;
    }
  }

  return ['', ''];
};

const InputDataHora: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { nome } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const [[valueDate, valueTime], setDatetime] = React.useState<string[]>(() =>
    getDatetimeParts(value as string | null),
  );

  const dispatchChange = React.useCallback(() => {
    if (onChange) {
      onChange({
        [nome]:
          valueDate && valueTime ? [valueDate, valueTime].join(' ') : null,
      });
    }
  }, [nome, onChange, valueDate, valueTime]);

  const handleChangeData = React.useCallback(
    (changeValue?: Date) => {
      let dateString = '';
      if (changeValue !== undefined) {
        dateString = format(changeValue, 'yyyy-MM-dd');

        setDatetime(([, timeString]) => {
          setValue([dateString, timeString].join(' '));
          return [dateString, timeString];
        });
      }
    },
    [setValue],
  );

  const handleChangeHora = React.useCallback(
    (timeString = '') => {
      setDatetime(([dateString]) => {
        setValue([dateString, timeString].join(' '));
        return [dateString, timeString];
      });
    },
    [setValue],
  );

  React.useEffect(() => {
    setDatetime(getDatetimeParts(value as string | null));
  }, [value]);

  React.useEffect(() => {
    dispatchChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate, valueTime]);

  return (
    <Container>
      <Group>
        <InputFieldDate
          title="Data:"
          style={{ width: 279 }}
          value={valueDate}
          disabled={disabled}
          onChangeDate={handleChangeData}
        />
      </Group>

      <Group>
        <InputFieldTime
          title="Hora:"
          value={valueTime}
          style={{ width: 279 }}
          disabled={disabled}
          onChangeTime={handleChangeHora}
        />
      </Group>
    </Container>
  );
};

export default InputDataHora;
