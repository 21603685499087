import React from 'react';

import { Row, Column } from './styles';

// css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-properties-for-the-parentflex-container
interface FlexContainerProps
  extends Pick<
    React.CSSProperties,
    | 'flexDirection'
    | 'flexWrap'
    | 'flexFlow'
    | 'justifyContent'
    | 'alignItems'
    | 'alignContent'
    | 'gap'
    | 'rowGap'
    | 'columnGap'
  > {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

// css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-properties-for-the-childrenflex-items
interface FlexItemProps
  extends Pick<
    React.CSSProperties,
    'order' | 'flexGrow' | 'flexShrink' | 'flexBasis' | 'flex' | 'alignSelf'
  > {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

interface FlexContainerElement {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function Flexbox(
  FlexContainer: React.ComponentType<FlexContainerElement>,
): React.FC<FlexContainerProps> {
  return ({ children, style, className, ...flexContainerStyle }) => (
    <FlexContainer
      className={className}
      style={{ ...style, ...flexContainerStyle }}
    >
      {children}
    </FlexContainer>
  );
}

const Item: React.FC<FlexItemProps> = ({
  style,
  className,
  children,
  ...flexItemStyle
}) => {
  return (
    <div className={className} style={{ ...style, ...flexItemStyle }}>
      {children}
    </div>
  );
};

export default {
  Row: Flexbox(Row),
  Column: Flexbox(Column),
  Item,
};
