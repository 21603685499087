import React from 'react';

import CheckboxAlternatives from '@/components/CheckboxAlternatives';

import {
  toSelectionControlOptions,
  toSelectionControlValues,
} from '@/features/execucao/helpers/perguntas';
import { InputPergunta, InputPerguntaProps } from '../types';

const InputMultiplaEscolha: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const wrapItems = React.useMemo(() => pergunta.variavel.multiplas_colunas, [
    pergunta.variavel,
  ]);

  const [selected, setSelected] = React.useState<number[]>(() =>
    toSelectionControlValues(pergunta),
  );

  const options = React.useMemo(() => toSelectionControlOptions(pergunta), [
    pergunta,
  ]);

  const handleChange = React.useCallback(
    (value: number[]) => {
      setSelected(value);

      if (onChange) {
        onChange({
          [pergunta.variavel.nome]: value,
        });
      }
    },
    [onChange, pergunta],
  );

  return (
    <CheckboxAlternatives
      value={selected}
      options={options}
      disabled={disabled}
      horizontal={wrapItems}
      onChange={handleChange}
    />
  );
};

InputMultiplaEscolha.defaultProps = {
  disabled: false,
  onChange: undefined,
};

export default InputMultiplaEscolha;
