import React from 'react';

import ModalFluxograma from '@/features/execucao/components/ModalFluxograma';
import ExecucaoAPI from '@/features/execucao/services/ExecucaoAPI';

import { useExecucaoContext } from '../ExecucaoProvider';

interface FlowchartValue {
  dispatchFlowchartDisplay: () => void;
  dispatchFlowchartHide: () => void;
}

const FlowchartContext = React.createContext<FlowchartValue>({
  dispatchFlowchartDisplay: () => ({}),
  dispatchFlowchartHide: () => ({}),
});

const FlowchartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const [content, setContent] = React.useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const dispatchFlowchartDisplay = React.useCallback(() => setIsOpen(true), []);
  const dispatchFlowchartHide = React.useCallback(() => setIsOpen(false), []);

  React.useEffect(() => {
    (async () => {
      if (protocoloExecutado) {
        const flowchartContent = await ExecucaoAPI.getFlowchartProtocolo({
          protocoloExecutadoId: protocoloExecutado.id,
        });

        setContent(flowchartContent);
      }
    })();
  }, [protocoloExecutado]);

  return (
    <FlowchartContext.Provider
      value={{ dispatchFlowchartDisplay, dispatchFlowchartHide }}
    >
      <ModalFluxograma
        content={content}
        isOpen={modalIsOpen}
        onAfterClose={dispatchFlowchartHide}
      />
      {children}
    </FlowchartContext.Provider>
  );
};

export function useProtocoloFlowchart(): FlowchartValue {
  return React.useContext<FlowchartValue>(FlowchartContext);
}

export default FlowchartProvider;
