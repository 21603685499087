/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiPlayCircle } from 'react-icons/fi';

import { ProtocoloExecutadoListItem } from '@/models/ProtocoloExecutado';
import { RenderableElement, enforceElement } from '@/utils/elements';

import {
  Container,
  Input,
  InputContent,
  InputIcon,
  InputText,
  MetaInfo,
} from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  protocolo: ProtocoloExecutadoListItem;
  helperInfo?: string;
  Right?: RenderableElement;
}

const InputProtocoloExecutado: React.FC<Props> = ({
  protocolo,
  helperInfo,
  Right = <FiPlayCircle size={24} />,
  ...props
}) => {
  const metaInfo = React.useMemo(() => {
    const items = protocolo.tags.map(tag => tag.valor);
    if (helperInfo) {
      items.push(helperInfo);
    }

    return items.join(' | ');
  }, [helperInfo, protocolo.tags]);

  return (
    <Container>
      {metaInfo && <MetaInfo>{metaInfo}</MetaInfo>}
      <Input {...props}>
        <InputContent>
          <InputIcon src={protocolo.imagem_url} alt="" />
          <InputText>{protocolo.nome}</InputText>
          {enforceElement(Right)}
        </InputContent>
      </Input>
    </Container>
  );
};

export default InputProtocoloExecutado;
