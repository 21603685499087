import Flexbox from '@/components/Flexbox';
import PlaceholderLoading from '@/components/PlaceholderLoading';
import React from 'react';

import { Container } from './styles';

const PlaceholderSidebar: React.FC = () => {
  return (
    <Container>
      <Flexbox.Row columnGap={16}>
        <PlaceholderLoading.Circle width={56} height={56} />
        <Flexbox.Item flex={1}>
          <Flexbox.Column rowGap={5}>
            <PlaceholderLoading.Rect width={59} height={16} />
            <PlaceholderLoading.Rect width="min(100%, 183px)" height={16} />
            <PlaceholderLoading.Rect width="min(100%, 183px)" height={8} />
          </Flexbox.Column>
        </Flexbox.Item>
      </Flexbox.Row>

      <PlaceholderLoading.Box style={{ padding: 16 }}>
        <Flexbox.Row columnGap={16}>
          <PlaceholderLoading.Circle width={46} height={46} />
          <Flexbox.Item flex={1}>
            <Flexbox.Column rowGap={13}>
              <Flexbox.Column rowGap={5}>
                <PlaceholderLoading.Rect height={16} />
                <PlaceholderLoading.Rect height={8} />
              </Flexbox.Column>
              <Flexbox.Column rowGap={5}>
                <PlaceholderLoading.Rect height={16} />
                <PlaceholderLoading.Rect height={8} />
              </Flexbox.Column>
              <Flexbox.Column rowGap={5}>
                <PlaceholderLoading.Rect height={16} />
                <PlaceholderLoading.Rect height={8} />
              </Flexbox.Column>
            </Flexbox.Column>
          </Flexbox.Item>
        </Flexbox.Row>
      </PlaceholderLoading.Box>

      <PlaceholderLoading.Box style={{ padding: 16 }}>
        <Flexbox.Row columnGap={16} alignItems="center">
          <PlaceholderLoading.Rect width={46} height={46} />
          <Flexbox.Item flex={1}>
            <Flexbox.Column rowGap={5}>
              <PlaceholderLoading.Rect height={16} />
              <PlaceholderLoading.Rect height={8} />
            </Flexbox.Column>
          </Flexbox.Item>
        </Flexbox.Row>
      </PlaceholderLoading.Box>

      <PlaceholderLoading.Box style={{ padding: 16 }}>
        <Flexbox.Row columnGap={16} alignItems="center">
          <Flexbox.Item flex={1}>
            <Flexbox.Column rowGap={5}>
              <PlaceholderLoading.Rect height={16} />
              <PlaceholderLoading.Rect height={8} width="70%" />
            </Flexbox.Column>
          </Flexbox.Item>
        </Flexbox.Row>
      </PlaceholderLoading.Box>
    </Container>
  );
};

export default PlaceholderSidebar;
