import React from 'react';
import { ReactComponent as SpinnerIcon } from '@/assets/icons/spinner.svg';

import { SpinnerContainer } from './styles';

interface SpinnerProps extends React.PropsWithChildren {
  size?: number;
  className?: string;
  style?: React.CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 16,
  className,
  style,
  children,
}) => {
  return (
    <SpinnerContainer className={className} style={style}>
      <SpinnerIcon width={size} height={size} />
      {children}
    </SpinnerContainer>
  );
};

export default Spinner;
