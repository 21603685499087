/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PageLayout, { PageLayoutProps } from '@/layouts/PageLayout';

import EnterpriseSidebar from '../../components/EnterpriseSidebar';

type Props = PageLayoutProps;
const EnterprisePageLayout: React.FC<Props> = ({
  children,
  ...layoutProps
}) => {
  return (
    <PageLayout Side={EnterpriseSidebar} {...layoutProps}>
      {children}
    </PageLayout>
  );
};

export default EnterprisePageLayout;
