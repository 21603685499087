import styled from 'styled-components';
import colors from '@/styles/colors';

import Btn from '@/components/Button';

export const Button = styled(Btn)`
  margin-top: 24px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;

  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  justify-self: center;

  margin-top: auto;

  img {
    margin-bottom: 17px;
  }

  h3 {
    color: ${colors.black};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  p {
    color: ${colors.hardGray};
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    max-width: 400px;
    text-align: center;
  }
`;

export const Footer = styled.div`
  margin-bottom: 50px;
  margin-top: auto;
`;
