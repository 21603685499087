import React from 'react';
import fhirpath from 'fhirpath';
import { FiEdit2, FiPrinter } from 'react-icons/fi';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import {
  patientToJson,
  practitionerToJson,
} from '@/features/enterprise/helpers';

import IconButton from '@/components/IconButton';
import Markdown from '@/components/Markdown';
import Editor from '@/components/Editor';

import { FhirPedidoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import Feature from '@/features/features/components/Feature';
import { useEventsContext } from '@/features/tracking/context/EventsContext';
import {
  Container,
  Header,
  HeaderTitle,
  Actions,
  CollapsedContent,
  Description,
  TextSpan,
  DescriptionContainer,
  ButtonHover,
  CollapseButton,
} from './styles';
import { printEncaminhamento } from '../../helpers/print';

interface BoxEncaminhamentoEditavelProps {
  encaminhamento: FhirPedidoType;
  selected?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
}

interface EditorState {
  hoverContainer: boolean;
  status: null | 'disabled' | 'editing' | 'disabled-edited' | 'edited';
}

const BoxEncaminhamentoEditavel: React.FC<BoxEncaminhamentoEditavelProps> = ({
  encaminhamento,
  selected,
  disabled,
  isOpen,
}) => {
  const contentRef = React.createRef<HTMLDivElement>();

  const [contentHeight, setContentHeight] = React.useState<number>(0);
  const [isOpenedDetails, setIsOpenedDetails] = React.useState(isOpen || false);

  const { user } = useAuth();
  const [{ encounter }] = useEnterpriseContext();
  const [{ dispatchEvent }] = useEventsContext();

  const [, { dispatchAlterarStatus }] = useExecucaoContext();

  const canEditIndicacao = React.useMemo(() => {
    return encaminhamento.status === 'draft' && !disabled;
  }, [encaminhamento, disabled]);

  const paciente = React.useMemo(() => {
    if (encounter) {
      return patientToJson(encounter.data);
    }

    return { nome: '', genero: '', data: '', idade: '' };
  }, [encounter]);

  const profissional = React.useMemo(() => {
    if (encounter) {
      return practitionerToJson(encounter.data);
    }

    return {
      nome: '',
      identificador: '',
      especialidade: '',
      genero: '',
    };
  }, [encounter]);

  const entidadeDeSaude = React.useMemo(() => {
    if (encounter.data) {
      const entidade = fhirpath.evaluate(
        encounter,
        'Encounter.contained.ofType(Location).name.first()',
      )[0];

      return entidade;
    }

    return '';
  }, [encounter]);

  const [
    encaminhamentoEditavel,
    setEncaminhamentoEditavel,
  ] = React.useState<FhirPedidoType>(encaminhamento);

  const titulo = React.useMemo(() => {
    return fhirpath.evaluate(
      encaminhamentoEditavel,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [encaminhamentoEditavel]);

  const indicacao = React.useMemo(() => {
    return fhirpath.evaluate(
      encaminhamentoEditavel,
      'ServiceRequest.reasonCode.text',
    )[0] as string;
  }, [encaminhamentoEditavel]);

  const [editorState, setEditorState] = React.useState<EditorState>({
    hoverContainer: false,
    status: encaminhamento.reportedBoolean ? 'edited' : null,
  });

  const hasDescription = React.useMemo(() => !!indicacao, [indicacao]);

  const toggleDetails = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (hasDescription) {
        setIsOpenedDetails(prev => {
          if (prev) {
            setEditorState(prevStateIndicacao => ({
              ...prevStateIndicacao,
              hoverContainer: false,
            }));
          }

          return !prev;
        });
      }
    },
    [hasDescription],
  );

  const imprimirEncaminhamento = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      printEncaminhamento(
        titulo,
        user?.empresa?.logo || '',
        entidadeDeSaude,
        paciente,
        profissional,
        indicacao,
      );

      dispatchEvent('print-encaminhamento', 'print', titulo);
    },
    [
      titulo,
      user?.empresa?.logo,
      entidadeDeSaude,
      paciente,
      profissional,
      indicacao,
      dispatchEvent,
    ],
  );

  const dispatchCustomData = React.useCallback(
    async (pedido: FhirPedidoType) => {
      const retorno = await IntegracaoService.updateFhirResource(pedido);

      if (retorno) {
        setEditorState(prev => ({
          ...prev,
          status: 'edited',
        }));

        setEncaminhamentoEditavel(retorno);
      }
    },
    [],
  );

  const onCancelClick = React.useCallback(() => {
    setEditorState(prev => {
      return {
        ...prev,
        status: encaminhamentoEditavel.reportedBoolean ? 'edited' : null,
        hoverContainer: false,
      };
    });
  }, [encaminhamentoEditavel]);

  const onConfirmClick = React.useCallback(
    (content: string) => {
      dispatchCustomData({
        ...encaminhamentoEditavel,
        ...{
          reasonCode: { text: content },
        },
      });
    },
    [dispatchCustomData, encaminhamentoEditavel],
  );

  const onChangeEditorText = React.useCallback(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef]);

  const onMouseOver = React.useCallback(() => {
    if (canEditIndicacao) {
      setEditorState(prev => ({ ...prev, hoverContainer: true }));
    }
  }, [canEditIndicacao]);

  const onMouseLeave = React.useCallback(() => {
    if (canEditIndicacao) {
      setEditorState(prev => ({ ...prev, hoverContainer: false }));
    }
  }, [canEditIndicacao]);

  const onEditClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      if (canEditIndicacao) {
        if (selected) {
          event.stopPropagation();
        }
        setEditorState(prev => ({
          ...prev,
          status: 'editing',
          hoverContainer: false,
        }));
      }
    },
    [canEditIndicacao, selected],
  );

  const handleEditing = React.useCallback(() => {
    dispatchAlterarStatus({ status: 'on-hold' });
  }, [dispatchAlterarStatus]);

  const handleEdited = React.useCallback(() => {
    dispatchAlterarStatus({ status: 'active' });
  }, [dispatchAlterarStatus]);

  React.useEffect(() => {
    if (editorState.status === 'editing') {
      handleEditing();
    } else {
      handleEdited();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState.status]);

  React.useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef]);

  return (
    <Container>
      <Header>
        <HeaderTitle highlight={isOpenedDetails}>{titulo}</HeaderTitle>

        <Actions>
          {(editorState.status === 'edited' ||
            editorState.status === 'disabled-edited') && (
            <TextSpan>editado</TextSpan>
          )}
          {isOpenedDetails && canEditIndicacao && (
            <ButtonHover onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
              <IconButton
                tooltip="Editar encaminhamento"
                onClick={onEditClick}
                icon={<FiEdit2 />}
              />
            </ButtonHover>
          )}
          <Feature name="IMPRIMIR_ENCAMINHAMENTO">
            <IconButton
              tooltip="Imprimir"
              onClick={imprimirEncaminhamento}
              icon={<FiPrinter />}
            />
          </Feature>

          {hasDescription && (
            <CollapseButton
              collapsed={isOpenedDetails}
              onClick={toggleDetails}
            />
          )}
        </Actions>
      </Header>

      <CollapsedContent
        ref={contentRef}
        style={{
          marginLeft: editorState.status === 'editing' ? -32 : 0,
          maxHeight: isOpenedDetails ? contentHeight : 0,
        }}
      >
        {indicacao && editorState.status === 'editing' ? (
          <Editor
            content={indicacao}
            onChange={onChangeEditorText}
            onCancelClick={onCancelClick}
            onConfirmClick={onConfirmClick}
          />
        ) : (
          <DescriptionContainer
            highlight={editorState.hoverContainer}
            disabled={disabled}
            selected={selected}
            onClick={onEditClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <Description>
              <span style={{ textOverflow: 'ellipsis' }}>
                <Markdown text={indicacao} />
              </span>
            </Description>
          </DescriptionContainer>
        )}
      </CollapsedContent>
    </Container>
  );
};

export default BoxEncaminhamentoEditavel;
