/* eslint-disable react/jsx-props-no-spreading */
import debounce from 'lodash.debounce';
import React from 'react';
import { FiX } from 'react-icons/fi';

import { ClearButton, Container, Input, SearchIcon } from './styles';

interface SearchProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  debounceTimeInMS: number;
  onChange?: (value: string) => void;
}

export interface SearchRef {
  clear: () => void;
  focus: () => void;
}

const Search = React.forwardRef<SearchRef, SearchProps>(
  ({ debounceTimeInMS, onChange, style, className, ...props }, searchRef) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const [clearable, setClearble] = React.useState(false);

    const debounceChange = React.useMemo(() => {
      if (onChange) {
        return debounce(onChange, debounceTimeInMS);
      }

      return undefined;
    }, [debounceTimeInMS, onChange]);

    const handleChange = React.useCallback(
      (value: string) => {
        if (debounceChange) {
          debounceChange(value);
        }

        setClearble(!!value);
      },
      [debounceChange],
    );

    const handleClear = React.useCallback(() => {
      if (ref.current) {
        ref.current.value = '';

        // Clear should trigger event immediately
        if (onChange) {
          onChange('');
        }

        setClearble(false);
      }
    }, [onChange]);

    const handleFocus = React.useCallback(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, []);

    React.useEffect(() => {
      return () => {
        if (debounceChange) {
          debounceChange.cancel();
        }
      };
    }, [debounceChange]);

    React.useImperativeHandle(
      searchRef,
      () => ({
        clear: handleClear,
        focus: handleFocus,
      }),
      [handleClear, handleFocus],
    );

    return (
      <Container style={style} className={className}>
        <Input
          ref={ref}
          {...props}
          onChange={event => handleChange(event.target.value)}
        />
        <SearchIcon />

        {clearable && (
          <ClearButton
            onClick={event => {
              event.stopPropagation();
              handleClear();
            }}
          >
            <FiX size={18} />
          </ClearButton>
        )}
      </Container>
    );
  },
);

export default Search;
