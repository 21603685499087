import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall } from '@/design/typography';

export const InputIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const InputTitle = styled.span`
  ${HeadingSmall(16)};
  align-items: center;
  text-align: center;
  color: ${colors.blackAlpha200};
  transition: color 0.1s linear;
`;

export const Input = styled.button`
  width: 200px;
  height: 120px;
  padding: 16px;

  background: ${colors.white};

  border: 1px solid ${colors.gray100};
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  transition: all 0.3s ease-in-out;

  &:hover:not(:disabled) {
    box-shadow: ${shadows.smallShadow};

    ${InputTitle} {
      color: ${colors.purple100};
    }
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 8px;
`;
