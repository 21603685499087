/* eslint-disable no-console */
import React from 'react';

import CheckboxLabel from '@/components/CheckboxLabel';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';

interface Props {
  disabled?: boolean;
}

const PermissaoReceitaCheckbox: React.FC<Props> = ({ disabled = false }) => {
  const {
    getItemsByType,
    consentPermitOrDeny,
    consentDeny,
    hasConsentPermit,
  } = useCondutaComplementarContext();

  const consentVerified = React.useMemo(
    () => hasConsentPermit('prescription-record-sharing'),
    [hasConsentPermit],
  );

  const canConsent = React.useMemo(
    () =>
      getItemsByType('manipulado').filter(
        ({ itemState: { isAdding = false } = {} }) => !isAdding,
      ).length > 0,
    [getItemsByType],
  );

  React.useEffect(() => {
    return () => {
      consentDeny('prescription-record-sharing');
    };
  }, [consentDeny]);

  if (canConsent) {
    return (
      <CheckboxLabel
        disabled={disabled}
        size="small"
        state={consentVerified ? 'marked' : 'unmarked'}
        onClick={() => consentPermitOrDeny('prescription-record-sharing')}
      >
        Permissão de compartilhamento de informações da receita.
      </CheckboxLabel>
    );
  }

  return <></>;
};

export default PermissaoReceitaCheckbox;
