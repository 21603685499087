import api from '@/sevices/api';

import { APIResult } from '@/types/Api';

import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';
import {
  AddingItemComplementarState,
  ItemComplementarState,
  ItemTerapiaComplementarAPI,
  TipoItemComplementar,
} from '../models';
import SolicitacoesAPI from './SolicitacoesAPI';
import { apiToItemState, defaultItemStatus } from '../helpers/parsers';

export interface ItemComplementarSearchResult {
  value: string;
  label: string;
  data: fhir4.FhirResource;
}

interface SearchItemComplementarOptions {
  type: TipoItemComplementar;
  term: string;
}

async function search({
  type,
  term,
}: SearchItemComplementarOptions): Promise<ItemComplementarSearchResult[]> {
  const { results } = (
    await api.get<APIResult<ItemTerapiaComplementarAPI>>(
      `/itens-complementares/${type}/`,
      {
        params: { search: term },
      },
    )
  ).data;

  const searchResults = results.map<ItemComplementarSearchResult>(apiItem => ({
    label: apiItem.titulo,
    value: apiItem.id.toString(),
    data: apiItem.recurso,
  }));

  return searchResults;
}

async function addItem(
  protocolo: ProtocoloExecutado,
  { type: tipo, resource }: AddingItemComplementarState,
  keepState: ItemComplementarState['itemState'] = defaultItemStatus,
): Promise<ItemComplementarState> {
  const solicitacao = await SolicitacoesAPI.create({
    tipo,
    resource,
    protocolo,
  });

  return apiToItemState(solicitacao, { ...keepState });
}

async function updateOrCreateItem(
  protocolo: ProtocoloExecutado,
  item: ItemComplementarState,
  keepState?: ItemComplementarState['itemState'],
): Promise<[ItemComplementarState, boolean]> {
  const {
    id,
    type: tipo,
    resource,
    itemState: { isAdding = false } = {},
  } = item;

  // Item ainda não foi adicionado ao protocolo (salvo)
  if (isAdding) {
    const addeItem = await addItem(protocolo, item, {
      ...keepState,
      reference: id, // Se o item ainda não foi salvo, seu id é local
    });
    return [addeItem, true];
  }

  const solicitacao = await SolicitacoesAPI.update({ id, tipo, resource });
  return [apiToItemState(solicitacao, keepState), false];
}

export default {
  search,
  addItem,
  updateOrCreateItem,
};
