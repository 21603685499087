import { User } from '@/models/User';
import { AxiosRequestConfig } from 'axios';

export function getUserDisplayName(user?: User | null): string {
  if (user) {
    return user.name || user.username;
  }

  return '';
}

export function isSignInRequest(req: AxiosRequestConfig): boolean {
  if (req.url) {
    // O response de signup também loga o usuário, por isso tmbém está incluso
    return (
      req.url.includes('/signin/') ||
      req.url.includes('/auth/signup/') ||
      req.url.includes('/enterprise/authorize')
    );
  }

  return false;
}

export function shouldIncludeRefreshToken(req: AxiosRequestConfig): boolean {
  if (req.url) {
    return (
      req.url.includes('/auth/token/refresh/') ||
      req.url.includes('/auth/signout/')
    );
  }

  return false;
}

export function configureAccessToken(
  req: AxiosRequestConfig,
  token: string,
): void {
  const { headers = {} } = req;

  if (token) {
    req.headers = {
      ...headers,
      authorization: `Bearer ${token}`,
    };
  }
}

export function configureRefreshToken(
  req: AxiosRequestConfig,
  token: string,
): void {
  req.data = {
    refresh: token,
  };
}
