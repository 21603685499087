import ToastContent from '@/components/ToastContent';
import styled from 'styled-components';

export const ContentClickable = styled.div`
  display: flex;

  flex: 1;
`;

export const Toast = styled(ToastContent)`
  width: 340px;
`;
