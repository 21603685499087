import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { Text } from '@/design/typography';

import { SelectionControlContainer } from '../SelectionControl/styles';

export const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;

  width: fit-content;
  column-gap: 10px;

  ${Text(14)};
  color: ${colors.blackAlpha200};

  width: fit-content;

  cursor: pointer;
  user-select: none;

  &:hover:not(:disabled) {
    ${SelectionControlContainer} {
      border-color: ${colors.purple100};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
