import React from 'react';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { ReactComponent as Alert } from '@/assets/illustrations/feedback-graphics/alert.svg';

import Button from '@/components/Button';
import { closeMedflowApp } from '@/utils/window';
import { useExecucaoContext } from '../../providers/ExecucaoProvider';
import B2BComponent from './index.b2b';
import PersonalComponent from './index.personal';
import { ErrorContainer } from './styles';

const ProtocoloExecutadoDetail: React.FC = () => {
  const { user } = useAuth();

  const [{ status, protocoloExecutado }] = useExecucaoContext();

  const closeTab = React.useCallback(() => {
    if (protocoloExecutado) {
      closeMedflowApp(protocoloExecutado);
    }
  }, [protocoloExecutado]);

  if (status === 'on-error') {
    const message = user?.empresa
      ? `Por favor, retorne ao prontuário e tente novamente em alguns instantes.`
      : 'Por favor, tente novamente em alguns instantes.';

    return (
      <ErrorContainer>
        <Alert />

        <h3>Ops, algo deu errado.</h3>

        <p>{message}</p>

        <Button theme="tertiary" onClick={() => closeTab()}>
          Fechar janela
        </Button>
      </ErrorContainer>
    );
  }

  return user?.empresa ? <B2BComponent /> : <PersonalComponent />;
};

export default ProtocoloExecutadoDetail;
