import React, { useState } from 'react';

import DoctorUndefined from '@/assets/illustrations/ui/doctors/undefined.svg';
import FormGroup from '@/components/FormGroup';
import Button from '@/components/Button';
import { ButtonLink } from '@/components/Link';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import ImageLoader from '@/components/ImageLoader';
import PageLayout from '@/layouts/PageLayout';
import HeaderDefault from '@/components/HeaderDefault';
import ChangePasswordModal from './ChangePasswordModal';
import {
  CardProfile,
  PasswordSection,
  PasswordCreate,
  PasswordChange,
} from './styles';
import { getUserDisplayName } from '../../helpers';

const Profile: React.FC = () => {
  const { user } = useAuth();

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(
    false,
  );

  const fakePassword = '*'.repeat(12);

  const userAvatar = React.useMemo(() => {
    return user?.avatar_url || DoctorUndefined;
  }, [user]);

  const openChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  return (
    <>
      <ChangePasswordModal
        create={!user?.has_usable_password}
        isOpen={isChangePasswordModalOpen}
        onAfterClose={closeChangePasswordModal}
      />

      <PageLayout Nav={<HeaderDefault title="Perfil" />}>
        {user && (
          <CardProfile>
            <h1>Minha conta</h1>

            <ImageLoader src={userAvatar} alt={getUserDisplayName(user)} />

            <form>
              <FormGroup label="Nome" placeholder={user.name} readOnly />
              <FormGroup
                label="E-mail"
                placeholder={user.email || undefined}
                readOnly
              />
            </form>

            <PasswordSection>
              <h1>Segurança</h1>

              {user.has_usable_password ? (
                <PasswordChange>
                  <FormGroup
                    label="Senha"
                    type="password"
                    value={fakePassword}
                    readOnly
                    canAlterVisibility={false}
                  />
                  <ButtonLink onClick={openChangePasswordModal}>
                    Alterar senha
                  </ButtonLink>
                </PasswordChange>
              ) : (
                <PasswordCreate>
                  <p>Você ainda não adicionou uma senha.</p>
                  <Button theme="secondary" onClick={openChangePasswordModal}>
                    Criar senha
                  </Button>
                </PasswordCreate>
              )}
            </PasswordSection>
          </CardProfile>
        )}
      </PageLayout>
    </>
  );
};

export default Profile;
