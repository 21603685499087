import React from 'react';
import { FiX } from 'react-icons/fi';

import { Container, ModalTitle, ModalClose } from './styles';

export interface ModalHeaderProps {
  title: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => {
  return (
    <Container>
      <ModalTitle>{title}</ModalTitle>
      <ModalClose onClick={onClose}>
        <FiX size={24} />
      </ModalClose>
    </Container>
  );
};

export default ModalHeader;
