import React from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { ReactComponent as UndoIcon } from '@/assets/undo.svg';
import colors from '@/design/colors';

import {
  ButtonContainer,
  CircularProgressContainer,
  CircularProgressLabel,
  DefaultLabel,
  UndoButton,
  UndoHelpText,
  WaitingLabel,
} from './styles';

interface Props extends React.PropsWithChildren {
  timeoutInSeconds: number;
  helpText: string;
  onTimerEnd?: () => void;
  onTimerStart?: () => void;
  onTimerCancel?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const UndoableButton: React.FC<Props> = ({
  timeoutInSeconds,
  helpText,
  onTimerEnd,
  onTimerStart,
  onTimerCancel,
  disabled = false,
  children,
  className,
  style,
}) => {
  const [waiting, setWaiting] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(timeoutInSeconds);
  const intervalRef = React.useRef<NodeJS.Timeout>();

  const stopCount = React.useCallback(() => {
    setWaiting(false);
    setTimeLeft(timeoutInSeconds);
    clearInterval(intervalRef.current);
  }, [timeoutInSeconds]);

  const handleUndo = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      stopCount();
      if (onTimerCancel) {
        onTimerCancel();
      }
    },
    [onTimerCancel, stopCount],
  );

  React.useEffect(() => {
    if (waiting) {
      intervalRef.current = setInterval(() => setTimeLeft(t => t - 1), 1000);
      if (onTimerStart) {
        onTimerStart();
      }
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [onTimerStart, waiting]);

  React.useEffect(() => {
    if (timeLeft <= 0) {
      if (onTimerEnd) {
        onTimerEnd();
      }
      stopCount();
    }
  }, [onTimerEnd, stopCount, timeLeft]);

  return (
    <ButtonContainer
      className={className}
      style={style}
      waiting={waiting}
      onClick={() => {
        if (!disabled) {
          setWaiting(true);
        }
      }}
      disabled={disabled}
    >
      <SwitchTransition>
        <CSSTransition
          key={waiting ? 'waiting' : 'not-waiting'}
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          unmountOnExit
        >
          {waiting ? (
            <WaitingLabel>
              <CircularProgressContainer>
                <CircularProgressbarWithChildren
                  background
                  counterClockwise
                  value={(timeLeft / timeoutInSeconds) * 100}
                  styles={buildStyles({
                    backgroundColor: colors.whiteAlpha100,
                    pathColor: colors.purple100,
                    strokeLinecap: 'butt',
                    trailColor: colors.white,
                  })}
                >
                  <CircularProgressLabel>{timeLeft}</CircularProgressLabel>
                </CircularProgressbarWithChildren>
              </CircularProgressContainer>

              {helpText && <UndoHelpText>{helpText}</UndoHelpText>}

              <UndoButton onClick={handleUndo}>
                <UndoIcon />
                Desfazer
              </UndoButton>
            </WaitingLabel>
          ) : (
            <DefaultLabel>{children}</DefaultLabel>
          )}
        </CSSTransition>
      </SwitchTransition>
    </ButtonContainer>
  );
};

export default UndoableButton;
