import mermaid from 'mermaid';
import SvgPanZoom from 'svg-pan-zoom';

import colors from '@/design/colors';

interface ProtocoloFlowchartProps {
  svgId: string;
  mermaidElem: HTMLElement;
  content: string;
  percentageStep?: number;
  panZoomOptions?: SvgPanZoom.Options;
}

function nextMultipleOf(value: number, multipleOf: number): number {
  if (value % multipleOf) {
    return value + multipleOf - (value % multipleOf);
  }

  return value + multipleOf;
}

function lastMultipleOf(value: number, multipleOf: number): number {
  if (value % multipleOf) {
    return value - (value % multipleOf);
  }

  return value - multipleOf;
}

export class ProtocoloFlowchart {
  /**
   * Elemento HTML referente a div MermaidJS onde o SVG será colocado
   */
  mermaidElem: HTMLElement;

  /**
   * Id HTML do elemento SVG que será criado/manipulado
   */
  svgId: string;

  /**
   * Texto estilo markdown usado pelo MermaidJS
   */
  content: string;

  // Valor da aproximação (em procentagem)
  percentageStep: number;

  /**
   * Configurações de pan e zoom (manilupados pela lib svg-pan-zoom)
   *
   * https://github.com/bumbu/svg-pan-zoom#how-to-use
   */
  panZoomOptions: SvgPanZoom.Options;

  /**
   * Instância do manipulador de pan e zoom, criado após chamada do método
   * `.render()`
   */
  private panZoom?: SvgPanZoom.Instance;

  /**
   * Escala inicial de zoom calculada durante a centralização do passo corrente,
   * ou seja, após chamada do método `.centerOnPassoCorrente()`
   */
  private initialZoomScale?: number;

  constructor({
    mermaidElem,
    svgId,
    content,
    percentageStep = 20,
    panZoomOptions = {},
  }: ProtocoloFlowchartProps) {
    this.mermaidElem = mermaidElem;
    this.svgId = svgId;
    this.content = content;
    this.percentageStep = percentageStep;
    this.panZoomOptions = panZoomOptions;

    this.insertSvg = this.insertSvg.bind(this);
  }

  public render(): void {
    mermaid.render(this.svgId, this.content, this.insertSvg, this.mermaidElem);
  }

  public centerOnPassoCorrente(): void {
    const { realZoom, width, height } = this.panZoomInstance.getSizes();

    const selector = `#${this.svgId} .passo-corrente`;
    const passoCorrenteElem = document.querySelector<SVGGElement>(selector);

    // Recupera o atributo transform do elemento (ex: translate(x,y)) para pegar
    // as posições x e y e assim centralizar o gráfico na posição correta
    const translateString = passoCorrenteElem
      ? passoCorrenteElem.getAttribute('transform')
      : null;

    if (translateString) {
      const [x, y] = translateString
        .replace(/[^0-9\-.,]/g, '')
        .split(',')
        .map(val => parseFloat(val));

      // Posiciona o elemento no centro do gráfico
      this.panZoomInstance.pan({
        x: -(x * realZoom) + width / 2,
        y: -(y * realZoom) + height / 2,
      });

      /**
       * Faz o zoom proporcional ao tamanho do gráfico. Quanto maior o tamanho
       * do fluxograma, maior o zoom a ser aplicado.
       *
       * Fluxogramas grandes possuem valores baixos de `realZoom`, por isso a
       * relação inversamente proporcional y = c/x
       */
      if (!this.initialZoomScale) {
        const calcScale = Math.round((0.7 / realZoom) * 100);
        this.initialZoomScale = nextMultipleOf(calcScale, 20) / 100;
      }

      this.panZoomInstance.zoom(this.initialZoomScale);
    }
  }

  public zoomIn(): void {
    const nextZoom = nextMultipleOf(this.currentZoomPercentage, 20) / 100;
    this.panZoomInstance.zoom(nextZoom);
  }

  public zoomOut(): void {
    const nextZoom = lastMultipleOf(this.currentZoomPercentage, 20) / 100;
    this.panZoomInstance.zoom(nextZoom);
  }

  private insertSvg(
    svgCode: string,
    bindFunctions?: (el: Element) => void,
  ): void {
    this.mermaidElem.innerHTML = svgCode;
    this.enablePanzoom();
    this.decreaseArrowHeadSize();
    if (bindFunctions) {
      bindFunctions(this.mermaidElem);
    }
  }

  private enablePanzoom() {
    this.panZoom = SvgPanZoom(`#${this.svgId}`, this.panZoomOptions);
    this.centerOnPassoCorrente();
  }

  private decreaseArrowHeadSize(): void {
    // Aplicação de estilo extra: Diminui o tamanho da cabeça das setas
    // destacadas (sequências executadas) geradas pelo MermaidJS, deixando-as do
    // mesmo tamanho, proporcionalmente, que as normais
    const prefix = `#${this.svgId}`;
    const selector =
      '.edgePath .path[style=" stroke-width: 3.5px;fill:none"] + defs > marker';

    const arrowHeadList = document.querySelectorAll<SVGMarkerElement>(
      `${prefix} ${selector}`,
    );

    arrowHeadList.forEach(arrow => {
      arrow.setAttribute('markerWidth', '5');
      arrow.setAttribute('markerHeight', '3');
    });
  }

  public get panZoomInstance(): SvgPanZoom.Instance {
    if (!this.panZoom) {
      throw new Error(
        'You should call `.render()` option before attempting to access `.panZoomInstance`.',
      );
    }

    return this.panZoom;
  }

  public get currentZoomPercentage(): number {
    return Math.round(
      parseFloat(this.panZoomInstance.getZoom().toFixed(2)) * 100,
    );
  }

  static initialize(): void {
    mermaid.initialize({
      startOnLoad: false,
      fontFamily: 'Source Sans Pro',
      flowchart: {
        curve: 'basis',
        useMaxWidth: false,
        nodeSpacing: 100,
        diagramPadding: 16,
      },
      /**
       * A propriedade `themeCSS` ainda não foi adicionada em `@types/mermaid`,
       * porém ela está presente na documentação.
       *
       * https://mermaid-js.github.io/mermaid/#/Setup?id=theme
       *
       * Foi necessário desabilitar a checagem de erros do TypeScript nesta
       * linha.
       */

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      themeCSS: `
          .node circle,
          .node rect {
            fill: ${colors.white};
            stroke: ${colors.purple100};
            stroke-width: 1px;
          }
          .node .label {
            color: ${colors.purple100};
          }

          .node.passo-executado circle,
          .node.passo-executado rect {
            fill: ${colors.purple100};
          }
          .node.passo-executado .nodeLabel {
            color: ${colors.white};
          }

          .node.passo-corrente > rect {
            fill: ${colors.darkBlue100};
            stroke: ${colors.darkBlue100};
          }
          .node.passo-corrente .nodeLabel {
            color: ${colors.white};
          }

          .marker.flowchart{
            fill: ${colors.purple100};
            stroke: none;
          }
          .edgePaths path {
            stroke: ${colors.purple100};
          }

          .edgePath .path {
            stroke: ${colors.purple100};
          }
          .edgePath .arrowheadPath {
            fill: ${colors.purple100};
          }

          .edgeLabel {
            background-color: ${colors.white};
            color: ${colors.purple100};
          }
          .edgeLabel rect {
            opacity: 1;
            fill: ${colors.white};
          }
          .edgeLabel .nodeLabel span {
            color: ${colors.purple100};
          }
        `,
    });
  }
}

export default ProtocoloFlowchart;
