import React from 'react';
import { Rect, Circle, Box } from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['width'];
}

function Placeholder(
  Element: React.ComponentType<React.HTMLAttributes<HTMLSpanElement>>,
  defaultProps: {
    width: React.CSSProperties['width'];
    height: React.CSSProperties['width'];
  },
): React.FC<Props> {
  return ({
    style,
    children,
    width = defaultProps?.width,
    height = defaultProps?.height,
    ...htmlProps
  }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Element style={{ ...style, width, height }} {...htmlProps}>
      {children}
    </Element>
  );
}

export default {
  Box,
  Rect: Placeholder(Rect, { height: 23, width: '100%' }),
  Circle: Placeholder(Circle, { width: 32, height: 32 }),
};
