import React, { useState, useMemo, useCallback, useEffect } from 'react';

import SelectCheckboxIntegracao from '@/features/enterprise/components/SelectCheckboxIntegracao';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import { Content, Wrapper } from './styles';

interface OrientacoesProps {
  orientacoes: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaOrientacoesFhir: React.FC<OrientacoesProps> = ({
  orientacoes,
  passo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: orientacoes, passoExecutado: passo });

  const [
    { orientacoesStatus },
    ,
    { updateOrientacoesStatus },
  ] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map((orientacao, index) => {
      const option = {
        key: `${index}`,
        title: orientacao.titulo,
        detail: orientacao.descricao,
        categoria: orientacao.categoria,
        value: orientacao.id,
        pedido: orientacao.pedido,
      } as OptionIntegracao;

      return toOptionIntegracao(option);
    });
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback((opts: OptionIntegracao[]) => {
    setSelectedOptions(opts);
  }, []);

  useEffect(() => {
    updateOrientacoesStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions) {
      changeIntegrationStatus(selectedOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Wrapper>
      <Content>
        <SelectCheckboxIntegracao
          type="Orientacao"
          options={options}
          generalStatus={passo.executado ? 'disabled' : orientacoesStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaOrientacoesFhir;
