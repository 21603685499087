import styled from 'styled-components';

import {
  CheckboxItem,
  CheckboxItemCheck,
  CheckboxItemTitle,
} from '../SelectCheckbox/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioItemTitle = styled(CheckboxItemTitle)`
  margin-left: 16px;
`;

export const RadioItemCheck = styled(CheckboxItemCheck)`
  border-radius: 50%;

  span {
    border-radius: 50%;
  }
`;

export const RadioItem = styled(CheckboxItem)`
  align-items: center;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;
