import styled from 'styled-components';

import Search from '@/components/Search';
import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import Dropdown from '@/components/Dropdown';
import shadows from '@/design/shadows';

import { TipoLabel } from '../TipoArtefatoLabel/styles';

export const FormControlSearch = styled(Search)``;

export const TipoArtefatoDropdown = styled(Dropdown)`
  min-width: 0;

  @media (max-width: 1050px) {
    > button {
      ${TipoLabel} {
        display: none;
      }
    }
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  padding: 16px 12px;

  color: ${colors.blackAlpha100};
  background-color: ${colors.gray50};

  border: 1px solid ${colors.gray100};
  border-radius: 8px;

  ${HeadingSmall(16)};

  svg {
    width: 16px;
    height: 16px;
  }
  :active:not(:disabled),
  :focus:not(:disabled),
  :hover:not(:disabled) {
    background-color: ${colors.purple50};
    color: ${colors.purple100};
    border-color: ${colors.purple100};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 44px;
  border-radius: 8px;

  ${DropdownButton} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    height: 100%;
  }

  &:focus-within {
    box-shadow: ${shadows.smallShadow};

    ${DropdownButton} {
      border-color: ${colors.purple100};
    }
  }

  ${FormControlSearch} {
    flex: 1;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
      padding-left: 34px;
      padding-right: 42px;
    }

    svg {
      left: 8px;
      top: 13px;
    }
  }
`;
