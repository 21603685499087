import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { User } from '@/models/User';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import AuthSetupPreloader from '../AuthSetupPreloader';

interface Props extends React.PropsWithChildren {
  canActivate: (user: User | null) => boolean;
  redirectToIfFails?: string;
  location?: RouteComponentProps['location'];
}

const AuthGuard: React.FC<Props> = ({
  redirectToIfFails = '/entrar',
  canActivate,
  children,
  location,
}) => {
  const { user } = useAuth();

  const Guarded = React.useMemo(() => {
    if (canActivate(user)) {
      return children;
    }

    let next;
    if (location) {
      next = location.pathname + location.search + location.hash;
    }

    return <Redirect to={{ pathname: redirectToIfFails, state: { next } }} />;
  }, [canActivate, children, location, redirectToIfFails, user]);

  return <AuthSetupPreloader>{Guarded}</AuthSetupPreloader>;
};

export default AuthGuard;
