import React from 'react';
import { NavLink } from 'react-router-dom';

import calculadoraIcon from '@/assets/calculadora-icon.svg';
import { Calculadora } from '@/models/Calculadora';

import { Container } from './styles';

type CalculadoraProps = {
  calculadora: Calculadora;
};

const CalculadoraItem: React.FC<CalculadoraProps> = ({ calculadora }) => {
  return (
    <NavLink to={`/calculadoras/${calculadora.codigo}`}>
      <Container>
        <img src={calculadoraIcon} alt="" srcSet="" />

        <span>{calculadora.nome}</span>
      </Container>
    </NavLink>
  );
};

export default CalculadoraItem;
