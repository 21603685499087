import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import ButtonSmall from '../ButtonSmall';

export const ListFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ListTitle = styled.legend`
  ${HeadingSmall(14)};

  color: ${colors.blackAlpha200};

  margin-bottom: 8px;
`;

export const ListInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ListInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > :first-child {
    flex: 1;
  }
`;

export const ListInputDelete = styled(ButtonSmall)`
  margin-left: 5px;
`;
