import React from 'react';
import { BsX } from 'react-icons/bs';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { naturalTime } from '@/utils/datetime';

import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

import PageLayout from '@/layouts/PageLayout';

import ButtonArea from '@/components/ButtonArea';
import HeaderDefault from '@/components/HeaderDefault';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';

import ExecucaoAPI from '@/features/execucao/services/ExecucaoAPI';
import GuidelineFinalStep from '@/features/execucao/components/GuidelineFinalStep';
import HeaderBarProtocoloExecutado from '@/features/execucao/components/HeaderBarProtocoloExecutado';
import HeaderTitleProtocoloExecutado from '@/features/execucao/components/HeaderTitleProtocoloExecutado';
import ProtocoloExecutadoGuideline from '@/features/execucao/components/ProtocoloExecutadoGuideline';
import PlaceholderGuidelineStep from '@/features/execucao/components/PlaceholderGuidelineStep';
import PlaceholderHeaderTitle from '@/features/execucao/components/PlaceholderHeaderTitle';
import ProtocoloExecutadoTagForm from '@/features/execucao/components/ProtocoloExecutadoTagForm';
import useSettings from '@/features/enterprise/hooks/useSettings';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

const PageHistoryHeader: React.FC = () => {
  const history = useHistory();

  const { state } = useLocation<ProtocoloExecutado | undefined | null>();
  const [{ protocoloExecutado }] = useExecucaoContext();

  const protocolo = React.useMemo(() => protocoloExecutado || state, [
    protocoloExecutado,
    state,
  ]);

  return (
    <HeaderDefault
      Title={
        <LoadingSwitchTransition
          loading={!protocolo}
          LoadingComponent={<PlaceholderHeaderTitle />}
        >
          {protocolo && <HeaderTitleProtocoloExecutado protocolo={protocolo} />}
        </LoadingSwitchTransition>
      }
    >
      {protocolo && (
        <HeaderBarProtocoloExecutado
          protocoloExecutado={protocolo}
          helperInfo={
            protocolo.data_execucao
              ? naturalTime(protocolo.data_execucao, 'Executado')
              : naturalTime(protocolo.data_criacao, 'Iniciado')
          }
          Right={
            <ButtonArea onClick={() => history.replace('/historicos')}>
              <BsX size={32} />
            </ButtonArea>
          }
        />
      )}
    </HeaderDefault>
  );
};

const PageHistoryDetail: React.FC = () => {
  const history = useHistory();
  const { id: executionID } = useParams<{ id: string }>();

  const { tags: definedTags } = useSettings();
  const [{ protocoloExecutado }] = useExecucaoContext();

  const [
    ,
    { dispatchSetExecucaoResumo, dispatchEncerrarProtocolo },
  ] = useExecucaoContext();

  const [loading, setLoading] = React.useState(true);

  const instanceTags = React.useMemo(() => {
    if (protocoloExecutado) {
      return protocoloExecutado.tags;
    }

    return [];
  }, [protocoloExecutado]);

  const InitialStep = React.useMemo(() => {
    if (definedTags.length) {
      return (
        <ProtocoloExecutadoTagForm
          disabled
          questionnaire={definedTags}
          response={instanceTags}
        />
      );
    }

    return undefined;
  }, [definedTags, instanceTags]);

  const FinalStep = React.useMemo(
    () => (
      <GuidelineFinalStep
        title="Protocolo finalizado."
        buttonTitle="Sair do histórico"
        onButtonClick={() => history.replace('/historicos')}
      />
    ),
    [history],
  );

  React.useEffect(() => {
    async function loadProtocolo() {
      try {
        const resume = await ExecucaoAPI.getExecucaoResumo(executionID);
        dispatchSetExecucaoResumo(resume);
      } catch {
        history.replace('/historicos');
      } finally {
        setLoading(false);
      }
    }

    loadProtocolo();

    return () => {
      dispatchEncerrarProtocolo();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout Nav={PageHistoryHeader}>
      <LoadingSwitchTransition
        loading={loading}
        style={{ flex: 1 }}
        LoadingComponent={<PlaceholderGuidelineStep />}
      >
        <ProtocoloExecutadoGuideline
          mode="read-only"
          InitialStep={InitialStep}
          FinalStep={FinalStep}
        />
      </LoadingSwitchTransition>
    </PageLayout>
  );
};

export default PageHistoryDetail;
