/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from 'react';

import { Container } from './styles';

type ImageLoaderProps = React.ImgHTMLAttributes<HTMLImageElement>;

const ImageLoader: React.FC<ImageLoaderProps> = ({
  ...imageProps
}: ImageLoaderProps) => {
  const [imageStatus, setImageStatus] = useState<
    'loaded' | 'failed' | 'loading'
  >('loading');

  const onImageLoaded = useCallback(() => {
    setImageStatus('loaded');
  }, []);

  const onImageError = useCallback(() => {
    setImageStatus('failed');
  }, []);

  return (
    <Container imageStatus={imageStatus}>
      <span>
        <img {...imageProps} onLoad={onImageLoaded} onError={onImageError} />
      </span>
    </Container>
  );
};

export default ImageLoader;
