/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Option, LinkItem } from './styles';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean;
};

const BoxMenuOption: React.FC<Props> = ({
  disabled = false,
  ...anchorProps
}) => {
  return (
    <Option>
      <LinkItem disabled={disabled} {...anchorProps} />
    </Option>
  );
};

export default BoxMenuOption;
