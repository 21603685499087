import React, { useMemo, useState } from 'react';

import MedflowLogo from '@/components/Logo/MedflowLogo';
import NewPasswordForm from './NewPasswordForm';
import NewPasswordSuccess from './NewPasswordSuccess';
import NewPasswordFailure from './NewPasswordFailure';
import {
  Container,
  NewPasswordBox,
  NewPasswordHeader,
  NewPasswordBody,
} from './styles';

const NewPassword: React.FC = () => {
  const [step, setStep] = useState<'form' | 'success' | 'failure'>('form');

  const Component = useMemo(() => {
    switch (step) {
      case 'form':
        return (
          <NewPasswordForm
            onSuccess={() => setStep('success')}
            onFailure={() => setStep('failure')}
          />
        );
      case 'success':
        return <NewPasswordSuccess />;
      case 'failure':
        return <NewPasswordFailure />;
      default:
        return null;
    }
  }, [step]);

  return (
    <Container>
      <NewPasswordBox>
        <NewPasswordHeader>
          <MedflowLogo color="primary" version="primary" />
        </NewPasswordHeader>
        <NewPasswordBody>{Component}</NewPasswordBody>
      </NewPasswordBox>
    </Container>
  );
};

export default NewPassword;
