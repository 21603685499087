import styled from 'styled-components';
import colors from '@/styles/colors';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.secondaryLite};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3<{ highlight?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s ease;
  color: ${props => (props.highlight ? colors.black : colors.hardGray)};
`;

export const CollapsedContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

export const Description = styled.p`
  color: ${colors.hardGray};
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
`;

export const Actions = styled.div`
  display: flex;
`;
