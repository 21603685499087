import styled, { css, keyframes } from 'styled-components';

import IconButton from '@/components/IconButton';
import { CheckboxItemCheck } from '@/components/SelectCheckbox/styles';
import designColors from '@/design/colors';
import styledColors from '@/styles/colors';

export const CheckboxDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxDetailItemTitle = styled.strong`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  /* --color-secondary */

  color: #75777f;
  margin-left: 16px;
`;

export const OptionContentDetail = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  /* Black Alpha 100 */

  color: #666666;

  margin-left: 40px;
`;

export const ButtonTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  align-items: stretch;

  > div:last-child {
    width: 100%;
    padding: 16px 16px 16px 0px;
    border: none;

    /* > div:first-child { */
    /* padding: 0px; */
    /* align-items: center; */
    /* } */

    > h3 {
      padding: 0px;
    }
  }
`;

const defaultStyle = css`
  opacity: 1;
  color: ${styledColors.secondaryHard};
  background-color: ${styledColors.white};

  ${ButtonTitle} {
    > div:last-child {
      color: ${styledColors.secondaryHard};
      background-color: ${styledColors.white};
    }
  }
`;

const selectedStyle = css`
  background-color: ${styledColors.backgroundColor};
  color: ${styledColors.black};

  ${ButtonTitle} {
    > div:last-child {
      background-color: ${styledColors.backgroundColor};
      color: ${styledColors.black};
    }
  }
`;

const solicitadoStyle = css`
  background-color: ${styledColors.backgroundColor};
  color: ${styledColors.gray};

  ${ButtonTitle} {
    > div:last-child {
      background: ${styledColors.backgroundColor};
    }
  }
`;

const rejeitadoStyle = css`
  background-color: ${styledColors.backgroundColor};
  color: ${styledColors.black};
  border: 1px solid #eb3b5a;

  ${ButtonTitle} {
    > div:last-child {
      background-color: ${styledColors.backgroundColor};
      color: ${styledColors.black};
    }
  }
`;

const descartadoStyle = css`
  background-color: ${styledColors.white};
  color: ${styledColors.secondaryLite};
  border: 1px solid ${styledColors.backgroundColor};

  ${CheckboxItemCheck} {
    border: 1px solid ${styledColors.backgroundColor};

    span {
      display: none;
    }
  }

  ${ButtonTitle} {
    > div:last-child {
      color: ${styledColors.secondaryLite};
    }
  }
`;

const getStyle = (props: CheckboxItemProps) => {
  if (props.status && props.status === 'solicitado') {
    return solicitadoStyle;
  }
  if (
    (props.status && props.status === 'rejeitado') ||
    (props.error && props.selected)
  ) {
    return rejeitadoStyle;
  }

  if (props.status && props.status === 'descartado') {
    return descartadoStyle;
  }

  if (props.disabled || (props.status && props.status === 'disabled')) {
    return descartadoStyle;
  }

  if (props.selected) {
    return selectedStyle;
  }

  return defaultStyle;
};

interface CheckboxItemProps {
  status?: string;
  selected: boolean;
  disabled: boolean;
  included: boolean;
  error: boolean;
}

export const CheckboxDetailItem = styled.div<CheckboxItemProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: ${props =>
    props.included
      ? `2px dashed ${designColors.gray100}`
      : `1px solid ${styledColors.secondaryLite}`} !important;
  border-radius: 4px;
  text-align: start;

  width: 100%;
  transition: color 0.3s ease, box-shadow 0.3s ease-in-out;

  &:hover:not(:disabled),
  &:focus-visible {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${getStyle}
`;

export const CheckboxItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 16px;
`;

export const CheckboxDetailItemCheck = styled(CheckboxItemCheck)`
  margin-top: 8px;
  span {
    border-radius: 2px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  margin-left: 8px;

  img {
    animation: ${rotate} 600ms linear infinite;
    display: block;
  }
`;

export const TitleIconButton = styled(IconButton)``;

export const ButtonContent = styled.div``;

export const OptionContent = styled.div`
  z-index: 1;
  display: block;
  overflow: hidden;
  background-color: transparent;
  /* padding: 0 16px 16px 16px; */
  transition: max-height 400ms ease-in,
    transform 400ms cubic-bezier(0, 1, 0.6, 1) 100ms,
    opacity 200ms ease-out 100ms;

  width: 100%;

  &.enter {
    opacity: 0;
    transform: translateY(-50%);
    max-height: 0;
  }

  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    max-height: 100vh;
  }

  &.exit {
    opacity: 1;
    transform: translateY(0);
    max-height: 100vh;
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-50%);
    max-height: 0;
  }
`;
