import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { Text } from '@/design/typography';

import ButtonSmall from '../ButtonSmall';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const SearchIcon = styled(FiSearch).attrs({ size: 18 })`
  color: ${colors.gray200};
  position: absolute;
  left: 16px;
  top: 15px;

  transition: color 100ms ease-in;
`;

export const Input = styled.input`
  flex: 1;
  padding: 12px 58px;
  color: ${colors.blackAlpha200};
  ${Text(16)};

  border-radius: 8px;
  border: 1px solid ${colors.gray100};

  &::placeholder {
    color: ${colors.gray200};
  }

  &:focus:not(:disabled) {
    border-color: ${colors.purple100};
    box-shadow: ${shadows.smallShadow};

    & + ${SearchIcon} {
      color: ${colors.purple100};
    }
  }
`;

export const ClearButton = styled(ButtonSmall)`
  position: absolute;
  right: 16px;
  top: 10px;
`;
