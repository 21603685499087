import Header from '@/components/Header';
import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import PlaceholderLoading from '@/components/PlaceholderLoading';
import HeaderTitleProtocoloExecutado from '@/features/execucao/components/HeaderTitleProtocoloExecutado';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import React from 'react';

const PlaceholderTitle = () => (
  <PlaceholderLoading.Rect
    style={{ display: 'flex' }}
    width={288}
    height={30}
  />
);

const EnterpriseHeaderProtocoloExecutado: React.FC = () => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const Title = React.useMemo(() => {
    return (
      <LoadingSwitchTransition
        loading={!protocoloExecutado}
        LoadingComponent={<PlaceholderTitle />}
      >
        {protocoloExecutado && (
          <HeaderTitleProtocoloExecutado protocolo={protocoloExecutado} />
        )}
      </LoadingSwitchTransition>
    );
  }, [protocoloExecutado]);

  return <Header Title={Title} />;
};

export default EnterpriseHeaderProtocoloExecutado;
