import { useCallback, useEffect, useState } from 'react';
import {
  FhirArtefatoType,
  GeneralStatusType,
  IntegrationDispatchType,
} from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';

import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import { parseOptionIntegracao } from '../helpers/integracao';

type IntegrationDataType = FhirArtefatoType;

type IntegrationDataTypeArray = FhirArtefatoType[];

interface UseIntegrationArgs {
  items: IntegrationDataTypeArray;
  passoExecutado: PassoExecutado;
}

interface UseIntegrationReturnProps {
  integrationItems: IntegrationDataTypeArray;
  integrationStatus: GeneralStatusType;
  integrationTotalSelected: number;
  changeIntegrationStatus: (
    totalSelected: number,
    totalEnabled?: number,
  ) => void;
  dispatchIntegrationData: (
    data: IntegrationDispatchType[],
    loading?: boolean,
  ) => void;
  toOptionIntegracao: (option: OptionIntegracao) => OptionIntegracao;
}

const useIntegration = ({
  items,
  passoExecutado,
}: UseIntegrationArgs): UseIntegrationReturnProps => {
  const [
    integrationItems,
    setIntegrationItems,
  ] = useState<IntegrationDataTypeArray>([]);

  const [integrationStatus, setIntegrationStatus] = useState<GeneralStatusType>(
    'none_selected',
  );

  const [
    integrationTotalSelected,
    setIntegrationTotalSelected,
  ] = useState<number>(0);

  const changeIntegrationStatus = useCallback(
    (selectedItemsTotal: number, integrationItemsTotal?: number) => {
      if (integrationStatus !== 'done' && integrationStatus !== 'disabled') {
        const totalItens = integrationItemsTotal || integrationItems.length;
        if (selectedItemsTotal !== 0 && totalItens === selectedItemsTotal)
          setIntegrationStatus('all_selected');
        if (selectedItemsTotal !== 0 && totalItens > selectedItemsTotal)
          setIntegrationStatus('some_selected');
        if (selectedItemsTotal === 0) setIntegrationStatus('none_selected');
      }
    },
    [integrationItems, integrationStatus],
  );

  const dispatchIntegrationData = useCallback(
    async (data: IntegrationDispatchType[], loading = true) => {
      if (loading) {
        setIntegrationStatus('loading');
      }

      const solicitacoes = [...data.map(item => item.id as number)];

      try {
        const {
          results: integrationResponse,
        } = await IntegracaoService.requestSolicitarArtefatos(
          passoExecutado.id,
          { solicitacoes },
        );

        const integrationData = integrationResponse.filter(item =>
          solicitacoes.includes(item.id),
        );

        setIntegrationItems(prev => {
          const merged = prev.map(itm => ({
            ...itm,
            ...integrationData.find(item => item.id === itm.id && item),
          }));

          return merged;
        });

        setIntegrationTotalSelected(
          integrationData.filter(
            (opt: IntegrationDataType) =>
              opt.pedido.status === 'completed' ||
              opt.pedido.status === 'active',
          ).length,
        );

        setIntegrationStatus(
          integrationData.every(
            (opt: IntegrationDataType) =>
              opt.pedido.status === 'entered-in-error',
          )
            ? 'error'
            : 'done',
        );
      } catch (error) {
        setIntegrationStatus('error');
      }
    },
    [passoExecutado.id],
  );

  const toOptionIntegracao = useCallback(
    (option: OptionIntegracao) => parseOptionIntegracao(option),
    [],
  );

  useEffect(() => {
    if (items) {
      setIntegrationItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    const status =
      (integrationItems.length &&
        integrationItems.some(
          item =>
            item.pedido.status === 'completed' ||
            item.pedido.status === 'active',
        )) ||
      passoExecutado.executado
        ? 'disabled'
        : 'none_selected';

    setIntegrationStatus(status);
  }, [integrationItems, passoExecutado.executado]);

  return {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    dispatchIntegrationData,
    toOptionIntegracao,
  };
};

export default useIntegration;
