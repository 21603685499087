import React, { useCallback, useMemo } from 'react';

import Option from '@/types/Option';
import {
  InputPergunta,
  InputPerguntaProps,
} from '@/features/execucao/components/PerguntaDetail/types';
import { useCalculadoraContext } from '@/features/calculadoras/contexts/CalculadoraContext';

import SelectCheckbox from '@/components/SelectCheckbox';

const InputMultiplaEscolha: InputPergunta = ({
  pergunta,
  disabled,
  onChange,
}: InputPerguntaProps) => {
  const { payloadCalculadora } = useCalculadoraContext().state;

  const selectedOptionsId = useMemo(() => {
    return (payloadCalculadora[pergunta.variavel.nome] as Array<number>) || [];
  }, [payloadCalculadora, pergunta]);

  const options = useMemo(() => {
    return pergunta.variavel.opcoes.map(opt => ({
      key: opt.nome,
      title: opt.descricao,
      value: opt.id,
      selected: !!selectedOptionsId.find(id => opt.id === id),
    }));
  }, [pergunta, selectedOptionsId]);

  const handleChange = useCallback(
    (selectedOptions: Option[]) => {
      if (onChange) {
        onChange({
          [pergunta.variavel.nome]: selectedOptions.map(
            opt => opt.value as number,
          ),
        });
      }
    },
    [onChange, pergunta],
  );

  return (
    <SelectCheckbox
      options={options}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

InputMultiplaEscolha.defaultProps = {
  disabled: false,
  onChange: undefined,
};

export default InputMultiplaEscolha;
