import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall, Text } from '@/design/typography';
import { PageContentWrapper } from '@/layouts/PageLayout/styles';

export const HeaderNav = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;

  position: sticky;
  top: 0px;
  left: 0px;

  background-color: ${colors.white};

  /* Separator */
  border-bottom: 1px solid ${colors.gray100};

  /* Minimum Shadows */
  filter: drop-shadow(${shadows.minimumShadows});
`;

export const HeaderContent = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 82px;

  column-gap: 16px;

  padding: 16px 24px;

  ${PageContentWrapper}
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    ${HeadingSmall(24)};
    color: ${colors.blackAlpha200};
  }

  h2 {
    ${Text(16)};
    color: ${colors.blackAlpha100};
    margin-top: -1px;
  }
`;
