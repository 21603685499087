import { Solicitacao } from '@/models/Solicitacao';

import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';
import api from '@/sevices/api';
import { TipoItemComplementar } from '../models';

interface AddItemOptions {
  tipo: TipoItemComplementar;
  resource: fhir4.FhirResource;
  protocolo: ProtocoloExecutado;
}

interface UpdateItemOptions {
  id: string;
  tipo: TipoItemComplementar;
  resource: fhir4.FhirResource;
}

async function create({
  tipo,
  resource,
  protocolo,
}: AddItemOptions): Promise<Solicitacao> {
  return (
    await api.post<Solicitacao>(
      `/protocolos-executados/${protocolo.id}/solicitacoes/`,
      {
        tipo,
        incluir_bundle: false,
        pedido: resource,
      },
    )
  ).data;
}

async function destroy(itemID: string): Promise<void> {
  await api.delete(`/solicitacoes/${itemID}/`);
}

async function update({
  id: itemID,
  tipo,
  resource,
}: UpdateItemOptions): Promise<Solicitacao> {
  return (
    await api.put<Solicitacao>(`/solicitacoes/${itemID}/`, {
      tipo,
      incluir_bundle: false,
      pedido: resource,
    })
  ).data;
}

export default {
  create,
  destroy,
  update,
};
